import React from 'react';

import { Routes } from './routes';
import { BrowserRouter } from 'react-router-dom';

import './App.less';

export const App = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);
