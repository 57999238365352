import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Skeleton, Space } from "antd";

import { ContentHeader } from "../../../../../components/ContentHeader";

import { usePendingRegistration } from "../../hooks/usePendingRegistration";

import { FormLayout } from "../../../../../utils/Styles";
import { CustomerInformation } from "./components/CustomerInformation";
import { SocialMedia } from "./components/SocialMedia";
import { LinkSetup } from "./components/LinkSetup";
import { StylesSetup } from "./components/StylesSetup";
import { useCompany } from "../../../../../hooks/useCompany";
import { CompanyService } from "../../services/CompanyService";
import { ErrorModal } from "../../../../../components/ErrorModal";
import { AxiosError } from "axios";
import { SuccessModal } from "../../../../../components/SuccessModal";
import { LogoAndCover } from "./components/LogoAndCover";

export const PendingCompanyRegistrationPage = () => {
  const [error, setError] = useState<string>();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const { uuid } = useParams();
  const { data, isFetching } = usePendingRegistration(uuid);
  const { create, isLoadingCreate: isLoadingCompanyCreation } = useCompany();
  const navigate = useNavigate();

  const handleSubmitForm = (values: any) => {
    const company = {
      ...values,
      coverUrl: data?.images.cover?.url,
      logoUrl: data?.images.logo.url,
      slug: values.menuUrl,
    };

    create({
      company,
      onSuccess: () => {
        if (uuid) {
          // TODO: this should go to the hook
          CompanyService.removePendingRegistration(uuid);
        }

        setIsFinished(true);
      },
      onError: (ex: any) => {
        const error = ex as AxiosError;

        if (error.response?.status === 409) {
          setError('Já existe uma loja com esse menu.');
        } else {
          setError('Ocorreu um erro ao tentar cadastrar a loja.');
        }
      }
    });
  }

  return (
    <>
      <ContentHeader title="Cadastro pendente" />

      {isFetching ? (
        <Skeleton />
      ) : (
        <Form
          {...FormLayout}
          layout="vertical"
          initialValues={data}
          onFinish={handleSubmitForm}
        >
          <CustomerInformation />

          <SocialMedia />

          <LogoAndCover
            coverUrl={data?.images.cover?.url}
            logoUrl={data?.images.logo.url || ''}
          />

          <LinkSetup />

          <StylesSetup />

          <Space style={{ marginTop: '20px' }}>
            <Button
              danger
              style={{ marginRight: '10px' }}
              onClick={() => navigate(-1)}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Cancelar
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingCompanyCreation}
            >
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />{' '}
              Cadastrar
            </Button>
          </Space>
        </Form>
      )}

      <ErrorModal
        error={error}
        isOpen={!!error}
        onClose={() => setError(undefined)}
        title="Error no cadastro"
      />

      <SuccessModal
        title="Cadastro realizado com sucesso!"
        isOpen={isFinished}
        onClose={() => navigate(-1)}
      />
    </>
  );
}