import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;

  .concluded {
    background-color: #66bb6a;
  }  

  .ready {
    background-color: #2f78b8;
  }  
  
  .canceled {
    background-color: #ef5350;
  }  

  .progress {
    background-color: orange;
  }  

  .pending {
    background-color: #edbf09;
  }  
  
  .transit {
    background-color: #9f2fb8;
  }  

  .payment {
    background-color: #FFA07A;
  }  

  .total {
    background-color: gray;
  }  
`;

export const CardCounter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  box-shadow: 2px 2px 10px #DADADA;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: .3s linear all;
`;

export const CountNumber = styled.span`
  font-size: 26px;
  font-weight: bold;
  color: #FFF;
  z-index: 10;
`;

export const CountValue = styled.span`
  color: #FFF;
  font-size: 14px;
  z-index: 10;
`;

export const CountName = styled.span`
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.9;
  text-transform: capitalize;
  z-index: 10;
`;
