import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import { useDebounce } from 'usehooks-ts'
import { SearchOutlined } from '@ant-design/icons';
import { useAddressAutocomplete } from '../../../../../../../hooks/useAddressAutocomplete';
import {
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { LoaderSpinner } from '../../../../../../../components/LoaderSpinner';

interface AddressInputProps {
  address?: string;
  autocomplete?: boolean;
  isCurrencyInEuro: boolean; // This is temp, while we don't have the internationalization
  onAddressSelected: (address: string) => void;
}

export const AddressInput = ({ address, autocomplete, isCurrencyInEuro, onAddressSelected }: AddressInputProps) => {
  const [isFieldEmpty, setIsFieldEmpty] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);
  const { data: addresses, isLoading, setInputAddressSearch } = useAddressAutocomplete(autocomplete);

  const onSelect = (value: string) => {
    onAddressSelected(value);
    setSelectedAddress(true);
  };

  const handleBlur = () => {
    setIsFieldEmpty(!selectedAddress);
  };

  useEffect(() => {
    if (autocomplete) {
      setInputAddressSearch(debouncedSearch);
    }
  }, [autocomplete, debouncedSearch, setInputAddressSearch]);

  return (
    <>
      {autocomplete ? (
        <AutoComplete
          options={addresses?.map((address) => ({ value: address }))}
          onSelect={onSelect}
          onSearch={setSearchText}
          onBlur={handleBlur}
          onClear={() => setSelectedAddress(false)}
        >
          <TextField
            className="form-general-input"
            helperText={isFieldEmpty ? 'É preciso selecionar o endereço na lista das sugestões' : ''}
            id="address"
            label={`Digite ${isCurrencyInEuro ? 'a sua morada' : 'o seu endereço'} e o número da casa`}
            type="text"
            variant="outlined"
            required
            error={isFieldEmpty}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    {isLoading ? (
                      <LoaderSpinner isSmall />
                    ) : (
                      <SearchOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </AutoComplete>
      ) : (
        <TextField
          className="form-general-input"
          id="address"
          label={`Digite ${isCurrencyInEuro ? 'a sua morada' : 'o seu endereço'} e o número da casa`}
          type="text"
          variant="outlined"
          value={address}
          required
          onChange={(e) => onAddressSelected(e.target.value)}
          error={isFieldEmpty}
        />
      )}
    </>
  );
};