import React from 'react';
import { Form, Switch } from 'antd';
import { FormHelper } from '../FormHelper';

type FormSwitchItemProps = {
  label: string;
  propertyId: string;
  helper?: string;
  defaultChecked: boolean;
  disabled?: boolean;
  checkedValueText?: string;
  uncheckedValueText?: string;
  handleCheckboxChange(checked: boolean, propertyId: string): void;
};

export const FormSwitchItem: React.FC<FormSwitchItemProps> = ({
  label,
  propertyId,
  helper,
  defaultChecked,
  disabled,
  checkedValueText,
  uncheckedValueText,
  handleCheckboxChange,
}) => {
  const isDisabled = disabled || false;
  return (
    <Form.Item
      label={<label className="label-input-field">{label}</label>}
      name={propertyId}
      help={helper && <FormHelper label={helper}/>}
    >
      <Switch
        id={propertyId}
        key={propertyId}
        checked={defaultChecked}
        checkedChildren={checkedValueText ?? 'Sim'}
        unCheckedChildren={uncheckedValueText ?? 'Não'}
        disabled={isDisabled}
        onChange={(checked) => handleCheckboxChange(checked, propertyId)}
      />
    </Form.Item>
  );
};
