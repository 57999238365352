import React, { useState } from "react";
import { Button } from "antd";
import { NotificationDispatcher, NotificationType } from "../../../../../../components/Notification";
import { useGetCompany, useLoggedUser } from "../../../../../../hooks";
import { Dropzone } from "../../../../../../components/Dropzone";
import { useUploadImage } from "../../../../../../hooks/useUploadImage";
import { useCompany } from "../../../../../../hooks/useCompany";

export const LogoUpload = () => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const { userLogged: { user: { company: companyId } } } = useLoggedUser();
  const { data: company, isFetching: isFetchingCompany } = useGetCompany(companyId);
  const { update, isLoadingUpdate } = useCompany();
  const { isLoading: isLoadingUploadImage, upload } = useUploadImage();
  const isLoading = isFetchingCompany || isLoadingUpdate || isLoadingUploadImage;

  const handleUpdateLogo = async () => {
    upload({
      file: imageFile,
      onSuccess: (logoUrl: string) => {
        update({
          company: { ...company, logoUrl },
          onSuccess: () => {
            NotificationDispatcher({
              message: 'Operação realizada com sucesso',
            });
          },
          onError: () => {
            NotificationDispatcher({
              message: 'Atenção',
              description: 'Erro ao tentar efetuar upload da imagem.',
              type: NotificationType.ERROR,
            });
          },
        });
      },
      onError: () => {
        NotificationDispatcher({
          message: 'Atenção',
          description: 'Erro ao tentar efetuar upload da imagem.',
          type: NotificationType.ERROR,
        });
      },
    });
  }

  return (
    <>
      <label className="label-input-field">Imagem da Logo</label>
      {!isFetchingCompany && (
        <div style={{ width: '30%' }}>
          <Dropzone
            imageUrl={company?.logoUrl}
            onFileUploaded={setImageFile} />
        </div>
      )}

      <Button
        loading={isLoading}
        disabled={imageFile === null}
        type="primary"
        style={{ marginTop: '16px' }}
        onClick={handleUpdateLogo}
      >
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Salvar
      </Button>
    </>
  );
}