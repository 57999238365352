import styled from 'styled-components';

interface TitleProps {
  isCollapsed?: boolean;
}

export const Title = styled.p<TitleProps>`
  margin: 20px 16px;
  display: ${(props) => props.isCollapsed ? 'none' : 'block'};
  font-weight: bold;
  color: white;
`;
