import React, { useState } from 'react';
import { Button, Drawer, Form, Space, Switch } from 'antd';

import api from '../../../../../../../services/api';
import { DeliveryFee } from '../../../../../../../models/DeliveryFee';
import { ActionType } from '../../../../../../../models/Enum';
import { FormInputField } from '../../../../../../../components/FormInputField';

interface DeliveryFeeDetailsProps {
  isVisible: boolean;
  companyFeatureId: string;
  companyId: string;
  currency: string;
  selectedDeliveryFee?: DeliveryFee;
  onClose(actiontype: ActionType, clientFare?: DeliveryFee): void;
}

export const DeliveryFeeDetails = ({
  isVisible,
  selectedDeliveryFee,
  companyId,
  companyFeatureId,
  currency,
  onClose,
}: DeliveryFeeDetailsProps) => {
  const [isLoading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    const formData = {
      ...values,
      price: (values.price < 0 || values.price === undefined) ? 0 : values.price,
    };

    if (selectedDeliveryFee) {
      api
        .put(
          `/api/company/${companyId}/features/clientFare/${selectedDeliveryFee._id}`,
          formData
        )
        .then((response) => {
          dismissPage(ActionType.UPDATE, response.data.data);
        })
        .catch((error) => console.error('Fail updating MenuOption', error));
    } else {
      formData.featureId = companyFeatureId;
      api
        .post(`/api/company/${companyId}/features/clientFare`, formData)
        .then((response) => {
          dismissPage(ActionType.ADD, response.data.data);
        })
        .catch((e) => console.error('Fail adding MenuOption', e));
    }
  };

  const dismissPage = (actiontype: ActionType, clientFare?: DeliveryFee) => {
    setLoading(false);
    onClose(actiontype, clientFare);
  };

  return (
    <Drawer
      title={selectedDeliveryFee ? 'Editar Frete' : 'Novo Frete'}
      onClose={() => dismissPage(ActionType.CANCEL)}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isVisible}
    >
      <Form
        layout="vertical"
        initialValues={selectedDeliveryFee}
        onFinish={onFinish}
      >
        {selectedDeliveryFee && (
          <Form.Item
            label={<label className="label-input-field">Disponibilidade</label>}
            name="isEnabled"
          >
            <Switch
              id="isEnabled"
              key="isEnabled"
              defaultChecked={selectedDeliveryFee.isEnabled}
              checkedChildren="Ativo"
              unCheckedChildren="Inativo"
            />
          </Form.Item>
        )}

        <FormInputField
          label="Destino/Zona"
          name="destiny"
          placeholder="Ex.: São Paulo"
          useDefaultRules
        />

        <FormInputField
          label="Valor"
          name="price"
          type="number"
          prefix={currency}
          placeholder="0.00"
        />

        <FormInputField
          label="Descrição"
          name="description"
          placeholder="Descrição"
          isInputArea={true}
          inputTextAreaProps={{ rows: 5 }}
        />

        <Space>
          <Button danger onClick={() => dismissPage(ActionType.CANCEL)}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button type="primary" htmlType="submit" loading={isLoading}>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};
