import api from './api';
import { Contact } from '../models/DataResponse';

export class ContactService {
  public static async update(contact: Contact): Promise<void> {
    return await api.put(`api/contacts/${contact._id}`, contact);
  }

  public static async delete(id: string): Promise<void> {
    return await api.delete(`api/contacts/${id}`);
  }
}
