import React from 'react';
import { MenuOption } from '../../../../models/MenuOption';
import { ContentHeader } from '../../../../components/ContentHeader';
import { Profile } from './components/Profile';

export const SettingsProfilePage = () => {
  return (
    <>
      <ContentHeader title={MenuOption.SETTINGS_PROFILE} />

      <Profile />
    </>
  );
};
