import React from "react";
import { FormInputField } from "../../../../../../../components/FormInputField";
import { Title } from "../../styles";

const menuNameValidationRules = [
  { required: true, message: 'Campo obrigatório' },
  { pattern: /^([a-z0-9-]+)$/, message: 'Permitido apenas letras, números e hífen. Exemplo: lanchonete-manu.' },
];

export const LinkSetup = () => {
  return (
    <>
      <Title style={{ marginTop: 50 }}>Configuração do link</Title>
      <FormInputField
        label="Nome do menu"
        name="menuUrl"
        placeholder="meu-menu"
        rules={menuNameValidationRules}
      />
    </>
  );
}