import { Product } from '../models/Product';
import api from './api';
import { CompanyActivityLogService } from './CompanyActivityLogService';
import { firebaseStorage } from './firebase';

export class ProductService {
  public static async create(product: Product): Promise<Product> {
    const response = await api.post('api/catalog', product);

    CompanyActivityLogService.register({ action: `Novo Produto: <strong>${product.name}</strong>`, actionArea: 'Produtos', extra: product._id });

    return response.data.data;
  }

  public static async update(product: Product): Promise<Product> {
    const headers = {
      company: product.company,
    };

    const response = await api.put(`api/catalog/${product._id}`, product, {
      headers,
    });

    CompanyActivityLogService.register({ action: `Produto <strong>${product.name}</strong> atualizada`, actionArea: 'Produtos', extra: product._id });

    return response.data.data;
  }

  public static async updatePositionsOnDatabase(
    companyId: string,
    updatedListPositions: Product[],
  ): Promise<void> {
    return await api.put(
      `/api/catalog/changepositions/${companyId}`,
      updatedListPositions
    );
  }

  public static async delete(product: Product): Promise<void> {
    if (!product || !product._id) {
      console.warn('Error trying to delete Product: id undefined');
      return;
    }

    if (product.imageUrl) {
      ProductService.removeImageFromFirebase(product.imageUrl);
    }

    // Remove from API
    CompanyActivityLogService.register({ action: `Produto <strong>${product.name}</strong> removido`, actionArea: 'Produtos', extra: product._id });

    const headers = {
      company: product.company,
    };

    return await api.delete(`api/catalog/${product._id}`, {
      headers,
    });
  }

  private static async removeImageFromFirebase(imageUrl: string) {
    try {
      api
        .get(
          `/api/catalog/checkCatalogHasImageUrlDependency/${encodeURIComponent(
            imageUrl
          )}`
        )
        .then((response) => {
          const data = response.data;
          const numberOfDependency = data.data.numberOfDependency;
          if (data.success && numberOfDependency <= 1) {
            // Remove image from Firebase
            const ref = firebaseStorage.refFromURL(imageUrl);
            ref
              .delete()
              .then(function () {
                // File deleted successfully
                console.log(
                  '%c Image removed successfully from Firebase',
                  'color: green'
                );
              })
              .catch((error) =>
                console.error('Error tyring to remove image from Firebase', error)
              );
          } else {
            console.warn(
              'Image not removed from Firebase, due to have dependencies'
            );
          }
        })
        .catch((error) =>
          console.error(
            'Error fetching checkCatalogHasImageUrlDependency API',
            error
          )
        );
    } catch (error) {
      console.error('Error fetching checkCatalogHasImageUrlDependency', error);
    }
  }
}
