import React from 'react';
import { Space, Table } from 'antd';

import { DeliveryFee } from '../../../../../../../models/DeliveryFee';
import { RoundTag } from '../../../../../../../common-styles';
import formatCurrency from '../../../../../../../utils/formatCurrency';
import { TableEditAction } from '../../../../../../../components/TableEditAction';
import { TableDeleteAction } from '../../../../../../../components/TableDeleteAction';

interface DeliveryFeeListProps {
  clientFareList: DeliveryFee[];
  deleteAction(clientFare: DeliveryFee): void;
  editAction(clientFare: DeliveryFee): void;
  currency: string;
};

export const DeliveryFeeList = ({
  clientFareList,
  deleteAction,
  editAction,
  currency,
}: DeliveryFeeListProps) => {

  const handleEditAction = (fee: DeliveryFee) => editAction(fee);

  return (
    <Table
      bordered
      dataSource={[...clientFareList]}
      pagination={false}
      style={{ marginTop: '16px' }}
    >
      <Table.Column
        title="Status"
        render={(fee: DeliveryFee) => (
          <RoundTag color={fee.isEnabled ? 'green' : 'red'} key={fee._id}>
            {fee.isEnabled ? 'Ativo' : 'Pausado'}
          </RoundTag>
        )}
      />
      <Table.Column
        dataIndex="destiny"
        title="Destino"
      />
      <Table.Column
        title="Preço"
        render={(fee: DeliveryFee) => formatCurrency(fee.price || 0, currency)}
      />
      <Table.Column
        fixed="right"
        title="Ações"
        width={145}
        render={(fee: DeliveryFee) => {
          return (
            <Space direction="vertical" key={fee._id}>
              <TableEditAction
                onClick={() => handleEditAction(fee)}
              />
              <TableDeleteAction
                onClick={() => deleteAction(fee)}
                title={
                  <span>
                    Tem certeza que deseja remover o frete <strong>{fee.destiny}</strong>? <br />
                    Uma vez removido, esta ação não poderá ser revertida.
                  </span>
                }
              />
            </Space>
          );
        }}
      />
    </Table>
  );
};
