import React, { useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { DatePicker, Form, Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { ContainerFilters, ContentFilters } from '../../../../../common-styles';

import { DATE_FORMAT } from '../../../../../utils/dateTime';
import { DEFAULT_SELECT_COMPANY, DEFAULT_SELECT_DRIVER } from '../../../../../utils/Constants';
import { Company } from '../../../../../models/Company';
import { getOrderStatusData, OrderStatus } from '../../../../../models/OrderStatus';
import { useAvailableDrivers } from '../../../../../hooks';

interface OrderFiltersProps {
  companies: Company[];
  companyId: string;
  endDate: moment.Moment;
  isAdmin: boolean;
  onCompanySelected: (companyId: string) => void;
  onDriverSelected: (driverId: string) => void;
  onStatusSelect: (status: OrderStatus[]) => void;
  setEndDate: (endDate: moment.Moment) => void;
  setSearchInputFilter: (searchInput: string) => void;
  setStartDate: (startDate: moment.Moment) => void
  startDate: moment.Moment;
}

const MIN_SUPPORTED_YEAR = 2021;

export const OrderFilters = ({
  companies,
  companyId,
  endDate,
  isAdmin,
  onCompanySelected,
  onDriverSelected,
  onStatusSelect,
  setEndDate,
  setSearchInputFilter,
  setStartDate,
  startDate,
}: OrderFiltersProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<OrderStatus[]>([]);
  const [selectedDriverId, setSelectedDriverId] = useState<string>(DEFAULT_SELECT_DRIVER);
  const { data: drivers } = useAvailableDrivers(companyId);

  const companiesName = useMemo(() => {
    const names = companies.map((company) => {
      return {
        value: company._id,
        label: company.fantasyName,
      };
    });
    names.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: DEFAULT_SELECT_COMPANY,
    });
    return names;
  }, [companies]);

  const driversName = useMemo(() => {
    const names = drivers?.map((driver) => {
      return {
        value: driver._id,
        label: driver.name,
      };
    });
    names?.unshift({
      value: '',
      label: DEFAULT_SELECT_DRIVER,
    });

    return names;
  }, [drivers]);

  const status = useMemo(() => {
    return Object.values(OrderStatus).map((status) => {
      return {
        value: status,
        label: getOrderStatusData(status).label,
      };
    });
  }, []);

  return (
    <ContainerFilters>
      <Form
        layout="vertical"
        style={{ marginTop: '16px' }}
      >
        <ContentFilters>
          <Form.Item
            label={<label className="label-input-field">Pesquisar</label>}
          >
            <Input.Search
              allowClear
              defaultValue={searchInput}
              enterButton="Pesquisar"
              onSearch={(value) => {
                setSearchInput(value);
                setSearchInputFilter(value);
              }}
              placeholder="Pesquise por qualquer entrada..."
              style={{ height: '40px' }}
            />
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Status</label>}
          >
            <Select
              defaultValue={selectedStatus}
              onChange={(options: OrderStatus[]) => {
                onStatusSelect(options);
                setSelectedStatus(options);
              }}
              placeholder="0 Status selecionado"
              showSearch
              value={selectedStatus}
              mode="multiple"
            >
              {status.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Período</label>}
          >
            <DatePicker.RangePicker
              disabledDate={(current) =>
                current &&
                (current.valueOf() > Date.now() ||
                  current.year() < MIN_SUPPORTED_YEAR)
              }
              format={DATE_FORMAT}
              defaultValue={[startDate, endDate]}
              onChange={(val) => {
                setStartDate(moment(val?.[0]));
                setEndDate(moment(val?.[1]));
              }}
            />
          </Form.Item>

          {isAdmin && (
            <Form.Item
              label={<label className="label-input-field">Empresas</label>}
            >
              <Select
                defaultValue={companyId}
                onChange={onCompanySelected}
                suffixIcon={<CaretDownOutlined />}
              >
                {companiesName.map((company) => (
                  <Select.Option
                    key={company.value}
                    value={company.value}
                  >
                    {company.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label={<label className="label-input-field">Entregadores</label>}
          >
            <Select
              defaultValue={selectedDriverId}
              onChange={(driverId: string) => {
                setSelectedDriverId(driverId);
                onDriverSelected(driverId);
              }}
              suffixIcon={<CaretDownOutlined />}
            >
              {driversName?.map((driver) => (
                <Select.Option
                  key={driver.value}
                  value={driver.value}
                >
                  {driver.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ContentFilters>
      </Form>
    </ContainerFilters>
  );
}