import styled from "styled-components";

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  .button-success {
    margin-top: 20px;
    width: 20%;
    align-self: end;
  }

  .image-success {
    width: 220px;
    height: 220px;
    margin-left: ${props => props.isMobile ? '0' : '120px'};
  }
`;

export const Title = styled.h3`
  margin-top: 20px;
`;

export const Description = styled.p`
  text-align: center;
  color: #3f3e3e;
`;