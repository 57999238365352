import { useCallback } from 'react';
import { UploadImageService } from './../services/UploadImageService';
import { useMutation } from 'react-query';

export const useUploadImage = () => {
  const { mutate, isLoading } = useMutation(
    (file: File) => UploadImageService.upload(file)
  );

  const upload = useCallback(({ file, onSuccess, onError }) => {
    mutate(file, {
      onSuccess: (resultData) => onSuccess(resultData.secure_url),
      onError,
    });
  }, [mutate]
  );

  return {
    isLoading,
    upload,
  };
};
