import styled from 'styled-components';

export const Container = styled.div`
  min-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
    margin: 10px 20px;
  }

  @media (min-width: 1200px) {
    p {
      width: 50%;
    }
  }
`;

export const Logo = styled.img`
  width: 22vh;
  display: block;
  margin: auto;
  object-fit: scale-down;
  margin-top: 40px;

  @media (max-width: 500px) {
    width: 75vw;
  }
`;

export const NotFoundImage = styled.img`
  height: 200px;
`;