import React from "react";
import { removeAccents } from "../../../../../utils/Util";
import { Container, LabelOptionsContainer, OptionsItemsContainer } from "./styles";

export interface CategoryOption {
  category: string,
  isSelected: boolean,
  key: string,
};

interface CategoryOptionsProps {
  categories: CategoryOption[];
  onCategorySelected(category: string): void;
  tabsAvailable: boolean;
}

export const CategoryOptions = ({ categories, onCategorySelected, tabsAvailable }: CategoryOptionsProps) => {
  const renderLabelsOptions = (option: CategoryOption) => {
    return (
      <LabelOptionsContainer
        id={removeAccents(option.category)}
        isSelected={option.isSelected}
        key={option.key}
        onClick={() => onCategorySelected(option.category)}
      >
        <small>
          {option.category}
        </small>
      </LabelOptionsContainer>
    );
  };

  return (
    <Container
      className={tabsAvailable ? 'div-sticky div-sticky-header2' : 'div-sticky'}
      id="categoryOptionsScroller"
      hasTabOptions={tabsAvailable}
    >
      <OptionsItemsContainer>
        {categories.map((item) => renderLabelsOptions(item))}
      </OptionsItemsContainer>
    </Container>
  );
}