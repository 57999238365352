import { useFetch } from './useFetch';

const ORDER_SUMMARY_PREVIEW = 'orderSummaryPreview';

export const useOrderSummaryPreview = (companyId: string) => {
  const {
    data: preview,
    isFetching: isFetchingPreview,
    refetch,
  } = useFetch<string>({
    queryKey: ORDER_SUMMARY_PREVIEW,
    url: `api/company/${companyId}/features/simulate-order`,
  });

  return { preview, isFetchingPreview, refetch };
};
