import React from 'react';
import { Form, Input } from 'antd';

interface DisabledFormInputProps {
  label: string;
  defaultValue?: string;
  name?: string;
};

export const DisabledFormInput = ({
  defaultValue,
  label,
  name,
}: DisabledFormInputProps) => {
  return (
    <Form.Item
      label={<label className="label-input-field">{label}</label>}
      name={name}
    >
      <Input
        defaultValue={defaultValue}
        disabled={true}
        style={{ color: 'rgba(0, 0, 0, 0.6)', fontWeight: 'bold', height: '40px' }}
      />
    </Form.Item>
  );
};
