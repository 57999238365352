import React, { useEffect, useState } from 'react';
import { Button, Form, Typography } from 'antd';
import html2canvas from 'html2canvas';

import { ContentHeader } from '../../../components/ContentHeader';

import { MenuOption } from '../../../models/MenuOption';
import { LayoutType } from '../../../models/Enum';
import { getClientMenuUrl } from '../../../utils/Util';

import { GeneralForm } from '../../../common-styles';
import { ContentGrid, ImagesContainer } from './styles';
import { generateQRInBase64 } from '../../../utils/QRCodeGeneratorUtil';
import { QRLinkQrcode } from './components/QRLinkQrcode';
import { useLoggedUser, useQrlinks } from '../../../hooks';
import { DisabledFormInput } from '../../../components/DisabledFormInput';

/**
 * @deprecated TO BE UPDATED
 */
export const QRcodeGeneratorPage = () => {
  const [qrcode, setQrcode] = useState<string>();
  const [qrcodeToDownload, setQrcodeToDownload] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isQRcodeShowing, setQRCodeShowing] = useState<boolean>(false);
  const [clientLogo, setClientLogo] = useState<any>(null);

  const { userLogged } = useLoggedUser();
  const { data: linkTree } = useQrlinks();

  // const [currentCompany, setCurrentCompany] = useState<Company>();
  // Admin
  // const [companies, setCompanies] = useState<Company[]>([]);
  // const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');

  // const isAdmin = useMemo(() => {
  //   return loggedUser.role === Role.ADMIN;
  // }, [loggedUser.role]);

  useEffect(() => {
    // if (isAdmin) {
    //   api
    //     .get('/api/company?status=active')
    //     .then((response) => setCompanies(response.data.data))
    //     .catch((error) => {
    //       console.error('Error fetching Companies in QRcodeGeneratorPage', error);
    //     });
    // } else {
    // TODO: use useCompany hook here
    // const company = JSON.parse(localStorage.getItem(`${PREFIX_LOCALSTORAGE}company`)!!) as Company;
    if (!userLogged.companyInfo) return;

    const urlQRCode = getClientMenuUrl(userLogged.companyInfo.slug);
    setQrcode(urlQRCode);
    setClientLogo(userLogged.companyInfo.logoUrl);
    generateQR(urlQRCode);
    setQRCodeShowing(true);
    // }
  }, [userLogged.companyInfo]);

  async function generateQR(qrcode: string) {
    if (!qrcode) return;

    try {
      const base64 = await generateQRInBase64(qrcode);
      if (base64) {
        setQrcodeToDownload(base64);
        setQRCodeShowing(true);
        setError('');
      }
    } catch (err) {
      console.log(err);
      setError(`Erro ao tentar gerar QRcode.`);
    }
  }

  // const companiesName = useMemo(() => {
  //   const names = companies.map((company) => {
  //     return {
  //       value: company._id,
  //       label: company.fantasyName,
  //     };
  //   });
  //   names.sort((a, b) => a.label.localeCompare(b.label)).unshift({
  //     value: '',
  //     label: DEFAULT_SELECT_COMPANY,
  //   });
  //   return names;
  // }, [companies]);

  // function handleSelectedCompany(companyId: string) {
  //   setSelectedCompanyId(companyId);
  //   setQRCodeShowing(qrcodeToDownload.startsWith('base64'));

  //   const company = companies.find((company) => company._id === companyId);
  //   setCurrentCompany(company);
  //   fetchData(company);
  // }

  // function fetchData(company?: Company) {
  //   const urlQRCode = getClientMenuUrl(`${company?.slug}`);
  //   setQrcode(urlQRCode);
  //   setClientLogo(`${company?.logoUrl}`);
  //   generateQR(urlQRCode);
  // }

  function handleDownloadAction() {
    const capturedElement = document.querySelector('#capture') as HTMLElement;

    html2canvas(capturedElement, {
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
    }).then((canvasResult) => {
      const image = canvasResult
        .toDataURL('image/jpeg')
        .replace('image/png', 'image/octet-stream');
      const link = document.createElement('a');
      link.download = `QRcode_${userLogged.companyInfo?.name}.jpeg`;
      link.href = image;
      link.click();
    });
  }

  return (
    <>
      <ContentHeader title={MenuOption.QRCODE}>
        {/* {isAdmin && (
          <SelectInput
            options={companiesName}
            onChange={(e) => handleSelectedCompany(e.target.value)}
            defaultValue={selectedCompanyId}
          />
        )} */}
      </ContentHeader>

      <GeneralForm>
        {qrcode && (
          <Form
            layout="vertical">
            <DisabledFormInput label="URL de acesso" defaultValue={qrcode} />
          </Form>
        )}

        {isQRcodeShowing && (
          <>
            <Typography.Title level={4} style={{ marginTop: '20px' }}>
              {userLogged.companyInfo?.layoutType === LayoutType.MENU
                ? 'Cardápio'
                : 'Catálogo'}
            </Typography.Title>

            <ContentGrid>
              <div>
                <ImagesContainer id="capture">
                  <img
                    className="client-img"
                    src={clientLogo}
                    alt="Client logo"
                  />

                  <img
                    className="qrcode-img"
                    src={qrcodeToDownload}
                    alt="generated qrcode"
                  />

                  <img
                    className="qrcode-logo"
                    src={require('../../../assets/images/logo-bg-white.png')}
                    alt="qrcode preferido"
                  />
                </ImagesContainer>
                <Button
                  id="download-button"
                  type="primary"
                  style={{ marginTop: '20px', width: '100%' }}
                  onClick={handleDownloadAction}
                >
                  Download
                </Button>
              </div>

              {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Form>
                  <Form.Item label="Largura da logo">
                    <Input placeholder="largura" />
                  </Form.Item>
                  <Form.Item label="Altura da logo">
                    <Input placeholder="largura" />
                  </Form.Item>
                </Form>
              </div> */}
            </ContentGrid>

            {linkTree && Boolean(linkTree?.links?.length > 0) && Boolean(linkTree?.isEnabled) && (
              <QRLinkQrcode
                clientLogo={clientLogo}
                companyName={userLogged.companyInfo?.name ?? ''}
              />
            )}
          </>
        )}
        {error && (
          <div style={{ marginTop: '10px' }} className="alert alert-danger">
            <strong>Oops!</strong> {error}
          </div>
        )}
      </GeneralForm >
    </>
  );
};
