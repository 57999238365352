import React from "react";
import { Button } from "antd";
import { PageDetailsButtonsContainer, RoundTag } from "../../../../../../../common-styles";
import { User } from "../../../../../../../models/User";
import { formatDate } from "../../../../../../../utils/dateTime";
import { translateRole } from "../../../../../../../utils/RoleUtil";
import { SummaryList } from "../../../../../../../components/SummaryList";

interface ViewUserProps {
  onClosePage(): void;
  user: User;
}

export const ViewUser = ({ onClosePage, user }: ViewUserProps) => {
  return (
    <>
      <SummaryList title="Informações">
        <SummaryList.Item>
          <SummaryList.Label>ID</SummaryList.Label>
          <SummaryList.Value>{user._id}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Perfíl</SummaryList.Label>
          <SummaryList.Value>{translateRole(user.role).label}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Nome</SummaryList.Label>
          <SummaryList.Value>{user.name}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Email</SummaryList.Label>
          <SummaryList.Value>{user.email}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Data de criação</SummaryList.Label>
          <SummaryList.Value>{formatDate(user.createdAt)}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>

      <SummaryList title="Empresa" visible={Boolean(user.company)}>
        <SummaryList.Item>
          <SummaryList.Label>ID</SummaryList.Label>
          <SummaryList.Value>{user.company}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(user.companyName)}>
          <SummaryList.Label>Nome</SummaryList.Label>
          <SummaryList.Value>{user.companyName}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(user.companyAssociation.length)}>
          <SummaryList.Label>Estabelecimentos associados</SummaryList.Label>
          <SummaryList.Value>{user.companyAssociation.map(association => <RoundTag color={'gray'}>
            {association.companyName}
          </RoundTag>)}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>

      <PageDetailsButtonsContainer>
        <Button
          danger
          onClick={onClosePage}
        >
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Fechar
        </Button>
      </PageDetailsButtonsContainer>
    </>
  );
}