import api from '../../../../services/api';
import { Company } from '../../../../models/Company';

export class CompanyService {
  public static async create(company: Company): Promise<Company> {
    const response = await api.post('api/company', company);

    return response.data.data;
  }

  public static async update(company: Company): Promise<Company> {
    const response = await api.put(`api/company/${company._id}`, company);

    return response.data.data;
  }

  public static async removePendingRegistration(id: string): Promise<void> {
    return await api.delete(`api/prospection/${id}`);
  }
}
