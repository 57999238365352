import React from "react";
import { Button, Space } from "antd";

interface QRLinkEditorFooterProps {
  loading?: boolean;
  onClose(): void;
  onSubmit(): void;
}

export const QRLinkEditorFooter = ({ loading, onClose, onSubmit }: QRLinkEditorFooterProps) => {
  return (
    <Space>
      <Button danger style={{ marginRight: '10px' }} onClick={onClose}>
        <i
          className="fa fa-times"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Cancelar
      </Button>

      <Button
        type="primary"
        loading={loading}
        onClick={onSubmit}
      >
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />{' '}
        Salvar
      </Button>
    </Space>
  );
}