import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch";

const ORDER_MESSAGE = 'orderMessage';

interface OrderMessage {
  message: string;
}

export const useGetOrderMessage = () => {
  const [orderId, setOrderId] = useState<string>();
  
  const { data, isLoading } = useFetch<OrderMessage>({
    enabled: orderId !== undefined,
    queryKey: [ORDER_MESSAGE],
    url: `api/orders/${orderId}/message`,
  });

  return { data, isLoading, setOrderId };
};