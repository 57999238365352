import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Controllers = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: 450px) {
    width: 100%;
    justify-content: space-around;
  }
`;
