import React from "react";
import { SettingsSection } from "../..";
import { MenuOption } from "../../../../models/MenuOption";
import { MenuItem } from "../MenuItem";
import { Title } from "../../styles";

import {
  AppstoreAddOutlined,
  PieChartOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

export const kitchenMenuOptions = (collapsed: boolean) => {
  return [
    <Title key={SettingsSection.ORDERS} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.ORDERS}
    </Title>,

    MenuItem(MenuOption.WHATSAPP_ORDERS, <WhatsAppOutlined />),

    <Title key={SettingsSection.REGISTER} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.REGISTER}
    </Title>,

    MenuItem(MenuOption.CATEGORIES, <PieChartOutlined />),

    MenuItem(MenuOption.PRODUCTS, <AppstoreAddOutlined />,),

    <Title key={SettingsSection.SETTINGS} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.SETTINGS}
    </Title>,
    MenuItem(MenuOption.SETTINGS_PROFILE, <UserOutlined />),
  ];
};
