import { Driver } from './../models/Driver';
import { useFetch } from './useFetch';

const DRIVER = 'driver';

export const useDriver = () => {
  const { data, ...rest } = useFetch<Driver>({
    queryKey: DRIVER,
    url: 'api/driver/me',
  });

  return { data, ...rest };
};
