import styled from 'styled-components';
import { CounterLabelContainer } from '../../../common-styles';

export const CounterLabelContent = styled(CounterLabelContainer)`
  margin-top: 30px;

  p > span {
    font-weight: bold;
  }
`;
