
export const Currency = [
  {
    label: 'Real',
    value: 'R$', //'BRL'
  },
  {
    label: 'Euro',
    value: '€', // 'EUR'
  },
  // {
  //   label: 'Dolar',
  //   value: 'USD', //USD'
  // },
];