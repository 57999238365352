import React from "react";

import { SettingsSection } from "../..";
import { MenuItem } from "../MenuItem";
import { Title } from "../../styles";

import { MenuOption } from "../../../../models/MenuOption";

import {
  AppstoreAddOutlined,
  BankOutlined,
  BranchesOutlined,
  CommentOutlined,
  FileSyncOutlined,
  GiftOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

export const managerMenuOptions = (collapsed: boolean) => {
  return [
    <Title key={SettingsSection.ORDERS} isCollapsed={collapsed}>
      {SettingsSection.ORDERS}
    </Title>,
    MenuItem(MenuOption.WHATSAPP_ORDERS, <WhatsAppOutlined />),
    MenuItem(MenuOption.HISTORY_ORDERS, <FileSyncOutlined />),

    <Title key={SettingsSection.REGISTER} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.REGISTER}
    </Title>,

    MenuItem(MenuOption.CATEGORIES, <PieChartOutlined />),
    MenuItem(MenuOption.PRODUCTS, <AppstoreAddOutlined />),
    MenuItem(MenuOption.COUPON, <GiftOutlined />),
    MenuItem(MenuOption.USER, <TeamOutlined />),

    <Title key={SettingsSection.GENERAL} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.GENERAL}
    </Title>,
    MenuItem(MenuOption.FEEDBACK, <CommentOutlined />),
    // MenuItem(MenuOptions.BANNER_INFO, <FundProjectionScreenOutlined />),
    MenuItem(MenuOption.QR_LINKS, <BranchesOutlined />),
    MenuItem(MenuOption.STORES, <BankOutlined />),

    <Title key={SettingsSection.SETTINGS} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.SETTINGS}
    </Title>,
    MenuItem(MenuOption.SETTINGS_PROFILE, <UserOutlined />),
    MenuItem(MenuOption.SETTINGS_GENERAL, <SettingOutlined />),
  ];
};
