import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { OrderDetails } from '../OrderDetails';
import { ContentHeader } from '../../../components/ContentHeader';
import { PanelResult, PanelResultStatus } from '../../../components/PanelResult';
import { OrderCounters } from '../../../components/OrderCounters';
import { OrderFilters } from './components/Filters';
import { ButtonGeneratePdfReport } from './components/ButtonGeneratePdfReport';
import { HistoryOrdersTable } from './components/Table';

import api from '../../../services/api';
import { MenuOption } from '../../../models/MenuOption';

import { Company } from '../../../models/Company';
import { OrderStatus } from '../../../models/OrderStatus';
import { Role } from '../../../models/Enum';
import { WhatsappOrder } from '../../../models/DataResponse';

import {
  formatDate,
  SERVER_DATE_FORMAT,
} from '../../../utils/dateTime';
import { removeAccents } from '../../../utils/Util';
import { DEFAULT_SELECT_COMPANY } from '../../../utils/Constants';

import { useCompanyFeatures, useFeatures, useListCompaniesForCustomers, useLoggedUser } from '../../../hooks';
import { FilterAndCounterContainer } from './styles';
import { CounterLabelContainer } from '../../../common-styles';

export const HistoryOrders = () => {
  const startMoment = moment(new Date());
  const [startDate, setStartDate] = useState(startMoment);
  const [endDate, setEndDate] = useState(startMoment);
  const [isFormLoading, setFormLoading] = useState(false);
  const [isShowingDetails, setShowingDetails] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [whatsappOrders, setWhatsappOrders] = useState<WhatsappOrder[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<WhatsappOrder>(
    {} as WhatsappOrder
  );
  const [selectedStatus, setSelectedStatus] = useState<OrderStatus[]>([]);
  const [selectedDriverId, setSelectedDriverId] = useState<string>();

  // FIXME: This can be deleted once we move the Fetching Orders to a hook.
  const [forceUpdateList, setForceUpdateList] = useState<boolean>(false);

  // For Admin view
  const [companies, setCompanies] = useState<Company[]>([]);

  const { data: companiesForCustomers } = useListCompaniesForCustomers();
  const { userLogged } = useLoggedUser();
  const { user } = userLogged;

  const { companyFeatures } = useCompanyFeatures(user.company);
  const currency = companyFeatures?.sellsConfiguration.currency || 'BRL'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>(user.role === Role.ADMIN ? '' : DEFAULT_SELECT_COMPANY);

  const companyId = useMemo(() => {
    const isAdminOrManager = userLogged.user.role === Role.ADMIN || userLogged.user.role === Role.MANAGER;

    if (!isAdminOrManager && userLogged.user.company) {
      return userLogged.user.company;
    }
    return selectedCompanyId;
  }, [userLogged, selectedCompanyId]);

  const isAdmin = useMemo(() => {
    return user.role === Role.ADMIN || user.role === Role.MANAGER;
  }, [user.role]);

  const { data: features } = useFeatures(companyId);

  useEffect(() => {
    if (user.role === Role.ADMIN) {
      api.get('/api/company/whatsappFeature').then((response) => {
        setCompanies(response.data.data);
      });
    } else if (user.role === Role.MANAGER && companiesForCustomers) {
      setCompanies(companiesForCustomers);
    }
  }, [user.role, companiesForCustomers]);

  useEffect(() => {
    if (!companyId) return;

    setFormLoading(true);

    const startStr = startDate.format(SERVER_DATE_FORMAT);
    const endStr = endDate.format(SERVER_DATE_FORMAT);

    api
      .get(`api/orders/${companyId}?startDate=${startStr}&endDate=${endStr}`)
      .then((response) => {
        setFormLoading(false);
        if (response.data.success) {
          const data = response.data.data as WhatsappOrder[];
          setWhatsappOrders(data);
        } else {
          console.error('Não foi possível obter os dados.', response);
        }
      })
      .catch((error) => {
        console.log(error);
        setFormLoading(false);
      });
  }, [companyId, startDate, endDate, forceUpdateList]);

  const filteredOrders = useMemo(() => {
    const input = removeAccents(searchInput.toLowerCase());

    if (!input && selectedStatus.length === 0 && !selectedDriverId) return null;

    let result = whatsappOrders;

    if (input) {
      result = result.filter((order) => {
        return (
          order.orderId?.toLowerCase().includes(input) ||
          String(order.total).includes(input) ||
          removeAccents(order.client.deliveryType ?? '').includes(input) ||
          removeAccents(order.client.address ?? '').includes(input) ||
          removeAccents(order.client.name ?? '').includes(input) ||
          removeAccents(order.client.paymentType ?? '').includes(input) ||
          order.client.phone?.toLowerCase().includes(input) ||
          removeAccents(order.client.referencePoint ?? '').includes(input) ||
          formatDate(order.createdAt).includes(input) ||
          order.coupon?.couponCode?.toLowerCase().includes(input) ||
          order.products.some(product => removeAccents(product.name).includes(input)) ||
          order.products.some(product => product.complements?.some(complement => removeAccents(complement.title).includes(input))) ||
          order.products.some(product => product.complements?.some(
            complement => complement.complementItemOptions
              .filter((product) => product.counter !== undefined && product.counter !== 0)
              .some(item => removeAccents(item.title).includes(input))
          ))
        );
      });
    }

    if (Boolean(selectedStatus.length)) {
      result = result.filter((item => selectedStatus.some(status => status === item.status)))
    }

    if (Boolean(selectedDriverId?.length)) {
      result = result.filter((item => item.driver?.id === selectedDriverId));
    }

    return result;

  }, [searchInput, selectedDriverId, selectedStatus, whatsappOrders]);

  const listOfOrders = filteredOrders ?? whatsappOrders;

  const modalDismissed = (withAction: boolean) => {
    setShowingDetails(false);
    if (withAction) {
      setForceUpdateList(!forceUpdateList);
    }
  };

  const handleItemClick = (order: WhatsappOrder) => {
    setShowingDetails(true);
    setSelectedOrder(order);
  };

  const onCompanySelect = useCallback((companyId: string) => setSelectedCompanyId(companyId), []);

  const onDriverSelected = useCallback((driverId: string) => setSelectedDriverId(driverId), []);

  const onSearchFiltersChange = useCallback((searchFilter: string) => setSearchInput(searchFilter), []);

  const onStatusFiltersChange = useCallback((status: OrderStatus[]) => setSelectedStatus(status), []);

  if (!features?.sellViaWhatsapp && !isAdmin) {
    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title="Para ter acesso a esta funcionalidade, é preciso ativar o módulo de pedidos."
        subtitle="Entre já em contato com a equipe QRcode Preferido para mais informações."
      />
    );
  }

  const renderOrderContent = () => {
    return (
      <>
        <OrderFilters
          companies={companies}
          companyId={companyId}
          endDate={endDate}
          isAdmin={isAdmin}
          onCompanySelected={onCompanySelect}
          onDriverSelected={onDriverSelected}
          onStatusSelect={onStatusFiltersChange}
          setEndDate={setEndDate}
          setSearchInputFilter={onSearchFiltersChange}
          setStartDate={setStartDate}
          startDate={startDate}
        />

        <FilterAndCounterContainer>
          <CounterLabelContainer>
            <OrderCounters
              orders={listOfOrders}
              currency={currency}
            />
          </CounterLabelContainer>

          <ButtonGeneratePdfReport
            endDate={endDate}
            startDate={startDate}
            companyId={companyId}
            currency={currency}
            ordersIds={Boolean(listOfOrders.length) ? JSON.stringify(listOfOrders.map(order => order._id)) : null}
          />

        </FilterAndCounterContainer>

        <HistoryOrdersTable
          companyId={companyId}
          currency={currency}
          loading={isFormLoading}
          onItemClick={handleItemClick}
          orders={listOfOrders}
        />
      </>
    );
  };

  return (
    <>
      {!isShowingDetails && <ContentHeader title={MenuOption.HISTORY_ORDERS} />}

      {isShowingDetails ? (
        <OrderDetails
          modalDismissed={modalDismissed}
          order={selectedOrder}
        />
      ) : (
        renderOrderContent()
      )}
    </>
  );
};
