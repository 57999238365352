import React, { useEffect, useState } from 'react';

import { Footer } from '../../../components/Footer';
import { LoaderSpinner } from '../../../components/LoaderSpinner';
import { ModalImage } from '../../../components/ModalImage';

import MenuController from '../MenuOnline/MenuController';

import { logEstablishmentEvent } from '../../../services/firebase';

import { MenuTabOption } from '../../../models/DataResponse';
import { ProductMenu } from '../../../models/ProductCatalog';

import { useRodeadouro } from './hooks/useRodeadouro';
import {
  MenuOptionsContainer,
  MenuOptionsItemsContainer,
} from '../MenuOnline/styles';
import './styles.css';
import { useClientProducts } from '../../../hooks/useClientProducts';
import { FeedbackRateButton } from '../../../components/FeedbackRateButton';
import { FeedbackDrawer } from '../MenuOnline/components/FeedbackDrawer';
import { useModal } from '../../../hooks/useModal';

const menuController = new MenuController();

export const Rodeadouro = () => {
  const [selectedMenuOption, setSelectedMenuOption] = useState<MenuTabOption>({} as MenuTabOption);
  const [menuOptions, setMenuOptions] = useState<MenuTabOption[]>([]);
  const [selectedData, setSelectedData] = useState<ProductMenu>({} as ProductMenu);

  const { isOpen: isOpenFeedbackDrawer, close: closeFeedbackDrawer, toggle: toggleFeedbackDrawer } = useModal();
  const { data, company, configuration, isLoading: isLoadingData } = useRodeadouro();
  const { products, isFetching: isFetchingProducts } = useClientProducts(company?._id);
  const isLoading = isLoadingData || isFetchingProducts;

  useEffect(() => {
    if (!data || !products) return;

    logEstablishmentEvent('Rodeadouro');

    const menuOptionFilter = data?.tabOptions.sort(
      (a, b) => a.order - b.order
    );
    const selectedMenuOption = menuController.getSelectedMenuOption(menuOptionFilter);

    setMenuOptions(data.tabOptions);
    setSelectedMenuOption(selectedMenuOption);

    const filteredData = menuController.doFilterMenu(
      selectedMenuOption,
      products,
    );
    setSelectedData(filteredData[0].products[0]);
  }, [data, products, company]);

  const handleChangeMenuOption = (selectedOption: MenuTabOption) => {
    setSelectedMenuOption(selectedOption);

    const filteredData = menuController.doFilterMenu(selectedOption, products ?? []);
    setSelectedData(filteredData[0].products[0]);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const showNotificationPopUp = () => {
    return (
      configuration?.companyFeatures?.notificationPopUp?.isEnabled &&
      configuration?.companyFeatures.notificationPopUp.content
    );
  };

  return isLoading ? (
    <LoaderSpinner />
  ) : (
    <div id="page-rodeadouro">
      {showNotificationPopUp() && (
        <ModalImage
          content={configuration?.companyFeatures?.notificationPopUp?.content ?? ''}
        />
      )}

      <div className="content">
        <main className="main-rodeadouro">
          <img
            className="rodeadouro-menu-image"
            src={selectedData.name}
            alt="Rodeadouro Menu"
          />
        </main>

        <MenuOptionsContainer style={{ marginBottom: '16px' }}>
          <MenuOptionsItemsContainer>
            {menuOptions.map((option) => {
              return (
                <i
                  key={option._id}
                  className={option.icon}
                  onClick={() => handleChangeMenuOption(option)}
                  style={{
                    color:
                      option === selectedMenuOption ? '#F0D89A' : '#FFFFFF',
                    cursor: 'pointer',
                  }}
                />
              );
            })}
          </MenuOptionsItemsContainer>

        </MenuOptionsContainer>

        <div className='feedback-item'>
          <FeedbackRateButton onClick={toggleFeedbackDrawer} />
        </div>

        <Footer
          instagram={company?.instagram}
          facebook={company?.facebook}
          whatsapp={company?.whatsapp}
          phone={company?.phoneFooter}
        />
      </div>

      <FeedbackDrawer
        isOpen={isOpenFeedbackDrawer}
        onClose={closeFeedbackDrawer}
        items={configuration?.companyFeatures?.feedback?.items ?? []}
        slug="rodeadouro" />
    </div>
  );
};
