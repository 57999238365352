import React, { useState } from 'react'
import { Button } from 'antd';
import { FeeEditor } from './components/FeeEditor';
import { useModal } from '../../../../../../hooks';
import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';
import { FeesTable } from './components/FeesTable';
import { ExtraFee } from '../../../../../../models/SellsConfiguration';

interface ExtraFeesProps {
  companyFeatures: CompanyFeatures;
}

export const ExtraFees = ({ companyFeatures }: ExtraFeesProps) => {
  const [selectedFee, setSelectedFee] = useState<ExtraFee>();
  const { isOpen, close, toggle } = useModal();

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setSelectedFee(undefined);
          toggle();
        }}
      >
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar taxa
      </Button>

      <FeeEditor
        companyFeatures={companyFeatures}
        extraFee={selectedFee}
        isOpen={isOpen}
        onClose={() => {
          setSelectedFee(undefined);
          close();
        }} />

      <FeesTable
        companyFeatures={companyFeatures}
        onRowClick={(fee) => {
          setSelectedFee(fee);
          toggle();
        }} />
    </>
  )
}
