import React, { useMemo } from "react";
import parse from 'html-react-parser';
import { Table } from "antd";
import Column from "antd/lib/table/Column";

import { ProductMenu } from "../../models/ProductCatalog";
import { ProductWhatsappOrder } from "../../models/DataResponse";
import { calculateTotalPrice, formattedTotalPrice } from "../../utils/ProductUtil";
import { ProductComplementsDescription } from "./components/ProductComplementsDescription";

interface ProductOrderTableProps {
  currency: string;
  displayPrice?: boolean;
  products: ProductWhatsappOrder[];
  showComplementTitle: boolean;
}

export const ProductOrderTable = ({ currency, displayPrice, products, showComplementTitle }: ProductOrderTableProps) => {

  const hasDescriptionAvailable = useMemo(() => products.some(product => product.description), [products])
  const hasCommentsAvailable = useMemo(() => products.some(product => product.extraNote), [products])

  const getProductPriceFormatted = (product: ProductWhatsappOrder) => {
    const convertToMenuItem = {
      counter: product.counter,
      price: product.price,
      complements: product.complements,
    } as ProductMenu;

    const totalPrice = calculateTotalPrice([
      convertToMenuItem,
    ]);

    return formattedTotalPrice(currency, totalPrice);
  }

  return (
    <Table
      bordered
      dataSource={products}
      pagination={false}
      style={{ width: '100%', padding: '8px' }}
    >
      <Column title="Quantidade" render={(product: ProductWhatsappOrder) => Number(product.counter)} />

      <Column title="Produto" render={(product: ProductWhatsappOrder) => {
        return (
          <ProductComplementsDescription
            lastProduct={true}
            product={product}
            showComplementTitle={showComplementTitle} />
        );
      }} />

      {hasDescriptionAvailable && <Column title="Descrição" render={(product: ProductWhatsappOrder) => product.description && parse(product.description)} />}

      {hasCommentsAvailable && <Column title="Comentário" dataIndex="extraNote" />}

      {displayPrice && <Column title="Preço" render={(product: ProductWhatsappOrder) => getProductPriceFormatted(product)} />}
    </Table>
  );
}