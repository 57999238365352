import React, { useEffect } from "react";
import { Drawer, Select } from "antd";
import { useAvailableDrivers, useUpdateOrder } from "../../../../../hooks";
import { WhatsappOrder } from "../../../../../models/DataResponse";
import { NotificationDispatcher, NotificationType } from "../../../../../components/Notification";

interface SelectDriverDrawerProps {
  close(): void;
  isOpen: boolean;
  onDriverSelected(): void;
  order?: WhatsappOrder;
}

const { Option } = Select;

export const SelectDriverDrawer = ({ close, isOpen, onDriverSelected, order }: SelectDriverDrawerProps) => {
  const { data: drivers, refetch } = useAvailableDrivers(order?.companyId);
  const { associateDriverToOrder } = useUpdateOrder();

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  const handleDriverSelected = (driverId: string) => {
    associateDriverToOrder(
      order?._id,
      driverId,
      {
        onSuccess: () => {
          NotificationDispatcher({
            description: 'O  pedido foi associado ao entregador. Aguarde a confirmação do entregador.',
            message: 'Entregador selecionado',
            type: NotificationType.WARNING,
          });
          onDriverSelected();
        },
        onError: () => {
          NotificationDispatcher({
            message: 'Erro ao tentar selecionar entregador',
            type: NotificationType.ERROR,
          });
        }
      }
    );
  }

  return (
    <Drawer
      destroyOnClose
      onClose={close}
      open={isOpen}
      placement="right"
      title="Selecione o entregador"
    >
      <Select
        defaultValue={''}
        onChange={handleDriverSelected}
        style={{ width: '100%' }}
      >
        <Option value="" disabled>
          Selecione um entregador
        </Option>

        {drivers?.map((driver) => (
          <Option key={driver._id} value={driver._id} disabled={!driver.isAvailable}>
            {driver.name} ({driver.isAvailable ? 'Disponível' : 'Indisponível'})
          </Option>
        ))}
      </Select>
    </Drawer>
  );
}