import React from 'react';
import { Modal } from 'reactstrap';
import { Button } from 'antd';

import './styles.css';

type ModalPageProps = {
  isOpen: boolean;
  toggle(): void;
  title?: string;
  body?: string;
  bgModal?: string;
  color?: string;
  canShowCloseButton?: boolean;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  primaryButton?: any;
  secondaryButton?: any;
  children?: React.ReactNode;
};

export const ModalPage = ({
  isOpen,
  toggle,
  title,
  body,
  bgModal,
  color,
  canShowCloseButton,
  primaryButtonLabel,
  secondaryButtonLabel,
  primaryButton,
  secondaryButton,
  children,
}: ModalPageProps) => {
  const bgColor = bgModal ? bgModal : 'green';
  let showCloseButton = true;
  if (canShowCloseButton !== undefined) {
    showCloseButton = canShowCloseButton;
  }

  return (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName={`bg-gradient-${bgColor}`}
      isOpen={isOpen || false}
      backdrop={'static'}
      toggle={toggle}
    >
      <div className="modal-header">
        <h2
          className="modal-title"
          id="modal-title-notification"
          style={{ color: color ? color : 'white' }}
        >
          {title}
        </h2>
        {showCloseButton && (
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ color: color ? color : 'white' }}>
              ×
            </span>
          </button>
        )}
      </div>
      <div className="modal-body">
        {body && (
          <div className="py-3 text-center">
            <p>{body}</p>
          </div>
        )}
        {children}
      </div>
      <div className="modal-footer">
        {primaryButtonLabel && (
          <Button type="primary" onClick={primaryButton || toggle}>
            {primaryButtonLabel}
          </Button>
        )}
        {secondaryButtonLabel && (
          <Button danger onClick={secondaryButton || toggle}>
            {secondaryButtonLabel}
          </Button>
        )}
      </div>
    </Modal>
  );
};
