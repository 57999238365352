import React, { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { AutoComplete, Button, Form, Input } from "antd";
import { CompanyFeatures } from "../../../../../../models/CompanyFeatures";
import { useAddressAutocomplete, useUpdateCompanyFeatures } from "../../../../../../hooks";
import { useAddressCoordinates } from "../../../../../../hooks/useAddressCoordinates";
import { FormInputField } from "../../../../../../components/FormInputField";
import { NotificationDispatcher, NotificationType } from "../../../../../../components/Notification";

interface AddressStoreProps {
  companyFeatures: CompanyFeatures;
}

export const AddressStore = ({ companyFeatures }: AddressStoreProps) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<string>(companyFeatures.location?.address ?? '');
  const debouncedSearch = useDebounce(searchText, 500);
  const { data: addresses, setInputAddressSearch } = useAddressAutocomplete(true);
  const { data: coordinates } = useAddressCoordinates(selectedAddress);
  const { isLoading, update } = useUpdateCompanyFeatures();

  useEffect(() => {
    setInputAddressSearch(debouncedSearch);
  }, [debouncedSearch, setInputAddressSearch]);

  const onSelect = (value: string) => setSelectedAddress(value);

  const handleSave = (values: any) => {
    companyFeatures.location = {
      ...values,
      latitude: coordinates?.location?.lat,
      longitude: coordinates?.location?.lng,
    }

    update(companyFeatures, {
      onSuccess: () => {
        NotificationDispatcher({
          message: 'Operação realizada com sucesso.',
        });
      },
      onError: () => {
        NotificationDispatcher({
          message: 'Erro ao tentar proceder com a operação.',
          type: NotificationType.ERROR,
        });
      },
    });
  }

  return (
    <Form
      initialValues={companyFeatures.location}
      layout="vertical"
      onFinish={handleSave}
      {...{ wrapperCol: { span: 8 } }}
    >
      <Form.Item
        name="address"
        label={<label className="label-input-field">Localização da loja</label>}
        help="Informe a localização da loja e o número da porta."
      >
        <AutoComplete
          options={addresses?.map((address) => ({ value: address }))}
          onSelect={onSelect}
          onSearch={setSearchText}

        >
          <Input.Search placeholder="Ex: Avenida da liberdade 12" />
        </AutoComplete>
      </Form.Item>

      {coordinates && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${coordinates?.location?.lat}, ${coordinates?.location?.lng}`}
          style={{ margin: '10px 0' }}>
          Ver no Google maps
        </a>
      )}

      <FormInputField
        help="Informe o complemento da localização da loja. Ex: Loja 1A"
        label="Complemento"
        name="complement"
        placeholder="Loja 1A"
        style={{ marginTop: '25px' }}
      />

      <FormInputField
        help="Defina o raio de entrega da loja em km. Ao deixar vazio, o sistema não irá restringir a distância de entrega."
        label="Raio de entrega"
        name="radius"
        type="number"
        suffix="km"
      />

      <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }} loading={isLoading}>
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Salvar
      </Button>
    </Form >
  );
};