import React from 'react';
import { Button } from 'antd';
import { Separator } from '../../../../../../../common-styles';
import { ProductsCannotBeSold } from '../../../../../../../models/ProductStock';
import { ModalHeadLess } from '../../../../../../../components/ModalHeadless';
import { ProductName, Subtitle, Title } from './styles';

interface ProductsNotAvailableModalProps {
  close: () => void;
  isOpen: boolean;
  productsCannotBeSold: ProductsCannotBeSold;
}

export const ProductsNotAvailableModal = ({ close, isOpen, productsCannotBeSold }: ProductsNotAvailableModalProps) => {
  const productsOutOfStock = productsCannotBeSold.productWithoutStock.filter(product => product.quantityAvailableToBuy === 0);
  const productsNotEnoughStock = productsCannotBeSold.productWithoutStock.filter(product => product.quantityAvailableToBuy > 0);
  const productsNotAvailable = productsCannotBeSold.productNotAvailable;

  return (
    <ModalHeadLess
      bgModal="neutral"
      isOpen={isOpen}
      toggle={close}
    >
      <>
        <Title>Não é possível continuar com o seu pedido!</Title>
        <Separator />

        {Boolean(productsNotAvailable.length) && (
          <>
            <Subtitle>Produtos não disponíveis</Subtitle>
            <ul>
              {productsNotAvailable.map(product => <ProductName key={product.id}>{product.name}</ProductName>)}
            </ul>
          </>
        )}

        {Boolean(productsOutOfStock.length) && (
          <>
            <Subtitle>Produtos sem estoque</Subtitle>
            <ul>
              {productsOutOfStock.map(product => <ProductName>{product.name}</ProductName>)}
            </ul>
          </>
        )}

        {Boolean(productsNotEnoughStock.length) && (
          <>
            <Subtitle>Produtos com baixo estoque</Subtitle>
            <ul>
              {productsNotEnoughStock.map(product => <ProductName>{product.name} - Qtd disponível: {product.quantityAvailableToBuy}</ProductName>)}
            </ul>
          </>
        )}

        <Button type="primary" style={{ float: 'right' }} onClick={close}>
          Ok
        </Button>
      </>
    </ModalHeadLess>
  );
};

