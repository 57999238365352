import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { queryClient } from './services/queryClient';

import { App } from './App';

// Fonts
import 'fontsource-roboto';
import 'typeface-roboto';

// Styles
import 'antd/dist/antd.less';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-sliding-side-panel/lib/index.css';
import 'react-phone-number-input/style.css'
import 'react-loading-skeleton/dist/skeleton.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#31B86F',
    },
    error: {
      main: '#ff604f',
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
});

Sentry.init({
  dsn: 'https://3049ca6fc1c64518a74331c293d97bdc@o863288.ingest.sentry.io/5822185',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR}>
      <MuiThemeProvider theme={theme}>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </MuiThemeProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);