import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  padding: 10px;
  color: white;
  text-align: center;

  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  :hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;

  > div {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  
  > h2 {
    color: #4a4a4a;
    margin-top: 8px;
  }
  
  .grid-item-picture {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    object-fit: scale-down;

    border: 1px solid #d9d9d9
  }
  
  @media (max-width: 500px) {
    .grid-item-picture {
      width: 100%;
      height: 160px;
    }
  }
`;