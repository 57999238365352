import { useQuery } from 'react-query';
import { ActivityLogResponse } from '../models/ActivityLog';
import api from '../services/api';

export const ACTIVITY_LOG = 'activityLog';

export const useActivityLogs = (companyId: string) => {
  const { data, isFetching, error } = useQuery<ActivityLogResponse>(
    ACTIVITY_LOG,
    async () => {
      const response = await api.get(`api/activity-log?page=1&limit=20&companyId=${companyId}`);

      return response.data;
    });

  return {
    activityLogs: data?.data,
    pagination: data?.pagination,
    isFetching,
    error
  };
};