import styled from 'styled-components';

interface IncrementalButtonsContentProps {
  withBorder: boolean;
  isSingleElement: boolean;
}

export const IncrementalButtonsContent = styled.div<IncrementalButtonsContentProps>`
  display: flex;
  justify-content: ${(props) =>
    props.isSingleElement ? 'flex-end' : 'space-between'};
  width: 130px;
  height: 50px;
  align-items: center;

  border: ${(props) => (props.withBorder ? '1px solid #6c6c80' : '')};
  border-radius: 4px;

  button {
    height: 40px;
    width: 40px;
    background: transparent;
    cursor: pointer;
    outline: none;
    box-shadow: none;
  }

  button > span {
    color: #6c6c80;
    font-size: 20px;
    font-weight: 500;
  }

  button:disabled,
  button[disabled] {
    cursor: not-allowed;
    > span {
      opacity: 0.7;
    }
  }
`;
