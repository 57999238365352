import React, { useMemo } from 'react';

import { Header } from 'antd/lib/layout/layout';
import { Container, Dash, Image } from './styles';
import { CompanyInfo } from '../../models/User';
import { Button } from 'antd';
import { BreakPoints, useBreakpoint } from '../../hooks';

interface TopBarProps {
  companyInfo?: CompanyInfo;
  onSideMenuClick(): void;
  username: string;
};

const getCompanyName = (name?: string) => {
  return (
    <>
      <span className='company-name'>
        {name ?? 'QRpreferido'}
      </span>
      <Dash> - </Dash>
    </>
  );
}

export const TopBar = ({ companyInfo, onSideMenuClick, username }: TopBarProps) => {
  const { breakPnt } = useBreakpoint()
  const showSideMenuButton = useMemo(() => breakPnt !== BreakPoints.LG, [breakPnt]);

  return (
    <Header className="site-layout-background" style={{ padding: 0, height: '65px' }}>
      <Container>
        <div>
          {showSideMenuButton && (
            <Button
              onClick={onSideMenuClick}
              style={{ backgroundColor: '#15395b', marginRight: '20px' }}
              type="primary">
              <i
                className="fa fa-bars"
                aria-hidden="true"
              />
            </Button>
          )}

          {!showSideMenuButton && companyInfo?.logoUrl && (
            <Image src={companyInfo?.logoUrl} alt="Customer's logo" loading="lazy" />
          )}
        </div>

        <div className='company-info'>
          {breakPnt !== BreakPoints.SM && getCompanyName(companyInfo?.name)}
          <span>{username}</span>
        </div>
      </Container>
    </Header>
  );
}