import { UserLogged } from './../models/User';
import api from './api';

export const registerAction = (action: string, loggedUser: UserLogged, actionCompletedCallback?: any) => {
  if (!loggedUser) {
    actionCompletedCallback();
    return;
  }

  const { companyInfo, user } = loggedUser;

  const body = {
    action,
    userId: user._id,
    userName: user.name,
    email: user.email,
    companyId: user.company,
    companyName: companyInfo?.name ?? '',
  };

  api
    .post('api/analytics', body)
    .then(() => {
      if (actionCompletedCallback) {
        actionCompletedCallback();
      }
    })
    .catch((error) => {
      console.error('analytics-error', error);
      if (actionCompletedCallback) {
        actionCompletedCallback();
      }
    });
};
