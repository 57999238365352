import React, { useEffect, useState } from 'react';
import { ModalPage } from '../Modal';

interface InformativeOfSellsPopUpProps {
  shownPopup(): void;
}

export const InformativeOfSellsPopUp = ({ shownPopup }: InformativeOfSellsPopUpProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    shownPopup();
  };

  return (
    <ModalPage isOpen={open} toggle={handleClose} title="Pedido via WhatsApp">
      <div>
        <p>
          <strong>
            Faça já o seu pedido, através da plataforma:
          </strong>
        </p>
        <p>
          <strong>1</strong> - Escolha os itens desejados;{' '}
        </p>
        <p>
          <strong>2</strong> - Identifique-se;{' '}
        </p>
        <p>
          <strong>3</strong> - Acione o botão pedir;{' '}
        </p>
        <p>
          <strong>4</strong> - Conclua o seu pedido através do Whatsapp com o
          estabelecimento.
        </p>
      </div>
    </ModalPage>
  );
};

