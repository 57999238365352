import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .checkbox-item {
    margin-left: 10px;
  }
`;

export const PaymentLabel = styled.p`
  font-size: 16px;
  font-family: Ubuntu;
`;