import React from "react";
import { CompanyFeatures } from "../../../../../models/CompanyFeatures";
import { WhatsappOrder } from "../../../../../models/DataResponse";
import { getTotalFromOrder } from "../../../../../utils/OrderUtil";
import { SummaryList } from "../../../../../components/SummaryList";
import formatCurrency from "../../../../../utils/formatCurrency";

interface ExtractOrderProps {
  companyFeatures: CompanyFeatures | undefined;
  currency: string;
  order: WhatsappOrder;
}

export const ExtractOrder = ({ companyFeatures, currency, order }: ExtractOrderProps) => {
  const extraFees = companyFeatures?.sellsConfiguration?.extraFees ?? [];
  const { coupon, client } = order;
  const { couponFormatted, totalFormatted, subtotalFormatted } = getTotalFromOrder(currency, order);

  const getFareText = () => {
    let fareText = '';
    if (client.clientFare) {
      const { destiny, price } = client.clientFare;
      fareText = destiny;

      if (price && price !== 0) {
        fareText = `${formatCurrency(price, currency)} (${destiny})`;
      }
    }

    return fareText;
  };

  return (
    <SummaryList.SummarySubList title="Extrato">
      <SummaryList.Item visible={Boolean(coupon || client.clientFare)}>
        <SummaryList.Label>Subtotal</SummaryList.Label>
        <SummaryList.Value>{subtotalFormatted}</SummaryList.Value>
      </SummaryList.Item>

      <SummaryList.Item visible={Boolean(coupon)}>
        <SummaryList.Label>Desconto cupom</SummaryList.Label>
        <SummaryList.Value>{couponFormatted} (cupom: {coupon?.couponCode})</SummaryList.Value>
      </SummaryList.Item>

      <SummaryList.Item visible={Boolean(client.clientFare)}>
        <SummaryList.Label>Taxa de entrega</SummaryList.Label>
        <SummaryList.Value>{getFareText()}</SummaryList.Value>
      </SummaryList.Item>

      {extraFees?.map(fee => (
        <SummaryList.Item key={fee._id} visible={fee.isEnabled}>
          <SummaryList.Label>{fee.label}</SummaryList.Label>
          <SummaryList.Value>{formatCurrency(fee.value, currency)}</SummaryList.Value>
        </SummaryList.Item>
      ))}

      <SummaryList.Item>
        <SummaryList.Label>Total</SummaryList.Label>
        <SummaryList.Value>{totalFormatted}</SummaryList.Value>
      </SummaryList.Item>
    </SummaryList.SummarySubList>
  );
};
