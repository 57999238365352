import styled from 'styled-components';

export const Content = styled.div``;

export const FooterHint = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const PageTitle = styled.h1`
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: left;
  font-size: 32px;
`;

export const Separator = styled.hr`
  border-top: 2px solid gray;
  margin-top: 20px;
  margin-bottom: 12px;
`;
