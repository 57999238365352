import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import moment from 'moment';

import { ContentHeader } from '../../../components/ContentHeader';
import { OrderCounters } from '../../../components/OrderCounters';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../components/Notification';
import { PanelResult, PanelResultStatus } from '../../../components/PanelResult';

import { OrderDetails } from '../OrderDetails';
import { TableOrders } from './components/Table';

import api from '../../../services/api';
import { MenuOption } from '../../../models/MenuOption';
import { WhatsappOrder } from '../../../models/DataResponse';
import { getOperatingSystem } from '../../../utils/Util';
import { SERVER_DATE_FORMAT } from '../../../utils/dateTime';

import { Role } from '../../../models/Enum';
import { OrderStatus } from '../../../models/OrderStatus';
import { useCompanyFeatures, useFeatures, useListCompaniesForCustomers, useLoggedUser, useOrderAudioNotification } from '../../../hooks';
import { baseURL } from '../../../services/endpoints';
import { useCheckPendingOrders } from './hooks/useCheckPendingOrders';
import { CounterLabelContainer } from '../../../common-styles';

export const OrdersPage = () => {
  const today = moment(new Date()).format(SERVER_DATE_FORMAT);

  // Deprecated - To be removed when we replace with hooks + react-query
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forceUpdateList, setForceUpdateList] = useState<boolean>(false);
  const [isShowingDetails, setShowingDetails] = useState<boolean>(false);
  // Deprecated

  const [orders, setOrders] = useState<WhatsappOrder[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<WhatsappOrder>(
    {} as WhatsappOrder
  );
  const [pendingOrders, setPendingOrders] = useState<number>(0);

  const navigate = useNavigate();
  const { userLogged } = useLoggedUser();
  const { data: companies } = useListCompaniesForCustomers();

  const { company: companyId, role } = userLogged.user;

  const { data: features, isFetching: isFetchingFeatures } = useFeatures(companyId);
  const { companyFeatures, isFetching: isFetchingCompanyFeatures } = useCompanyFeatures(companyId);
  const { isPlaying, resetAudio, toggleAudio } = useOrderAudioNotification()

  useCheckPendingOrders(today);

  const isRegularUser = role === Role.USER;
  const isLoadingPage = isLoading || isFetchingFeatures || isFetchingCompanyFeatures;
  const currency = companyFeatures?.sellsConfiguration.currency || 'BRL'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52

  useEffect(() => {
    const socket = io(baseURL);

    const handleNewOrder = (data: any) => {
      const hasNewOrder = isRegularUser ? (data === companyId) : companies?.some(item => item._id === data);

      if (hasNewOrder) {
        NotificationDispatcher({
          message: 'Novo pedido recebido.',
        });

        if (!isPlaying) {
          toggleAudio();
        }
        setForceUpdateList((prevValue) => !prevValue);
      }
    };

    const handleOrderUpdated = (data: any) => {
      const { companyId: id } = data;
      const hasUpdatedOrder = isRegularUser ? (id === companyId) : companies?.some(item => item._id === id);

      if (hasUpdatedOrder) {
        setForceUpdateList((prevValue) => !prevValue);

        if (pendingOrders <= 1 && isPlaying) {
          toggleAudio();
        }
      }
    };

    socket.on('newOrder', handleNewOrder);
    socket.on('orderUpdated', handleOrderUpdated);

    return () => {
      socket.off('newOrder', handleNewOrder);
      socket.off('orderUpdated', handleOrderUpdated);
      socket.disconnect(); // Disconnect socket connection
      resetAudio();
    };
  }, [companyId, companies, isPlaying, isRegularUser, pendingOrders, resetAudio, toggleAudio]);

  useEffect(() => {
    setIsLoading(true);

    if (!isRegularUser && companies) {
      const { user: { role, companyAssociation } } = userLogged;

      let ids = companies.map(company => company._id);
      if (role === Role.KITCHEN && companyAssociation.length > 0) {
        ids = companyAssociation.map(c => c.companyId);
      }

      const body = {
        startDate: today,
        endDate: today,
        ids: JSON.stringify(ids),
      }

      api
        .post('/api/orders/aggregated', body)
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data as WhatsappOrder[];
            const orders = data.filter(order => order.status !== OrderStatus.CONCLUDED && order.status !== OrderStatus.CANCELLED).concat(
              data
                .filter(order => order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED)
                .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            );
            setOrders(orders);
            setPendingOrders(orders.filter(order => order.status === OrderStatus.PENDING).length);
          } else {
            NotificationDispatcher({
              message: 'Não foi possível obter os dados.',
              type: NotificationType.ERROR,
            });
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          NotificationDispatcher({
            message: 'Não foi possível obter os dados.',
            type: NotificationType.ERROR,
          });
        });
    } else {
      api
        .get(`api/orders/${companyId}?startDate=${today}&endDate=${today}`)
        .then((response) => {
          if (response.data.success) {
            const data = response.data.data as WhatsappOrder[];
            const orders = data.filter(order => order.status !== OrderStatus.CONCLUDED && order.status !== OrderStatus.CANCELLED).concat(
              data
                .filter(order => order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED)
                .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            );
            setOrders(orders);
            setPendingOrders(orders.filter(order => order.status === OrderStatus.PENDING).length);
          } else {
            NotificationDispatcher({
              message: 'Não foi possível obter os dados.',
              type: NotificationType.ERROR,
            });
          }

          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          NotificationDispatcher({
            message: 'Não foi possível obter os dados.',
            type: NotificationType.ERROR,
          });
        });
    }
  }, [companyId, companies, forceUpdateList, isRegularUser, userLogged, today]);

  const isDesktop = useMemo(() => {
    return getOperatingSystem() === 'Desktop';
  }, []);

  const modalDismissed = (withAction: boolean) => {
    setShowingDetails(false);
    if (withAction) {
      setForceUpdateList(!forceUpdateList);
    }
  };

  const handleItemClick = (order: WhatsappOrder) => {
    if (isDesktop) {
      setShowingDetails(true);
      setSelectedOrder(order);
    } else {
      navigate('/sysadmin/order-detail', {
        state: {
          order,
          companyId,
        },
      });
    }
  };

  if (!isLoadingPage && !features?.sellViaWhatsapp && isRegularUser) {
    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title="Para ter acesso a esta funcionalidade, é preciso ativar o módulo de pedidos."
        subtitle="Entre já em contato com a equipe QRcode Preferido para mais informações."
      />
    );
  }

  const renderOrderContent = () => {
    return (
      <>
        <ContentHeader title={MenuOption.WHATSAPP_ORDERS} />

        {orders.length > 0 && (
          <CounterLabelContainer>
            <OrderCounters orders={orders} currency={currency} />
          </CounterLabelContainer>
        )}

        <TableOrders
          key="orders-table"
          companyId={companyId}
          currency={currency}
          handleItemClick={handleItemClick}
          isLoadingPage={isLoadingPage}
          orders={orders}
          toggleAudio={() => {
            if (pendingOrders <= 1 && isPlaying) {
              toggleAudio();
            }
          }}
        />
      </>
    );
  };
  
  return (
    <>
      {isShowingDetails ? (
        <OrderDetails
          modalDismissed={modalDismissed}
          order={selectedOrder}
        />
      ) : (
        renderOrderContent()
      )}
    </>
  );
};
