import styled, { keyframes } from 'styled-components';
import { ImageDimension } from '../../../models/DataResponse';

interface DimensProps {
  dimens?: ImageDimension;
}

const animate = keyframes`
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    opacity: .3;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const Container = styled.div`
  max-width: 650px;
  margin: 0 auto;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
  text-align: left;
  padding-bottom: 40px;

  background-color: #fff;
`;

export const LogoContent = styled.img<DimensProps>`
  height: ${(props) => (props.dimens?.height ? props.dimens?.height : '180px')};
  width: ${(props) => (props.dimens?.width ? props.dimens?.width : '80%')};
  object-fit: ${(props) =>
    props.dimens?.objectFit ? props.dimens?.objectFit : 'scale-down'};

  @media (max-width: 500px) {
    height: ${(props) =>
    props.dimens?.mobileHeight ? props.dimens?.mobileHeight : '20vh'};
    width: ${(props) => props.dimens?.mobileWidth};
    object-fit: ${(props) => props.dimens?.mobileObjectFit};
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
`;

export const CustomLink = styled.a`
  background: #31b86f;
  color: #ffffff;

  border-radius: 20px;
  padding: 10px 20px;
  margin-top: 16px;

  width: 60%;

  text-align: center;
  animation: ${animate} 0.5s ease;

  &:visited {
    color: #ffffff;
  }

  &:hover {
    background: gray;
    opacity: 0.7;
    transform: translateX(10px);
  }
`;

export const Title = styled.h1`
  margin-top: 10px;
  color: #4a4a4a;
  color: ${(props) => props.color};
  text-align: center;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: #4a4a4a;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: 500px) {
    width: 90%;
    grid-template-columns: 1fr;
  }
`;

export const ImageItem = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin-left: 16px;
  object-fit: cover;
`;