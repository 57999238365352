import { OrderStatusResponse } from '../models/OrderStatus';
import { useFetch } from './useFetch';

const ORDER_STATUS = 'orderStatus';

export const useOrderStatus = (slug?: string, orderId?: string) => {
  const { data, ...rest } = useFetch<OrderStatusResponse>({
    isPublicAPI: true,
    queryKey: ORDER_STATUS,
    url: `api/orders/status?slug=${slug}&orderId=${orderId}`,
  });

  return { data, ...rest };
};
