import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer } from 'antd';

import TimePickers from '../../../../../../../components/time-picker';
import WeekdaysList from '../../../../../../../components/WeekdaysList';

import { WorkHours } from '..';

import { CompanyFeatures } from '../../../../../../../models/CompanyFeatures';
import { OpeningHours } from '../../../../../../../models/DataResponse';
import { MIDNIGHT } from '../../../../../../../utils/Constants';

import {
  HourErrorMessage,
  ScheduleContainer,
  ScheduleContent,
} from '../../../../../../../common-styles';

type WorkSchedulePageDetailProps = {
  isOpen: boolean;
  selectedOpenHours: OpeningHours | null;
  startWorkHour: WorkHours;
  startWeekdays: number[];
  companyFeatures: CompanyFeatures;
  pageCallbackResult(updatedFeatures: CompanyFeatures | null): void;
};

const WorkSchedulePageDetail: React.FC<WorkSchedulePageDetailProps> = ({
  selectedOpenHours,
  startWorkHour,
  startWeekdays,
  isOpen,
  companyFeatures,
  pageCallbackResult,
}) => {
  const [hoursErrorMessage, setHoursErrorMessage] = useState('');
  const [weekdays, setWeekdays] = useState<number[]>([]);
  const [workHour, setWorkHour] = useState<WorkHours>({} as WorkHours);

  useEffect(() => {
    setWorkHour(startWorkHour);
  }, [startWorkHour]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    setWorkHour({
      ...workHour,
      [id]: value,
    });
  };

  async function handleSubmitSchedule() {
    if (weekdays.length === 0) {
      setHoursErrorMessage('Escolha pelo menos 1 dia da semana.');
      return;
    }

    if (!workHour.startHour && !workHour.endHour) {
      setHoursErrorMessage('Defina o horário.');
      return;
    } else if (!workHour.startHour) {
      setHoursErrorMessage('Defina a hora inicial.');
      return;
    } else if (!workHour.endHour) {
      setHoursErrorMessage('Defina a hora final.');
      return;
    } else {
      if (
        workHour.endHour !== MIDNIGHT &&
        workHour.endHour.localeCompare(workHour.startHour) < 0
      ) {
        setHoursErrorMessage(
          'A hora final não pode ser inferior a hora inicial.'
        );
        return;
      }
    }

    setHoursErrorMessage('');

    const newSchedule: OpeningHours = {
      startHour: workHour.startHour,
      endHour: workHour.endHour,
      weekdays,
    };

    if (selectedOpenHours) {
      const index = companyFeatures.openingHours.findIndex(
        (item) => item._id === selectedOpenHours._id
      );
      newSchedule._id = selectedOpenHours._id;
      companyFeatures.openingHours[index] = newSchedule;
    } else {
      companyFeatures.openingHours = [
        ...companyFeatures.openingHours,
        newSchedule,
      ];
    }

    pageCallbackResult(companyFeatures);
  }

  return (
    <Drawer
      title="Escolha os dias e os horários"
      onClose={() => pageCallbackResult(null)}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isOpen}
    >
      <WeekdaysList
        selectedWeekdays={startWeekdays}
        handleSelectedWeekdays={(days) => setWeekdays(days)}
      />
      <ScheduleContainer style={{ marginTop: '16px' }}>
        <ScheduleContent>
          <TimePickers
            id={'startHour'}
            label="Início"
            defaultValue={startWorkHour.startHour}
            handleInputChange={handleInputChange}
          />
          <TimePickers
            id={'endHour'}
            label="Fim"
            defaultValue={startWorkHour.endHour}
            handleInputChange={handleInputChange}
          />
        </ScheduleContent>
      </ScheduleContainer>

      <HourErrorMessage>{hoursErrorMessage}</HourErrorMessage>

      <ButtonContainer>
        <Button
          type="primary"
          style={{
            marginTop: '16px',
          }}
          onClick={handleSubmitSchedule}
        >
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Salvar
        </Button>
        &nbsp;&nbsp;&nbsp;
        <Button danger onClick={() => pageCallbackResult(null)}>
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Cancelar
        </Button>
      </ButtonContainer>
    </Drawer>
  );
};

export default WorkSchedulePageDetail;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
`;
