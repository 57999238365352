import React, { useState, useEffect, useMemo } from 'react';

import { Form, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { ContentHeader } from '../../../components/ContentHeader';

import AnalyticList from './AnalyticsList';

import { MenuOption } from '../../../models/MenuOption';
import { Company } from '../../../models/Company';
import api from '../../../services/api';

export const AdminActivityLogPage = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');

  useEffect(() => {
    api.get('/api/company?status=active').then((response) => {
      const data = response.data.data as Company[];
      setCompanies(
        data.sort((a, b) => a.fantasyName.localeCompare(b.fantasyName))
      );
    });
  }, []);

  const companiesName = useMemo(() => {
    const names = companies.map((company) => {
      return {
        value: company._id,
        label: company.fantasyName,
      };
    });
    names.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: 'Últimos acessos',
    });
    return names;
  }, [companies]);

  const handleSelectedCompany = (companyId: string) => {
    setSelectedCompanyId(companyId);
  };

  return (
    <>
      <ContentHeader title={MenuOption.ACTIVITY_LOG}>
        <Form.Item label="Filtrar por empresa">
          <Select
            defaultValue={selectedCompanyId}
            onChange={handleSelectedCompany}
            showSearch
            suffixIcon={<CaretDownOutlined />}
          >
            {companiesName.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </ContentHeader>

      <AnalyticList selectedCompanyId={selectedCompanyId} />
    </>
  );
};
