import React, { useEffect, useMemo, useState } from 'react';

import { FeedbackTable } from './components/Table';
import { ContentHeader } from '../../../components/ContentHeader';
import { FeedbackLink } from './components/FeedbackLink';

import { MenuOption } from '../../../models/MenuOption';
import { Role } from '../../../models/Enum';
import { DEFAULT_SELECT_COMPANY } from '../../../utils/Constants';
import { useLoggedUser } from '../../../hooks';
import { FeedbackFilters } from './components/Filters';

export const FeedbackPage = () => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(DEFAULT_SELECT_COMPANY);

  const { userLogged } = useLoggedUser();

  const isAdminOrManager = userLogged.user.role === Role.ADMIN || userLogged.user.role === Role.MANAGER;

  const companyId = useMemo(() => {
    if (!isAdminOrManager && userLogged.user.company) {
      return userLogged.user.company;
    }
    return selectedCompanyId;
  }, [isAdminOrManager, selectedCompanyId, userLogged]);

  useEffect(() => {
    if (userLogged.user.company) {
      setSelectedCompanyId(userLogged.user.company);
    }
  }, [userLogged.user.company]);

  return (
    <>
      <ContentHeader title={MenuOption.FEEDBACK}>
        {!isAdminOrManager ? <FeedbackLink /> : null}
      </ContentHeader>

      <FeedbackFilters
        companyId={companyId}
        onCompanySelect={(selected) => setSelectedCompanyId(selected)}
      />

      <FeedbackTable companyId={selectedCompanyId} />
    </>
  );
};
