import React from "react";
import { Space } from "antd";
import { EyeOutlined } from '@ant-design/icons';

import { WhatsappOrder } from "../../../../../../../models/DataResponse";
import { TableGenericAction } from "../../../../../../../components/TableGenericAction";
import { OrderStatus } from "../../../../../../../models/OrderStatus";
import { NotificationDispatcher, NotificationType } from "../../../../../../../components/Notification";
import { useUpdateOrder } from "../../../../../../../hooks";

interface OrderTableActionsProps {
  handleItemClick(order: WhatsappOrder): void;
  order: WhatsappOrder;
  width?: string;
}

export const OrderTableActions = ({ handleItemClick, order, width }: OrderTableActionsProps) => {

  const { update } = useUpdateOrder();

  const showErrorMessage = (message: string) => {
    NotificationDispatcher({
      message,
      type: NotificationType.ERROR,
    });
  };

  const handleUpdateStatus = async (newStatus: string) => {
    update(
      {
        ...order,
        status: newStatus,
      },
      {
        onSuccess: () => {
          NotificationDispatcher({
            message: 'Operação realizada com sucesso',
          });
        },
        onError: () => {
          showErrorMessage('Falha ao tentar atualizar o status do Pedido')
        }
      }
    );
  };

  const getStyleForStatusConcluded = (status: string) => {
    switch (status) {
      case OrderStatus.PENDING:
      case OrderStatus.IN_PROGRESS:
      case OrderStatus.IN_TRANSIT:
      case OrderStatus.READY:
        return {
          backgroundColor: '#2fb86e',
          color: '#fff',
        };
      default: return {};
    }
  }

  return (
    <>
      <Space direction="vertical" key={order._id} style={{ width: width ?? '180px' }}>
        <TableGenericAction
          icon={<EyeOutlined style={{ fontSize: '18px' }} />}
          label="Detalhes"
          onClick={() => handleItemClick(order)}
        />

        <TableGenericAction
          dashed
          disabled={order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED}
          icon={<i
            className='fa fa-handshake-o'
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />}
          label={'Entregar'}
          style={getStyleForStatusConcluded(order.status)}
          onClick={() => {
            if (order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED) {
              return;
            }

            handleUpdateStatus(OrderStatus.CONCLUDED);
          }}
        />

        <TableGenericAction
          dashed
          disabled={order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED}
          icon={<i
            className='fa fa-times'
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />}
          label={'Cancelar'}
          style={order.status === OrderStatus.CONCLUDED || order.status === OrderStatus.CANCELLED ? {} : {
            backgroundColor: '#b82f2f',
            color: '#fff',
          }}
          onClick={() => {
            handleUpdateStatus(OrderStatus.CANCELLED);
          }}
        />
      </Space>
    </>
  );
}