import React from "react";
import { Divider, Menu } from 'antd';
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { confirmAlert } from "react-confirm-alert";

import { MenuOption } from "../../../../models/MenuOption";
import { Role } from "../../../../models/Enum";
import { useLoggedUser } from "../../../../hooks";
import { useSidebarMenuKeySelection } from "../../hooks/useSidebarMenuKeySelection";

import { registerAction } from "../../../../services/internalAnalytics";
import { LocalStorageService } from "../../../../services/LocalStorageService";

import { adminMenuOptions } from "../AdminMenuOptions";
import { clientMenuOptions } from "../ClientMenuOptions";
import { kitchenMenuOptions } from "../KitchenMenuOptions";
import { driverMenuOptions } from "../DriverMenuOptions";
import { managerMenuOptions } from "../ManagerMenuOptions";

import minLogoImg from '../../../../assets/images/icon-qrcode.png';
import logoImg from '../../../../assets/images/logo-bg-transparent-text-white.png';

interface SideBarMenuContentProps {
  collapsed?: boolean;
  contactsBadgeCounter?: number;
  onMenuSelected?(): void;
  stockCounter?: any;
};

export const SideBarMenuContent = ({
  collapsed = false,
  contactsBadgeCounter = 0,
  onMenuSelected,
  stockCounter = 0,
}: SideBarMenuContentProps) => {

  const { currentSelectedMenu, handleMenuSelection } = useSidebarMenuKeySelection();
  const { userLogged } = useLoggedUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { companyInfo, user: { role } } = userLogged;
  const menuUrl = companyInfo?.menuUrl ?? 'clientes';

  const handleLogout = () =>
    confirmAlert({
      title: 'Tem certeza que deseja sair do sistema?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            registerAction('Logout', userLogged, () => {
              LocalStorageService.clearAll();
              queryClient.clear();
              navigate('/session');
            });
          },
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
      closeOnClickOutside: false,
    });

  const renderItemsByRole = () => {
    switch (role) {
      case Role.ADMIN:
        return adminMenuOptions(
          collapsed,
          contactsBadgeCounter,
        );

      case Role.USER:
        return clientMenuOptions(
          collapsed,
          menuUrl,
          stockCounter,
        );

      case Role.KITCHEN:
        return kitchenMenuOptions(collapsed);

      case Role.DRIVER:
        return driverMenuOptions(collapsed);

      case Role.MANAGER:
        return managerMenuOptions(collapsed);
    }
  }

  const getDefaultSelectedKeys = () => {
    let defaultKey = MenuOption.CATEGORIES;

    if (role === Role.ADMIN) {
      defaultKey = MenuOption.COMPANY;
    } else if (role === Role.MANAGER || role === Role.KITCHEN || role === Role.DRIVER) {
      defaultKey = MenuOption.WHATSAPP_ORDERS;
    }

    return [defaultKey];
  }

  const handleMenuClick = (selectedMenu: string) => {
    if (onMenuSelected) {
      onMenuSelected();
    }

    if (selectedMenu === MenuOption.MY_LINK) return;

    const menuSelected = selectedMenu as MenuOption;
    handleMenuSelection(menuSelected);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <>
      <div className="sidebar-logo-container">
        <img
          className={collapsed ? 'admin-sidebar-logo_collapsed' : "admin-sidebar-logo"}
          src={collapsed ? minLogoImg : logoImg}
          alt="QRcode Preferido"
        />
      </div>

      <Menu
        defaultSelectedKeys={getDefaultSelectedKeys()}
        style={{ background: '#15395b' }}
        mode="inline"
        onClick={({ key }) => handleMenuClick(key)}
        theme="dark"
        selectedKeys={currentSelectedMenu ? [currentSelectedMenu] : undefined}
      >
        {renderItemsByRole()}

        {role !== Role.USER && <Divider key="divider" style={{ backgroundColor: 'white' }} />}

        {(role !== Role.KITCHEN && role !== Role.DRIVER) && (
          <li className="ant-menu-item" role="menuitem" style={{ paddingLeft: '24px' }}>
            <span role="img" aria-label="bulb" className="anticon anticon-bulb ant-menu-item-icon">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="bulb" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M632 888H392c-4.4 0-8 3.6-8 8v32c0 17.7 14.3 32 32 32h192c17.7 0 32-14.3 32-32v-32c0-4.4-3.6-8-8-8zM512 64c-181.1 0-328 146.9-328 328 0 121.4 66 227.4 164 284.1V792c0 17.7 14.3 32 32 32h264c17.7 0 32-14.3 32-32V676.1c98-56.7 164-162.7 164-284.1 0-181.1-146.9-328-328-328zm127.9 549.8L604 634.6V752H420V634.6l-35.9-20.8C305.4 568.3 256 484.5 256 392c0-141.4 114.6-256 256-256s256 114.6 256 256c0 92.5-49.4 176.3-128.1 221.8z"></path>
              </svg>
            </span>
            <span className="ant-menu-title-content">
              <a href="https://drive.google.com/file/d/18j-PaNqceCA9OmmDuVCExSV9D4sKWyIx/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontWeight: ' bold', fontSize: '14px' }}>Tutorial</a>
            </span>
          </li>
        )}

        <li className="ant-menu-item" role="menuitem" style={{ paddingLeft: '24px' }} onClick={handleLogout}>
          <span role="img" aria-label="logout" className="anticon anticon-logout ant-menu-item-icon">
            <svg viewBox="64 64 896 896" focusable="false" data-icon="logout" width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 01-112.7 75.9A352.8 352.8 0 01512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 01-112.7-75.9 353.28 353.28 0 01-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z"></path>
            </svg>
          </span>
          <span className="ant-menu-title-content" style={{ color: 'white', fontWeight: ' bold', fontSize: '14px' }}>
            Sair
          </span>
        </li>
      </Menu>
    </>
  );
}
