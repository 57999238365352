import React, { useEffect } from "react";
import { useListProductsOutOfStock } from "./useListProductsOutOfStock";
import { useLoggedUser } from "./useLoggedUser";
import { NotificationDispatcher, NotificationType } from "../components/Notification";

export const useStockAlert = () => {
  const { data: loggedUser } = useLoggedUser();
  const { data: stock, isLoading } = useListProductsOutOfStock(loggedUser?.user?.company);

  useEffect(() => {
    if (isLoading) return;

    if (Boolean(stock?.listOutOfStock.length)) {
      const withoutStock = <ul>{stock?.listOutOfStock.map(item => <li>{item.name} </li>)}</ul >;

      NotificationDispatcher({
        message: 'Produtos sem estoque',
        description: withoutStock,
        type: NotificationType.ERROR,
      });
    }

    if (Boolean(stock?.listLowStock.length)) {
      const lowStock = <ul>{stock?.listLowStock.map(item => <li>{item.name} </li>)}</ul >;

      NotificationDispatcher({
        message: 'Produtos baixo estoque',
        description: lowStock,
        type: NotificationType.WARNING,
      });
    }
  }, [isLoading, stock]);
};