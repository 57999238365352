import { useEffect, useState } from "react";
import { useGetOrderMessage } from "./useGetOrderMessage";
import { confirmAlert } from "react-confirm-alert";
import { sendWhatsAppMessage } from "../../../../utils/SendWhatsappMessageUtil";
import { MenuConfiguration } from "../../../../models/DataResponse";
import { ProductStore } from "../../../../hooks/ProductStore/productsStore";
import { LocalStorageService, PAYMENT_EASYPAY } from "../../../../services/LocalStorageService";
import { getComplementOrderMessage } from "../../../../utils/OrderUtil";
import { OrderFromPayment } from "../components/OrdersViaWhatsapp/models/EasypayPayment";

interface UseOrderSendMessageProps {
  companySlug: string | undefined;
  menuConfiguration: MenuConfiguration | undefined;
}

export const useOrderSendMessage = ({ companySlug, menuConfiguration }: UseOrderSendMessageProps) => {
  const [order, setOrder] = useState<OrderFromPayment>();
  const { data: orderMessage, isLoading, setOrderId } = useGetOrderMessage();

  useEffect(() => {
    if (!order) return;

    setOrderId(order.id);
  }, [order, setOrderId]);

  useEffect(() => {
    if (!orderMessage || !menuConfiguration || !order) return;

    const sendMessage = () => {
      const message = getComplementOrderMessage(
        menuConfiguration.companyFeatures.sellsConfiguration.sendLinkToFollowOrder,
        orderMessage.message,
        companySlug,
        order.orderId,
      )

      sendWhatsAppMessage(message, menuConfiguration?.whatsappNumber ?? '');
      ProductStore.clearProducts();
      LocalStorageService.clear(PAYMENT_EASYPAY);
    };

    confirmAlert({
      title: 'Pronto!',
      message: menuConfiguration?.companyFeatures.informingOrderWasSentToWhatsappMessage,
      buttons: [
        {
          label: 'Ok',
          onClick: () => sendMessage(),
        },
      ],
      closeOnClickOutside: false,
      onKeypressEscape: () => sendMessage(),
    });

  }, [menuConfiguration, orderMessage, order, companySlug]);

  return {
    isLoading,
    setOrder,
  };
};