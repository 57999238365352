import React from "react";

interface FormHelperProps {
  label: string;
  style?: React.CSSProperties;
}

export const FormHelper = ({ label, style }: FormHelperProps) => {
  style = style ?? { marginTop: '10px', fontStyle: 'italic' };
  return (
    <p style={style}>{label}</p>
  );
}