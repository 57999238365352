import React, { useEffect, useState } from 'react';

import { CustomerForm } from './components/CustomerForm';
import { LoaderSpinner } from '../../../components/LoaderSpinner';
import { Footer } from '../../../components/Footer';

import api from '../../../services/api';

import {
  Content,
  FormContainer,
  ImageHolder,
  LoadingContainer,
  LogoContainer,
  RequestDemoButton,
  TitlePage,
} from './styles';

interface ClientData {
  name: string;
  logo: string;
  menuUrl: string;
}

export const CustomersPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [isShowingForm, setShowingForm] = useState(false);
  const [data, setData] = useState<ClientData[]>([]);

  useEffect(() => {
    api
      .get(`api/boot/clients`)
      .then((response) => {
        const clients = response.data.data as ClientData[];
        clients.sort((a, b) => a.name.localeCompare(b.name));
        setData(clients);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fail loding menu', error);
      });
  }, []);

  return (
    <div id="page-internal-page">
      <div className="content">
        <main className="main-internal-page">
          <LogoContainer
            onClick={() => {
              window.open('https://qrcodepreferido.com/', '_blank');
            }}
          >
            <img
              className="logo-specs"
              src={require('../../../assets/images/logo-bg-transparent-text-white.png')}
              alt="QRcode Preferido"
            />
          </LogoContainer>

          <TitlePage>
            <h1>Nossos Clientes</h1>
          </TitlePage>

          {isLoading ? (
            <LoadingContainer>
              <LoaderSpinner isSmall />
            </LoadingContainer>
          ) : (
            <Content>
              {data
                .filter((item) => item.logo)
                .map((menuItem) => {
                  return (
                    <div>
                      <ImageHolder
                        src={menuItem.logo}
                        onClick={() => {
                          const baseUrl = 'https://app.qrcodepreferido.com/';
                          window.open(
                            `${baseUrl}${menuItem.menuUrl}`,
                            '_blank'
                          );
                        }}
                      />
                    </div>
                  );
                })}
            </Content>
          )}

          {!isLoading && (
            <FormContainer>
              {isShowingForm ? (
                <CustomerForm />
              ) : (
                !isLoading && (
                  <RequestDemoButton onClick={() => setShowingForm(true)}>
                    Adquira já!
                  </RequestDemoButton>
                )
              )}
            </FormContainer>
          )}

          <Footer
            instagram="https://instagram.com/qrcodepreferido"
            facebook="https://facebook.com/QRcode-Preferido-108736257559674/"
            whatsapp="https://api.whatsapp.com/send?phone=351910919855&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20o%20QRcode%20Preferido."
            phone="351910919855"
          />
        </main>
      </div>
    </div>
  );
};
