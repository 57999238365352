import React, { useEffect, useState } from 'react';

import { Popconfirm, Popover, Space, Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { ComplementOfProduct } from '../../../../../../../../models/ProductCatalog';
import { ActionType } from '../../../../../../../../models/Enum';
import { TableEditAction } from '../../../../../../../../components/TableEditAction';
import { TableCloneAction } from '../../../../../../../../components/TableCloneAction';
import { TableDeleteAction } from '../../../../../../../../components/TableDeleteAction';
import { ExpandedComplementItems } from '../ExpandedItems';
import { RoundTag } from '../../../../../../../../common-styles';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => (
  <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: 'rgb(153, 153, 153)' }} />
));

interface ComplementTableProps {
  handleAction(item: ComplementOfProduct, actionType: ActionType): void;
  items: ComplementOfProduct[];
  onTableChanged(items: ComplementOfProduct[]): void;
}

export const ComplementTable = ({
  handleAction,
  items,
  onTableChanged,
}: ComplementTableProps) => {
  const [dataSource, setDataSource] = useState<ComplementOfProduct[]>([]);

  useEffect(() => {
    setDataSource(items);
  }, [items]);

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: 'Status',
      dataIndex: 'isEnabled',
      width: 80,
      render: (_: any, complement: ComplementOfProduct) => {
        const color = complement.isEnabled ? 'green' : 'red';
        return (
          <RoundTag color={color} key={complement._id}>
            {complement.isEnabled ? 'Ativo' : 'Pausado'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'isRequired',
      width: 100,
      render: (_: any, complement: ComplementOfProduct) => {
        const color = complement.isRequired ? '#717171' : undefined;
        return (
          <RoundTag color={color} key={complement._id}>
            {complement.isRequired ? 'OBRIGATÓRIO' : 'OPCIONAL'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Título',
      dataIndex: 'title',
    },
    {
      title: 'N. Itens',
      dataIndex: 'options',
      render: (_: any, complement: ComplementOfProduct) => {
        return (
          complement.complementItemOptions && complement.complementItemOptions.length ?
            <Popover content={<ExpandedComplementItems items={complement.complementItemOptions ?? []} />}>
              <span style={{ fontSize: '16px', paddingRight: '6px' }}>{complement.complementItemOptions?.length}</span>
              <i className="fa fa-info-circle" aria-hidden="true" />
            </Popover>
            : null
        );
      },
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      width: 150,
      render: (_: any, complement: ComplementOfProduct) => (
        <Space direction="vertical" key={complement._id}>
          <TableEditAction
            onClick={() => handleAction(complement, ActionType.UPDATE)}
          />
          <Popconfirm
            placement="bottomRight"
            title={
              <span>
                Tem certeza que deseja duplicar este complemento{' '}
                <strong>{complement.title}</strong>?
              </span>
            }
            onConfirm={() => handleAction(complement, ActionType.CLONE)}
            okText="Sim"
            cancelText="Não"
          >
            <TableCloneAction onClick={() => { }} />
          </Popconfirm>

          <TableDeleteAction
            onClick={() => handleAction(complement, ActionType.DELETE)}
            title={
              <span>
                Tem certeza que deseja excluir o complemento <strong>{complement.title}</strong>? <br />
                Uma vez removido, esta ação não poderá ser revertida.
              </span>
            }
          />
        </Space>
      ),
    },
  ];

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        ([] as ComplementOfProduct[]).concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      onTableChanged(newData);
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerWrapper
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={[...dataSource]}
      columns={columns}
      rowKey="index"
      bordered
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};
