import styled from 'styled-components';

interface LabelProps {
  isMobile: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OkButton = styled.button`
  height: 50px;
  width: 100%;

  background: #2fb86e;
  border-radius: 4px;

  margin-top: 20px;

  cursor: pointer;
  outline: none;
  box-shadow: none;

  :hover {
    background: #088a08;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div > span {
    color: white;
    font-weight: 500;
    font-size: 18px;
  }
`;

export const Title = styled.p<LabelProps>`
  color: #3f3e3e;
  font-size: ${props => props.isMobile ? '18px' : '22px'};
  font-weight: bold;
  margin-top: 16px;
`;

export const Subtitle = styled.p<LabelProps>`
  color: #3f3e3e;
  font-size: ${props => props.isMobile ? '14px' : '16px'};
  padding-bottom: 16px;
  margin-top: 20px;
  text-align: center;
`;
