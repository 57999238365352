import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import parse from 'html-react-parser';
import * as Sentry from '@sentry/browser';

import { Button, Drawer, Form, Radio, Tabs } from 'antd';

import ComplementItem from './components/ComplementItem';
import ComplementTable from './components/ComplementTable';
import { FormInputField } from '../../../../../../../components/FormInputField';
import { FormRadioGroup } from '../../../../../../../components/FormRadioGroup';

import {
  ComplementOfProduct,
  ComplementItemOption,
} from '../../../../../../../models/ProductCatalog';
import { FormLayout } from '../../../../../../../utils/Styles';

import { PageDetailsButtonsContainer } from '../../../../../../../common-styles';
import { ErrorMessage } from './styles';

type ProductComplementProps = {
  isComplementPageVisible: boolean;
  hasSellViaWhatsappEnabled: boolean;
  selectedComplement?: ComplementOfProduct;
  handleComplementSubmission(
    complement: ComplementOfProduct,
    isUpdate: boolean
  ): void;
  onClose(): void;
};

const DESCRIPTION_CHARACTER_LIMIT = 100;
const TITLE_CHARACTER_LIMIT = 45;

const itemTypesOptions = [
  { label: 'Obrigatório', value: true },
  { label: 'Opcional', value: false },
];

const itemOptionOptions = [
  { label: 'Única opção', value: true },
  { label: 'Multiplas opções', value: false },
];

const { TabPane } = Tabs;

export const ProductComplement = ({
  isComplementPageVisible,
  selectedComplement,
  hasSellViaWhatsappEnabled,
  handleComplementSubmission,
  onClose,
}: ProductComplementProps) => {
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [isRequired, setRequired] = useState(true);
  const [isSingleChoice, setSingleChoice] = useState(true);
  const [isEnabled, setEnabled] = useState(
    selectedComplement ? selectedComplement.isEnabled : true
  );
  const [currentTab, setCurrentTab] = useState('1');
  const [isComplementPageOpen, setComplementPageOpen] =
    useState<boolean>(false);

  const [complementItemOptions, setComplementItemOptions] = useState<
    ComplementItemOption[]
  >([]);
  const [currentComplementItemOption, setCurrentComplementItemOption] =
    useState<ComplementItemOption>();

  useEffect(() => {
    if (selectedComplement) {
      setEnabled(selectedComplement.isEnabled);
      setRequired(selectedComplement.isRequired);
      setSingleChoice(selectedComplement.isSingleChoice);

      const itemOptions = selectedComplement.complementItemOptions.map(
        (item, index) => {
          return {
            ...item,
            index,
          };
        }
      );
      setComplementItemOptions(itemOptions);
    }
  }, [selectedComplement]);

  function addNewComplementItem() {
    setCurrentComplementItemOption(undefined);
    setComplementPageOpen(true);
  }

  const executeDeletion = (item: ComplementItemOption) => {
    const index = complementItemOptions.indexOf(item);

    const list = [...complementItemOptions];
    list.splice(index, 1);
    setComplementItemOptions(list);
  };

  function handleComplementItem(item: ComplementItemOption) {
    const complementItem = {
      title: item.title,
      description: item.description,
      price: item.price && item.price !== undefined ? Number(item.price) : 0,
      isEnabled: item.isEnabled !== undefined ? item.isEnabled : true,
      index:
        currentComplementItemOption !== undefined
          ? currentComplementItemOption.index
          : complementItemOptions.length,
      color:
        item.color && item.color !== undefined
          ? item.color.replace('#', '')
          : '',
    } as ComplementItemOption;

    if (currentComplementItemOption === undefined) {
      // New Item
      complementItem.index = complementItemOptions.length;
      const newList = [...complementItemOptions, complementItem];
      setComplementItemOptions(newList);
    } else {
      // Update item
      const index = complementItemOptions.findIndex(
        (item) => item.index === currentComplementItemOption.index
      );
      complementItemOptions[index] = complementItem;
      setComplementItemOptions(complementItemOptions);
    }

    setFormErrorMessage('');
    setComplementPageOpen(false);
  }

  function handleSubmitComplement(formData: ComplementOfProduct) {
    if (isSingleChoice) {
      formData.requiredFieldsCount = 1;
    } else {
      if (!formData.requiredFieldsCount) {
        // TODO: remove this after analysis.
        Sentry.captureMessage('Failed submit complement requiredFieldsCount: ' + formData.requiredFieldsCount);
        setFormErrorMessage('Quantidade de opções obrigatório para multipla escolha');
        return;
      }
    }

    formData.isEnabled = isEnabled;
    formData.isRequired = hasSellViaWhatsappEnabled ? isRequired : false;
    formData.isSingleChoice = hasSellViaWhatsappEnabled ? isSingleChoice : true;
    formData.complementItemOptions = complementItemOptions;

    if (!formData._id) {
      formData._id = shortid.generate();
      formData.isTransientData = true;
    } else {
      formData.isTransientData = false;
    }

    handleComplementSubmission(formData, selectedComplement != null);
    onClose();
    resetScreenInitialState();
  }

  const resetScreenInitialState = () => {
    setCurrentTab('1');
    setComplementItemOptions([]);
  };

  function onFinish(values: any) {
    if (complementItemOptions.length === 0) {
      setFormErrorMessage('Adicione pelo menos 1 item a lista');
      setCurrentTab('2');
      return;
    }

    handleSubmitComplement({
      ...values,
    });
  }

  function onFinishFailed() {
    setCurrentTab('1');
  }

  function renderComplementItems() {
    return (
      <>
        <ComplementItem
          complementItemOption={currentComplementItemOption}
          isVisible={isComplementPageOpen}
          onClose={() => setComplementPageOpen(false)}
          handleItem={handleComplementItem}
        />

        <div style={{ marginBottom: '16px' }}>
          <Button
            type="primary"
            className="form-button"
            onClick={addNewComplementItem}
          >
            <i
              className="fa fa-plus-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Novo item
          </Button>
        </div>

        <ComplementTable
          items={complementItemOptions}
          handleSelectedComplementOption={(item) => {
            setCurrentComplementItemOption(item);
            setComplementPageOpen(true);
          }}
          handleDeleteComplementOption={(item) => executeDeletion(item)}
          onTableChanged={(items) => setComplementItemOptions(items)}
        />

        {complementItemOptions.length > 2 && (
          <div>
            <Button
              type="primary"
              className="form-button"
              onClick={addNewComplementItem}
              style={{ marginTop: '16px' }}
            >
              <i
                className="fa fa-plus-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Novo item
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <Drawer
      title={selectedComplement ? selectedComplement.title : 'Novo Complemento'}
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      size="large"
      open={isComplementPageVisible}
    >
      <Form
        {...FormLayout}
        layout="vertical"
        initialValues={selectedComplement}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Tabs
          size="large"
          activeKey={currentTab}
          onChange={(key: string) => setCurrentTab(key)}
        >
          <TabPane tab="Detalhes" key="1">
            <FormRadioGroup
              label="Disponibilidade"
              propertyId="isEnabled"
              defaultValue={isEnabled}
              handleOnChange={(checked) => setEnabled(checked)}
            />

            <FormInputField
              label="Título"
              name="title"
              placeholder="Ex: Escolha o sabor"
              maxLength={TITLE_CHARACTER_LIMIT}
              rules={[
                {
                  required: true,
                  message: 'Título obrigatório',
                },
              ]}
            />

            <FormInputField
              label="Descrição"
              name="description"
              placeholder="Peça molhos para acompanhar"
              maxLength={DESCRIPTION_CHARACTER_LIMIT}
              isInputArea={true}
              inputTextAreaProps={{ showCount: true }}
            />

            {hasSellViaWhatsappEnabled && (
              <>
                <Form.Item
                  label={
                    <label className="label-input-field">Tipo de item</label>
                  }
                  name="isRequired"
                  rules={[
                    {
                      required: true,
                      message: 'Escolha o tipo de item',
                    },
                  ]}
                >
                  <Radio.Group
                    options={itemTypesOptions}
                    value={isRequired}
                    onChange={(e) => setRequired(e.target.value)}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <label className="label-input-field">Tipo de opção</label>
                  }
                  name="isSingleChoice"
                  rules={[
                    {
                      required: true,
                      message: 'Escolha o tipo de opção',
                    },
                  ]}
                >
                  <Radio.Group
                    options={itemOptionOptions}
                    value={isSingleChoice}
                    onChange={(e) => setSingleChoice(e.target.value)}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>

                {!isSingleChoice && (
                  <FormInputField
                    label="Qtd. de opções"
                    name="requiredFieldsCount"
                    type="number"
                    rules={[
                      {
                        required: true,
                        message:
                          'Quantidade de opções obrigatório para multipla escolha',
                      },
                    ]}
                  />
                )}
              </>
            )}
          </TabPane>
          <TabPane tab="Itens" key="2">
            {renderComplementItems()}
          </TabPane>
        </Tabs>

        <ErrorMessage>
          {formErrorMessage && parse(formErrorMessage)}
        </ErrorMessage>

        <PageDetailsButtonsContainer>
          <Button danger style={{ marginRight: '10px' }} onClick={onClose}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button type="primary" htmlType="submit">
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </PageDetailsButtonsContainer>
      </Form>
    </Drawer>
  );
};
