import React, { useMemo } from 'react';
import { ProductComplementsDescription } from '../../../../../components/ProductOrderTable/components/ProductComplementsDescription';
import { SummaryList } from '../../../../../components/SummaryList';
import { WhatsappOrder } from '../../../../../models/DataResponse';
import formatCurrency from '../../../../../utils/formatCurrency';
import { getTotalFromOrder } from '../../../../../utils/OrderUtil';
import { OrderTableActions } from '../Table/components/TableActions';
import { Container } from './styles';

interface OrderCardProps {
  order: WhatsappOrder;
  currency: string;
  handleItemClick(order: WhatsappOrder): void;
}

export const OrderCard = ({
  order,
  currency,
  handleItemClick,
}: OrderCardProps) => {
  const clientInfo = order.client;

  const isCurrencyInEuro = useMemo(() => {
    return currency === '€'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
  }, [currency]);

  const renderTotalAndCouponData = () => {
    const { coupon, client } = order;
    const { couponFormatted, totalFormatted, subtotalFormatted } = getTotalFromOrder(currency, order);

    let fareText = '';
    if (client.clientFare) {
      const { destiny, price } = client.clientFare;
      fareText = `${destiny}`;
      if (price && price !== 0) {
        fareText = `${formatCurrency(price, currency)} (${destiny})`;
      }
    }

    return (
      <>
        <SummaryList.Item visible={Boolean(coupon || client.clientFare)}>
          <SummaryList.Label>Subtotal</SummaryList.Label>
          <SummaryList.Value>{subtotalFormatted}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(coupon)}>
          <SummaryList.Label>Desconto cupom</SummaryList.Label>
          <SummaryList.Value>{couponFormatted} (cupom: {coupon?.couponCode})</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(client.clientFare)}>
          <SummaryList.Label>Taxa de entrega</SummaryList.Label>
          <SummaryList.Value>{fareText}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Total</SummaryList.Label>
          <SummaryList.Value>{totalFormatted}</SummaryList.Value>
        </SummaryList.Item>
      </>
    );
  }

  return (
    <Container>
      <div>
        <h2>{`Pedido #${order.orderId}`}</h2>
        <SummaryList title="Cliente">
          <SummaryList.Item>
            <SummaryList.Label>Nome</SummaryList.Label>
            <SummaryList.Value>{clientInfo.name}</SummaryList.Value>
          </SummaryList.Item>

          <SummaryList.Item>
            <SummaryList.Label>Telefone</SummaryList.Label>
            <SummaryList.Value>{clientInfo.phone}</SummaryList.Value>
          </SummaryList.Item>

          <SummaryList.Item>
            <SummaryList.Label>{isCurrencyInEuro ? 'Morada' : 'Endereço'}</SummaryList.Label>
            <SummaryList.Value>{clientInfo.address}</SummaryList.Value>
          </SummaryList.Item>

          <SummaryList.Item visible={Boolean(clientInfo.referencePoint)}>
            <SummaryList.Label>Ponto de referência</SummaryList.Label>
            <SummaryList.Value>{clientInfo.referencePoint}</SummaryList.Value>
          </SummaryList.Item>
        </SummaryList>

        <SummaryList title="Pagamento">
          <SummaryList.Item>
            <SummaryList.Label>Forma de pagamento</SummaryList.Label>
            <SummaryList.Value>{clientInfo.paymentType}</SummaryList.Value>
          </SummaryList.Item>

          <SummaryList.Item visible={Boolean(clientInfo.moneyChange)}>
            <SummaryList.Label>Troco</SummaryList.Label>
            <SummaryList.Value>{formatCurrency(
              Number(clientInfo.moneyChange),
              currency
            )}</SummaryList.Value>
          </SummaryList.Item>

          {renderTotalAndCouponData()}
        </SummaryList>

        <SummaryList title="Produtos">
          <SummaryList.Item>
            <ul style={{ marginTop: '8px' }}>
              {order.products.map((product, index) => {
                return (
                  <li style={{
                    height: '25px',
                    marginLeft: '16px'
                  }}>
                    <ProductComplementsDescription
                      lastProduct={(order.products.length - 1) === index}
                      product={product}
                      showComplement={false}
                      showComplementTitle={false}
                    />
                  </li>
                );
              })}
            </ul>
          </SummaryList.Item>
        </SummaryList>

        <OrderTableActions
          handleItemClick={handleItemClick}
          order={order}
          width="100%"
        />
      </div>
    </Container >
  );
};