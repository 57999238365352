import React from "react";
import { ContentHeader } from "../../../components/ContentHeader";
import { MenuOption } from "../../../models/MenuOption";
import { StoresTable } from "./components/table";

export const StorekeeperPage = () => {
  return (
    <>
      <ContentHeader title={MenuOption.STORES} />

      <StoresTable />
    </>
  );
}
