import React, { useMemo, useState } from 'react';
import { Button, Skeleton } from 'antd';

import { ContentHeader } from '../../../components/ContentHeader';
import { CouponEditor } from './components/Editor';
import { CouponTable } from './components/Table';
import {
  PanelResult,
  PanelResultStatus,
} from '../../../components/PanelResult';
import { Coupon } from '../../../models/Coupon';

import { ActionType, Role } from '../../../models/Enum';
import { MenuOption } from '../../../models/MenuOption';

import { useCompanyFeatures, useFeatures, useLoggedUser, useModal } from '../../../hooks';
import { DEFAULT_SELECT_COMPANY } from '../../../utils/Constants';
import { CouponFilters } from './components/Filters';
import { UpdateCompanyFeaturesService } from '../../../services/UpdateCompanyFeaturesService';


export const CouponPage = () => {
  const [needRefreshScreen, setNeedRefreshScreen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<Coupon>();
  const [selectedCompanyId, setSelectedCompanyId] = useState(DEFAULT_SELECT_COMPANY);

  const { close, isOpen, toggle } = useModal();
  const { userLogged } = useLoggedUser();

  const isAdminOrManager = userLogged.user.role === Role.ADMIN || userLogged.user.role === Role.MANAGER;

  const companyId = useMemo(() => {
    if (!isAdminOrManager && userLogged.user.company) {
      return userLogged.user.company;
    }
    return selectedCompanyId;
  }, [isAdminOrManager, selectedCompanyId, userLogged]);

  const { companyFeatures, isFetching } = useCompanyFeatures(companyId);
  const { data: features, isFetching: isFetchingFeatures } = useFeatures(companyId);
  const isLoading = isFetching || isFetchingFeatures;

  if (!features?.sellViaWhatsapp && !isAdminOrManager) {
    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title="Para ter acesso a esta funcionalidade, é preciso ativar o módulo de pedidos."
        subtitle="Entre já em contato com a equipe QRcode Preferido para mais informações."
      />
    );
  }

  if (
    !needRefreshScreen &&
    (!companyFeatures?.sellsConfiguration?.isTelephoneRequired)
    && !isAdminOrManager
  ) {
    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title="Para iniciar o uso dos cupons, o número de telefone precisa estar marcado como obrigatório."
        subtitle="Esse opção é configurável na página de configurações do sistema. Mas podemos te ajudar a fazer isso agora. Deseja ativar essa configuração?"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              companyFeatures!.sellsConfiguration!.isTelephoneRequired = true;
              UpdateCompanyFeaturesService.update(companyFeatures!);

              setNeedRefreshScreen(!needRefreshScreen);
            }}
          >
            Sim, vamos lá!
          </Button>,
        ]}
      />
    );
  }

  const handleAction = (coupon: Coupon, action: ActionType) => {
    switch (action) {
      case ActionType.UPDATE:

        setSelectedCoupon(coupon);
        toggle();
        break;
    }
  };

  return (
    <>
      <CouponEditor
        companyId={companyId}
        isPageOpen={isOpen}
        onClose={close}
        selectedCoupon={selectedCoupon}
      />

      <ContentHeader title={MenuOption.COUPON} />

      <CouponFilters
        companyId={companyId}
        onCompanySelect={(selected) => setSelectedCompanyId(selected)} />

      <Button
        disabled={companyId === DEFAULT_SELECT_COMPANY}
        type="primary"
        onClick={toggle}
        style={{ marginBottom: '10px' }}
      >
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar
      </Button>

      {isLoading ? <Skeleton /> : <CouponTable companyId={companyId} handleAction={handleAction} />}
    </>
  );
};
