import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { Button, Space } from 'antd';
import { SERVER_DATE_FORMAT } from '../../../../../utils/dateTime';
import api from '../../../../../services/api';
import { NotificationDispatcher, NotificationType } from '../../../../../components/Notification';

interface ButtonGeneratePdfReportProps {
  companyId: string;
  currency: string;
  endDate: moment.Moment;
  ordersIds: string | null;
  startDate: moment.Moment;
}

export const ButtonGeneratePdfReport = ({ companyId, currency, endDate, ordersIds, startDate }: ButtonGeneratePdfReportProps) => {
  const [isGeneratingReport, setGeneratingReport] = useState(false);

  const dispatchReportPrintRequest = async () => {
    if (ordersIds === null) {
      NotificationDispatcher({
        message: 'Sem pedidos para gerar o relatório',
        type: NotificationType.INFO,
      });

      return;
    }

    setGeneratingReport(true);

    const startStr = startDate.format(SERVER_DATE_FORMAT);
    const endStr = endDate.format(SERVER_DATE_FORMAT);

    const body = {
      companyId,
      currency,
      startDate: startStr,
      endDate: endStr,
      ids: ordersIds,
    }

    await api
      .post('/api/orders/generateReport',
        body,
        {
          responseType: 'blob',
        })
      .then((response) => {
        setGeneratingReport(false);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, 'PRINT');
      })
      .catch((e) => {
        const error = e as AxiosError;
        if (error.response && error.response.status === 404) {
          NotificationDispatcher({
            message: 'Não há pedidos finalizados no período escolhido',
            type: NotificationType.INFO,
          });
        } else {
          NotificationDispatcher({
            message: 'Falha ao tentar imprimir relatório',
            type: NotificationType.ERROR,
          });
        }
        setGeneratingReport(false);
      });
  }

  return (
    <Space direction="vertical">
      <Button
        onClick={dispatchReportPrintRequest}
        style={{ float: 'right' }}
        loading={isGeneratingReport}
      >
        <i
          className="fa fa-file-pdf-o"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Gerar Relatório
      </Button>
    </Space>
  );
}