import React from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface ResetPasswordProps {
  isFetching: boolean;
}

export const ResetPassword = ({ isFetching }: ResetPasswordProps) => {
  return (
    <>
      <Form.Item
        name="password"
        label="Nova senha"
        help="No mínimo 6 digitos"
        rules={[{ required: true, message: 'Campo obrigatório' }]}
        style={{ marginTop: '16px' }}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Digite a sua senha"
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: '100%', marginTop: '20px' }}
        loading={isFetching}
      >
        Redefinir senha
      </Button>
    </>
  );
};
