import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2rem;

  margin-left: 60px;
  margin-right: 60px;

  .header {
    margin-bottom: 0.5rem;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #000;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }

    .actions {
      text-align: right;
    }
  }

  .body {
    min-width: 280px;
    
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;

    .blockRow {
      .value {
        flex: 0 0 auto;
        line-height: 1;
        margin: 0 -1rem;
        max-width: unset;
        padding: 0;
        width: calc(100% + 2rem);
      }
    }

    .truncated > .value {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @media (max-width: 700px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ContainerSubList = styled.div`
  margin-bottom: 2rem;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 20px;

  .header {
    margin-bottom: 0.5rem;
    
    .subtitle {
      font-size: 16px;
      font-weight: 500;
      color: #000;
    }
  }

  .body {
    min-width: 230px;
    
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 0.25rem;
  }
`;

export const LabelContainer = styled(Col)`
  padding: 1rem 0.75rem;

  font-size: 14px;
  font-weight: 500;
`;

export const ValueContainer = styled(Col)`
  padding: 1rem 0.75rem;
  font-size: 14px;
  font-weight: normal;
`;

export const ItemContainer = styled(Row)`
  display: flex;
  
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 1rem;

  &:last-child {
    border-bottom: none;
  }
`;
