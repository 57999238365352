import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 40%;
  grid-gap: 20px;

  margin-top: 16px;
  padding-bottom: 40px;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: 1650px) {
    grid-template-columns: 1fr;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WhatsAppPreview = styled.div`
  max-width: 380px;
  padding: 1rem;
  background-color: #d9fdd3;
  border-radius: 10px;
`;
