import { isEmpty } from 'lodash';
import { Features } from '../models/Features';
import { DEFAULT_SELECT_COMPANY } from '../utils/Constants';
import { useFetch } from './useFetch';

const FEATURES = 'features';

export const useFeatures = (companyId: string) => {
  const { data, ...rest } = useFetch<Features>({
    enabled: !isEmpty(companyId) && companyId !== DEFAULT_SELECT_COMPANY,
    queryKey: [FEATURES, companyId],
    url: `/api/features/${companyId}`,
  });

  return { data, ...rest };
};
