import styled from "styled-components";

export const Content = styled.div`
  display: inline-block;
`;

export const Title = styled.span`
  color: #333333;
  font-size: 24px;
  font-weight: bold;
`;
