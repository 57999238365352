import { useWindowSize } from 'usehooks-ts';

const mobileWidth = 425;
const tabletWidth = 768;

export const useResponsive = () => {
  const { width } = useWindowSize();

  const isMobile = width <= mobileWidth;
  const isTablet = width <= tabletWidth && width > mobileWidth;

  return {
    isMobile,
    isTablet,
    isResponsive: isMobile || isTablet,
  };
};
