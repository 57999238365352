import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { Contact } from '../models/DataResponse';
import { ContactService } from '../services/ContactService';

export const useContactUpdate = () => {

  const { mutate: mutateUpdate } = useMutation(
    (contact: Contact) => ContactService.update(contact)
  );

  const { mutate: mutateDelete } = useMutation(
    (id: string) => ContactService.delete(id)
  );

  const update = useCallback((contact, { onSuccess, onError }) => {
    mutateUpdate(contact, {
      onSuccess: () => {
        onSuccess();
      },
      onError,
    });
  }, [mutateUpdate]
  );

  const deleteContact = useCallback((id, { onSuccess, onError }) => {
    mutateDelete(id, {
      onSuccess: () => {
        onSuccess();
      },
      onError,
    });
  }, [mutateDelete]
  );

  return {
    deleteContact,
    update,
  };
};
