import React from 'react';
import { ProductCatalog } from '../../../../../models/ProductCatalog';
import parse from 'html-react-parser';
import { removeAccents } from '../../../../../utils/Util';

interface MenuHeaderTitleProps {
  productCatalog: ProductCatalog;
  titleColor: string;
  subTitleColor: string;
  shouldShowTitle: boolean;
}

export const MenuHeaderTitle = (
  {
    productCatalog,
    titleColor,
    subTitleColor,
    shouldShowTitle,
  }: MenuHeaderTitleProps
) => {

  if (productCatalog.products && productCatalog.products.length > 0) {
    const id = removeAccents(productCatalog.category.name);
    return (
      <div id={`${id}_${id}`}>
        {shouldShowTitle && (
          <h3 className="h3-menuonline" style={{ color: titleColor }}>
            {productCatalog.category.name}
          </h3>
        )}
        {productCatalog.category.description && (
          <h4 className="h4-menuonline" style={{ color: subTitleColor }}>
            {productCatalog.category.description && parse(productCatalog.category.description)}
          </h4>
        )}
        <br />
      </div>
    );
  }

  // TODO: The code below will be removed, once we implement the Banner on TOP/BOTTOM Support.
  // The layout below will be different for the cases that the Client added a
  // title and subtitle without products associated.
  return (
    <div className="menuonline-header-background">
      <h1 className="h1-title" style={{ color: titleColor }}>
        {productCatalog.category.name}
      </h1>
      {productCatalog.category.description && (
        <p className="h4-menuonline" style={{ color: subTitleColor }}>
          {productCatalog.category.description && parse(productCatalog.category.description)}
        </p>
      )}
    </div>
  );
};
