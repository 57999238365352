import React from "react";
import { Helmet } from "react-helmet";

interface HeaderMetadataProps {
  favicon?: string;
  title?: string;
}

export const HeaderMetadata = ({ favicon, title }: HeaderMetadataProps) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {title && <title>{title}</title>}
      {favicon && <link rel="icon" type="image/png" href={favicon} sizes="16x16"></link>}
    </Helmet>
  );
}