import { BootClientSetup } from '../models/DataResponse';
import { useFetch } from './useFetch';

const BOOT_CLIENT_SETUP = 'bootClientSetup';
const CACHE_TIME = 60 * 1000;
const STALE_TIME = 60 * 1000;

export const useBootClientSetup = (companySlug: string | undefined) => {
  const { data, ...rest } = useFetch<BootClientSetup>({
    enabled: !!companySlug,
    cacheTime: CACHE_TIME,
    staleTime: STALE_TIME,
    isPublicAPI: true,
    queryKey: BOOT_CLIENT_SETUP,
    url: `api/boot/setup/${companySlug}`,
  });

  const { tabOptions, company, configuration } = data || {};

  return {
    tabOptions,
    company,
    configuration,
    isCountByProduct: configuration?.companyFeatures?.sellsConfiguration?.orderCountType === 'BY_PRODUCT',
    ...rest
  };
};
