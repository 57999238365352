import React from 'react';
import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { validateEmail } from '../../../../../utils/Util';

interface SignUpProps {
  isFetching: boolean;
  isRequired: boolean;
};

export const SignUp = ({ isFetching, isRequired }: SignUpProps) => {
  return (
    <>
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: isRequired, message: 'Campo nome obrigatório' }]}
        style={{ marginTop: '16px' }}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="Digite o seu nome e sobrenome"
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: isRequired,
            message: 'Campo email obrigatório',
          },
          () => ({
            validator(_: any, value = '') {
              if (!validateEmail(value)) {
                return Promise.reject('Insira um email válido.');
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input
          onInput={e => e.currentTarget.value = e.currentTarget.value.trim().toLocaleLowerCase()}
          prefix={<UserOutlined />}
          placeholder="Digite o seu email"
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Form.Item
        name="password"
        label="Senha"
        help="No mínimo 6 digitos"
        rules={[{ required: isRequired, message: 'Campo obrigatório' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Digite a sua senha"
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirmação"
        rules={[{ required: isRequired, message: 'Campo obrigatório' }]}
        style={{ marginTop: '16px' }}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Digite a confirmação de senha"
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Form.Item
        name="accessCode"
        label="Código"
        rules={[{ required: isRequired, message: 'Campo obrigatório' }]}
      >
        <Input placeholder="Insira o código do estabelecimento" style={{ height: '40px' }} />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: '100%' }}
        loading={isFetching}
      >
        Cadastrar
      </Button>
    </>
  );
};
