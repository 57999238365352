import React, { useState } from 'react';
import { Layout } from 'antd';
import { SideBarMenuContent } from './components/SideBarMenuContent';

export enum SettingsSection {
  SUPPORT = 'SUPORTE',
  GENERAL = 'GERAL',
  INTEGRATIONS = 'INTEGRAÇÕES',
  ORDERS = 'PEDIDOS',
  REGISTER = 'CADASTROS',
  SETTINGS = 'CONFIGURAÇÕES',
}

interface SideBarMenuProps {
  contactsBadgeCounter?: number;
  stockCounter?: any;
};

export const SideBarMenu = ({
  contactsBadgeCounter = 0,
  stockCounter = 0,
}: SideBarMenuProps) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider
      breakpoint="xl"
      collapsed={collapsed}
      collapsible
      onCollapse={setCollapsed}
      style={{ background: '#15395b' }}
      width={220}
    >
      <SideBarMenuContent
        collapsed={collapsed}
        contactsBadgeCounter={contactsBadgeCounter}
        stockCounter={stockCounter}
      />
    </Layout.Sider>
  );
};
