import { useCallback } from "react";
import { merge } from "lodash";
import { useUpdateCompanyFeatures } from "../../../../../../../hooks";
import { CompanyFeatures } from "../../../../../../../models/CompanyFeatures";
import { ExtraFee } from "../../../../../../../models/SellsConfiguration";
import { useExtraFees } from "./useExtraFees";

interface UseFeeProps {
  companyFeatures: CompanyFeatures;
  extraFee?: ExtraFee;
}

export const useFee = ({ companyFeatures, extraFee }: UseFeeProps) => {
  const { isLoading, update } = useUpdateCompanyFeatures();
  const { data: fees, isLoading: isLoadingExtraFees } = useExtraFees(companyFeatures.companyId);

  const processFee = useCallback((values: any, callbackResult: Function) => {
    if (!fees || isLoadingExtraFees) return;

    const listFees = [...fees];

    if (extraFee) {
      const index = listFees.findIndex((item) => item._id === extraFee._id);
      if (index !== -1) {
        merge(listFees[index], values);
      } else {
        listFees.push(values);
      }
    } else {
      listFees.push(values);
    }

    update(
      { ...companyFeatures, sellsConfiguration: { ...companyFeatures.sellsConfiguration, extraFees: listFees } },
      {
        onSuccess: () => callbackResult(true),
        onError: () => callbackResult(false)
      },
    );
  }, [companyFeatures, extraFee, fees, isLoadingExtraFees, update]);

  return {
    isLoading,
    processFee,
  }
};