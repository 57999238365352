import styled from 'styled-components';

interface IContainer {
  isSmall?: boolean;
}

export const Container = styled.div<IContainer>`
  ${({ isSmall }) =>
    isSmall
      ? `
    text-align: center;
  `
      : `position: absolute;
      top: 45%;
      left: 50%;
      margin-left: -35px;`}

  > p {
    font-weight: bold;
  }
`;
