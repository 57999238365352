import React from "react";
import { Checkbox, Skeleton } from "antd";
import { usePaymentTypes } from "../../hooks";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Container, PaymentLabel } from "./styles";

interface PaymentListProps {
  paymentsMethods: string[];
  handleSelectedPayments(payments: string[]): void;
}

export const PaymentList = ({
  paymentsMethods = [],
  handleSelectedPayments,
}: PaymentListProps) => {
  const { data: paymentOptions, isFetching } = usePaymentTypes();

  const onChange = (e: CheckboxChangeEvent) => {
    const value = e.target.value;

    const currentIndex = paymentsMethods.indexOf(value);
    const newPayments = [...paymentsMethods];

    if (currentIndex === -1) {
      newPayments.push(value);
    } else {
      newPayments.splice(currentIndex, 1);
    }

    handleSelectedPayments(newPayments);
  };

  if (isFetching || paymentsMethods === undefined) {
    return <Skeleton />
  }

  return (
    <Container>
      {paymentOptions?.map((payment) => <Checkbox
        className="checkbox-item"
        checked={paymentsMethods.indexOf(payment.payment) !== -1}
        onChange={onChange}
        key={payment._id}
        value={payment.payment}
      >
        <PaymentLabel>{payment.payment}</PaymentLabel>
      </Checkbox>)}
    </Container>
  );
};
