import styled from 'styled-components';

export const Controllers = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-top: 10px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const ToggleButtonContainer = styled.div`
  font-size: 20px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: #3f3e3e;

  margin: 0 0 0 16px;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;
