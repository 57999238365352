import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';

import { WhatsappOrder } from '../../../../../models/DataResponse';
import { Role } from '../../../../../models/Enum';
import { getOrderStatusData } from '../../../../../models/OrderStatus';

import { CASH_MONEY } from '../../../../../utils/Constants';
import { DeliveryType, getDeliveryTypeLabel } from '../../../../../utils/DeliveryUtil';
import { getHoursByTimezone } from '../../../../../utils/dateTime';
import { getTotalFromOrder } from '../../../../../utils/OrderUtil';
import formatCurrency from '../../../../../utils/formatCurrency';

import { RoundTag } from '../../../../../common-styles';
import { BreakPoints, useBreakpoint, useCompanyFeatures, useListCompaniesForCustomers, useLoggedUser, useModal } from '../../../../../hooks';
import { ProductComplementsDescription } from '../../../../../components/ProductOrderTable/components/ProductComplementsDescription';
import { OrderTableActions } from './TableActions';
import { SelectDriverDrawer } from '../SelectDriver';
import { DriverInfo } from './DriverInfo';

interface TableOrdersProps {
  companyId: string;
  currency: string;
  isLoadingPage: boolean;
  handleItemClick(order: WhatsappOrder): void;
  orders: WhatsappOrder[];
  toggleAudio: () => void;
}

export const TableOrders = ({
  companyId,
  currency,
  handleItemClick,
  isLoadingPage,
  orders,
  toggleAudio,
}: TableOrdersProps) => {

  const [selectedOrder, setSelectedOrder] = useState<WhatsappOrder>();
  const { companyFeatures, isLoading: isLoadingCompanyFeatures } = useCompanyFeatures(companyId);
  const { data: companies, isLoading: isLoadingCompanies } = useListCompaniesForCustomers();
  const { userLogged } = useLoggedUser();
  const { user: { role } } = userLogged;
  const { breakPnt } = useBreakpoint();
  const { close, isOpen, toggle } = useModal();

  const isLoading = isLoadingCompanies || isLoadingCompanyFeatures || isLoadingPage;

  const getCompanyName = (id: string) => companies?.find(item => item._id === id)?.fantasyName;

  const hasCouponAvailable = useMemo(() => orders.some((item) => item.coupon), [orders]);

  const hasAddressAvailable = useMemo(() => orders.some((item) => item.client.address), [orders]);

  const columns = [
    {
      title: 'Status',
      visible: true,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => {
        const { label, color } = getOrderStatusData(order.status);
        return (
          <RoundTag key={order._id} color={color}>
            {label}
          </RoundTag>
        );
      },
    },
    {
      title: 'Empresa',
      visible: role !== Role.USER,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => getCompanyName(order.companyId)
    },
    {
      title: 'Ordem',
      dataIndex: 'sequency',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      visible: role === Role.USER,
    },
    {
      title: 'N° Pedido',
      dataIndex: 'orderId',
      visible: true,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
    },
    {
      title: 'Hora do pedido',
      visible: true,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => getHoursByTimezone(order.createdAt, companyFeatures?.timezone)
    },
    {
      title: 'Nome',
      dataIndex: ['client', 'name'],
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      visible: true,
    },
    {
      title: 'Telefone',
      dataIndex: ['client', 'phone'],
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      visible: role !== Role.KITCHEN,
    },
    {
      title: 'Endereço',
      dataIndex: ['client', 'address'],
      width: breakPnt === BreakPoints.LG ? 150 : 100,
      visible: hasAddressAvailable && role !== Role.KITCHEN,
    },
    {
      title: 'Total',
      visible: role !== Role.KITCHEN,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => getTotalFromOrder(currency, order).totalFormatted
    },
    {
      title: 'Forma de pagamento',
      visible: role !== Role.KITCHEN,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => {
        return (
          <div key={order._id}>
            <p>{order.client.paymentType}</p>
            {order.client.paymentType === CASH_MONEY &&
              order.client.moneyChange && (
                <p>
                  Troco: $
                  {formatCurrency(Number(order.client.moneyChange), currency)}
                </p>
              )}
          </div>
        );
      },
    },
    {
      title: 'Cupom',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      visible: hasCouponAvailable && (role !== Role.KITCHEN && role !== Role.DRIVER),
      render: (_: any, order: WhatsappOrder) => order.coupon ? order.coupon.couponCode : '',
    },
    {
      title: 'Tipo de entrega',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      visible: (role !== Role.KITCHEN && role !== Role.DRIVER),
      render: (_: any, order: WhatsappOrder) => {
        const { client: { deliveryType } } = order;
        let deliveryTypeLabel = getDeliveryTypeLabel(deliveryType);
        return (
          <>
            <RoundTag key={order._id} color={'green'}>
              {deliveryTypeLabel}
            </RoundTag>
            {(deliveryType === DeliveryType.DELIVERY) && (
              <DriverInfo order={order} />
            )}
          </>
        );
      },
    },
    {
      title: 'Produtos',
      visible: true,
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => {
        const productsSize = order.products.length - 1;
        return order.products.map((product, index) => {
          return (
            <ProductComplementsDescription
              lastProduct={productsSize === index}
              product={product}
              showComplement={role === Role.KITCHEN}
              showComplementTitle={companyFeatures?.sellsConfiguration?.orderComplementExhibition?.showTitle}
            />
          );
        });
      },
    },
    {
      title: 'Ações',
      fixed: 'right',
      visible: true,
      width: breakPnt === BreakPoints.LG ? 100 : 50,
      render: (_: any, order: WhatsappOrder) => (
        <OrderTableActions
          currency={currency}
          handleItemClick={handleItemClick}
          onSelectDriver={(order => {
            setSelectedOrder(order);
            toggle();
          })}
          order={order}
          role={role}
          toggleAudio={toggleAudio}
        />
      ),
    },
  ].filter(item => item.visible);

  return (
    <>
      <SelectDriverDrawer
        close={close}
        isOpen={isOpen}
        onDriverSelected={toggle}
        order={selectedOrder}
      />

      <Table
        bordered
        columns={columns as ColumnsType<WhatsappOrder>}
        dataSource={orders}
        loading={isLoading}
        scroll={{ x: true }}
      />
    </>
  );
};
