import styled from 'styled-components';
import { ImageDimension } from '../../../models/DataResponse';

interface DimensionProps {
  dimens?: ImageDimension;
}

export const Container = styled.div`
  max-width: 650px;
  margin: 0 auto;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  box-shadow: 0 16px 16px -8px rgba(0, 0, 0, 0.2);
  text-align: left;
  background-color: #fff;
  padding: 20px 20px;
`;

export const Title = styled.span`
  color: #3f3e3e;
  font-size: 30px;
  font-weight: bold;
  margin-top: 16px;
`;

export const Heading = styled(Title)`
  font-size: 20px;
  margin: 'auto';
  padding-top: 16px;
  text-align: center;
`;

export const HeadingDescription = styled.small`
  text-align: center;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
`;

export const LogoContent = styled.img<DimensionProps>`
  height: ${(props) => (props.dimens?.height ? props.dimens?.height : '180px')};
  width: ${(props) => (props.dimens?.width ? props.dimens?.width : '80%')};
  object-fit: ${(props) =>
    props.dimens?.objectFit ? props.dimens?.objectFit : 'scale-down'};

  @media (max-width: 500px) {
    height: ${(props) =>
    props.dimens?.mobileHeight ? props.dimens?.mobileHeight : '20vh'};
    width: ${(props) => props.dimens?.mobileWidth};
    object-fit: ${(props) => props.dimens?.mobileObjectFit};
  }
`;

export const TalkToStore = styled.span`
  align-self: flex-end;
  cursor: pointer;
  font-size: 15px;
  margin-top: 16px;
  text-decoration: underline;

  .link {
    color: #3f3e3e;
  }

  .whatsapp {
    color: #60b656;
    margin-left: 0px;
  }

  @media (max-width: 400px) {
    align-self: center;
    margin-top: 30px;
  }
`;