import React from 'react';
import { Space, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { EyeOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';

import { RoundTag } from '../../../../../common-styles';

import { User } from '../../../../../models/User';

import { formatDate } from '../../../../../utils/dateTime';
import { translateRole } from '../../../../../utils/RoleUtil';
import { TableGenericAction } from '../../../../../components/TableGenericAction';
import { TableDeleteAction } from '../../../../../components/TableDeleteAction';
import { ActionType } from '../../../../../models/Enum';
import { TableEditAction } from '../../../../../components/TableEditAction';
import { useLoggedUser } from '../../../../../hooks';

interface UserTableProps {
  isLoading: boolean;
  onRowSelected(action: ActionType, user: User): void;
  users: User[];
};

export const UserTable = ({ isLoading, users, onRowSelected }: UserTableProps) => {
  const { userLogged: { user: { _id: userId } } } = useLoggedUser();

  const columns: ColumnsType<User> = [
    {
      title: 'Status',
      sorter: (a, b) => b.isActive.toString().localeCompare(a.isActive.toString()),
      sortDirections: ["descend", "ascend"],
      render: (_: any, user: User) => {
        const color = user.isActive ? 'green' : 'red';
        return (
          <RoundTag color={color} key={user._id}>
            {user.isActive ? 'Ativo' : 'Inativo'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Perfíl',
      sorter: (a, b) => translateRole(a.role).label.localeCompare(translateRole(b.role).label),
      sortDirections: ["descend", "ascend"],
      render: (_: any, user: User) => {
        const { color, label } = translateRole(user.role);
        return (
          <RoundTag color={color} key={user._id}>
            {label}
          </RoundTag>
        );
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Empresa',
      dataIndex: 'companyName',
      sorter: (a, b) => a.companyName?.localeCompare(b.companyName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: 'Estabelecimentos associados',
      width: "20%",
      render: (_: any, user: User) => user.companyAssociation.map(company => {
        let { companyName } = company;
        if (companyName.includes(' - ')) {
          const [first, second] = companyName.split('-');
          companyName = `${first} <br/> ${second}`
        }
        return (
          <RoundTag color={'gray'} key={companyName}>
            {parse(companyName)}
          </RoundTag>
        );
      }),
    },
    {
      title: 'Data de criação',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ["descend", "ascend"],
      render: (_: any, user: User) => formatDate(user.createdAt),
    },
    {
      title: 'Ações',
      fixed: 'right',
      width: "15%",
      render: (_: any, user: User) => (
        <Space direction="vertical" key={user._id}>
          {user._id !== userId && (
            <TableEditAction
              onClick={() => onRowSelected(ActionType.UPDATE, user)}
            />
          )}

          <TableGenericAction
            icon={<EyeOutlined style={{ fontSize: '18px' }} />}
            label="Visualizar"
            onClick={() => onRowSelected(ActionType.OPEN, user)}
          />

          {user._id !== userId && (
            <TableDeleteAction
              onClick={() => onRowSelected(ActionType.DELETE, user)}
              title={
                <span>
                  Tem certeza que deseja remover o usuário <strong>{user.name}</strong>? <br />
                  Uma vez removido, esta ação não poderá ser revertida.
                </span>
              }
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={[...users]}
      loading={isLoading}
      pagination={false}
      scroll={{ x: 1300 }}
      style={{ paddingBottom: '40px' }}
    />
  );
};
