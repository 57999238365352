import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { TableGenericAction } from '../TableGenericAction';

interface TableEditActionProps {
  disabled?: boolean;
  onClick(): void;
};

export const TableEditAction = ({ disabled, onClick }: TableEditActionProps) => {
  return (
    <TableGenericAction
      disabled={disabled}
      icon={<EditOutlined style={{ fontSize: '18px' }} />}
      label="Editar"
      onClick={onClick}
    />
  );
};
