import { WhatsappOrder } from '../models/DataResponse';
import { useFetch } from './useFetch';

const ORDERS_DRIVE = 'ordersDriver';

export const useListOrdersToDriver = (date: string) => {
  const { data, ...rest } = useFetch<WhatsappOrder[]>({
    queryKey: ORDERS_DRIVE,
    url: `api/order_delivery?date=${date}`,
  });

  return { data, ...rest };
};
