import React from 'react';
import { Typography } from 'antd';

import { Container, Controllers } from './styles';

interface ContentHeaderProps {
  title: string;
  children?: React.ReactNode;
};

export const ContentHeader = ({ title, children }: ContentHeaderProps) => {
  return (
    <Container>
      <Typography.Title
        level={2}
        style={{ marginTop: '20px', color: '#17181f' }}
      >
        {title}
      </Typography.Title>

      {children && <Controllers>{children}</Controllers>}
    </Container>
  );
};
