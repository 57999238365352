import { Button } from 'antd';
import React from 'react';

import { ComplementOfProduct } from '../../../../../../models/ProductCatalog';
import { ActionType } from '../../../../../../models/Enum';
import { ComplementTable } from './components/ComplementTable';

interface ComplementListProps {
  complements: ComplementOfProduct[];
  handleCloneComplement(complement: ComplementOfProduct): void;
  handleDeleteComplement(complement: ComplementOfProduct): void;
  handleEditComplement(complement: ComplementOfProduct): void;
  handleShowComplementPage(): void;
  onComplementsListChanged(complements: ComplementOfProduct[]): void;
}

const ComplementList = ({
  complements,
  handleCloneComplement,
  handleDeleteComplement,
  handleEditComplement,
  handleShowComplementPage,
  onComplementsListChanged,
}: ComplementListProps) => {

  function handleAction(item: ComplementOfProduct, actionType: ActionType) {
    switch (actionType) {
      case ActionType.DELETE:
        handleDeleteComplement(item);
        break;
      case ActionType.UPDATE:
        handleEditComplement(item);
        break;
      case ActionType.CLONE:
        handleCloneComplement(item);
        break;
    }
  }

  return (
    <>
      <div style={{ paddingBottom: '10px' }}>
        <Button type="primary" onClick={handleShowComplementPage}>
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Adicionar
        </Button>
      </div>

      <ComplementTable
        items={complements}
        handleAction={handleAction}
        onTableChanged={onComplementsListChanged}
      />

      {complements.length > 2 && (
        <div>
          <Button
            type="primary"
            onClick={handleShowComplementPage}
            style={{ marginTop: '16px' }}
          >
            <i
              className="fa fa-plus-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Adicionar
          </Button>
        </div>
      )}
    </>
  );
};

export default ComplementList;
