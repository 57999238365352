import styled from 'styled-components';

export const MinOrderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  .apply-button {
    height: 34px;
    align-self: baseline;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;
