import { useFetch } from '../../../../hooks/useFetch';
import { PendingRegistration } from '../models/PendingRegistration';

const PENDING_REGISTRATIONS = 'pendingRegistrations';

export const usePendingRegistrations = () => {
  const { data, ...rest } = useFetch<PendingRegistration[]>({
    queryKey: [PENDING_REGISTRATIONS],
    url: 'api/prospection',
  });

  return {
    counter: data?.length ?? 0,
    data,
    ...rest,
  };
};
