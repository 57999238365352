import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  float: right;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  .company-name {
    font-weight: bold;
    font-size: 16px;
  }

  .company-info {
    margin-left: auto;
  }
`;

export const Dash = styled.span`
  padding: 0 10px;
`;

export const Image = styled.img`
  height: 32px;
  border-radius: 10px;
  object-fit: scale-down;
`;
