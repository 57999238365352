import React from "react";
import LazyLoad from "react-lazyload";
import { RoundTag } from "../../../../common-styles";
import { Container } from "./styles";

interface Props {
  tagColor: string;
  title: string;
  subtitle: string;
  image: string;
}

const InformativeCard: React.FC<Props> = ({ tagColor, title, subtitle, image }) => {
  return (
    <Container>
      <RoundTag color={tagColor} />
      <div>
        <span>{title}</span>
        <small>{subtitle}</small>
      </div>
      {image && (
        <div>
          <LazyLoad height={120} once={true}>
            <img className="grid-item-picture-specs" src={image} alt={title} />
          </LazyLoad>
        </div>
      )}
    </Container>
  );
};

export default InformativeCard;
