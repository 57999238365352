import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Container } from './styles';

/**
 * @Types:
 * "Audio" | "BallTriangle" | "Bars" | "Circles" | "Grid" | "Hearts" | "Rings" | "Puff" | "Rings" | "TailSpin" | "ThreeDots" | "Watch" | "RevolvingDot" | "Triangle" |  *  "Plane" | "MutatingDots" | "None" | "NotSpecified"
 * */
interface LoaderSpinnerProps {
  isSmall?: boolean;
}

export const LoaderSpinner = ({ isSmall }: LoaderSpinnerProps) => {
  const size = isSmall ? 40 : 80;

  return (
    <Container isSmall={isSmall}>
      <ThreeDots color="#31B86F" height={size} width={size} />
    </Container>
  );
};
