import React, { useMemo, useState } from 'react';
import { Button, Drawer } from 'antd';
import ReactStars from 'react-rating-stars-component';
import toast from 'react-hot-toast';

import api from '../../../../../services/api';
import {
  CommentLabel,
  Heading,
  HintCharCounter,
  InputComment,
  ItemsGrid,
  Main,
  ScoreContainer,
  Title,
} from './styles';
import { isMobile } from '../../../../../utils/Util';

interface FeedbackDrawerProps {
  slug: string;
  items: string[];
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  comment: string;
  likedItems: string[];
  menuName: string;
  rating: number;
};

const DESCRIPTION_CHARACTER_LIMIT = 500;

export const FeedbackDrawer = ({ slug, items, isOpen, onClose }: FeedbackDrawerProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({} as FormData);

  const isMobileDevice = useMemo(() => isMobile.any(), []);

  const ratingChanged = (newRating: number) => {
    setFormData({ ...formData, rating: newRating });
  };

  function handleSelectItem(selectedItem: string) {
    const alreadySelected = selectedItems.findIndex(
      (item) => item === selectedItem
    );
    if (alreadySelected >= 0) {
      const filtered = selectedItems.filter((item) => item !== selectedItem);
      setSelectedItems(filtered);
    } else {
      setSelectedItems([...selectedItems, selectedItem]);
    }
  }

  async function handleSubmit() {
    formData.menuName = slug;
    formData.likedItems = selectedItems;

    try {
      const response = await api.post('api/feedback', formData);
      if (response.status) {
        toast.success('Avaliação enviada');
        onClose();
      }
    } catch (error) {
      console.error(error);
      toast.error('É preciso que selecione o número de estrelas');
    }
  }

  const renderFooter = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
        <Button type='primary' danger onClick={onClose}>
          Cancelar
        </Button>

        <Button
          type="primary"
          onClick={() => handleSubmit()}
        >
          <i
            className="fa fa-paper-plane"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />{' '}
          Enviar
        </Button>
      </div>
    );
  }

  return (
    <Drawer
      onClose={onClose}
      headerStyle={{ display: 'none' }}
      open={isOpen}
      destroyOnClose
      height={isMobileDevice ? '100%' : undefined}
      placement={isMobileDevice ? 'bottom' : 'right'}
      closeIcon={null}
      footer={renderFooter()}
    >
      <Main>
        <Title>Opinião</Title>
        <Heading>Avalie a sua experiência</Heading>
        <ScoreContainer className="no_highlights">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={40}
            activeColor="#ffd700"
          />
        </ScoreContainer>
        <Heading>O que gostou?</Heading>
        <ItemsGrid>
          {items.map((item) => (
            <li
              key={item}
              onClick={() => handleSelectItem(item)}
              className={selectedItems.includes(item) ? 'selected' : ''}
            >
              <span>{item}</span>
            </li>
          ))}
        </ItemsGrid>

        <CommentLabel>Deixe o seu comentário</CommentLabel>
        <InputComment
          placeholder="Comentário..."
          rows={5}
          maxLength={500}
          onChange={(e) =>
            setFormData({ ...formData, comment: e.target.value })
          }
        />
        <HintCharCounter>
          {`${formData.comment ? formData.comment.length : 0
            }/${DESCRIPTION_CHARACTER_LIMIT}`}
        </HintCharCounter>
      </Main>
    </Drawer>
  );
};
