import { useCallback } from "react";
import { useMutation } from "react-query";
import { ProductCheckStock } from './../models/ProductStock';
import { CheckProductStockService } from "../services/CheckProductStockService";

export const useCheckStock = (slug: string) => {
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (products: ProductCheckStock[]) => CheckProductStockService.checkStock(slug, products)
  );

  const checkStock = useCallback((products, { onSuccess, onError }) => {
    mutate(products, {
      onSuccess: (stockResult) => {
        onSuccess(stockResult);
      },
      onError,
    });
  },
    [mutate]
  );

  return {
    checkStock,
    isError,
    isLoading,
    isSuccess,
  };
};
