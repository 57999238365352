import { WhatsappOrder } from "../../../../models/DataResponse";
import { Role } from "../../../../models/Enum";
import { OrderStatus } from "../../../../models/OrderStatus";
import { DeliveryType } from "../../../../utils/DeliveryUtil";

export const getShouldRenderSelectDriver = (order: WhatsappOrder, role: string) => {
  const isDelivery = order.client.deliveryType === DeliveryType.DELIVERY;
  const shouldRenderSelectDriver = isDelivery && role === Role.MANAGER && (order.status === OrderStatus.IN_PROGRESS || order.status === OrderStatus.READY);

  return { shouldRenderSelectDriver };
}