import React from "react";
import { sanitizePrice } from "../../utils/priceUtil";
import formatCurrency from "../../utils/formatCurrency";
import { Container, NewPrice, OldPrice, TagOff } from "./styles";

interface PriceWithPromotionProps {
  currency: string;
  discountPercent?: string;
  newPrice: string;
  oldPrice: string;
}

export const PriceWithPromotion = ({ currency, discountPercent, newPrice, oldPrice }: PriceWithPromotionProps) => {

  // TODO: This needs to be reviewed.
  const getFormattedPrice = (value?: string) => {
    if (
      !value ||
      value === undefined ||
      isNaN(Number(sanitizePrice(value, false)))
    ) {
      return value;
    }

    const price = Number(sanitizePrice(value));
    return formatCurrency(price, currency);
  }

  return (
    <Container>
      <OldPrice>{getFormattedPrice(oldPrice)}</OldPrice>
      <NewPrice>{getFormattedPrice(newPrice)}</NewPrice>
      {discountPercent && <TagOff>({`${discountPercent}% OFF`})</TagOff>}
    </Container>
  );
};
