import { useState } from "react";
import { useFetch } from "./useFetch";

const ADDRESSES = 'addresses';

export const useAddressAutocomplete = (isAutocompleteEnabeld: boolean | undefined) => {
  const [inputAddressSearch, setInputAddressSearch] = useState<string>('');

  const { data, ...rest } = useFetch<string[]>({
    enabled: isAutocompleteEnabeld,
    queryKey: [ADDRESSES, inputAddressSearch],
    url: `api/address/autocomplete?inputSearch=${encodeURIComponent(inputAddressSearch)}`,
  });

  return { data, ...rest, setInputAddressSearch };
};