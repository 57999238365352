import React from 'react';
import { Space, Table } from 'antd';
import { ExtraFee } from '../../../../../../../../models/SellsConfiguration';
import { RoundTag } from '../../../../../../../../common-styles';
import { PREFIX_LOCALSTORAGE } from '../../../../../../../../utils/Constants';
import formatCurrency from '../../../../../../../../utils/formatCurrency';
import { TableEditAction } from '../../../../../../../../components/TableEditAction';
import { TableDeleteAction } from '../../../../../../../../components/TableDeleteAction';
import { useExtraFees } from '../../hooks/useExtraFees';
import { CompanyFeatures } from '../../../../../../../../models/CompanyFeatures';
import { useRemoveFee } from '../../hooks/useRemoveFee';
import { NotificationDispatcher, NotificationType } from '../../../../../../../../components/Notification';

interface FeesTableProps {
  companyFeatures: CompanyFeatures;
  onRowClick(fee: ExtraFee): void;
}

export const FeesTable = ({ companyFeatures, onRowClick }: FeesTableProps) => {
  const { data: fees, isLoading: isLoadingExtraFees, refetch } = useExtraFees(companyFeatures.companyId);
  const { isLoading: isLoadingRemoveFee, removeFee } = useRemoveFee({ companyFeatures });
  const currency = localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
  const isLoading = isLoadingExtraFees || isLoadingRemoveFee;

  const handleRemoveFee = (fee: ExtraFee) => {
    removeFee(fee, (isSuccess: boolean) => {
      if (isSuccess) {
        NotificationDispatcher({ message: 'Taxa removida com sucesso' });
        refetch();
      } else {
        NotificationDispatcher({
          message: 'Erro ao tentar efetuar a operação.',
          type: NotificationType.ERROR,
        });
      }
    })
  }

  const columns = [
    {
      title: 'Status',
      align: 'center' as 'center',
      width: "8%",
      render: (_: any, fee: ExtraFee) => {
        const color = fee.isEnabled ? 'green' : 'orange';
        return (
          <RoundTag color={color} key={fee._id}>
            {fee.isEnabled ? 'Ativo' : 'Inativo'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Taxa',
      dataIndex: 'label',
    },
    {
      title: 'Valor',
      render: (_: any, fee: ExtraFee) => formatCurrency(fee.value, currency),
    },
    {
      title: 'Dica',
      dataIndex: 'tooltip',
    },
    {
      title: 'Ações',
      width: "15%",
      render: (_: any, fee: ExtraFee) => (
        <Space key={fee._id}>
          <TableEditAction onClick={() => onRowClick(fee)} />
          <TableDeleteAction
            onClick={() => handleRemoveFee(fee)}
            title={
              <span>
                Tem certeza que deseja excluir esta taxa <strong>{fee.label}</strong>? <br />
                Uma vez removida, esta ação não poderá ser revertida.
              </span>
            }
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={fees ? [...fees] : []}
      loading={isLoading}
      pagination={false}
      style={{ marginTop: '20px' }}
    />
  );
}
