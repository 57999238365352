import styled, { keyframes } from "styled-components";

const animate = keyframes`
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  50% {
    opacity: .3;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

export const Container = styled.li`
  background-color: rgba(0, 0, 0, 0.1);
  color: black;

  list-style: none;
  border-radius: 10px;

  margin: 16px 0;
  padding: 12px 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  animation: ${animate} 0.5s ease;

  > div small {
    margin-top: 4px;
  }
  > div span {
    font-size: 20px;
    font-weight: 500;
  }
`;