import React from 'react';
import { Container, Content } from './styles';

interface FooterProps {
  instagram?: string;
  facebook?: string;
  whatsapp?: string;
  wifi?: string;
  phone?: string;
  website?: string;
};

export const Footer = ({
  facebook,
  instagram,
  whatsapp,
  wifi,
  phone,
  website,
}: FooterProps) => {
  return (
    <Container>
      <Content>
        {instagram && (
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram icon" />
          </a>
        )}
        {facebook && (
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook icon"></i>
          </a>
        )}
        {whatsapp && (
          <a href={whatsapp} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp icon"></i>
          </a>
        )}
        {wifi && (
          <div>
            <i className="fa fa-wifi icon"></i>
            <p>{wifi}</p>
          </div>
        )}
        {phone && (
          <a href={`tel:${phone}`} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-phone icon"></i>
          </a>
        )}
        {website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-globe icon"></i>
          </a>
        )}
      </Content>

        <a
          className="link-company-footer"
          href="https://qrcodepreferido.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          ©{new Date().getFullYear()} QRcode Preferido. All Rights Reserved.
        </a>
    </Container>
  );
};
