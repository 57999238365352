import { useState, useEffect } from 'react';
import { firebaseStorage } from '../../../services/firebase';
import Resizer from 'react-image-file-resizer';

const resizeFile = (file, size) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      'JPEG',
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob'
    );
  });

const blobToFile = async (blob, fileName) => {
  return new File([blob], fileName, { type: 'image/jpeg' });
};

const useStorage = (file, size) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    const path = localStorage.getItem('@qrcodepreferido:slug'); // TO USE: ${PREFIX_LOCALSTORAGE} when change this file to Typescript
    const timestamp = Date.now();
    // references
    const nameWithoutExtension = file.name.split('.');
    const filename = `${timestamp}_${nameWithoutExtension}`;
    const storageRef = firebaseStorage.ref(`${path}/${filename}`);

    resizeFile(file, size).then(async (blob) => {
      const imageFile = await blobToFile(blob, filename);

      storageRef.put(imageFile).on(
        'state_changed',
        (snapshot) => {
          // Progress update
          let percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(percentage);
        },
        // Case for the Error
        (error) => {
          setError(error);
        },
        // Update the final URL
        async () => {
          let url = await storageRef.getDownloadURL();
          setUrl(url);
        }
      );
    });
  }, [file, size]);

  return { progress, url, error };
};

export default useStorage;
