import React from 'react';
import parse from 'html-react-parser';
import { MenuTabOption } from '../../../../../models/DataResponse';
import {
  MenuLabelOptionsContainer,
  MenuOptionsContainer,
  MenuOptionsItemsContainer,
  SelectedItemSeparator,
} from '../../styles';
import { removeAccents } from '../../../../../utils/Util';

interface TabOptionsProps {
  handleChangeMenuOption: any;
  labelColor: string;
  menuOptions: MenuTabOption[];
  selectedMenuOption: MenuTabOption;
};

const THRESHOLD_SCROLL = 60;

export const TabOptions = ({
  menuOptions,
  selectedMenuOption,
  labelColor,
  handleChangeMenuOption,
}: TabOptionsProps) => {
  menuOptions.forEach((option) => (option.key = React.createRef()));

  const handleClickTab = (option: MenuTabOption) => {
    const scroller = document.getElementById('tabOptionsScroller');
    if (scroller) {
      scroller.scrollTo({
        behavior: 'smooth',
        left: option.key.current.offsetLeft - THRESHOLD_SCROLL,
      });
    }
    handleChangeMenuOption(option);
  };

  const renderLabelsOptions = (option: MenuTabOption) => {
    const linkAlias = removeAccents(option.name).toLowerCase();
    return (
      <MenuLabelOptionsContainer
        key={`${option.name}}`}
        ref={option.key}
        onClick={() => handleClickTab(option)}
      >
        <a
          href={`#${linkAlias}`}
          style={{
            color: labelColor,
            fontSize: '24px',
            fontFamily: 'Ubuntu',
            cursor: 'pointer',
            textAlign: 'center',
            lineHeight: 'normal',
            fontWeight: selectedMenuOption === option ? 'bold' : 'normal',
          }}
        >
          {option.name && parse(option.name)}
        </a>
        {selectedMenuOption === option && (
          <SelectedItemSeparator color={labelColor} />
        )}
      </MenuLabelOptionsContainer>
    );
  };

  const renderIconsOptions = (option: MenuTabOption) => {
    return (
      <div
        className="menu-option_grid-content"
        key={`${option._id}${Math.random()}`}
      >
        <i
          className={`fa icon ${option.icon}`}
          style={{
            backgroundColor: labelColor,
            color: '#ffffff',
            margin: '10px',
            cursor: 'pointer',
          }}
          onClick={() => handleChangeMenuOption(option)}
        />
        {selectedMenuOption === option && (
          <SelectedItemSeparator color={labelColor} />
        )}
      </div>
    );
  };

  return (
    <MenuOptionsContainer className="div-sticky" id="tabOptionsScroller">
      {menuOptions && menuOptions.length > 0 && (
        <MenuOptionsItemsContainer>
          {menuOptions.map((option) => {
            const hasIcon = option.icon !== '';
            return hasIcon
              ? renderIconsOptions(option)
              : renderLabelsOptions(option);
          })}
        </MenuOptionsItemsContainer>
      )}
    </MenuOptionsContainer>
  );
};
