export const PREFIX_LOCALSTORAGE = '@qrcodepreferido:';

export const SLUG = PREFIX_LOCALSTORAGE + 'slug';
export const TOKEN = PREFIX_LOCALSTORAGE + 'token';
export const USER = PREFIX_LOCALSTORAGE + 'user';
export const CLIENT_INFO_DATA = PREFIX_LOCALSTORAGE + 'userInfoDataOrder';
export const COMPANY = PREFIX_LOCALSTORAGE + ':company';
export const COMPANY_ID = PREFIX_LOCALSTORAGE + ':company_id';
export const CURRENCY = PREFIX_LOCALSTORAGE + ':currency';
export const PAYMENT_EASYPAY = PREFIX_LOCALSTORAGE + ':payment_easypay';
export const SLUG_TRACK_CLIENT = PREFIX_LOCALSTORAGE + ':slug_track_client';

export class LocalStorageService {
  public static save(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public static get<T>(key: string): T | null {
    const object = window.localStorage.getItem(key);
    if (!object) {
      return null;
    }
    return JSON.parse(object);
  }

  public static clear(key: string) {
    localStorage.removeItem(key);
  }

  public static clearAll() {
    localStorage.clear();
  }
}