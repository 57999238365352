import React from 'react';
import List from '@material-ui/core/List';
import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core';

import { confirmAlert } from 'react-confirm-alert';

import { DeleteFilled as DeleteIcon, EditFilled as EditIcon } from '@ant-design/icons';

type FeedbackItemListProps = {
  feedbackItems: string[];
  deleteAction(item: string): void;
  editAction(item: string): void;
};

const FeedbackItemList: React.FC<FeedbackItemListProps> = ({
  feedbackItems,
  deleteAction,
  editAction,
}) => {
  function handleDeleteAction(item: string) {
    confirmAlert({
      title: 'Atenção',
      message: `Tem certeza que deseja excluir o item ${item}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: () => deleteAction(item),
        },
        {
          label: 'Não',
          onClick: () => {},
        },
      ],
    });
  }

  const handleEditAction = (item: string) => editAction(item);

  return (
    <List style={{ width: '30%' }}>
      {feedbackItems.map((option, index) => {
        return (
          <ListItem
            key={option}
            style={{ background: index % 2 === 0 ? '#f2f2f2' : '' }}
          >
            <ListItemText id={option} primary={option} />
            <ListItemSecondaryAction style={{ display: 'flex' }}>
              <Tooltip
                title="Editar"
                onClick={() => handleEditAction(option)}
                style={{ cursor: 'pointer' }}
              >
                <EditIcon />
              </Tooltip>
              <Tooltip
                title="Editar"
                onClick={() => handleDeleteAction(option)}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              >
                <DeleteIcon />
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FeedbackItemList;
