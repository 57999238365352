import styled from 'styled-components';

export const ScheduleItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  font-size: 0.875rem;
  font-family: 'SulSans', Helvetica, sans-serif;
  
  margin-bottom: 28px;
  margin-right: 10px;

  .details-schedule__day-title {
    flex: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .details-schedule__day-title-text {
    font-weight: 300;
    color: #3e3e3e;
  }

  .details-schedule__day-schedule {
    color: #717171;
    flex: 5;
  }

  .schedule-today {
    color: #1a1a1a;
    font-weight: bold;
  }
`;
