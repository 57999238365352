import { Product } from '../models/Product';
import { DEFAULT_SELECT_COMPANY } from '../utils/Constants';
import { useFetch } from './useFetch';

export const PRODUCTS = 'products';

export const useProducts = (companyId: string) => {
  const { data, ...rest } = useFetch<Product[]>({
    enabled: companyId !== DEFAULT_SELECT_COMPANY,
    queryKey: [PRODUCTS, companyId],
    url: 'api/catalog/',
    headers: {
      company: companyId,
    },
  });

  return {
    products: data,
    ...rest,
  };
};
