import React from 'react';
import { Button, Drawer, Form, Space, Switch } from 'antd';
import { FormInputField } from '../../../../../../../../components/FormInputField';
import { CustomInformationOption } from '../../../../../../../../models/SellsConfiguration';

interface EditorCustomInformationItemProps {
  close(): void;
  customInformationOption?: CustomInformationOption;
  isOpen: boolean;
  onOptionCreated(option: CustomInformationOption): void;
  onOptionChanged(oldOption: CustomInformationOption, newOption: CustomInformationOption): void;
};

export const EditorCustomInformationItem = ({ close, customInformationOption, isOpen, onOptionCreated, onOptionChanged }: EditorCustomInformationItemProps) => {

  const getTitle = () => {
    const prefix = customInformationOption ? 'Editar' : 'Nova';
    return `${prefix} informação`;
  }

  const onFinish = async (values: any) => {
    if (values.isEnabled === undefined) {
      values.isEnabled = true;
    }

    if (customInformationOption) {
      onOptionChanged(customInformationOption, { ...values });
    } else {
      onOptionCreated({ ...values });
    }

    close();
  };

  return (
    <Drawer
      title={getTitle()}
      onClose={close}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isOpen}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={customInformationOption}
      >
        <Form.Item
          label={<label className="label-input-field">Status</label>}
          name="isEnabled"
        >
          <Switch
            key="isEnabled"
            defaultChecked={customInformationOption?.isEnabled ?? true}
            checkedChildren="Ativo"
            unCheckedChildren="Pausado"
          />
        </Form.Item>

        <FormInputField
          label="Título"
          name="title"
          placeholder="Escreva um título..."
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        />

        <FormInputField
          label="Descrição"
          name="description"
          placeholder="Descrição..."
          isInputArea={true}
          inputTextAreaProps={{ rows: 5 }}
        />

        <Space>
          <Button danger onClick={close}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button type="primary" htmlType="submit">
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};
