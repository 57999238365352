import React from "react";
import { TableGenericAction } from "../../../../../../../../components/TableGenericAction";
import { WhatsappOrder } from "../../../../../../../../models/DataResponse";
import { useUpdateOrderStatus } from "../../../../../hooks/useUpdateOrderStatus";
import { OrderStatus } from "../../../../../../../../models/OrderStatus";

interface FinishActionButtonProps {
  order: WhatsappOrder;
}

export const FinishActionButton = ({ order }: FinishActionButtonProps) => {
  const { updateOrderStatus } = useUpdateOrderStatus();

  return (
    <TableGenericAction
      dashed
      icon={<i
        className='fa fa-handshake-o'
        aria-hidden="true"
        style={{ marginRight: '10px' }}
      />}
      label={'Entregar'}
      style={{
        backgroundColor: '#2fb86e',
        color: '#fff',
      }}
      onClick={() => updateOrderStatus(order, OrderStatus.CONCLUDED)}
    />
  )
};