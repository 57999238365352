import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #1A1919;

  .link-company-footer {
    color: white;
    font-size: 14px;
    font-weight: lighter;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .qrcode-company {
    text-align: center;
    width: 80px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  .icon {
    border-radius: 50%;
    display: inline-block;
    font-size: 30px;
    color: #fff;
    height: 55px;
    line-height: 42px;
    margin-top: 10px;
    text-align: center;
    transition: all 0.5s ease;
    width: 55px;
  }
`;
