import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

interface TimePickersProps {
  id: string;
  defaultValue: string;
  label: string;
  handleInputChange: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  })
);

const TimePickers: React.FC<TimePickersProps> = ({
  id,
  label,
  defaultValue,
  handleInputChange,
}) => {
  const classes = useStyles();

  return (
    <TextField
      required
      id={id}
      label={label}
      type="time"
      defaultValue={defaultValue}
      className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        step: 60, // 1 min
      }}
      onChange={handleInputChange}
    />
  );
};

export default TimePickers;
