import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';

import FeedbackItemList from '../../../../../../../../components/FeedbackItemList';
import { FormRadioGroup } from '../../../../../../../../components/FormRadioGroup';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../../../../components/Notification';

import { AddOrUpdateFeedback } from './components/AddOrUpdateFeedback';

import { CompanyFeatures } from '../../../../../../../../models/CompanyFeatures';
import { isValidHttpUrl } from '../../../../../../../../utils/Util';
import { FormLayout } from '../../../../../../../../utils/Styles';
import { UpdateCompanyFeaturesService } from '../../../../../../../../services/UpdateCompanyFeaturesService';

interface FeedbackItemsProps {
  companyFeatures: CompanyFeatures;
};

const customOptions = [
  { label: 'Customizado', value: true },
  { label: 'Padrão', value: false },
];

export const FeedbackItems = ({ companyFeatures }: FeedbackItemsProps) => {
  const [feedbackItems, setFeedbackItems] = useState<string[]>([]);
  const [linkCustomPage, setLinkCustomPage] = useState<string | undefined>('');
  const [isFeedbackEnabled, setFeedbackEnabled] = useState(true);
  const [isFeedbackPageVisible, setFeedbackPageVisible] = useState(false);
  const [selectedFeedbackItem, setSelectedFeedbackItem] = useState<string>();

  const isCustomPage =
    companyFeatures.feedback.customPage !== undefined &&
    companyFeatures.feedback.customPage !== '';
  const [isCustom, setCustom] = useState(isCustomPage);

  useEffect(() => {
    if (!companyFeatures.feedback) return;

    const { customPage, isEnabled, items } = companyFeatures.feedback;

    setFeedbackItems(items);
    setFeedbackEnabled(isEnabled);
    setLinkCustomPage(customPage);
  }, [companyFeatures]);

  const handleItemSubmitted = (item: string, isUpdate: boolean) => {
    if (isUpdate) {
      updateItem(selectedFeedbackItem ?? '', item);
    } else {
      saveNewItem(item);
    }
    setFeedbackPageVisible(false);
  };

  async function saveNewItem(item: string) {
    if (!item) return;

    companyFeatures.feedback.items = [...feedbackItems, item];
    const response = await UpdateCompanyFeaturesService.update(
      companyFeatures
    );
    if (response) {
      setFeedbackItems(companyFeatures.feedback.items);
      NotificationDispatcher({
        message: 'Item salvo com sucesso.',
      });
    } else {
      NotificationDispatcher({
        message: 'Não foi possível efetuar a adição.',
        type: NotificationType.ERROR,
      });
    }
  }

  async function updateItem(oldItem: string, newItem: string) {
    if (!newItem) return;

    const index = feedbackItems.findIndex((findItem) => findItem === oldItem);
    companyFeatures.feedback.items[index] = newItem;

    const response = await UpdateCompanyFeaturesService.update(
      companyFeatures
    );
    if (response) {
      setFeedbackItems(response.feedback.items);
      NotificationDispatcher({
        message: 'Item atualizado com sucesso.',
      });
    } else {
      NotificationDispatcher({
        message: 'Não foi possível efetuar a atualização.',
        type: NotificationType.ERROR,
      });
    }
  }

  async function deleteItem(itemToRemove: string) {
    if (!itemToRemove) return;

    const filteredItems = feedbackItems.filter((item) => item !== itemToRemove);
    companyFeatures.feedback.items = filteredItems;

    const response = await UpdateCompanyFeaturesService.update(
      companyFeatures
    );

    if (response) {
      setFeedbackItems(companyFeatures.feedback.items);
      NotificationDispatcher({
        message: 'Item removido com sucesso.',
      });
    } else {
      NotificationDispatcher({
        message: 'Não foi possível efetuar a remoção.',
        type: NotificationType.ERROR,
      });
    }
  }

  const handleEditAction = (item: string) => {
    setSelectedFeedbackItem(item);
    setFeedbackPageVisible(true);
  };

  const handleNewItem = () => {
    setSelectedFeedbackItem(undefined);
    setFeedbackPageVisible(true);
  };

  function renderFeedbackItems() {
    return (
      <>
        <Button
          type="dashed"
          onClick={() => {
            handleNewItem();
          }}
        >
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Adicionar Item
        </Button>

        <FeedbackItemList
          feedbackItems={feedbackItems}
          deleteAction={deleteItem}
          editAction={handleEditAction}
        />

        {feedbackItems.length === 0 && (
          <p style={{ paddingBottom: '16px' }}>
            <strong>
              Nenhum item cadastrado, irá ser exibido os itens padrões na página
              de Feedback.
            </strong>
          </p>
        )}
      </>
    );
  }

  async function handleSaveChanges() {
    if (isCustom) {
      if (linkCustomPage !== undefined && isValidHttpUrl(linkCustomPage)) {
        companyFeatures.feedback.customPage = linkCustomPage;
      } else {
        NotificationDispatcher({
          message: 'Insira uma URL válida.',
          type: NotificationType.ERROR,
        });
        return;
      }
    } else {
      companyFeatures.feedback.customPage = undefined;
    }
    companyFeatures.feedback.isEnabled = isFeedbackEnabled;

    const response = await UpdateCompanyFeaturesService.update(
      companyFeatures
    );
    if (response) {
      NotificationDispatcher({
        message: 'Atualizado com sucesso.',
      });
    } else {
      NotificationDispatcher({
        message: 'Erro ao tentar atualizar.',
        type: NotificationType.ERROR,
      });
    }
  }

  function renderCustomOption() {
    return (
      <Form.Item
        label={<label className="label-input-field">Link da Página</label>}
      >
        <Input
          placeholder="Ex.: http://google.com"
          value={linkCustomPage || ''}
          onChange={(e) => setLinkCustomPage(e.target.value)}
          style={{ height: '40px' }}
        />
      </Form.Item>
    );
  }

  return (
    <>
      <AddOrUpdateFeedback
        item={selectedFeedbackItem}
        isVisible={isFeedbackPageVisible}
        onClose={() => setFeedbackPageVisible(false)}
        onFinishForm={(item: string, isUpdate: boolean) =>
          handleItemSubmitted(item, isUpdate)
        }
      />

      <Form
        layout="vertical"
        {...FormLayout}
        initialValues={companyFeatures.feedback}
      >
        <FormRadioGroup
          label="Disponibilidade"
          propertyId="isEnabled"
          defaultValue={isFeedbackEnabled}
          handleOnChange={(isChecked) => setFeedbackEnabled(isChecked)}
        />

        {isFeedbackEnabled && (
          <>
            <FormRadioGroup
              label="Tipo de feedback"
              propertyId="isCustom"
              defaultValue={isCustom}
              options={customOptions}
              handleOnChange={(isChecked) => setCustom(isChecked)}
            />

            {isCustom ? renderCustomOption() : renderFeedbackItems()}
          </>
        )}

        <div style={{ marginTop: '16px', textAlign: 'start' }}>
          <Button type="primary" onClick={handleSaveChanges}>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </div>
      </Form>
    </>
  );
};
