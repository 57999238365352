import React from "react";
import { Form, Radio } from "antd";
import { LayoutType } from "../../../../../../../models/Enum";
import { Title } from "../../styles";

const LAYOUT_TYPES = [
  { label: 'Cardápio', value: LayoutType.MENU },
  { label: 'Catálogo', value: LayoutType.CATALOG },
];

export const StylesSetup = () => {
  return (
    <>
      <Title style={{ marginTop: 50 }}>Layout</Title>
      <Form.Item
        label={
          <label className="label-input-field">Tipos</label>
        }
        name={['layout', 'type']}
        rules={[
          {
            required: true,
            message: 'Escolha o tipo de layout',
          },
        ]}
      >
        <Radio.Group
          options={LAYOUT_TYPES}
          optionType="button"
          buttonStyle="solid"
        />
      </Form.Item>
    </>
  );
}