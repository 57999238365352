import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useDebounce } from 'usehooks-ts';

import { useListCompaniesForCustomers } from '../../../../../hooks';
import { Roles } from '../../../../../utils/RoleUtil';
import { DEFAULT_ALL_COMPANIES } from '../../../../../utils/Constants';
import { Role } from '../../../../../models/Enum';

import { ContainerFilters, ContentFilters } from '../../../../../common-styles';

interface UserFiltersProps {
  companyId: string;
  selectedRole: Role[];
  onCompanySelected: (companyId: string) => void;
  onRolesSelected(options: Role[]): void;
  setSearchInputFilter: (searchInput: string) => void;
}

export const UserFilters = ({ companyId, selectedRole: givenRole, onCompanySelected, onRolesSelected, setSearchInputFilter }: UserFiltersProps) => {
  const [selectedProfile, setSelectedProfile] = useState<Role[]>(givenRole);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 400);
  const { data: companies } = useListCompaniesForCustomers();

  useEffect(() => {
    setSearchInputFilter(debouncedSearch);
  }, [debouncedSearch, setSearchInputFilter]);

  const companiesName = useMemo(() => {
    const names = companies?.map((company) => {
      return {
        value: company._id,
        label: company.fantasyName,
      };
    });
    names?.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: DEFAULT_ALL_COMPANIES,
    });
    return names;
  }, [companies]);

  return (
    <ContainerFilters>
      <Form
        layout="vertical"
        style={{ marginTop: '16px' }}
      >
        <ContentFilters>
          <Form.Item
            label={<label className="label-input-field">Pesquisar</label>}
          >
            <Input.Search
              allowClear
              defaultValue={search}
              placeholder="Pesquise por qualquer entrada"
              onChange={(event) => setSearch(event.target.value)}
              onSearch={setSearch}
              style={{ height: '40px' }}
            />
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Perfil</label>}
          >
            <Select
              defaultValue={selectedProfile}
              onChange={(options: Role[]) => {
                onRolesSelected(options);
                setSelectedProfile(options);
              }}
              placeholder="Nenhum perfíl selecionado"
              value={selectedProfile}
              mode="multiple"
            >
              {Roles.map((type) => (
                <Select.Option key={type.label} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Empresas</label>}
          >
            <Select
              defaultValue={companyId}
              onChange={onCompanySelected}
              suffixIcon={<CaretDownOutlined />}
              value={companyId}
            >
              {companiesName?.map((company) => (
                <Select.Option
                  key={company.value}
                  value={company.value}
                >
                  {company.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ContentFilters>
      </Form>
    </ContainerFilters>
  );
}