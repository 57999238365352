import api from './api';
import { CompanyFeatures } from '../models/CompanyFeatures';
import { CompanyActivityLogService } from './CompanyActivityLogService';

export class UpdateCompanyFeaturesService {
  public static async update(features: CompanyFeatures): Promise<CompanyFeatures> {
    const response = await api.put(
      `/api/company/${features.companyId}/features/${features._id}`,
      features
    );

    CompanyActivityLogService.register({ action: 'Configurações atualizadas', actionArea: 'Configurações' });

    return response.data.data;
  }

  public static async deleteDeliveryFee(deliveryFeeId: string, companyId: string): Promise<void> {
    return await api.delete(`/api/company/${companyId}/features/clientFare/${deliveryFeeId}`);
  }
}
