import { useCallback } from "react";
import { useUpdateOrder } from "../../../../hooks";
import { NotificationDispatcher, NotificationType } from "../../../../components/Notification";

export const useUpdateOrderStatus = () => {
  const { update } = useUpdateOrder();

  const updateOrderStatus = useCallback((order, newStatus) => {
    update(
      {
        ...order,
        status: newStatus,
      },
      {
        onSuccess: () => {
          NotificationDispatcher({
            message: 'Operação realizada com sucesso',
          });
        },
        onError: () => {
          NotificationDispatcher({
            message: 'Falha ao tentar atualizar o status do Pedido',
            type: NotificationType.ERROR,
          });
        }
      }
    );
  }, [update]);

  return { updateOrderStatus };
};