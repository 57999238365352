import React from 'react';
import { RulesInfo } from './styles';

export const CouponRulesInfo = () => (
  <RulesInfo>
    <p>Regras:</p>
    <ul>
      <li>Apenas 1 cupom por pedido</li>
      <li>Apenas 1 cupom por telefone</li>
      <li>Aplicável apenas no valor da compra e não do frete</li>
    </ul>
  </RulesInfo>
);
