import { Contact } from '../models/DataResponse';
import { useFetch } from './useFetch';

const CONTACTS = 'contacts';

export const useContacts = (currentPage: number) => {
  const { data, isFetching, refetch } = useFetch<Contact[]>({
    // refetchOnWindowFocus: true, // TODO: Rollback this, when we change the pages to be a real page.
    queryKey: CONTACTS,
    url: `api/contacts?page=1&limit=15`,
  });

  return { data, isFetching, refetch };
};
