import React from 'react';
import { CopyFilled } from '@ant-design/icons';
import { TableGenericAction } from '../TableGenericAction';

type TableCloneActionProps = {
  onClick(): void;
};

export const TableCloneAction = ({ onClick }: TableCloneActionProps) => {
  return (
    <TableGenericAction
      icon={<CopyFilled style={{ fontSize: '18px' }} />}
      label="Duplicar"
      onClick={onClick}
    />
  );
};
