import React, { useEffect, useState, RefObject } from 'react';
import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import BoxWithLabel from '../../../../../components/BoxWithLabel';
import { Order } from '../../../../../models/DataResponse';
import { CASH_MONEY } from '../../../../../utils/Constants';

interface PaymentWayProps {
  paymentMethods: string[];
  formRef: RefObject<HTMLFormElement>;
  handleOrderData(key: string, value: any): void;
  userInfoData?: Order;
}

export const PaymentWay = ({
  paymentMethods,
  formRef,
  handleOrderData,
  userInfoData,
}: PaymentWayProps) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>('');
  const [moneyChange, setMoneyChange] = useState<string>('');

  useEffect(() => {
    if (userInfoData) {
      setSelectedPaymentMethod(userInfoData?.paymentType);
      setMoneyChange(userInfoData?.moneyChange || '');
    }
  }, [userInfoData]);

  return (
    <form ref={formRef}>
      <BoxWithLabel title="Forma de Pagamento">
        <RadioGroup
          name="paymentMethod"
          value={selectedPaymentMethod}
          onChange={(event) => {
            const paymentType = (event.target as HTMLInputElement).value;
            setSelectedPaymentMethod(paymentType);
            handleOrderData('paymentType', paymentType);
          }}
        >
          <div>
            {paymentMethods.map((paymentMethod) => {
              return (
                <FormControlLabel
                  key={paymentMethod}
                  control={<Radio required />}
                  value={paymentMethod}
                  label={paymentMethod}
                />
              );
            })}
          </div>
        </RadioGroup>

        {selectedPaymentMethod === CASH_MONEY && (
          <TextField
            id="moneyChange"
            variant="outlined"
            type="number"
            className="form-general-input"
            label="Troco para quanto?"
            style={{ marginTop: '16px' }}
            value={moneyChange}
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const moneyChange = event.target.value;
              setMoneyChange(moneyChange);
              handleOrderData('moneyChange', moneyChange);
            }}
          />
        )}

        <FormHelperText>
          Pagamento obrigatório<span style={{ color: 'red' }}>*</span>
        </FormHelperText>
      </BoxWithLabel>
    </form>
  );
};
