import React from 'react';

import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';
import { MenuOption } from '../../../../../../models/MenuOption';

import { ContentHeader } from '../../../../../../components/ContentHeader';
import { FeedbackItems } from './components/FeedbackItems';

interface SettingsFeedbackProps {
  companyFeatures: CompanyFeatures;
}

export const SettingsFeedback = ({ companyFeatures }: SettingsFeedbackProps) => {
  return (
    <>
      <ContentHeader title={MenuOption.SETTINGS_FEEDBACK} />

      <FeedbackItems companyFeatures={companyFeatures} />
    </>
  );
};
