import styled from 'styled-components';

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .client-img {
    width: 300px;
    object-fit: scale-down;
  }

  .qrcode-img {
    width: 300px;
  }

  .qrcode-logo {
    width: 100px;
  }

  .qrcode-download-button {
    text-align: end;
    margin-top: 35px;
  }
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  width: 60%;
`;
