import { ReactNode } from 'react';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';

export enum NotificationType {
  ERROR,
  INFO,
  OPEN,
  SUCCESS,
  WARNING,
}

interface NotificationProps {
  description?: string | ReactNode;
  message: string;
  placement?: NotificationPlacement;
  type?: NotificationType;
}

export const NotificationDispatcher = ({
  description,
  message,
  placement = 'bottomRight',
  type = NotificationType.SUCCESS,
}: NotificationProps) => {
  const config = {
    message,
    description,
    placement: placement,
  };

  switch (type) {
    case NotificationType.ERROR:
      notification.error(config);
      break;
    case NotificationType.SUCCESS:
      notification.success(config);
      break;
    case NotificationType.INFO:
      notification.info(config);
      break;
    case NotificationType.OPEN:
      notification.open(config);
      break;
    case NotificationType.WARNING:
      notification.warning(config);
      break;
  }
};
