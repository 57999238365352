import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

// This file is still in JS due to the fact that the CKEditor5 library is not yet available in TS.
export const RichText = (props) => {
  const editorConfig = {
    plugins: [
      'Essentials',
      'Heading',
      'Paragraph',
      'Bold',
      'Italic',
      'BlockQuote',
      'Link',
    ],
    toolbar: [
      'Heading',
      '|',
      'Bold',
      'Italic',
      'Link',
      'BlockQuote',
      'Undo',
      'Redo',
    ],
  };

  return (
    <div>
      <CKEditor
        config={editorConfig}
        editor={ClassicEditor}
        onInit={(editor) => {
          if (props.initialValue) {
            editor.setData(props.initialValue);
          }
        }}
        onChange={(_, editor) => props.handleInputChange(editor.getData())}
      />
    </div>
  );
};
