import { useQuery } from 'react-query';
import { QRLink, LinkTreeClientResponse } from '../models/QRLink';
import api from '../services/api';
import { useFetch } from './useFetch';
import { useLoggedUser } from './useLoggedUser';

export const QR_LINKS = 'linkTree';
const QR_LINKS_CLIENT_PAGE = 'linkTreeClientPage';

export const useQrlinks = () => {
  const { userLogged: { user: { company } } } = useLoggedUser();

  const { data, ...rest } = useFetch<QRLink>({
    queryKey: QR_LINKS,
    url: `api/linktree/${company}`,
  });

  return { data, ...rest };
};

export const useQrlinkBySlug = (clientSlug?: string) => {
  const { data, ...rest } = useQuery<LinkTreeClientResponse>(
    [QR_LINKS_CLIENT_PAGE],
    async () => {
      const response = await api.get(`api/linktree?slug=${clientSlug}`);
      return response.data.data;
    },
    {
      select: response => ({
        ...response,
        linkTree: {
          ...response.linkTree,
          links: response.linkTree.links.filter(item => item.isEnabled),
        },
      }),
    });

  return { data, ...rest };
}