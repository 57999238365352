import React, { useEffect } from "react";
import useStorage from "./hooks/useStorage";
import "./progress.css";

const ProgressBar = ({ file, resolution, setFile, imageUrlReady, setUploadInProgress }) => {
  const { url, progress } = useStorage(file, resolution);

  useEffect(() => {
    if (url) {
      // Setting as null to hide the ProgressBar
      setFile(null);
      imageUrlReady(url);
      setUploadInProgress(false);
    } else {
      setUploadInProgress(true);
    }
  }, [url, setFile, imageUrlReady, setUploadInProgress, progress]);

  return <div className="progress-bar" style={{ width: progress + "%" }}></div>;
};

export default ProgressBar;
