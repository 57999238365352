import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Typography } from 'antd';
import { PREFIX_LOCALSTORAGE } from '../../../../../utils/Constants';
import { generateQRInBase64 } from '../../../../../utils/QRCodeGeneratorUtil';
import { getClientMenuUrl } from '../../../../../utils/Util';
import { ContentGrid, ImagesContainer } from '../../styles';
import html2canvas from 'html2canvas';
import { DisabledFormInput } from '../../../../../components/DisabledFormInput';

type QRLinkQrcodeProps = {
  clientLogo: string;
  companyName: string;
};

export const QRLinkQrcode = ({
  clientLogo,
  companyName,
}: QRLinkQrcodeProps) => {
  const [qrcodeToDownload, setQrcodeToDownload] = useState<string>('');
  const [urlQRCode, setUrlQRCode] = useState<string>('');

  const generateQR = async (data: string) => {
    if (!data) return;

    try {
      const base64 = await generateQRInBase64(data);
      if (base64) {
        setQrcodeToDownload(base64);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const slug = localStorage.getItem(`${PREFIX_LOCALSTORAGE}slug`) || '';
    const link = getClientMenuUrl(`qrlinks/${slug}`);
    generateQR(link);
    setUrlQRCode(link);
  }, []);

  const handleDownloadAction = () => {
    const element = document.querySelector('#capture') as HTMLElement;

    html2canvas(element, {
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
    }).then((canvasResult) => {
      const image = canvasResult
        .toDataURL('image/jpeg')
        .replace('image/png', 'image/octet-stream');
      const link = document.createElement('a');
      link.download = `QRcode_qrlinks_${companyName}.jpeg`;
      link.href = image;
      link.click();
    });
  };

  return (
    <>
      <Divider />

      <Typography.Title level={4} style={{ marginTop: '20px' }}>
        QRLink
      </Typography.Title>

      {urlQRCode && (
        <Form
          layout="vertical">
          <DisabledFormInput label="URL de acesso" defaultValue={urlQRCode} />
        </Form>
      )}

      <ContentGrid>
        <div>
          <ImagesContainer id="capture">
            <img className="client-img" src={clientLogo} alt="Client logo" />

            <img
              className="qrcode-img"
              src={qrcodeToDownload}
              alt="generated qrcode"
            />

            <img
              className="qrcode-logo"
              src={require('../../../../../assets/images/logo-bg-white.png')}
              alt="qrcode preferido"
            />
          </ImagesContainer>
          <Button
            type="primary"
            style={{ marginTop: '20px', width: '100%' }}
            onClick={handleDownloadAction}
          >
            Download
          </Button>
        </div>
      </ContentGrid>
    </>
  );
};
