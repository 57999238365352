import React, { useState, useEffect } from 'react';
import ProgressBar from './ProgressBar';
import { Typography } from '@material-ui/core';
// import { firebaseStorage } from '../../../src/services/firebase';
// import api from '../../../src/services/api';

const UploadForm = ({
  setUrl,
  url,
  setUploadInProgress,
  isShortImage = true,
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const types = ['image/png', 'image/jpeg', 'image/jpg'];
  const resolution = isShortImage ? 400 : 1080;
  const imageSize = isShortImage ? 1 : 2;
  const maxAllowedSize = imageSize * 1024 * 1024;

  useEffect(() => {
    setImageUrl(url ?? '');
  }, [url]);

  function changeHandler(e) {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      if (selected.size > maxAllowedSize) {
        setFile(null);
        setError('Tamanho máximo permitido é 1MB.');
      } else {
        setFile(selected);
        setError('');
      }
    } else {
      setFile(null);
      setError('Selecione uma imagem válida (png ou jpeg)');
    }
  }

  function imageUrlReady(newUrl) {
    setImageUrl(newUrl);
    setUrl(newUrl);
  }

  async function removeImageFromFirebase(event) {
    event.preventDefault();
    setImageUrl(null);
    setUrl('');

    // try {
    //   api
    //     .get(
    //       `/api/catalog/checkCatalogHasImageUrlDependency/${encodeURIComponent(
    //         imageUrl
    //       )}`
    //     )
    //     .then((response) => {
    //       const data = response.data;
    //       const numberOfDependency = data.data.numberOfDependency;
    //       if (data.success && numberOfDependency <= 1) {
    //         // Remove image from Firebase
    //         const ref = firebaseStorage.refFromURL(imageUrl);
    //         ref
    //           .delete()
    //           .then(function () {
    //             // File deleted successfully
    //             console.log(
    //               '%c Image removed successfully from Firebase',
    //               'color: green'
    //             );
    //           })
    //           .catch((error) =>
    //             console.error('Error tyring to remove image from Firebase', error)
    //           );
    //       } else {
    //         console.warn(
    //           'Image not removed from Firebase, due to have dependencies'
    //         );
    //       }
    //     })
    //     .catch((error) =>
    //       console.log(
    //         'Error fetching checkCatalogHasImageUrlDependency API',
    //         error
    //       )
    //     );
    // } catch (error) {
    //   console.error('Error fetching checkCatalogHasImageUrlDependency', error);
    // } finally {
    //   setImageUrl(null);
    //   setUrl('');
    // }
  }

  return (
    <div>
      {!imageUrl && <input type="file" onChange={changeHandler} />}

      <div className="output">
        {error && (
          <Typography color="secondary" style={{ marginTop: '10px' }}>
            <strong>{error}</strong>
          </Typography>
        )}
        {file && (
          <ProgressBar
            file={file}
            resolution={resolution}
            setFile={setFile}
            imageUrlReady={imageUrlReady}
            setUploadInProgress={setUploadInProgress}
          />
        )}
        {imageUrl && (
          <div>
            <img
              className="grid-item-picture-specs"
              src={imageUrl}
              alt={imageUrl}
            />
            <i
              className="fa fa-fw fa-trash"
              style={{ fontSize: '1.75em', cursor: 'pointer' }}
              onClick={removeImageFromFirebase}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadForm;
