import React from 'react';
import { useLocation } from 'react-router-dom';
import { PREFIX_LOCALSTORAGE } from '../utils/Constants';
import { General } from './general.routes';
import { Backoffice } from './backoffice.routes';

const getTokenFromLocalStorage = (): string | null => {
  try {
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}token`);
  } catch (error) {
    // Handle any potential errors when accessing localStorage
    console.error('Error accessing localStorage:', error);
    return null;
  }
};

export const Routes = () => {
  const location = useLocation();
  const isPrivateRoute = getTokenFromLocalStorage() && location.pathname.includes('sysadmin');

  return isPrivateRoute ? <Backoffice /> : <General />;
};
