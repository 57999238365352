import React, { useEffect } from 'react';

import api from '../../../services/api';

type ClientData = {
  name: string;
  menuUrl: string;
};

const TestMenus = () => {
  useEffect(() => {
    api
      .get(`api/boot/clients`)
      .then((response) => {
        const clients = response.data.data as ClientData[];
        clients.sort((a, b) => a.name.localeCompare(b.name));

        clients.forEach((menuItem) => {
          const a = document.createElement('a');
          a.target = '_blank';
          a.href = menuItem.menuUrl;
          a.click();
        });
      })
      .catch((error) => {
        console.error('Fail loding menu', error);
      });
  }, []);

  return <h1>All menus</h1>;
};

export default TestMenus;
