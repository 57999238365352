import React from "react";
import { Menu } from "antd";
import { MenuOption } from "../../../../models/MenuOption";

export const MenuItem = (
  menuOption: MenuOption,
  icon: any,
  extra?: React.ReactNode,
) => {
  return (
    <Menu.Item
      key={menuOption}
      icon={icon}
      style={{ color: 'white' }}
    >
      {menuOption} {extra}
    </Menu.Item>
  );
}
