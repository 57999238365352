import styled from 'styled-components';

interface IPriceLabel {
  hasPromotion: boolean;
}

interface DetailsButtonProps {
  isDetail: boolean;
}

export const Container = styled.div`
  cursor: pointer;

  padding-top: 30px;
  padding-right: 3px;
  padding-left: 3px;

  color: white;

  border: 1px solid #e4e4e4;

  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;

  margin-top: 10px;
  margin-bottom: 10px;

  > div {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 6px;
  }

  > div h4 {
    text-decoration: line-through;
    color: #b4b4b4;
    font-weight: normal;
    font-size: 13px;
    margin-top: 4px;
  }

  > div h3 {
    color: red;
    font-weight: bold;
    font-size: 17px;
    margin-left: 8px;
  }

  > div small {
    color: black;
    font-weight: bold;
  }

  > small {
    color: gray;
    margin-top: 10px;
  }

  > h3 {
    color: #4a4a4a;
  }
`;

export const PriceLabel = styled.h2<IPriceLabel>`
  margin-top: 6px;
  color: ${(props) => (props.hasPromotion ? 'red' : '#0c0901')};
`;

export const OffLabel = styled.h3`
  color: black !important;
`;

export const DetailsButton = styled.p<DetailsButtonProps>`
  width: ${(props) => (props.isDetail ? '76px' : '80px')};
  display: flex;

  align-self: center;

  grid-area: add;
  margin-top: 16px;
  padding: 8px 10px;

  color: black;
  font-size: 14px;

  border-radius: 10px;
  border: 1px solid black;

  &:hover {
    opacity: 0.7;
  }
`;
