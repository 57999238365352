import styled from 'styled-components';

export const Container = styled.div`
  height: 400px;
  background: #E1FAEC;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 0;
  cursor: pointer;
  > img {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    border: 1px solid #333;
    border-radius: 4px;
  }
  > p {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    border-radius: 10px;
    border: 1px dashed #4ECB79;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
  }
  > p svg {
    color: #4ECB79;
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }
  > p span {
    text-align: center;
  }
`;

export const RemoveButton = styled.i`
  font-size: 1.75em;
  cursor: pointer;
  float: right;
  margin-top:  10px;
  margin-left: 10px;
  align-self: end;
`;