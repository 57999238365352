import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  position: fixed;
  top: 10%;
  right: 25%;
  margin-right: 25px;
  z-index: 999;
  overflow: hidden;
  height: 72px;
  width: 72px;
  border-radius: 10px;
  transition: height 0.4s 0.1s, width 0.4s 0.1s, box-shadow 0.3s;
  transition-timing-function: cubic-bezier(0.67, 0.17, 0.32, 0.95);
  background-color: white;
  box-shadow: 0px 4px 12px -6px black;

  @media (min-width: 1440px) {
    right: 34%;
  }

  @media (min-width: 1030px) {
    right: 18%;
  }

  @media (max-width: 1024px) {
    right: 12%;
  }

  @media (max-width: 800px) {
    right: 2%;
  }

  @media (max-width: 500px) {
    right: 0;
    margin-right: 10px;
  }

  .chart-circle-counter {
    border-radius: 50%;
    width: 24px;
    height: 22px;
    padding-top: 4px;
    margin-left: 0px;
    margin-top: 0px;
    background: #e94a35;
    text-align: center;
    font: 12px Arial, sans-serif;
    font-weight: bold;
    color: white;
    z-index: 10;
    overflow: hidden;
  }

  .floating-chart-icon {
    margin: 0 auto;
    padding-top: 20px;
    padding-right: 18px;
    font-size: 1.75em;
  }
`;
