import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: initial;
  margin-top: 10px;
`;

export const Label = styled.span`
  color: #717171;
  font-size: 18px;
  text-align: start;
`;

export const Value = styled.span`
  color: #3f3e3e;
  font-size: 18px;
  text-align: end;
`;
