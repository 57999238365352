import { useFetch } from './useFetch';

const UNREAD_CONTACTS_COUNTER = 'unreadContactsCounter';

export const useUnreadContactsCounter = () => {
  const { data, ...rest } = useFetch<{ total: number }>({
    // refetchOnWindowFocus: true, // TODO: Rollback this, when we change the pages to be a real page.
    queryKey: UNREAD_CONTACTS_COUNTER,
    url: 'api/contacts/unread',
  });

  return { data, ...rest };
};
