import { useFetch } from './useFetch';

type PaymentType = {
  _id: string;
  payment: string;
};

const PAYMENT_TYPES = 'paymentTypes';

export const usePaymentTypes = () => {
  const { data, ...rest } = useFetch<PaymentType[]>({
    queryKey: PAYMENT_TYPES,
    url: 'api/payment-type',
  });

  return { data, ...rest };
};
