export const PREFIX_LOCALSTORAGE = '@qrcodepreferido:';
export const DEFAULT_ALL_COMPANIES = 'Todas as empresas';
export const DEFAULT_SELECT_COMPANY = 'Selecione a Empresa...';
export const DEFAULT_SELECT_DRIVER = 'Selecione o Entregador...';
export const ALL_TABS = 'Todas as abas';

export const WeekdaysList = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const CASH_MONEY = 'Dinheiro';
export const MBWAY_PAYMENT_TYPE = 'MBWAY';
export const CARD_PAYMENT_TYPE = ['CARTÃO', 'VISA', 'MASTERCARD'];

export const WHATSAPP_API_LINK = 'https://api.whatsapp.com/send?phone=';

export const MIDNIGHT = '00:00';

export const DEFAULT_TIMEZONE = 'Europe/Lisbon';

export const SummarySectionOrder = {
  COMPANY_NAME: 'COMPANY_NAME',
  ORDER_ID: 'ORDER_ID',
  SEPARATOR: 'SEPARATOR',
  PRODUCTS: 'PRODUCTS',
  TOTAL: 'TOTAL',
  CLIENT_NAME: 'CLIENT_NAME',
  USER_INFO: 'USER_INFO',
  PAYMENT_TYPE: 'PAYMENT_TYPE',
  DELIVERY_TYPE: 'DELIVERY_TYPE',
  SCHEDULED_DATE_TIME: 'SCHEDULED_DATE_TIME',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
  OBSERVATION: 'OBSERVATION',
  AUTOMATIC_MESSAGE: 'AUTOMATIC_MESSAGE',
};