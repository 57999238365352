import React from 'react';
import parse from 'html-react-parser';
import { Skeleton, Table } from 'antd';
import { ContentHeader } from '../../../components/ContentHeader';
import { useActivityLogs, useLoggedUser } from '../../../hooks';
import { MenuOption } from '../../../models/MenuOption';
import { ActivityLog } from '../../../models/ActivityLog';
import { formatDatetime } from '../../../utils/dateTime';

const columns = [
  {
    title: 'Data e hora',
    render: (_: any, activityLog: ActivityLog) => formatDatetime(activityLog.createdAt)
  },
  {
    title: 'Ação',
    render: (_: any, activityLog: ActivityLog) => parse(activityLog.action)
  },
  {
    title: 'Área',
    dataIndex: 'actionArea',
  },
  {
    title: 'Usuário',
    dataIndex: 'userName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Browser',
    dataIndex: 'browser',
  },
  {
    title: 'Dispositivo',
    dataIndex: 'operatingSystem',
  },
];

export const ActivityLogsPage = () => {
  const { userLogged: { user: { company } } } = useLoggedUser();
  const { activityLogs, isFetching } = useActivityLogs(company);

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <>
      <ContentHeader title={MenuOption.ACTIVITY_LOG} />

      <Table
        pagination={false}
        dataSource={activityLogs ? [...activityLogs] : []}
        columns={columns}
        bordered
      />

      <div id="lastInvisibleRow" />
    </>
  );
}
