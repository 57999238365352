import React from 'react';
import { Button, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

type SignInProps = {
  isFetching: boolean;
  isRequired: boolean;
};

export const SignIn = ({ isFetching, isRequired }: SignInProps) => {
  return (
    <>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: isRequired, message: 'Campo email obrigatório' }]}
        style={{ marginTop: '16px' }}
      >
        <Input
          placeholder="Digite o seu email"
          onInput={e => e.currentTarget.value = e.currentTarget.value.trim().toLocaleLowerCase()}
          prefix={<UserOutlined />}
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Form.Item
        name="password"
        label="Senha"
        rules={[{ required: isRequired, message: 'Campo senha obrigatório' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Digite a sua senha"
          style={{ height: '40px' }}
        />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: '100%' }}
        loading={isFetching}
      >
        Login
      </Button>
    </>
  );
};
