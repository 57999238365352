import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Space, Table } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';
import { PendingRegistration } from '../../../../models/PendingRegistration';
import { formatDate } from '../../../../../../../utils/dateTime';
import { TableGenericAction } from '../../../../../../../components/TableGenericAction';

interface PendingRegistrationCompanyTableProps {
  companies: PendingRegistration[] | undefined;
  loading: boolean;
};

export const PendingRegistrationCompanyTable = ({ companies = [], loading }: PendingRegistrationCompanyTableProps) => {
  const navigate = useNavigate();

  const columns: ColumnsType<PendingRegistration> = [
    {
      title: 'Empresa',
      dataIndex: 'fantasyName',
      key: 'fantasyName',
    },
    {
      title: 'Data do cadastro',
      render: (_: any, company: PendingRegistration) => formatDate(company.createdAt)
    },
    {
      title: 'Ações',
      fixed: 'right',
      width: 150,
      key: 'actions',
      render: (_: any, item: PendingRegistration) => (
        <Space direction="vertical" key={item._id}>
          <TableGenericAction
            icon={<CheckCircleOutlined style={{ fontSize: '18px' }} />}
            label="Cadastrar"
            onClick={() => { 
              navigate(`/sysadmin/companies/${item._id}/pending`);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={[...companies]}
      loading={loading}
      rowKey="Id"
      scroll={{ x: 1300 }}
    />
  );
};