import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { Button, Input, Space } from 'antd';

import BoxWithLabel from '../../../../../../../components/BoxWithLabel';
import { CouponRulesInfo } from '../../../../../../../components/CouponRulesInfo';

import api from '../../../../../../../services/api';
import { Coupon } from '../../../../../../../models/Coupon';

import { ErrorMessage, SuccessMessage } from './styles';

type CouponInputProps = {
  slug: string;
  phone: string;
  handleCouponCode(coupon?: Coupon): void;
};

export const CouponInput = ({
  phone,
  slug,
  handleCouponCode,
}: CouponInputProps) => {
  const [coupon, setCoupon] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCheckCouponCode = async () => {
    if (!phone) {
      setErrorMessage('O número de telefone é obrigatório');
      return;
    }

    if (phone.length < 9) {
      setErrorMessage('Número de telefone inválido');
      return;
    }

    setLoading(true);
    setErrorMessage('');

    await api
      .get(
        `api/coupon/validate/code?couponCode=${coupon}&slug=${slug}&phone=${encodeURIComponent(
          phone
        )}`
      )
      .then((response) => {
        if (response.data.success) {
          const couponResponse = response.data.data as Coupon;
          setSuccessMessage(true);
          handleCouponCode(couponResponse);
          setErrorMessage('');
        }
      })
      .catch((ex) => {
        const error = ex as AxiosError;
        let errorMessage = 'Cupom inválido';

        if (error.response) {
          switch (error.response.status) {
            case 403:
              errorMessage = 'Cupom já utilizado';
              break;
          }
        }

        setSuccessMessage(false);
        handleCouponCode(undefined);
        setErrorMessage(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BoxWithLabel title="Cupom de desconto">
      <Space>
        <Input
          type="text"
          value={coupon}
          className="form-general-input"
          placeholder="Código do cupom"
          onChange={(e) => setCoupon(e.target.value.toUpperCase())}
          style={{ height: '40px' }}
        />

        <Button
          loading={isLoading}
          type="primary"
          onClick={handleCheckCouponCode}
        >
          Aplicar
        </Button>
      </Space>

      {errorMessage && (
        <ErrorMessage>
          {errorMessage}{' '}
          <i
            className="fa fa-times"
            aria-hidden="true"
            style={{ marginLeft: '2px' }}
          />
        </ErrorMessage>
      )}
      {successMessage && (
        <SuccessMessage>
          Cupom aplicado{' '}
          <i
            className="fa fa-check"
            aria-hidden="true"
            style={{ marginLeft: '2px' }}
          />
        </SuccessMessage>
      )}

      <CouponRulesInfo />
    </BoxWithLabel>
  );
};
