import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

export const Title = styled.span`
  color: #3f3e3e;
  font-size: 30px;
  font-weight: bold;
`;

export const Heading = styled(Title)`
  font-size: 20px;
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

export const ItemsGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  list-style: none;
  margin-top: 16px;

  li {
    background: #f5f5f5;
    border: 2px solid #f5f5f5;
    border-radius: 8px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    font-weight: 500;

    cursor: pointer;
  }

  li span {
    flex: 1;

    display: flex;
    align-items: center;
    color: #3f3e3e;
  }

  li.selected {
    background: #faffbb;
    border: 2px solid #ffc300;
  }
`;

export const CommentLabel = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #3f3e3e;
  text-align: left;

  margin-top: 25px;
`;

export const InputComment = styled.textarea`
  width: 100%;

  margin: 7px 0;
  padding: 10px;

  border-radius: 3px;
  border: 1px solid #dcdcdc;

  resize: none;
`;

export const HintCharCounter = styled.span`
  text-align: right;
  color: #0000008a;
  font-weight: bold;
  font-size: 12px;
`;
