import React from "react";
import LazyLoad from "react-lazyload";
import { LinkPage } from "../../../../../models/QRLink";
import { Container, Content } from "./styles";

import defaultThumbnail from '../../../../../assets/images/default_image_thumbnail.png';

interface QrLinkItemProps {
  linkPage: LinkPage;
}

export const QrLinkItem = ({ linkPage }: QrLinkItemProps) => {
  const { name, imageUrl, url } = linkPage;

  const handleItemClick = () => window.open(url, '_blank');
  
  return (
    <Container onClick={handleItemClick}>
      <Content>
        <LazyLoad height={120} once={true}>
          <img
            className="grid-item-picture"
            src={imageUrl ? imageUrl : defaultThumbnail}
            alt={name}
          />
        </LazyLoad>
        <h2>{name}</h2>
      </Content>
    </Container>
  );
}
