import React, { useMemo } from 'react';
import { useLoggedUser } from '../../hooks';
import { WhatsappOrder } from '../../models/DataResponse';
import { Role } from '../../models/Enum';
import { OrderStatus } from '../../models/OrderStatus';
import formatCurrency from '../../utils/formatCurrency';
import { getTotalFromOrder } from '../../utils/OrderUtil';
import { CardCounter, Container, CountName, CountNumber, CountValue } from './styles';

interface OrderCountersProps {
  orders: WhatsappOrder[];
  currency: string;
}

export const OrderCounters = ({ orders, currency }: OrderCountersProps) => {
  const { userLogged: { user: { role } } } = useLoggedUser();
  const showAmount = role === Role.ADMIN || role === Role.MANAGER || role === Role.USER;

  const counters = useMemo(() => {
    const initialCounters = {
      canceledAmount: 0,
      canceledCounter: 0,
      concludedAmount: 0,
      concludedCounter: 0,
      inProgressAmount: 0,
      inProgressCounter: 0,
      inTransitAmount: 0,
      inTransitCounter: 0,
      pendingAmount: 0,
      pendingCounter: 0,
      readyAmount: 0,
      readyCounter: 0,
      waitingPaymentCounter: 0,
      waitingPaymentAmount: 0,
    };

    return orders.reduce((acc, order) => {
      const orderTotal = getTotalFromOrder(currency, order).total;
      switch (order.status) {
        case OrderStatus.CANCELLED:
          return {
            ...acc,
            canceledCounter: acc.canceledCounter + 1,
            canceledAmount: acc.canceledAmount + orderTotal,
          };
        case OrderStatus.READY:
          return {
            ...acc,
            readyCounter: acc.readyCounter + 1,
            readyAmount: acc.readyAmount + orderTotal,
          };
        case OrderStatus.CONCLUDED:
          return {
            ...acc,
            concludedCounter: acc.concludedCounter + 1,
            concludedAmount: acc.concludedAmount + orderTotal,
          };
        case OrderStatus.PENDING:
          return {
            ...acc,
            pendingCounter: acc.pendingCounter + 1,
            pendingAmount: acc.pendingAmount + orderTotal,
          };
        case OrderStatus.IN_PROGRESS:
          return {
            ...acc,
            inProgressCounter: acc.inProgressCounter + 1,
            inProgressAmount: acc.inProgressAmount + orderTotal,
          };
        case OrderStatus.IN_TRANSIT:
          return {
            ...acc,
            inTransitCounter: acc.inTransitCounter + 1,
            inTransitAmount: acc.inTransitAmount + orderTotal,
          };
        case OrderStatus.WAITING_PAYMENT:
          return {
            ...acc,
            waitingPaymentCounter: acc.waitingPaymentCounter + 1,
            waitingPaymentAmount: acc.waitingPaymentAmount + orderTotal,
          };
        default:
          return acc;
      }
    }, { ...initialCounters, totalOrders: orders.length, totalValueOrders: 0 });
  }, [orders, currency]);

  counters.totalValueOrders =
    counters.canceledAmount +
    counters.readyAmount +
    counters.concludedAmount +
    counters.pendingAmount +
    counters.inProgressAmount +
    counters.waitingPaymentAmount;

  const getValueFormatted = (value: number) => formatCurrency(value, currency);

  return (
    <Container>
      {counters.waitingPaymentCounter > 0 && (
        <CardCounter className='payment'>
          <CountNumber>{counters.waitingPaymentCounter}</CountNumber>
          <CountName>Pagamento pendente</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.waitingPaymentAmount)}</CountValue>}
        </CardCounter>
      )}
      
      {counters.pendingCounter > 0 && (
        <CardCounter className='pending'>
          <CountNumber>{counters.pendingCounter}</CountNumber>
          <CountName>Pendentes</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.pendingAmount)}</CountValue>}
        </CardCounter>
      )}

      {counters.inProgressCounter > 0 && (
        <CardCounter className='progress'>
          <CountNumber>{counters.inProgressCounter}</CountNumber>
          <CountName>Em execução</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.inProgressAmount)}</CountValue>}
        </CardCounter>
      )}

      {counters.readyCounter > 0 && (
        <CardCounter className='ready'>
          <CountNumber>{counters.readyCounter}</CountNumber>
          <CountName>Prontos</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.readyAmount)}</CountValue>}
        </CardCounter>
      )}

      {counters.inTransitCounter > 0 && (
        <CardCounter className='transit'>
          <CountNumber>{counters.inTransitCounter}</CountNumber>
          <CountName>Em trânsito</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.inTransitAmount)}</CountValue>}
        </CardCounter>
      )}

      {counters.concludedCounter > 0 && (
        <CardCounter className='concluded'>
          <CountNumber>{counters.concludedCounter}</CountNumber>
          <CountName>Entregue</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.concludedAmount)}</CountValue>}
        </CardCounter>
      )}

      {counters.canceledCounter > 0 && (
        <CardCounter className='canceled'>
          <CountNumber>{counters.canceledCounter}</CountNumber>
          <CountName>Cancelados</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.canceledAmount)}</CountValue>}
        </CardCounter>
      )}

      {counters.totalOrders > 0 && (
        <CardCounter className='total'>
          <CountNumber>{counters.totalOrders}</CountNumber>
          <CountName>Total</CountName>
          {showAmount && <CountValue>{getValueFormatted(counters.totalValueOrders)}</CountValue>}
        </CardCounter>
      )}
    </Container>
  );
};
