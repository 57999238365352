import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Tabs } from 'antd';
import type { TabsProps } from 'antd';

import { ActiveTab } from './components/ActiveTab';
import { AddOrUpdateCompany } from './containers/AddOrUpdate';
import { ContentHeader } from '../../../components/ContentHeader';
import { InactiveTab } from './components/InactiveTab';
import { NotificationDispatcher } from '../../../components/Notification';
import { PendingTab } from './components/PendingTab';

import { Company } from '../../../models/Company';
import { MenuOption } from '../../../models/MenuOption';
import { useInitCompaniesCounter } from './hooks/useInitCompaniesCounter';

enum Tab {
  ACTIVE = 'Ativo',
  INACTIVE = 'Desativado',
  PENDING = 'Pendente',
}

export const CompanyPage = () => {
  const { inactiveCounter, pendingCounter } = useInitCompaniesCounter();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isViewMode, setViewMode] = useState<boolean>(true);
  const [searchInput, setSearchInput] = useState<string>('');
  const [activeCount, setActiveCount] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<Company | any>({} as Company);
  const [currentTab, setCurrentTab] = useState(Tab.ACTIVE);
  const [inactiveCount, setInactiveCount] = useState<number>(0);
  const [pendingCount, setPendingCount] = useState<number>(0);

  useEffect(() => {
    if (inactiveCounter) {
      setInactiveCount(inactiveCounter);
    }

    if (pendingCounter) {
      setPendingCount(pendingCounter);
    }
  }, [inactiveCounter, pendingCounter]);

  const onCompanyActiveUpdate = useCallback((count: number) => setActiveCount(count), []);

  const onCompanyInactiveUpdate = useCallback((count: number) => setInactiveCount(count), []);

  const onCompanyPendingUpdate = useCallback((count: number) => setPendingCount(count), []);

  const handleRowClick = (selectedItem: Company, isViewMode: boolean) => {
    setSelectedItem(selectedItem);
    setShowModal(true);
    setViewMode(isViewMode);
  };

  const handleAddOrUpdate = () => {
    setSelectedItem(null);
    setShowModal(true);
    setViewMode(false);
  };

  const modalDismissed = (withAction: boolean) => {
    setShowModal(false);
    if (withAction) {
      NotificationDispatcher({
        message: 'Operação realizada com sucesso',
      });
    }
  };

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  const onSearch = (value: string) => setSearchInput(value);

  const items: TabsProps['items'] = [
    {
      key: Tab.ACTIVE,
      label: `${Tab.ACTIVE} (${activeCount})`,
      children: (
        <ActiveTab
          handleRowClick={handleRowClick}
          searchInput={searchInput}
          onCompaniesCounterLoaded={onCompanyActiveUpdate}
        />
      ),
    },
    {
      key: Tab.INACTIVE,
      label: `${Tab.INACTIVE} (${inactiveCount})`,
      children: (
        <InactiveTab
          handleRowClick={handleRowClick}
          searchInput={searchInput}
          onCompaniesCounterLoaded={onCompanyInactiveUpdate}
        />
      ),
    },
    {
      key: Tab.PENDING,
      label: `${Tab.PENDING} (${pendingCount})`,
      children: (
        <PendingTab
          onCompaniesCounterLoaded={onCompanyPendingUpdate}
        />
      ),
    },
  ];

  return (
    <>
      {showModal ? (
        <AddOrUpdateCompany
          pageDismissed={modalDismissed}
          selectedCompany={selectedItem}
          isViewMode={isViewMode}
        />
      ) : (
        <div>
          <ContentHeader title={MenuOption.COMPANY}>
            <Input.Search
              allowClear
              defaultValue={searchInput}
              enterButton="Pesquisar"
              onSearch={onSearch}
              placeholder="Pesquisar..."
              style={{ height: '40px' }}
            />
          </ContentHeader>

          <Button type="primary" onClick={handleAddOrUpdate} style={{ marginBottom: '10px' }}>
            <i
              className="fa fa-plus-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Adicionar
          </Button>

          <Tabs
            activeKey={currentTab.toString()}
            items={items}
            onChange={onTabChanged}
            style={{ marginTop: '16px' }}
            size='large'
          />
        </div>
      )}
    </>
  );
};
