import { Company } from '../../../../models/Company';
import { useFetch } from '../../../../hooks/useFetch';

export const ACTIVE_COMPANIES = 'active-companies';
const INACTIVE_COMPANIES = 'inactive-companies';

export const useCompanies = (isActive: boolean = true) => {
  const { data, ...rest} = useFetch<Company[]>({
    queryKey: isActive ? ACTIVE_COMPANIES : INACTIVE_COMPANIES,
    url: `api/company?status=${isActive ? 'active' : 'inactive'}`,
  });

  return {
    counter: data?.length ?? 0,
    data,
    ...rest,
  };
};
