import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CompanyFeatures } from '../models/CompanyFeatures';
import { CompanyActivityLogService } from '../services/CompanyActivityLogService';
import { UpdateCompanyFeaturesService } from '../services/UpdateCompanyFeaturesService';
import { COMPANY_FEATURES } from './useCompanyFeatures';
import { EXTRA_FEES } from '../pages/backoffice/Settings/SettingsOrders/components/ExtraFees/hooks/useExtraFees';

export const useUpdateCompanyFeatures = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (features: CompanyFeatures) => UpdateCompanyFeaturesService.update(features),
    {
      onSettled: () => {
        queryClient.invalidateQueries(EXTRA_FEES);
      },
    },
  );

  const update = useCallback(
    (features, { onSuccess, onError }) => {
      mutate(features, {
        onSuccess: (updatedFeatures) => {
          queryClient.setQueryData<CompanyFeatures>(
            [COMPANY_FEATURES],
            updatedFeatures
          );

          CompanyActivityLogService.register({ action: 'Configurações atualizadas', actionArea: 'Configurações' });
          
          onSuccess();
        },
        onError,
      });
    },
    [mutate, queryClient]
  );

  return { isLoading, isSuccess, isError, update };
};
