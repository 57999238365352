import { User } from '../models/User';
import api from './api';

export class UserService {
  public static async create(user: User): Promise<User> {
    const response = await api.post(
      '/api/users/',
      user
    );

    return response.data.data;
  }

  public static async update(user: User): Promise<User> {
    const response = await api.put(
      `/api/users/${user._id}`,
      user
    );

    return response.data.data;
  }

  public static async delete(id: string): Promise<void> {
    return await api.delete(`api/users/${id}`);
  }
}
