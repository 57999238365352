import React from 'react';
import { Button, Drawer, Form, Input, Space } from 'antd';

interface AddOrUpdateFeedbackProps {
  isVisible: boolean;
  item?: string;
  onClose(): void;
  onFinishForm(item?: string, isVisible?: boolean): void;
};

export const AddOrUpdateFeedback = ({
  isVisible,
  item,
  onClose,
  onFinishForm,
}: AddOrUpdateFeedbackProps) => {
  const isUpdate = item !== undefined;
  const onFinish = (values: any) => onFinishForm(values.item, isUpdate);

  const prefixTitle = isUpdate ? 'Atualizar' : 'Novo';
  const title = `${prefixTitle} item de Feedback`;

  return (
    <Drawer
      title={title}
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isVisible}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={<label className="label-input-field">Nome</label>}
          name="item"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input
            placeholder="Ex.: Simpatia"
            maxLength={50}
            defaultValue={item}
            style={{ height: '40px' }}
          />
        </Form.Item>

        <Space style={{ marginTop: '16px' }}>
          <Button danger onClick={onClose}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button type="primary" htmlType="submit">
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};
