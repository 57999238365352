import styled from 'styled-components';

export const Container = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center;
  text-align: center;

  > h2 {
    margin-top: 1rem;
  }
`;

export const Image = styled.img`
  width: 40%;
  height: 10vh;
  display: block;
  margin: auto;
`;

export const ButtonTryAgain = styled.button`
  width: 255px;
  height: 50px;
  background: #2fb86e;
  border-radius: 40px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: 0;
  margin-top: 16px;
  transition: background-color 0.2s;
  cursor: pointer;

  :hover {
    background: #678f8e;
  }
`;