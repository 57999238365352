import styled from 'styled-components';

interface IContainer {
  bgColor?: string;
}

export const Container = styled.div<IContainer>`
  > select {
    padding: 7px 10px;
    border-radius: 5px;
    margin-left: 7px;
    background-color: ${(props) => props.bgColor ? props.bgColor : '#fff'};
  }
`;
