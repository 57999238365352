export const sanitizePrice = (value: string, checkNaN: boolean = true): string => {
  if (!value) {
    return "0";
  }

  let sanitizedPrice = value.replace(",", ".");
  if (checkNaN) {
    sanitizedPrice = sanitizedPrice.toUpperCase();
  }
  sanitizedPrice = sanitizedPrice.replace(":", " ");
  sanitizedPrice = sanitizedPrice.replace("-", " ");
  sanitizedPrice = sanitizedPrice.replace("/", " ");
  sanitizedPrice = sanitizedPrice.replace("\\", " ");
  sanitizedPrice = sanitizedPrice.replace("R$", " ");
  sanitizedPrice = sanitizedPrice.replace("$", " ");
  sanitizedPrice = sanitizedPrice.replace("€", " ");
  sanitizedPrice = sanitizedPrice.replace("£", " ");
  sanitizedPrice = sanitizedPrice.trim();

  if (checkNaN && isNaN(Number(sanitizedPrice))) {
    sanitizedPrice = "0";
  }

  return sanitizedPrice;
};

export const getUnitFromPrice = (value: string): string => {
  if (value.includes("R$")) {
    return "R$";
  } else if (value.includes("$")) {
    return "$";
  } else if (value.includes("€")) {
    return "€";
  } else {
    return "";
  }
};
