import { useCallback, useState } from 'react';

export function useModal<T = any>(defaultValue = false) {
	const [data, setData] = useState<T>();
	const [isOpen, setOpen] = useState(defaultValue);

	const toggle = useCallback(() => {
		setOpen(!isOpen);
	}, [isOpen]);

	const open = useCallback((dataToDelete?: T) => {
		setData(dataToDelete);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	return {
		close,
		data,
		isOpen,
		open,
		toggle,
	};
}
