import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { ContainerFilters, ContentFilters } from '../../../../../common-styles';
import { useListCompaniesForCustomers, useLoggedUser } from '../../../../../hooks';
import { Role } from '../../../../../models/Enum';
import { DEFAULT_SELECT_COMPANY } from '../../../../../utils/Constants';
import { Coupon } from '../../../../../models/Coupon';

interface FeedbackFiltersProps {
  coupons?: Coupon[];
  companyId: string;
  onCompanySelect: (companyId: string) => void;
}

export const FeedbackFilters = ({
  companyId,
  onCompanySelect,
}: FeedbackFiltersProps) => {
  const { data: companies } = useListCompaniesForCustomers();
  const { userLogged } = useLoggedUser();
  const isAdminOrManager = userLogged.user.role === Role.ADMIN || userLogged.user.role === Role.MANAGER;

  const companiesName = useMemo(() => {
    const names = companies?.map((company) => {
      return {
        value: company._id,
        label: company.fantasyName,
      };
    });
    names?.sort((a, b) => a.label.localeCompare(b.label)).unshift({
      value: '',
      label: DEFAULT_SELECT_COMPANY,
    });
    return names;
  }, [companies]);

  return (
    <ContainerFilters>
      <Form
        layout="vertical"
        style={{ marginTop: '16px' }}
      >
        <ContentFilters>
          {isAdminOrManager && (
            <Form.Item
              label={<label className="label-input-field">Empresas</label>}
            >
              <Select
                defaultValue={companyId}
                onChange={onCompanySelect}
                suffixIcon={<CaretDownOutlined />}
              >
                {companiesName?.map((company) => (
                  <Select.Option
                    key={company.value}
                    value={company.value}
                  >
                    {company.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </ContentFilters>
      </Form>
    </ContainerFilters>
  );
}