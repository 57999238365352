import { useCallback } from "react"
import { useNavigate } from "react-router-dom";
import { MenuOption } from "../../../models/MenuOption";
import { isEmpty } from "lodash";

const pathMap: Record<MenuOption, string> = {
  [MenuOption.MY_LINK]: '',
  [MenuOption.ACTIVITY_LOG]: 'activity-log',
  [MenuOption.BANNER_INFO]: 'banner',
  [MenuOption.CATEGORIES]: 'categories',
  [MenuOption.COMPANY]: 'companies',
  [MenuOption.CONTACT]: 'contacts',
  [MenuOption.COUPON]: 'coupons',
  [MenuOption.FEEDBACK]: 'feedbacks',
  [MenuOption.HISTORY_ORDERS]: 'history/orders',
  [MenuOption.PRODUCTS]: 'products',
  [MenuOption.QR_LINKS]: 'qrlinks',
  [MenuOption.QRCODE]: 'qrcode/generator',
  [MenuOption.SETTINGS_DEFAULT_TEXT]: 'settings/default-texts',
  [MenuOption.SETTINGS_FEEDBACK]: 'settings/feedback',
  [MenuOption.SETTINGS_GENERAL]: 'settings/general',
  [MenuOption.SETTINGS_ORDER]: 'settings/orders',
  [MenuOption.SETTINGS_PROFILE]: 'settings/profile',
  [MenuOption.SETTINGS_STORE]: 'settings/store',
  [MenuOption.STORES]: "companies",
  [MenuOption.USER]: 'users',
  [MenuOption.WHATSAPP_ORDERS]: 'orders',
};

export const useSidebarMenuKeySelection = () => {
  const navigate = useNavigate();
  const currentUrlPath = window.location.href.split('sysadmin/')[1];

  const handleMenuSelection = useCallback((key: MenuOption) => {
    const routePath = pathMap[key] || '';
    const path = `/sysadmin/${routePath}`;

    navigate(path);
  }, [navigate]);

  const getMenuOption = (value: string): MenuOption | undefined => {
    if (isEmpty(value)) {
      return undefined;
    }
    return (Object.keys(pathMap) as Array<keyof typeof pathMap>).find(key => pathMap[key] === value);
  }

  return {
    currentSelectedMenu: getMenuOption(currentUrlPath),
    handleMenuSelection,
  };
}
