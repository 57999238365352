import React, { useCallback, useMemo, useState } from 'react';
import { ContentHeader } from '../../../components/ContentHeader';

import AddOrUpdateProduct from './ProductPage/AddOrUpdateProduct';
import { ProductsTable } from './Table';

import { Category } from '../../../models/DataResponse';
import { Product } from '../../../models/Product';
import { MenuOption } from '../../../models/MenuOption';

import { removeAccents } from '../../../utils/Util';
import { DEFAULT_SELECT_COMPANY, PREFIX_LOCALSTORAGE } from '../../../utils/Constants';
import { useCategories, useFeatures, useListProductsOutOfStock, useLoggedUser, useProducts, useStockAlert } from '../../../hooks';
import { ProductFilters } from './components/Filters';
import { Role } from '../../../models/Enum';


export const ProductsPage = () => {
  const [prefetchCategoryId, setPrefetchCategoryId] = useState('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [isShowingDetails, setShowingDetails] = useState<boolean>(false);
  const [filteredProducts, setFilteredProducts] = useState<Product[] | null>(null);
  const [selectedItem, setSelectedItem] = useState<Product | any>({} as Product);
  const [isSearching, setSearch] = useState<boolean>(false);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>();

  const [selectedCompanyId, setSelectedCompanyId] = useState(DEFAULT_SELECT_COMPANY);
  const { userLogged: { user: { company, companyAssociation, role } } } = useLoggedUser();

  const companyId = useMemo(() => {
    if (role === Role.USER && company) {
      return company;
    } else if (role === Role.KITCHEN && companyAssociation.length === 1) {
      return companyAssociation[0].companyId;
    }

    return selectedCompanyId;
  }, [company, companyAssociation, role, selectedCompanyId]);

  const { data: features } = useFeatures(companyId);
  const { refetch: refetchStock } = useListProductsOutOfStock(companyId)
  const { products, isFetching: isFetchingProducts } = useProducts(companyId);
  const { data: categories, isFetching: isFetchingCategories } = useCategories(companyId);
  const isLoading = isFetchingProducts || isFetchingCategories;
  
  useStockAlert();

  const filteredProductsByInputSearch = useMemo(() => {
    setSearch(searchInput.length > 0);
    const input = removeAccents(searchInput.toLowerCase());
    const list = filteredProducts ?? products ?? [];

    return list.filter((product) => {
      return (
        removeAccents(product.description ?? '').includes(input) ||
        removeAccents(product.name ?? '').includes(input) ||
        removeAccents(product.price ?? '').includes(input) ||
        removeAccents(product.categoryName ?? '').includes(input)
      );
    });
  }, [filteredProducts, searchInput, products]);

  const listOfProducts = filteredProductsByInputSearch ?? products;

  function handleRowClick(selectedItem: Product) {
    sessionStorage.setItem(
      `${PREFIX_LOCALSTORAGE}productScrollPosition`,
      window.pageYOffset.toString()
    );
    setSelectedItem(selectedItem);
    setShowingDetails(true);
  }

  function handleAddAction(categoryId: string) {
    setPrefetchCategoryId(categoryId);
    setSelectedItem(null);
    setShowingDetails(true);
  }

  const pageProductDismissed = () => {
    if (role === Role.USER) {
      refetchStock();
    }

    setShowingDetails(false);
    setTimeout(() => {
      handleScrollPosition();
    }, 500);
  }

  const cloneCatalogItem = (selectedItem: Product) => {
    handleRowClick(selectedItem);
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem(
      `${PREFIX_LOCALSTORAGE}productScrollPosition`
    );
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem(`${PREFIX_LOCALSTORAGE}productScrollPosition`);
    }
  };

  const onSearchFiltersChanged = useCallback((searchFilter: string) => {
    setSearchInput(searchFilter);
  }, []);

  const onProductListChanged = useCallback((filteredProducts: Product[] | null) => {
    setFilteredProducts(filteredProducts);
  }, []);

  const onCategoryListChanged = useCallback((categories: Category[]) => {
    setFilteredCategories(categories);
  }, []);

  return (
    <>
      {isShowingDetails ? (
        <AddOrUpdateProduct
          pageProductDismissed={pageProductDismissed}
          selectedProduct={selectedItem}
          company={companyId}
          features={features}
          categories={categories ?? []}
          preFetchCategoryId={prefetchCategoryId}
        />
      ) : (
        <>
          <ContentHeader title={MenuOption.PRODUCTS} />

          <ProductFilters
            categories={categories ?? []}
            companyId={companyId}
            features={features}
            onCategoryListChanged={onCategoryListChanged}
            onCompanySelect={(companyId) => setSelectedCompanyId(companyId)}
            onProductListChanged={onProductListChanged}
            products={listOfProducts}
            setSearchInputFilter={onSearchFiltersChanged}
          />

          <ProductsTable
            isLoading={isLoading}
            isSearching={isSearching}
            products={listOfProducts}
            categories={filteredCategories ?? categories ?? []}
            company={companyId}
            features={features}
            handleRowClick={handleRowClick}
            handleAddAction={handleAddAction}
            handleCloneClick={cloneCatalogItem}
            pageProductDismissed={pageProductDismissed}
          />
        </>
      )}
    </>
  );
};
