import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { baseURL } from "../services/endpoints";
import { LocalStorageService, PAYMENT_EASYPAY } from "../services/LocalStorageService";
import { EasypayPaymentResponse } from "../pages/clients/MenuOnline/components/OrdersViaWhatsapp/models/EasypayPayment";

export const usePaymentProcess = (onPaymentProcessed: (isSuccess: boolean, paymentPayload: EasypayPaymentResponse | null) => void) => {
  const [isWaitingPayment, setIsWaitingPayment] = useState(false);

  useEffect(() => {
    if (!isWaitingPayment) return;

    const socket = io(baseURL);

    const handlePaymentUpdate = (data: any) => {
      const paymentPayload = LocalStorageService.get<EasypayPaymentResponse>(PAYMENT_EASYPAY);

      if (data.id === paymentPayload?.id) {
        onPaymentProcessed(data.status === 'success', paymentPayload);
        setIsWaitingPayment(false);
      }
    };

    socket.on('easypayPaymentUpdate', handlePaymentUpdate);

    return () => {
      socket.off('easypayPaymentUpdate', handlePaymentUpdate);
      socket.disconnect();
    };
  }, [isWaitingPayment, onPaymentProcessed]);

  return {
    isWaitingPayment,
    setIsWaitingPayment,
  }
}