import React, { FormEvent, useMemo, useState } from 'react';
import { Button } from 'antd';
import { PrinterFilled } from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert';

import { ContentHeader } from '../../../components/ContentHeader';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../components/Notification';
import SelectInput from '../../../components/SelectInput';

import { useCompanyFeatures, useLoggedUser } from '../../../hooks';
import { Role } from '../../../models/Enum';
import { WhatsappOrder } from '../../../models/DataResponse';
import formatCurrency from '../../../utils/formatCurrency';
import { formatDatetimeByTimezone } from '../../../utils/dateTime';
import { dispatchOrderPrintRequest } from '../../../utils/OrderUtil';
import api from '../../../services/api';

import {
  PageDetailsButtonsContainer, RoundTag,
} from '../../../common-styles';

import {
  StatusContainer,
  UpdateButton,
} from './styles';
import { SummaryList } from '../../../components/SummaryList';
import { ProductOrderTable } from '../../../components/ProductOrderTable';
import { PREFIX_LOCALSTORAGE } from '../../../utils/Constants';
import { getOrderStatusData, OrderStatus } from '../../../models/OrderStatus';
import { getDeliveryTypeLabel } from '../../../utils/DeliveryUtil';
import { ExtractOrder } from './components/ExtractOrder';

interface OrderDetailsProps {
  modalDismissed(withAction: boolean): void;
  order: WhatsappOrder;
};

const allowedStatus = [OrderStatus.IN_PROGRESS, OrderStatus.READY, OrderStatus.CONCLUDED, OrderStatus.CANCELLED];

export const OrderDetails = ({
  modalDismissed,
  order,
}: OrderDetailsProps) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(order.status);
  const [statusChanged, setStatusChanged] = useState(false);
  const { companyFeatures } = useCompanyFeatures(order.companyId);
  const { userLogged: { user: { role } } } = useLoggedUser();
  const clientInfo = order.client;

  const currency = localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52

  const productsCounter = useMemo(() => {
    let amount = 0;
    order.products.forEach((product) => (amount += product.counter));
    if (isNaN(amount)) {
      amount = order.products.length;
    }
    return amount;
  }, [order.products]);

  const isCurrencyInEuro = useMemo(() => {
    return currency === '€'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
  }, [currency]);

  const status = useMemo(() => {
    return allowedStatus.map((status) => {
      return {
        value: status,
        label: getOrderStatusData(status).label,
      };
    });
  }, []);

  const dismissPage = () => modalDismissed(statusChanged);

  const printOrder = () => dispatchOrderPrintRequest(order._id, order.companyId, currency);

  const deleteOrder = () => {
    confirmAlert({
      title: 'Atenção',
      message: `Tens a certeza que deseja deletar este pedido ${order.orderId}?`,
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            try {
              await api.delete(`api/orders/${order._id}`);
              NotificationDispatcher({
                message: 'Pedido removido com sucesso.',
              });
              dismissPage();
            } catch (e) {
              NotificationDispatcher({
                message:
                  'Erro ao tentar efetuar operação, não foi possível deletar. Tente novamente.',
                type: NotificationType.ERROR,
              });
            }
          },
        },
        {
          label: 'Não',
          onClick: () => { },
        },
      ],
    });
  }

  const handleStatusChange = (status: string) => setSelectedStatus(status);

  const getAdditionalInformation = () => {
    if (!order.additionalInformation) return;

    return JSON.parse(order.additionalInformation)
  };

  async function handleUpdateStatus(event: FormEvent) {
    event.preventDefault();

    const body = {
      ...order,
      status: selectedStatus,
    };

    await api
      .put(`/api/orders/${order._id}`, body)
      .then((response) => {
        if (response.status === 200) {
          setStatusChanged(true);
          NotificationDispatcher({
            message: 'Operação realizada com sucesso.',
          });
        } else {
          NotificationDispatcher({
            message: 'Falha ao tentar atualizar o status do Pedido.',
            type: NotificationType.INFO,
          });
        }
      })
      .catch((error) => console.log(error));
  }

  const getFiscalAddressFormatted = () => {
    const fiscalAddress = clientInfo.taxData?.fiscalAddress;
    let address = fiscalAddress?.address;
    if (fiscalAddress?.number) {
      address += `, ${fiscalAddress?.number}`;
    }
    if (fiscalAddress?.postalCode) {
      address += `, ${fiscalAddress?.postalCode}`;
    }

    return address;
  }

  const getScheduledDateTime = () => {
    const { scheduledDate, scheduledTime } = clientInfo;

    if (scheduledDate && scheduledTime) {
      return `${scheduledDate} : ${scheduledTime}`;
    }

    return `${scheduledDate || scheduledTime}`;
  };

  return (
    <>
      <ContentHeader title="Detalhes do Pedido">
        <>
          <Button
            type="dashed"
            onClick={printOrder}
            style={{ marginRight: '10px' }}
          >
            <PrinterFilled /> Imprimir
          </Button>

          <Button danger onClick={dismissPage} style={{ marginRight: '10px' }}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Fechar
          </Button>
        </>
      </ContentHeader>

      <SummaryList title="Pedido">
        <SummaryList.Item>
          <SummaryList.Label>Status</SummaryList.Label>
          <SummaryList.Value>
            <StatusContainer>
              {(role === Role.DRIVER || role === Role.KITCHEN) ? (
                <>
                  <RoundTag color={getOrderStatusData(order.status).color}>
                    {getOrderStatusData(order.status).label}
                  </RoundTag>
                </>
              ) : (
                <>
                  <SelectInput
                    options={status}
                    bgColor={'#f0f0f5'}
                    onChange={(e) => handleStatusChange(e.target.value)}
                    defaultValue={selectedStatus}
                  />
                  <UpdateButton onClick={handleUpdateStatus}>Atualizar</UpdateButton>
                </>
              )}

            </StatusContainer>
          </SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={role !== Role.DRIVER}>
          <SummaryList.Label>Ordem</SummaryList.Label>
          <SummaryList.Value>{order.sequency}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Código</SummaryList.Label>
          <SummaryList.Value>#{order.orderId}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Data e Hora</SummaryList.Label>
          <SummaryList.Value>{formatDatetimeByTimezone(
            order.createdAt,
            companyFeatures?.timezone ?? 'UTC'
          )}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.scheduledDate || clientInfo.scheduledTime)}>
          <SummaryList.Label>Pré-agendamento (Data/Hora)</SummaryList.Label>
          <SummaryList.Value>{getScheduledDateTime()}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(getAdditionalInformation())}>
          <SummaryList.Label>{getAdditionalInformation()?.title}</SummaryList.Label>
          <SummaryList.Value>{getAdditionalInformation()?.option.title}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.deliveryType)}>
          <SummaryList.Label>Tipo de Entrega</SummaryList.Label>
          <SummaryList.Value>{getDeliveryTypeLabel(clientInfo.deliveryType)}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(order.driver)}>
          <SummaryList.Label>Entregador</SummaryList.Label>
          <SummaryList.Value>{order.driver?.name}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={role !== Role.KITCHEN}>
          <SummaryList.Label>Forma de pagamento</SummaryList.Label>
          <SummaryList.Value>{clientInfo.paymentType}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.moneyChange) && role !== Role.KITCHEN}>
          <SummaryList.Label>Troco</SummaryList.Label>
          <SummaryList.Value>{formatCurrency(
            Number(clientInfo.moneyChange),
            currency
          )}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.observation)}>
          <SummaryList.Label>Observação</SummaryList.Label>
          <SummaryList.Value>{clientInfo.observation}</SummaryList.Value>
        </SummaryList.Item>

        {role !== Role.KITCHEN && (
          <ExtractOrder
            companyFeatures={companyFeatures}
            currency={currency}
            order={order} />
        )}
      </SummaryList>

      <SummaryList title={`Produtos (${productsCounter})`}>
        <SummaryList.Item>
          <ProductOrderTable
            currency={currency}
            displayPrice={role !== Role.KITCHEN}
            products={order.products}
            showComplementTitle={companyFeatures?.sellsConfiguration?.orderComplementExhibition?.showTitle ?? false}
          />
        </SummaryList.Item>
      </SummaryList>

      <SummaryList title="Cliente">
        <SummaryList.Item>
          <SummaryList.Label>Nome</SummaryList.Label>
          <SummaryList.Value>{clientInfo.name}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Telefone</SummaryList.Label>
          <SummaryList.Value>{clientInfo.phone}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.email)}>
          <SummaryList.Label>Email</SummaryList.Label>
          <SummaryList.Value>{clientInfo.email}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.instagram)}>
          <SummaryList.Label>Instagram</SummaryList.Label>
          <SummaryList.Value>{clientInfo.instagram}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.taxData?.identification)}>
          <SummaryList.Label>{isCurrencyInEuro ? 'NIF' : 'CPF'}</SummaryList.Label>
          <SummaryList.Value>{clientInfo.taxData?.identification}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.taxData?.fiscalAddress?.address)}>
          <SummaryList.Label>Morada fiscal</SummaryList.Label>
          <SummaryList.Value>{getFiscalAddressFormatted()}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>

      <SummaryList title="Endereço" visible={Boolean(clientInfo.address) && role !== Role.KITCHEN}>
        <SummaryList.Item>
          <SummaryList.Label>{isCurrencyInEuro ? 'Morada' : 'Endereço'}</SummaryList.Label>
          <SummaryList.Value>{clientInfo.address}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.number)}>
          <SummaryList.Label>Número</SummaryList.Label>
          <SummaryList.Value>{clientInfo.number}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.complement)}>
          <SummaryList.Label>Complemento</SummaryList.Label>
          <SummaryList.Value>{clientInfo.complement}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.referencePoint)}>
          <SummaryList.Label>Ponto de referência</SummaryList.Label>
          <SummaryList.Value>{clientInfo.referencePoint}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>

      <SummaryList title="Geral" visible={role === Role.ADMIN}>
        <SummaryList.Item>
          <SummaryList.Label>Browser</SummaryList.Label>
          <SummaryList.Value>{order.browser}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Sistema Operacional</SummaryList.Label>
          <SummaryList.Value>{order.operatingSystem}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>

      <div className="form-general" style={{ marginTop: '16px' }}>
        <PageDetailsButtonsContainer>
          {role === Role.ADMIN && (
            <Button
              danger
              onClick={deleteOrder}
              style={{ background: 'red', marginRight: '10px' }}
              type="primary"
            >
              Remover
            </Button>
          )}
        </PageDetailsButtonsContainer>
      </div>
    </>
  );
};
