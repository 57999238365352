import React from "react";
import { WhatsappOrder } from "../../../../../../../../models/DataResponse";
import { OrderStatus } from "../../../../../../../../models/OrderStatus";
import { ReadyActionButton } from "../ReadyActionButton";
import { ExecuteActionButton } from "../ExecuteActionButton";
import { FinishActionButton } from "../FinishActionButton";

interface ActionButtonsProps {
  order: WhatsappOrder;
  toggleAudio(): void;
}

export const ActionButtons = ({ order, toggleAudio }: ActionButtonsProps) => {
  switch (order.status) {
    case OrderStatus.PENDING:
      return (
        <ExecuteActionButton order={order} toggleAudio={toggleAudio} />
      );
    case OrderStatus.IN_PROGRESS:
      return (
        <ReadyActionButton order={order} />
      );
    case OrderStatus.READY:
      return (
        <FinishActionButton order={order} />
      );

    default: return null;
  }
};