import api from "./api";
import { UserLogged } from './../models/User';
import { PREFIX_LOCALSTORAGE } from "../utils/Constants";
import { getBrowserType, getOperatingSystem } from "../utils/Util";

interface CompanyActivityLogServiceProps {
  action: string;
  actionArea: string;
  extra?: string;
}

export class CompanyActivityLogService {
  public static async register({ action, actionArea, extra }: CompanyActivityLogServiceProps): Promise<void> {
    const userStr = localStorage.getItem(`${PREFIX_LOCALSTORAGE}user`) || '';
    const userLogged = JSON.parse(userStr) as UserLogged;
    const { user } = userLogged;

    const body = {
      action,
      actionArea,
      userId: user._id,
      userName: user.name,
      email: user.email,
      companyId: user.company,
      extra,
      browser: getBrowserType(),
      operatingSystem: getOperatingSystem(),
    };

    await api.post('api/activity-log/', body);
  }
}