import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Space } from 'antd';
import { LinkPage } from '../../../../../models/QRLink';
import { isValidHttpUrl } from '../../../../../utils/Util';
import { FormInputField } from '../../../../../components/FormInputField';
import UploadForm from '../../../../../components/upload/UploadForm';
import { FormRadioGroup } from '../../../../../components/FormRadioGroup';

interface LinkItemProps {
  handleItem(isUpdate: boolean, values: LinkPage): void;
  isVisible: boolean;
  linkItem?: LinkPage;
  onClose(): void;
};

export const LinkItem = ({
  linkItem,
  isVisible,
  handleItem,
  onClose,
}: LinkItemProps) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isEnabled, setEnabled] = useState<boolean>(linkItem?.isEnabled ?? true);

  useEffect(() => {
    // This hook is only need because of the Drawer, when we migrate this to be a page, this will be not needed anymore.
    const image = linkItem ? linkItem?.imageUrl : '';
    setImageUrl(image);
  }, [linkItem]);

  const onFinish = (values: any) => {
    const isUpdate = (linkItem && linkItem !== undefined) || false;

    if (isUpdate) {
      values._id = linkItem!._id;
    }

    const linkPage = {
      ...values,
      imageUrl
    };

    handleItem(isUpdate, linkPage);
    onClose();
  };

  return (
    <Drawer
      title={linkItem === undefined ? 'Novo link' : 'Atualizar link'}
      onClose={onClose}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isVisible}
    >
      <Form layout="vertical" initialValues={linkItem} onFinish={onFinish}>
        <FormInputField
          label="Título"
          name="name"
          rules={[
            {
              required: true,
              message: 'Campo obrigatório',
            },
          ]}
          placeholder="Título"
        />

        <FormRadioGroup
          label="Disponibilidade"
          propertyId="isEnabled"
          defaultValue={isEnabled}
          handleOnChange={(checked) => setEnabled(checked)}
        />

        <FormInputField
          label="URL de acesso"
          name="url"
          rules={[
            () => ({
              validator(_: any, value = '') {
                if (value.length === 0) {
                  return Promise.reject('Campo obrigatório');
                } else if (!isValidHttpUrl(value)) {
                  return Promise.reject('URL inválida');
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          placeholder="https://www.minhaurl.com"
        />

        <Form.Item
          label={<label className="label-input-field">Imagem</label>}
          name="imageUrl"
        >
          <UploadForm
            setUrl={(url: string) => setImageUrl(url)}
            url={linkItem?.imageUrl ?? imageUrl}
            setUploadInProgress={setIsUploading}
          />
        </Form.Item>

        <Space style={{ marginTop: '16px' }}>
          <Button danger style={{ marginRight: '10px' }} onClick={onClose}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Cancelar
          </Button>

          <Button type="primary" htmlType="submit" disabled={isUploading}>
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />{' '}
            Salvar
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};
