import React from "react";
import { WhatsappOrder } from "../../../../../../models/DataResponse";
import { RoundTag } from "../../../../../../common-styles";
import { DriverStatusInOrder } from "../../../../../../models/OrderStatus";

interface DriverInfoProps {
  order: WhatsappOrder;
}

export const DriverInfo = ({ order }: DriverInfoProps) => {
  const { driverStatus, driver } = order;
  
  switch (driverStatus) {
    case DriverStatusInOrder.PICKED_UP_PRODUCT:
    case DriverStatusInOrder.DRIVER_ACCEPTED:
      return (
        <RoundTag color={'orange'}>
          Entregador: {driver.name}
        </RoundTag>
      );

    case DriverStatusInOrder.WAITING_DRIVER_ACCEPT:
      return (
        <RoundTag color={'orange'}>
          Aguardando entregador aceitar
        </RoundTag>
      );

    case DriverStatusInOrder.DRIVER_DENIED:
      return (
        <RoundTag color='red'>
          Escolha outro entregador
        </RoundTag>
      );

    default: return null;
  }
};