import { Coupon } from '../models/Coupon';
import { DEFAULT_SELECT_COMPANY } from '../utils/Constants';
import { useFetch } from './useFetch';

export const COUPONS = 'coupons';

export const useCoupons = (companyId: string) => {
  const { data, ...rest } = useFetch<Coupon[]>({
    enabled: companyId !== DEFAULT_SELECT_COMPANY,
    queryKey: [COUPONS, companyId],
    url: `api/coupon/company/${companyId}`,
    staleTime: 1000,
  });

  return { data, ...rest };
};
