import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;

  display: flex;
  align-items: baseline;
  margin-left: 10px;
  
  > p {
    font-size: 14px;
    font-weight: bold;
    color: black;
    margin-left: 2px;
  }
`;
