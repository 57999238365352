import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ModalHeadLess } from '../ModalHeadless';
import { useResponsive } from '../../hooks';
import successIcon from './success.gif';
import { Button } from 'antd';
import { Container, Description, Title } from './styles';

interface SuccessModalProps {
  message?: string;
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

export const SuccessModal = ({ message, isOpen, onClose, title }: SuccessModalProps) => {
  const { isMobile } = useResponsive();

  return (
    <ModalHeadLess
      bgModal="secondary"
      isOpen={isOpen}
    >
      <Container isMobile={isMobile}>
        <LazyLoadImage
          alt={'Success modal'}
          className='image-success'
          effect="blur"
          src={successIcon}
        />

        <Title>{title}</Title>
        {message && <Description>{message}</Description>}

        <Button
          className='button-success'
          onClick={onClose}
          type='primary'
        >
          Ok
        </Button>
      </Container>
    </ModalHeadLess>
  );
};
