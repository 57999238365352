import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useOrderStatus } from '../../../hooks';

import { formatTime } from '../../../utils/dateTime';
import { getOperatingSystem } from '../../../utils/Util';
import { baseURL } from '../../../services/endpoints';
import { convertStatusToStep, OrderStatus } from '../../../models/OrderStatus';
import { LoaderSpinner } from '../../../components/LoaderSpinner';
import { PageNotFound } from '../../../components/PageNotFound';
import { Container, Heading, HeadingDescription, LogoContent, LogoContainer, Main, TalkToStore } from './styles';
import { HeaderMetadata } from '../../../components/HeaderMetadata';
import { Footer } from '../../../components/Footer';

const { Step } = Steps;

const DEFAULT_LOGO = 'https://res.cloudinary.com/qrcode-preferido/image/upload/v1615913048/logo_h0tsnx.png';

export const OrderStatusPage = () => {
  const [currentStatus, setCurrentStatus] = useState<number>(0);
  const [lastUpdate, setLastUpdate] = useState<string>(formatTime(new Date(Date.now()).toString()));
  const { slug, order } = useParams();
  const { data, isFetching, isError, refetch } = useOrderStatus(slug, order);

  useEffect(() => {
    if (!data) return;

    const { status, isDelivery } = data.order;
    const current = convertStatusToStep(status, isDelivery);
    setCurrentStatus(current);
  }, [data]);

  useEffect(() => {
    const socket = io(baseURL);

    socket.on('orderUpdated', (received) => {
      const { companyId, isDelivery, status } = received;

      if (companyId === data?.company.id) {
        const current = convertStatusToStep(status, isDelivery);
        setCurrentStatus(current);
        setLastUpdate(formatTime(new Date(Date.now()).toString()));

        if (status === OrderStatus.CANCELLED) {
          refetch();
        }

        toast.success('Status atualizado');
      }
    });

    return () => {
      socket.connect();
    };
  }, [data, refetch]);

  const redirectToStoreWhatsapp = () => {
    const isDesktop = getOperatingSystem() === 'Desktop';
    const baseUrl = isDesktop ? 'https://api.whatsapp.com/' : 'whatsapp://';
    const whatsappUrl = `${baseUrl}send?phone=${data?.company.whatsappNumber}`;

    const a = document.createElement('a');
    if (isDesktop) {
      a.target = '_blank';
    }
    a.href = whatsappUrl;
    a.click();
  }

  if (isFetching) {
    return <LoaderSpinner />
  }

  if (isError) {
    return <PageNotFound message={'Nenhum pedido encontrado :('} />
  }

  return (
    <>
      <HeaderMetadata title={data?.company.name} />

      <Container>
        <Main>
          <LogoContainer>
            <LogoContent
              src={data?.company.logo ?? DEFAULT_LOGO}
              alt="Logo do estabelecimento"
            />
          </LogoContainer>

          <Heading>Pedido #{order}</Heading>
          <HeadingDescription>Última atualização: {lastUpdate}</HeadingDescription>

          <div style={{ margin: 'auto', paddingTop: '30px' }}>
            <Steps direction="vertical" size="default" current={currentStatus}>
              <Step title="Pedido efetuado" description="Pedido enviado para o lojista." />
              {data?.order.status === OrderStatus.CANCELLED &&
                <Step title="Cancelado" description="Pedido cancelado." status="error" />
              }
              <Step title="Em execução" description="Iniciou preparo." />
              <Step title="Pronto" description="Pedido pronto." />
              {data?.order.isDelivery && <Step title="Em trânsito" description="O entregador está a caminho." />}

              {data?.order.status === OrderStatus.NOT_DELIVERED ?
                (<Step title="Não entregue" description="Pedido não entregue." status="error" />) :
                (<Step title="Entregue" description="Finalizado com sucesso." />)
              }
            </Steps>
          </div>

          <TalkToStore onClick={redirectToStoreWhatsapp}>
            Falar com lojista <i className="fa fa-whatsapp icon whatsapp"></i>
          </TalkToStore>
        </Main>

        <Footer />
      </Container>
    </>
  );
};
