import React from 'react';
import { Button, Form, Input, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface ForgotPasswordProps {
  isFetching: boolean;
  isRequired: boolean;
}

export const ForgotPassword = ({ isFetching, isRequired }: ForgotPasswordProps) => {
  return (
    <>
      <Typography.Title level={5}>
        Não se preocupe. Redefinir a senha é fácil, apenas informe o e-mail de
        cadastro e o código do estabelecimento nos campos abaixo:
      </Typography.Title>

      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: isRequired, message: 'Campo email obrigatório' }]}
        style={{ marginTop: '25px' }}
      >
        <Input prefix={<UserOutlined />} placeholder="Digite o seu usuário" style={{ height: '40px' }} />
      </Form.Item>

      <Form.Item
        name="accessCode"
        label="Código"
        rules={[{ required: isRequired, message: 'Campo obrigatório' }]}
      >
        <Input placeholder="Insira o código do estabelecimento" style={{ height: '40px' }} />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: '100%' }}
        loading={isFetching}
      >
        Redefinir senha
      </Button>
    </>
  );
};
