import React, { useMemo } from 'react';
import { formatDate } from '../../../../../utils/dateTime';
import { removeAccents } from '../../../../../utils/Util';
import { CompanyTable } from '../Table';
import { useCompanies } from '../../hooks/useCompanies';

interface ActiveTabProps {
  handleRowClick: any;
  onCompaniesCounterLoaded(count: number): void;
  searchInput: string;
}

export const ActiveTab = ({ handleRowClick, searchInput, onCompaniesCounterLoaded }: ActiveTabProps) => {

  const { data: companies, isLoading } = useCompanies();

  const filteredCompanies = useMemo(() => {
    if (!companies) return;
    
    const input = removeAccents(searchInput);
    let result = companies;

    if (input) {
      result = companies?.filter((company) => {
        return (
          removeAccents(company._id ?? '').includes(input) ||
          removeAccents(company.fantasyName ?? '').includes(input) ||
          removeAccents(company.companyName ?? '').includes(input) ||
          removeAccents(company.cpfOrCnpj ?? '').includes(input) ||
          removeAccents(formatDate(company.createdAt)).includes(input) ||
          removeAccents(company.phoneNumber ?? '').includes(input) ||
          removeAccents(company.responsible ?? '').includes(input)
        );
      });
    }

    onCompaniesCounterLoaded(result?.length ?? 0);

    return result;
  }, [searchInput, companies, onCompaniesCounterLoaded]);

  return (
    <CompanyTable
      companies={filteredCompanies ?? companies ?? []}
      loading={isLoading}
      handleRowClick={handleRowClick} />
  );
};