import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ModalHeadLess } from '../ModalHeadless';
import paymentLoading from '../../assets/images/payment_loading.gif';
import { useResponsive } from '../../hooks';

interface PaymentLoadingModalProps {
  isOpen: boolean;
  children: React.ReactNode;
};

export const PaymentLoadingModal = ({ isOpen, children }: PaymentLoadingModalProps) => {
  const { isMobile } = useResponsive();

  return (
    <ModalHeadLess
      bgModal="secondary"
      isOpen={isOpen}
    >
      <LazyLoadImage
        alt={'Loading Payment'}
        effect="blur"
        src={paymentLoading}
        style={{ width: '300px', height: '300px', marginLeft: isMobile ? '0px' : '60px', marginTop: '-50px' }} />

      {children}
    </ModalHeadLess>
  );
};
