import api from '../services/api';

import { WhatsappOrder } from '../models/DataResponse';
import formatCurrency from './formatCurrency';
import { SummarySectionOrder } from './Constants';
import { formatTime } from './dateTime';

import {
  NotificationDispatcher,
  NotificationType,
} from '../components/Notification';

export const getTotalFromOrder = (currency: string, order: WhatsappOrder) => {
  const { total, subtotal, coupon } = order;

  let couponFormatted = undefined;

  if (coupon) {
    const isPercentage = Number(coupon.discountType) === 0;
    couponFormatted = formatCurrency(coupon.discountValue, currency);

    if (isPercentage) {
      const discount = subtotal - (subtotal * coupon.discountValue) / 100;
      couponFormatted = `${coupon.discountValue}% (${formatCurrency(
        total - discount,
        currency
      )})`;
    }
  }

  return {
    couponFormatted,
    totalFormatted: formatCurrency(total ?? 0, currency),
    subtotalFormatted: formatCurrency(subtotal, currency),
    total,
    subtotal,
  };
};

export const dispatchOrderPrintRequest = async (
  orderId: string,
  companyId: string,
  currency: string
): Promise<void> => {
  await api
    .get(
      `/api/orders/print?order=${orderId}&company=${companyId}&currency=${currency}`,
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, 'PRINT', 'height=800,width=700');
    })
    .catch(() => {
      NotificationDispatcher({
        message: 'Falha ao tentar imprimir o Pedido',
        type: NotificationType.ERROR,
      });
    });
};

export const defaultSummaryMessageOrder = [
  {
    order: 1,
    name: SummarySectionOrder.COMPANY_NAME,
    value: 'Nome da empresa',
    status: true,
  },
  {
    order: 2,
    name: SummarySectionOrder.ORDER_ID,
    value: 'N. do pedido',
    status: true,
  },
  {
    order: 3,
    name: SummarySectionOrder.SEPARATOR,
    value: 'Separador',
    status: true,
  },
  {
    order: 4,
    name: SummarySectionOrder.PRODUCTS,
    value: 'Produtos',
    status: true,
  },
  {
    order: 5,
    name: SummarySectionOrder.TOTAL,
    value: 'Total/Subtotal',
    status: true,
  },
  {
    order: 6,
    name: SummarySectionOrder.SEPARATOR,
    value: 'Separador',
    status: true,
  },
  {
    order: 7,
    name: SummarySectionOrder.CLIENT_NAME,
    value: 'Nome do cliente',
    status: true,
  },
  {
    order: 8,
    name: SummarySectionOrder.USER_INFO,
    value: 'Informação do cliente',
    status: true,
  },
  {
    order: 9,
    name: SummarySectionOrder.PAYMENT_TYPE,
    value: 'Tipo de pagamento',
    status: true,
  },
  {
    order: 10,
    name: SummarySectionOrder.DELIVERY_TYPE,
    value: 'Delivery/Recolha',
    status: true,
  },
  {
    order: 11,
    name: SummarySectionOrder.SCHEDULED_DATE_TIME,
    value: 'Data/Hora do pré-agendamento',
    status: true,
  },
  {
    order: 12,
    name: SummarySectionOrder.OBSERVATION,
    value: 'Observação',
    status: true,
  },
  {
    order: 13,
    name: SummarySectionOrder.ADDITIONAL_INFORMATION,
    value: 'Informação adicional',
    status: true,
  },
  {
    order: 14,
    name: SummarySectionOrder.SEPARATOR,
    value: 'Separador',
    status: true,
  },
  {
    order: 15,
    name: SummarySectionOrder.AUTOMATIC_MESSAGE,
    value: 'Mensagem automática',
    status: true,
  },
];

const generateOrderStatusLink = (companySlug: string, orderId: string): string => {
  const [urlBrowser] = window.location.href.split('#')
  const baseUrl = urlBrowser.replace(companySlug, '');
  return `${baseUrl}order-status/${companySlug}/${orderId}`;
}

export const getComplementOrderMessage = (
  sendLinkToFollowOrder: boolean,
  message: string,
  companySlug: string = '',
  orderId: string = '',
) => {
  const today = formatTime(new Date(Date.now()).toString());

  if (sendLinkToFollowOrder) {
    const link = generateOrderStatusLink(companySlug, orderId);
    message += `\n\n*Acompanhe o seu pedido através deste link:* ${link}`;
  }

  message += `\n\n_Pedido gerado pelo QRcode Preferido às ${today}_`;

  return message;
}