import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: left;
`;

export const OldPrice = styled.span`
  text-decoration: line-through;
  color: #b4b4b4;
  font-weight: normal;
  font-size: 15px;
  margin-top: 4px;
`;

export const NewPrice = styled.span`
  color: #3f3e3e;
  font-size: 18px;
  font-weight: bold;
  margin-left: 8px;
  margin-top: 2px;
`;

export const TagOff = styled.span`
  font-size: 14px;
  margin-top: 4px;
  margin-left: 6px;
  font-weight: bold;
`;