import api from './api';
import { ProductCheckStock, ProductOutOfStockResponse } from '../models/ProductStock';

export class CheckProductStockService {
  public static async checkStock(
    slug: string,
    products: ProductCheckStock[],
  ): Promise<ProductOutOfStockResponse> {
    const body = {
      slug,
      products
    }

    const response = await api.post('/api/catalog/checkProductStock', body);
    return response.data as ProductOutOfStockResponse;
  }
}
