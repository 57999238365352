import React, { useMemo, useState } from "react";
import { Skeleton, Tabs } from "antd";

import { CompanySelection } from "../../../../components/CompanySelection";
import { ContentHeader } from "../../../../components/ContentHeader";
import { PanelResult, PanelResultStatus } from "../../../../components/PanelResult";
import { SettingsFeedback } from "./components/SettingsFeedback";
import { SettingsOrdersPage } from "../SettingsOrders";

import { DEFAULT_SELECT_COMPANY } from "../../../../utils/Constants";
import { useCompanyFeatures } from "../../../../hooks";
import { DefaultTexts } from "../SettingsDefaultTexts/components/DefaultTexts";
import { EasypaySetup } from "../components/Easypay";
import { SettingsStorePage } from "../SettingsStore";

enum Tab {
  SCHEDULE = 'Horário de funcionamento',
  ORDERS = 'Pedidos',
  FEEDBACK = 'Feedback',
  DEFAULT_TEXTS = 'Textos padrões',
  EASYPAY = 'Easypay',
}

export const SettingsManagerPage = () => {
  const [selectedTab, setSelectedTab] = useState(Tab.SCHEDULE);
  const [selectedCompanyId, setSelectedCompanyId] = useState(DEFAULT_SELECT_COMPANY);

  const companyId = useMemo(() => {
    return selectedCompanyId;
  }, [selectedCompanyId]);

  const { companyFeatures, isFetching: isFetchingCompanyFeatures } = useCompanyFeatures(companyId);
  
  if (companyId === DEFAULT_SELECT_COMPANY || !companyFeatures) {
    let title = "Selecione uma Empresa";
    let subtitle = "Para carregar as configurações.";

    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title={title}
        subtitle={subtitle}
        extra={
          <CompanySelection
            centered
            companyId={companyId}
            onCompanySelect={(company) => setSelectedCompanyId(company)}
          />
        }
      />
    );
  }

  const isLoading = isFetchingCompanyFeatures || !companyFeatures;

  return (
    <>
      <ContentHeader title='Configurações gerais' />

      <CompanySelection
        companyId={companyId}
        onCompanySelect={(company) => setSelectedCompanyId(company)}
      />

      {isLoading ? <Skeleton /> : (
        <Tabs
          size="large"
          activeKey={selectedTab.toString()}
          onChange={(key) => setSelectedTab(key as Tab)}
          tabPosition='left'
        >
          <Tabs.TabPane tab={Tab.SCHEDULE} key={Tab.SCHEDULE}>
            <SettingsStorePage companyIdOpt={companyId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={Tab.ORDERS} key={Tab.ORDERS}>
            <SettingsOrdersPage companyIdOpt={companyId} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={Tab.FEEDBACK} key={Tab.FEEDBACK}>
            <SettingsFeedback companyFeatures={companyFeatures} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={Tab.DEFAULT_TEXTS} key={Tab.DEFAULT_TEXTS}>
            <DefaultTexts companyFeatures={companyFeatures} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={Tab.EASYPAY} key={Tab.EASYPAY}>
            <EasypaySetup companyFeatures={companyFeatures} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </>
  );
}