import { useCallback, useEffect, useState } from "react";
import { useCompanyFeatures } from "./useCompanyFeatures";
import { useLoggedUser } from "./useLoggedUser";

export const useOrderAudioNotification = () => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { userLogged } = useLoggedUser();
  const { user: { company } } = userLogged;
  const { companyFeatures } = useCompanyFeatures(company);

  useEffect(() => {
    if (!companyFeatures) return;

    const media = require(`../assets/sounds/${companyFeatures.sellsConfiguration.ringing.song}`);
    const audioElement = new Audio(media);
    audioElement.loop = companyFeatures.sellsConfiguration.ringing.playUntilAccept ?? false;

    setAudio(audioElement);

    return () => {
      audioElement.pause();
      audioElement.currentTime = 0;
    };
  }, [companyFeatures]);

  const toggleAudio = useCallback(() => {
    if (isPlaying) {
      audio?.pause();
      setIsPlaying(false);
    } else {
      audio?.play();
      setIsPlaying(true);
    }
  }, [audio, isPlaying]);

  const resetAudio = useCallback(() => {
    if (!companyFeatures?.sellsConfiguration.ringing.playUntilAccept) {
      setIsPlaying(false);
    }
  }, [companyFeatures, setIsPlaying]);


  return { audio, isPlaying, resetAudio, toggleAudio };
};
