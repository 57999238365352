export const sendWhatsAppMessage = (message: string, number: string) => {
  const encodedMessage = encodeURIComponent(message);

  const whatsappUrl = `https://api.whatsapp.com/send?phone=${number}&text=${encodedMessage}`;

  const a = document.createElement('a');
  a.target = '_blank';
  a.href = whatsappUrl;
  a.click();
};
