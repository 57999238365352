import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ModalHeadLess } from '../ModalHeadless';
import { useResponsive } from '../../hooks';
import errorIcon from './error-icon.svg';
import { Button } from 'antd';
import { Container, Description, Title } from './styles';

interface ErrorModalProps {
  error: string | undefined;
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

export const ErrorModal = ({ error, isOpen, onClose, title }: ErrorModalProps) => {
  const { isMobile } = useResponsive();

  return (
    <ModalHeadLess
      bgModal="secondary"
      isOpen={isOpen}
    >
      <Container isMobile={isMobile}>
        <LazyLoadImage
          className='image-error'
          alt={'Error modal'}
          effect="blur"
          src={errorIcon}
        />

        <Title>{title}</Title>
        {error && <Description>{error}</Description>}

        <Button
          className='button-error'
          danger
          onClick={onClose}
          type='primary'
        >
          Fechar
        </Button>
      </Container>
    </ModalHeadLess>
  );
};
