import api from "./api";
import { ImageUpload } from "../models/ImageUpload";

export class UploadImageService {
  public static async upload(file: File): Promise<ImageUpload> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.post('api/image-upload', formData);

    return response.data.data;
  }
}
