import React, { useMemo, useState } from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import { CloseOutlined } from '@ant-design/icons';

import { isMobile } from '../../../../../utils/Util';
import { WeekdaysList } from '../../../../../utils/Constants';
import { OpeningHours } from '../../../../../models/DataResponse';

import { PageTitle, SidePanelContent } from '../../../../../common-styles';
import { ScheduleItem } from './styles';
import ScheduleController from '../../ScheduleController';

interface AboutDetailsProps {
  openingHours: OpeningHours[];
  dismissPage(): void;
};

export const AboutDetails = ({
  openingHours,
  dismissPage,
}: AboutDetailsProps) => {
  const [isOpen, setOpen] = useState(true);

  const isMobileDevice = useMemo(() => {
    return isMobile.any();
  }, []);

  const scheduleGroup = useMemo(() => {
    const controller = new ScheduleController();
    return controller.getScheduleItems(openingHours);
  }, [openingHours]);

  const handleClosePage = () => {
    setOpen(false);
    dismissPage();
  };

  const isToday = (day: string) => {
    const today = new Date().getDay();
    return WeekdaysList[today] === day;
  };

  return (
    <SlidingPanel
      type={isMobileDevice ? 'bottom' : 'right'}
      isOpen={isOpen}
      backdropClicked={handleClosePage}
      size={isMobileDevice ? 80 : 35}
    >
      <SidePanelContent>
        <CloseOutlined onClick={handleClosePage} />
        <PageTitle style={{ marginTop: '16px' }}>Horário</PageTitle>
        {scheduleGroup.map((schedule) => {
          return (
            <ScheduleItem>
              <div className="details-schedule__day-title">
                <span
                  className={`details-schedule__day-title-text ${isToday(schedule.weekdayText) && 'schedule-today'
                    }`}
                >
                  {schedule.weekdayText}
                </span>
              </div>
              <span
                className={`details-schedule__day-title-text ${isToday(schedule.weekdayText) && 'schedule-today'
                  }`}
              >
                {schedule.hoursText}
              </span>
            </ScheduleItem>
          );
        })}
      </SidePanelContent>
    </SlidingPanel>
  );
};
