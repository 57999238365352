import React, { useState } from 'react';
import { Space, Table } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
  SlidersOutlined,
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/lib/table';

import { TableGenericAction } from '../../../../../components/TableGenericAction';
import { FeaturesPage } from '../../containers/FeaturesPage';

import { Company } from '../../../../../models/Company';
import { formatDate } from '../../../../../utils/dateTime';
import { getClientMenuUrl } from '../../../../../utils/Util';
import { RoundTag } from '../../../../../common-styles';

interface CompanyTableProps {
  companies: Company[];
  loading: boolean;
  handleRowClick(selectedItem: Company, isViewMode: boolean): void;
};

export const CompanyTable = ({
  companies,
  loading,
  handleRowClick,
}: CompanyTableProps) => {
  const [isFeatureVisible, setFeatureVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<Company>();

  const handleLinkClick = (menuPath: string) => {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = getClientMenuUrl(menuPath);
    a.click();
  };

  const handleClickRow = (row: Company, isViewMode: boolean) => {
    const selectedItem = companies.find(
      (item) => item._id === row._id
    ) as Company;
    handleRowClick(selectedItem, isViewMode);
  };

  const handleClientFeatures = (company: Company) => {
    setFeatureVisible(true);
    setSelectedCompany(company);
  };

  const columns: ColumnsType<Company> = [
    {
      title: 'Empresa',
      dataIndex: 'fantasyName',
      key: 'fantasyName',
      sorter: (a, b) => a.fantasyName.localeCompare(b.fantasyName),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: 'Módulos',
      key: 'modules',
      render: (_: any, company: Company) => {
        const features = company.features[0];
        if (!features) return null;

        return <ul style={{ listStyleType: 'none' }} key={company._id}>
          {features.sellViaWhatsapp && <li><i className="fa fa-whatsapp" aria-hidden="true" /> - WhatsApp</li>}
          {features.menuOptionsEnabled && <li><i className="fa fa-window-restore" aria-hidden="true" /> - Multiplas abas</li>}
          {features.reservations && <li><i className="fa fa-book" aria-hidden="true" /> - Reservas</li>}
          {features.multilanguage && <li><i className="fa fa-language" aria-hidden="true" /> - Multilinguas</li>}
        </ul>
      },
    },
    {
      title: 'Centralizador?',
      sorter: (a, b) => a.isCentralizer.toString().localeCompare(b.isCentralizer.toString()),
      sortDirections: ["descend", "ascend"],
      render: (_: any, company: Company) => {
        const color = company.isCentralizer ? 'blue' : 'orange';
        return (
          <RoundTag color={color} key={company._id}>
            {company.isCentralizer ? 'Sim' : 'Não'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Empresa associada',
      render: (_: any, company: Company) => company.companyParentAssociatedInfo[0]?.fantasyName
    },
    {
      title: 'Código de acesso',
      dataIndex: 'accessCode',
    },
    {
      title: 'Data de adesão',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortDirections: ["descend", "ascend"],
      render: (_: any, company: Company) => formatDate(company.createdAt)
    },
    {
      title: 'Responsável',
      dataIndex: 'responsible',
      key: 'responsible',
    },
    {
      title: 'Ações',
      fixed: 'right',
      width: 150,
      key: 'actions',
      render: (_: any, item: Company) => (
        <Space direction="vertical" key={item._id}>
          {!item.isCentralizer && (
            <TableGenericAction
              icon={<LinkOutlined style={{ fontSize: '18px' }} />}
              label="Link"
              onClick={() => handleLinkClick(item.menuUrl)}
            />
          )}

          <TableGenericAction
            icon={<EditOutlined style={{ fontSize: '18px' }} />}
            label="Editar"
            onClick={() => handleClickRow(item, false)}
          />

          <TableGenericAction
            icon={<EyeOutlined style={{ fontSize: '18px' }} />}
            label="Visualizar"
            onClick={() => handleClickRow(item, true)}
          />

          <TableGenericAction
            icon={<SlidersOutlined style={{ fontSize: '18px' }} />}
            label="Features"
            onClick={() => handleClientFeatures(item)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      {selectedCompany?._id && (
        <FeaturesPage
          companyId={selectedCompany._id}
          companyName={selectedCompany.fantasyName}
          isPageOpen={isFeatureVisible}
          onClose={() => setFeatureVisible(false)}
        />
      )}

      <Table
        bordered
        columns={columns}
        dataSource={[...companies]}
        loading={loading}
        rowKey="Id"
        scroll={{ x: 1300 }}
        pagination={false}
      />
    </>
  );
};