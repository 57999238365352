import api from './api';
import { WhatsappOrder } from './../models/DataResponse';

export class OrderService {
  public static async update(order: WhatsappOrder): Promise<WhatsappOrder> {
    const response = await api.put(`/api/orders/${order._id}`, order);

    return response.data.data;
  }

  public static async associateOrderToDriver(orderId: string, driverId: string): Promise<void> {
    return await api.post('/api/order_delivery/', {
      driverId,
      orderId,
    });
  }
}
