import { Divider } from "antd";
import React from "react";
import { ProductWhatsappOrder } from "../../../../models/DataResponse";
import { ProductComplementItemOption } from "./styles";

interface ProductComplementsDescriptionProps {
  lastProduct?: boolean;
  product: ProductWhatsappOrder;
  showComplement?: boolean;
  showComplementTitle?: boolean;
}

export const ProductComplementsDescription = ({ product, lastProduct = false, showComplement = true, showComplementTitle }: ProductComplementsDescriptionProps) => {
  return (
    <>
      <p>{`${product.counter}x ${product.name}`}</p>
      {showComplement && product.complements && (
        <ul style={{ marginLeft: '20px' }}>
          {product.complements?.map((complements) => {
            return (
              <>
                {showComplementTitle && <p>{complements.title}</p>}
                {complements.complementItemOptions
                  .filter(
                    (product) =>
                      product.counter !== undefined && product.counter !== 0
                  )
                  .map((option) => {
                    return (
                      <ProductComplementItemOption
                        showTitle={showComplementTitle ?? false}
                        key={option._id}
                      >
                        {`${option.counter}x ${option.title}`}
                      </ProductComplementItemOption>
                    );
                  })}
              </>
            );
          })}
        </ul>
      )}

      {!lastProduct && <Divider />}
    </>
  );
}