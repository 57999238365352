import React from 'react';
import { Container } from './style';

interface ProductImageProps {
  url: string;
}

export const ProductImage = ({ url }: ProductImageProps) => {
  if (!url) {
    return null;
  }

  const splitted = url.split('/');
  const name = splitted[splitted.length - 1];

  return (
    <Container>
      <img src={url} alt={name} />
    </Container>
  );
};
