import React from "react";
import { Tooltip } from "antd";
import { StyledInfoIcon } from "./styles";

interface InfoTooltipProps {
  className?: string;
  message: string;
}

export const InfoTooltip = ({ className, message }: InfoTooltipProps) => (
  <Tooltip className={className} title={message}>
    <StyledInfoIcon />
  </Tooltip>
);