import { Features } from "../../../../../models/Features";
import api from "../../../../../services/api";

export class FeatureService {
  public static async update(features: Features): Promise<void> {
    return await api.put(`api/features/${features._id}`, features);
  }

  public static async deleteMenuOptionById(id: string): Promise<void> {
    return await api.delete(`/api/features/menuoption/${id}`);
  }
}
