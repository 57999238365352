import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Category } from '../models/DataResponse';
import { CategoryService } from '../services/CategoryService';
import { CATEGORIES } from './useCategories';

export const useCategory = () => {
  const queryClient = useQueryClient();

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useMutation(
    (category: Category) => CategoryService.create(category)
  );

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (category: Category) => CategoryService.update(category)
  );

  const { mutate: mutateListPositionUpdate, isLoading: isLoadingListPositionUpdate } = useMutation(
    (categories: Category[]) => CategoryService.updateListPositions(categories)
  );

  const create = useCallback((category, { onSuccess, onError }) => {
    mutateCreate(category, {
      onSuccess: (newCategory) => {
        const caregories = queryClient.getQueryData<Category[]>(CATEGORIES) || [];

        queryClient.setQueryData<Category[]>(CATEGORIES, [...caregories, newCategory]);
        queryClient.refetchQueries(CATEGORIES);
        onSuccess(newCategory._id);
      },
      onError,
    });
  }, [mutateCreate, queryClient]
  );

  const update = useCallback((category, { onSuccess, onError }) => {
    mutateUpdate(category, {
      onSuccess: (updatedCategory) => {
        queryClient.setQueryData<Category[]>(
          CATEGORIES,
          (categories) =>
            categories?.map((category) =>
              category._id === updatedCategory._id
                ? updatedCategory
                : category
            ) ?? []
        );
        queryClient.refetchQueries(CATEGORIES);
        onSuccess();
      },
      onError,
    });
  }, [mutateUpdate, queryClient]
  );

  const updateListPosition = useCallback((categories, { onSuccess, onError }) => {
    mutateListPositionUpdate(categories, {
      onSuccess: (updatedCategories) => {
        queryClient.setQueryData<Category[]>(CATEGORIES, updatedCategories);
        queryClient.refetchQueries(CATEGORIES);
        onSuccess();
      },
      onError,
    });
  }, [mutateListPositionUpdate, queryClient]
  );

  return {
    isLoadingCreate,
    create,
    isLoadingUpdate,
    update,
    isLoadingListPositionUpdate,
    updateListPosition,
  };
};
