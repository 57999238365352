import { NotificationDispatcher, NotificationType } from "../../../../components/Notification";

interface SendWhatsappMessageArgs {
  clientNumber: string;
  isUserRole: boolean;
  message: string;
  shouldNotifyCustomerWithMessage: boolean;
}

export const sendMessageToWhatsapp = async ({
  clientNumber,
  isUserRole,
  message,
  shouldNotifyCustomerWithMessage,
}: SendWhatsappMessageArgs) => {
  // TODO: We need to find a way to handle this for the others roles.
  // The problem is when it is manager role, we should read the shouldNotifyCustomerWithMessage from the configuration and ignore the store configs.
  if (isUserRole && shouldNotifyCustomerWithMessage) {
    const sanitizedNumber = clientNumber.replace(/\s/g, '').replace('+', '');

    if (clientNumber) {
      redirectToWhatsapp(sanitizedNumber, message);
    } else {
      NotificationDispatcher({
        message: 'Não é possível enviar a mensagem, devido ao número de telefone não ser válido.',
        type: NotificationType.ERROR,
      });
    }
  }
}

const redirectToWhatsapp = (sanitizedNumber: string, message: string) => {
  const a = document.createElement('a');
  a.target = '_blank';
  a.href = `https://api.whatsapp.com/send?phone=${sanitizedNumber}&text=${message}`;
  a.click();
}