import React, { useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { Button, DatePicker, Drawer, Form, InputNumber, Select, Space } from 'antd';

import { CouponRulesInfo } from '../../../../../components/CouponRulesInfo';
import { FormInputField } from '../../../../../components/FormInputField';
import { FormRadioGroup } from '../../../../../components/FormRadioGroup';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../components/Notification';

import { registerAction } from '../../../../../services/internalAnalytics';
import { RestType, useMutations } from '../../../../../hooks/useMutations';
import { Coupon } from '../../../../../models/Coupon';
import {
  DATE_FORMAT,
  SERVER_DATE_FORMAT,
} from '../../../../../utils/dateTime';
import { PREFIX_LOCALSTORAGE } from '../../../../../utils/Constants';
import { CompanyActivityLogService } from '../../../../../services/CompanyActivityLogService';
import { COUPONS, useLoggedUser } from '../../../../../hooks';
import { FormItemDatePicker } from '../../../../../common-styles';

interface CouponEditorProps {
  companyId: string;
  isPageOpen: boolean;
  onClose(): void;
  selectedCoupon?: Coupon;
};

const COUPON_TYPES = [
  {
    value: -1,
    label: 'Selecione o tipo de desconto',
  },
  {
    value: 0,
    label: 'Porcentagem',
  },
  {
    value: 1,
    label: 'Valor Fixo',
  },
];

const MIN_CHARS_ALLOWED = 3;

export const CouponEditor = ({ companyId, isPageOpen, selectedCoupon, onClose }: CouponEditorProps) => {
  const [selectedDiscountType, setSelectedDiscountType] = useState<number>(
    COUPON_TYPES[0].value
  );
  const [isEnabled, setEnabled] = useState<boolean>();
  const [isInvalidExpireDate, setInvalidExpireDate] = useState<boolean>(false);
  const [selectedExpireDate, setSelectedExpireDate] = useState<moment.Moment>();

  const { userLogged } = useLoggedUser();
  const requestType = selectedCoupon ? RestType.PUT : RestType.POST;

  const apiPath = selectedCoupon
    ? `api/coupon/${selectedCoupon._id}`
    : 'api/coupon';

  const headers = {
    company_id: companyId,
  };

  const mutation = useMutations(requestType, apiPath, headers);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  useEffect(() => {
    setInvalidExpireDate(false);

    if (selectedCoupon) {
      setSelectedDiscountType(Number(selectedCoupon.discountType));
      setSelectedExpireDate(moment(new Date(selectedCoupon.expirationDate)));
      setEnabled(selectedCoupon.isEnabled);
    } else {
      form.resetFields();
      setEnabled(true);
      setSelectedDiscountType(COUPON_TYPES[0].value);
      setSelectedExpireDate(undefined);
    }
  }, [selectedCoupon, form]);

  const onFinish = (values: any) => {
    if (!selectedExpireDate) {
      setInvalidExpireDate(true);
      return;
    }
    setInvalidExpireDate(false);

    if (
      selectedCoupon &&
      values.quantity &&
      Number(values.quantity) < selectedCoupon.usedQuantity
    ) {
      const usedCouponText =
        selectedCoupon.usedQuantity > 1
          ? `${selectedCoupon.usedQuantity} cupons utilizados`
          : '1 cupom utilizado';

      NotificationDispatcher({
        message: 'Não é possível atualizar uma quantidade inferior a atual',
        description: `Você já teve ${usedCouponText} , não é possível alterar para uma quantidade menor.`,
        type: NotificationType.ERROR,
      });
      return;
    }

    if (values.quantity) {
      const quantity = Number(values.quantity);
      if (quantity < 0) {
        NotificationDispatcher({
          message: 'A quantidade deve ser um número positivo',
          type: NotificationType.ERROR,
        });
        return;
      }

      if (selectedCoupon && Number(selectedCoupon.usedQuantity) === quantity) {
        values.status = 'FINISHED';
      }
    }

    if (
      selectedDiscountType === COUPON_TYPES[0].value &&
      values.discountValue > 100
    ) {
      NotificationDispatcher({
        message: 'O valor de desconto não pode ser maior que 100%',
        type: NotificationType.ERROR,
      });
      return;
    }

    if (values.discountValue < 1) {
      NotificationDispatcher({
        message: 'O valor de desconto não pode ser menor do que 1',
        type: NotificationType.ERROR,
      });
      return;
    }

    if (!isEnabled) {
      values.status = 'PAUSED';
    }

    const body = {
      ...values,
      expirationDate: selectedExpireDate?.format(SERVER_DATE_FORMAT),
      companyId,
      discountType: selectedDiscountType,
      isEnabled,
    };

    mutation.mutate(body, {
      onSuccess: (result) => {
        const couponList = queryClient.getQueryData<Coupon[]>(COUPONS) || [];
        let dataToSave = [...couponList];
        if (selectedCoupon) {
          const index = couponList.findIndex(
            (item) => item._id === selectedCoupon!._id
          );

          const updateCoupon = result.data.data;
          dataToSave[index] = updateCoupon;

          CompanyActivityLogService.register({ action: `Cupom <strong>${updateCoupon.title}</strong> atualizado`, actionArea: 'Cupons', extra: updateCoupon._id });
        } else {
          const newCoupon: Coupon = result.data.data;
          dataToSave = [newCoupon, ...couponList];
          registerAction('Coupon creation', userLogged);
          CompanyActivityLogService.register({ action: `Novo Cupom: <strong>${newCoupon.title}</strong>`, actionArea: 'Cupons', extra: newCoupon._id });
        }

        queryClient.setQueryData(COUPONS, dataToSave);
        queryClient.refetchQueries(COUPONS);

        mutation.reset();
        handleCloseDrawer();
        NotificationDispatcher({
          message: 'Operação realizada com sucesso.',
        });
      },
      onError: (ex) => {
        const error = ex as AxiosError;
        console.error('Error trying to create coupon', error);

        let message = 'Erro ao tentar proceder com a operação.';

        if (error.response) {
          switch (error.response.status) {
            case 400:
              message = 'Código inválido.';
              break;
            case 409:
              message = 'Código de cupom em uso.';
              break;
          }
        }

        NotificationDispatcher({
          message,
          type: NotificationType.ERROR,
        });
      },
    });
  };

  const resetForm = () => {
    form.resetFields();
    selectedCoupon = undefined;
    setSelectedExpireDate(undefined);
    setEnabled(true);
    setSelectedDiscountType(COUPON_TYPES[0].value);
  };

  const handleCloseDrawer = () => {
    resetForm();
    onClose();
  };

  return (
    <Drawer
      title={selectedCoupon ? 'Editar Cupom' : 'Criar Cupom'}
      onClose={handleCloseDrawer}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isPageOpen}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={selectedCoupon}
      >
        <Form.Item
          label={<label className="label-input-field">Tipo de desconto</label>}
        >
          <Select
            defaultValue={selectedDiscountType}
            value={selectedDiscountType}
            onChange={(value) => setSelectedDiscountType(value)}
          >
            {COUPON_TYPES.map((type) => (
              <Select.Option key={type.label} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {selectedCoupon && (
          <FormRadioGroup
            label="Disponibilidade"
            propertyId="isEnabled"
            defaultValue={selectedCoupon.isEnabled ?? true}
            handleOnChange={(checked) => setEnabled(checked)}
          />
        )}

        {selectedDiscountType !== COUPON_TYPES[0].value && (
          <>
            <FormInputField
              label="Título"
              name="title"
              help="Identifique o seu cupom."
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
              maxLength={100}
            />

            <FormInputField
              label="Código do cupom"
              name="couponCode"
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
                () => ({
                  validator(_: any, value = '') {
                    if (value.length <= MIN_CHARS_ALLOWED) {
                      return Promise.reject('Mínimo 4 e máximo 20 caracteres.');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              normalize={(value: any) => (value || '').toUpperCase()}
              style={{ paddingTop: '16px' }}
              placeholder="Ex.: CUPOM10"
              maxLength={20}
            />

            {selectedDiscountType === COUPON_TYPES[1].value ? (
              <Form.Item
                label={<label className="label-input-field">Desconto %</label>}
                name="discountValue"
                help="Porcentagem de desconto."
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
                style={{ marginTop: '16px' }}
              >
                <InputNumber
                  maxLength={10}
                  type="number"
                  placeholder="%"
                  min={1}
                  max={100}
                  style={{ width: '50%' }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={
                  <label className="label-input-field">{`Desconto ${currency}`}</label>
                }
                name="discountValue"
                help="Valor de desconto."
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
                style={{ marginTop: '16px' }}
              >
                <InputNumber
                  addonBefore={currency}
                  maxLength={10}
                  type="number"
                  placeholder={'0,00'}
                  min={1}
                  max={9999}
                  style={{ width: '50%' }}
                />
              </Form.Item>
            )}

            <FormInputField
              label="Quantidade"
              name="quantity"
              help="Número de vezes que o cupom pode ser usado. Ao deixar vazio, o sistema irá assumir como ilimitado."
              style={{ paddingTop: '16px' }}
              maxLength={10}
              type="number"
              placeholder={'0'}
            />

            <FormItemDatePicker style={{ paddingTop: '25px' }}>
              <label
                className="ant-form-item-required label-input-field"
                title="Data de validade"
              >
                Data de validade
              </label>
              <DatePicker
                style={{ marginTop: '8px' }}
                format={DATE_FORMAT}
                defaultValue={selectedExpireDate}
                disabledDate={(current) => {
                  let customDate = moment().format('YYYY-MM-DD');
                  return current && current < moment(customDate, 'YYYY-MM-DD');
                }}
                onChange={(date) => {
                  if (date) setSelectedExpireDate(date);
                }}
              />

              {isInvalidExpireDate && (
                <div role="alert" className="ant-form-item-explain-error">
                  Campo obrigatório.
                </div>
              )}
            </FormItemDatePicker>

            <CouponRulesInfo />

            <Space style={{ paddingTop: '30px', display: 'flex', justifyContent: 'space-around' }}>
              <Button
                danger
                style={{ marginRight: '10px' }}
                onClick={handleCloseDrawer}
              >
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  style={{ marginRight: '10px' }}
                />
                Cancelar
              </Button>

              <Button type="primary" htmlType="submit">
                <i
                  className="fa fa-check-circle"
                  aria-hidden="true"
                  style={{ marginRight: '10px' }}
                />
                Salvar
              </Button>
            </Space>
          </>
        )}
      </Form>
    </Drawer>
  );
};
