import styled from 'styled-components';

export const ErrorMessage = styled.p`
  color: red;
  margin-top: 8px;
  margin-left: 8px;
  text-align: initial;
`;

export const SuccessMessage = styled.p`
  color: green;
  margin-top: 8px;
  margin-left: 8px;
  text-align: initial;
`;
