import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { UserLogged } from '../models/User';
import { PREFIX_LOCALSTORAGE } from '../utils/Constants';
import { useFetch } from './useFetch';

export const LOGGED_USER = 'loggedUser';

export const useLoggedUser = () => {
  const queryClient = useQueryClient();

  const { data, ...rest } = useFetch<UserLogged>({
    retry: 3,
    queryKey: LOGGED_USER,
    url: 'api/auth/me',
  });

  useEffect(() => {
    if (!data) return;

    // TODO: Remove this and start using queryData instead
    localStorage.setItem(`${PREFIX_LOCALSTORAGE}user`, JSON.stringify(data));
    localStorage.setItem(`${PREFIX_LOCALSTORAGE}:company_id`, data.companyInfo?.id ?? '');
    localStorage.setItem(`${PREFIX_LOCALSTORAGE}slug`, data.companyInfo?.slug ?? '');
  }, [data]);

  const userLogged = useMemo(() => {
    return queryClient.getQueryData<UserLogged>(LOGGED_USER)!;
  }, [queryClient]);

  return {
    data,
    userLogged,
    ...rest,
  };
};
