import React from 'react';
import { useQueryClient } from 'react-query';

import api from '../../../../../services/api';
import { CompanyActivityLogService } from '../../../../../services/CompanyActivityLogService';
import { Category } from '../../../../../models/DataResponse';
import { CategoryHasDependency } from '../../../../../models/responseData/CategoryHasDependency';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../components/Notification';
import { CATEGORIES } from '../../../../../hooks';
import { TableDeleteAction } from '../../../../../components/TableDeleteAction';

interface DeleteCategoryProps {
  category: Category;
}

export const DeleteCategory = ({ category }: DeleteCategoryProps) => {
  const queryClient = useQueryClient();

  const checkCategoryCanBeDeleted = async () => {
    const headers = {
      company: category.company,
    };

    try {
      const response = await api.get(
        `/api/category/checkDependency/${category._id}`,
        {
          headers,
        }
      );

      return response.data.data;
    } catch (e) {
      return false;
    }
  };

  const handleDeleteAction = async () => {
    const response: CategoryHasDependency = await checkCategoryCanBeDeleted();
    if (response.canDelete) {
      executeDeletion();
    } else {
      NotificationDispatcher({
        message: 'Não foi possível excluir a categoria',
        description:
          'Não é permitido remover esta categoria, pois existem produtos associados a mesma.',
        type: NotificationType.ERROR,
      });
    }
  };

  const executeDeletion = async () => {
    await api
      .delete(`api/category/${category._id}`, {
        headers: {
          company: category.company,
        },
      })
      .then(() => {
        queryClient.setQueryData<Category[]>(
          CATEGORIES,
          (categories) =>
            categories?.filter((item) => item._id !== category._id) ?? []
        );
        queryClient.refetchQueries(CATEGORIES);

        CompanyActivityLogService.register({ action: `Categoria <strong>${category.category}</strong> removida`, actionArea: 'Categoria', extra: category._id });

        NotificationDispatcher({
          message: 'Categoria removida com sucesso.',
        });
      })
      .catch(() => {
        NotificationDispatcher({
          message: 'Não foi possível excluir a categoria.',
          type: NotificationType.ERROR,
        });
      });
  };

  return (
    <TableDeleteAction
      onClick={handleDeleteAction}
      title={
        <span>
          Tem certeza que deseja remover esta categoria{' '}
          <strong>{category?.category}</strong>? <br />
          Uma vez removida, esta ação não poderá ser revertida.
        </span>
      }
    />
  );
};
