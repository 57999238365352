const formatCurrency = (current: number, currency: string): string => {
  if (currency === '€') {
    return current.toLocaleString('pt-pt', {
      style: 'currency',
      currency: 'EUR',
    });
  }
  return current.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
};

export default formatCurrency;
