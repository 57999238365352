export enum OperationResult {
  SUCCESS,
  FAIL,
}

export enum Role {
  ADMIN = 'admin',
  MANAGER = 'manager',
  KITCHEN = 'kitchen',
  DRIVER = 'driver',
  USER = 'user', // Storekeeper
}

export enum LayoutType {
  MENU = 'menu-food',
  CATALOG = 'catalog-sells',
}

/**
 * @deprecated Check if this still need when we implement Finance Page
 */
export const PaymentTypes = [
  {
    label: 'Boleto',
    value: 'boleto',
  },
  {
    label: 'Transferência',
    value: 'transfer',
  },
  {
    label: 'Pix',
    value: 'pix',
  },
  {
    label: 'Dinheiro',
    value: 'cash',
  },
  {
    label: 'Free',
    value: 'free',
  },
];

export enum SpinnerDay {
  TODAY = 'Hoje',
  MONTH = 'Mês',
}

export enum CouponStatus {
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
}

export enum ComplementAction {
  ADD,
  UPDATE,
  DELETE,
}

export enum ActionType {
  ADD,
  CANCEL,
  CLONE,
  CLOSE,
  DELETE,
  OPEN,
  UPDATE,
}

export const OrderCountType = [
  {
    label: 'Produto',
    value: 'BY_PRODUCT',
  },
  {
    label: 'Complemento',
    value: 'BY_COMPLEMENT',
  },
];
