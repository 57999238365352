import React from 'react';
import { Result } from 'antd';

export enum PanelResultStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  NOT_FOUND = '404',
}

interface PanelResultProps {
  status?: PanelResultStatus;
  title: String | React.ReactNode;
  subtitle?: String | React.ReactNode;
  extra?: React.ReactNode;
};

export const PanelResult = ({
  status = PanelResultStatus.SUCCESS,
  title,
  subtitle,
  extra,
}: PanelResultProps) => (
  <Result status={status} title={title} subTitle={subtitle} extra={extra} />
);
