import React from "react";
import { FormInputField } from "../../../../../../../components/FormInputField";
import { Title } from "../../styles";

export const CustomerInformation = () => {
  return (
    <>
      <Title>Informações do Contratante</Title>
      <FormInputField
        label="Nome fantasia"
        name="fantasyName"
        useDefaultRules
      />

      <FormInputField
        label="Razão social"
        name="companyName"
        useDefaultRules
      />

      <FormInputField
        label="Endereço"
        name="address"
      />

      <FormInputField
        label="Nome do responsável"
        name="responsible"
        useDefaultRules
      />

      <FormInputField
        label="Email"
        name="email"
        useDefaultRules
      />

      <FormInputField
        label="Número do telefone"
        name="phoneNumber"
        useDefaultRules
      />

      <FormInputField
        label="Identificação (CPF/CNPJ/NIF)"
        name="identification"
        useDefaultRules
      />
    </>
  );
}