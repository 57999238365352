import React from "react";
import { Badge } from "antd";

import {
  AppstoreAddOutlined,
  BankOutlined,
  CommentOutlined,
  ContactsOutlined,
  DatabaseOutlined,
  FileSyncOutlined,
  GiftOutlined,
  PieChartOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { MenuOption } from "../../../../models/MenuOption";
import { MenuItem } from "../MenuItem";
import { Title } from "../../styles";
import { SettingsSection } from "../..";

export const adminMenuOptions = (
  collapsed: boolean,
  contactsBadgeCounter?: number,
) => [
    <Title key={SettingsSection.GENERAL} isCollapsed={collapsed}>
      {SettingsSection.GENERAL}
    </Title>,
    MenuItem(MenuOption.COMPANY, <BankOutlined />),
    MenuItem(MenuOption.USER, <TeamOutlined />),
    MenuItem(MenuOption.CONTACT, <ContactsOutlined />, <Badge count={contactsBadgeCounter} style={{ marginLeft: '10px' }} />),
    MenuItem(MenuOption.ACTIVITY_LOG, <DatabaseOutlined />),

    <Title key={SettingsSection.SUPPORT} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.SUPPORT}
    </Title>,
    MenuItem(MenuOption.CATEGORIES, <PieChartOutlined />),
    MenuItem(MenuOption.PRODUCTS, <AppstoreAddOutlined />),
    MenuItem(MenuOption.HISTORY_ORDERS, <FileSyncOutlined />),
    // MenuItem(MenuOptions.QRCODE, <QrcodeOutlined />),
    MenuItem(MenuOption.COUPON, <GiftOutlined />),
    MenuItem(MenuOption.FEEDBACK, <CommentOutlined />),
    MenuItem(MenuOption.SETTINGS_GENERAL, <SettingOutlined />),

    <Title key={SettingsSection.SETTINGS} isCollapsed={collapsed} style={{ marginTop: '40px' }}>
      {SettingsSection.SETTINGS}
    </Title>,
    MenuItem(MenuOption.SETTINGS_PROFILE, <UserOutlined />),
  ];
