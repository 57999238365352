import React, { useState } from 'react';
import { Button, Form, Tabs } from 'antd';

import { CompanyFeatures } from '../../../../../../models/CompanyFeatures';
import { FormLayout } from '../../../../../../utils/Styles';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../../components/Notification';
import { FormInputField } from '../../../../../../components/FormInputField';
import { PanelResult, PanelResultStatus } from '../../../../../../components/PanelResult';
import { useFeatures, useUpdateCompanyFeatures } from '../../../../../../hooks';
import { FormSwitchItem } from '../../../../../../components/FormSwitchItem';

interface DefaultTextsProps {
  companyFeatures: CompanyFeatures;
}

enum Tab {
  GENERAL = 'Geral',
  ORDERS = 'Pedidos',
}

export const DefaultTexts = ({ companyFeatures }: DefaultTextsProps) => {
  const [selectedTab, setSelectedTab] = useState(Tab.GENERAL);
  const { data: features } = useFeatures(companyFeatures.companyId);
  const { update } = useUpdateCompanyFeatures();

  const updateCompanyFeatures = (companyFeatures: CompanyFeatures) => {
    update(
      { ...companyFeatures },
      {
        onSuccess: () => NotificationDispatcher({
          message: 'Atualizado com sucesso.',
        }),
        onError: () => NotificationDispatcher({
          message: 'Erro ao tentar atualizar.',
          type: NotificationType.ERROR,
        })
      },
    );
  }

  const handleFormSubmit = async (formData: any) => {
    const {
      automaticMessage,
      hintMessageForDateTimePicker,
      informingOrderWasSentToWhatsappMessage,
      orderProductObservationMessage,
      pickUpOnSiteStatusMessage,
      preparationStatusMessage,
      sentStatusMessage,
    } = formData;

    const featuresToUpdate = {
      ...companyFeatures,
      automaticMessage,
      hintMessageForDateTimePicker,
      informingOrderWasSentToWhatsappMessage,
      orderProductObservationMessage,
      pickUpOnSiteStatusMessage,
      preparationStatusMessage,
      sentStatusMessage,
    };

    updateCompanyFeatures(featuresToUpdate);
  }

  const onTabChanged = (key: string) => setSelectedTab(key as Tab);

  return (
    <Form
      layout="vertical"
      {...FormLayout}
      initialValues={companyFeatures}
      onFinish={handleFormSubmit}
    >
      <Tabs
        size="large"
        activeKey={selectedTab.toString()}
        onChange={onTabChanged}
      >

        <Tabs.TabPane tab={Tab.GENERAL} key={Tab.GENERAL}>
          <FormInputField
            label="Observação do Produto"
            name="orderProductObservationMessage"
            help={
              'Este texto será usado na observação de todos os produtos no Cardápio/Catálogo.'
            }
            placeholder="Ex: Retirar salada..."
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab={Tab.ORDERS} key={Tab.ORDERS}>
          {features?.sellViaWhatsapp ? (
            <>
              <FormSwitchItem
                label="Enviar mensagem na mudança de status do pedido?"
                propertyId="notifyCustomerWithMessage"
                defaultChecked={
                  companyFeatures.sellsConfiguration?.notifyCustomerWithMessage ?? true
                }
                handleCheckboxChange={(checked) => {
                  companyFeatures.sellsConfiguration['notifyCustomerWithMessage'] = checked;
                  updateCompanyFeatures({ ...companyFeatures });
                }}
                helper={
                  'Ao efetuar uma alteração no status do pedido, irá ser enviada uma mensagem de WhatsApp para o telefone do cliente. Essa mensagem pode ser alguma das opções definidas nos campos abaixo.'
                }
              />

              <FormInputField
                disableInput={!companyFeatures.sellsConfiguration?.notifyCustomerWithMessage}
                label={"Mensagem 'Em execução'"}
                name="preparationStatusMessage"
                style={{ marginTop: '1rem' }}
                placeholder="Ex: Olá, o seu pedido está a ir pro forno agora..."
              />

              <FormInputField
                disableInput={!companyFeatures.sellsConfiguration?.notifyCustomerWithMessage}
                label="Mensagem 'Pronto entrega'"
                name="sentStatusMessage"
                placeholder="Ex: Olá, o seu pedido está pronto para ser entregue..."
              />

              <FormInputField
                disableInput={!companyFeatures.sellsConfiguration?.notifyCustomerWithMessage}
                label="Mensagem 'Realizado'"
                name="pickUpOnSiteStatusMessage"
                placeholder="Ex: Olá, o seu pedido está pronto para retirada..."
              />

              <FormInputField
                label="Mensagem 'Automática'"
                name="automaticMessage"
                help="Essa mensagem irá ser exibida no rodapé da mensagem enviada para o WhatsApp, junto com o resumo do pedido. Para adicionar negrito a palavra ou frase, use (*) no início e no fim do local desejado, o mesmo para itálico (_)."
                placeholder="Ex: As instruções de pagamento..."
                isInputArea={true}
                inputTextAreaProps={{
                  showCount: true,
                  rows: 5,
                }}
              />

              <FormInputField
                label="Mensagem de finalização do pedido"
                name="informingOrderWasSentToWhatsappMessage"
                help={
                  'Este texto irá ser exibido quando o usuário clicar para finalizar o pedido, antes de ser redirecionado para o WhatsApp.'
                }
                style={{ marginTop: '16px' }}
              />

              <FormInputField
                help={'Este texto irá ser exibido por baixo dos campos de data/hora de pré-agendamento.'}
                label="Mensagem informativa para o pré-agendamento"
                maxLength={50}
                name="hintMessageForDateTimePicker"
                style={{ marginTop: '16px' }}
              />
            </>
          ) : (
            <PanelResult
              status={PanelResultStatus.INFO}
              title="Para ter acesso a esta funcionalidade, é preciso ativar o módulo de pedidos."
              subtitle="Entre já em contato com a equipe QRcode Preferido para mais informações."
            />
          )}
        </Tabs.TabPane>
      </Tabs>

      <div style={{ marginTop: '16px', textAlign: 'start' }}>
        <Button type="primary" htmlType="submit">
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Salvar
        </Button>
      </div>
    </Form>
  );
}
