import { isEmpty } from "lodash";
import { useFetch } from "./useFetch";

const ADDRESS_COORDINATES = 'addressCordinates';

export interface AddressCoordinates {
  location: {
    lat: number;
    lng: number;
  },
  postalCode: string;
}

export const useAddressCoordinates = (address: string) => {
  const { data, ...rest } = useFetch<AddressCoordinates>({
    enabled: !isEmpty(address),
    queryKey: [ADDRESS_COORDINATES, address],
    url: `api/address/coordinates?address=${encodeURIComponent(address)}`,
  });

  return { data, ...rest };
};