import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Skeleton } from 'antd';

import {
  useCompanyFeatures,
  useUpdateCompanyFeatures,
} from '../../../../../hooks';

import { NotificationDispatcher } from '../../../../../components/Notification';

import FeaturesContent from './FeaturesContent';
import { NewMenuOption } from './NewMenuOption';

import { Features } from '../../../../../models/Features';
import { MenuTabOption } from '../../../../../models/DataResponse';
import { FeatureService } from './FeatureService';
import api from '../../../../../services/api';
import { EasypayFeature } from '../../../../../models/Easypay';
import { CustomerMenuCurrency } from './components/CustomerMenuCurrency';
import { Timezone } from './components/Timezone';
import { MapQuestApiKey } from './components/MapQuestApiKey';

const SUCCESS_MESSAGE = 'Operação realizada com sucesso';

interface FeaturesPageProps {
  companyId: string;
  companyName: string;
  isPageOpen: boolean;
  onClose(): void;
}

const dispatchNotification = (message: string = SUCCESS_MESSAGE) => {
  NotificationDispatcher({
    message,
  });
};

export const FeaturesPage = ({
  companyId,
  companyName,
  isPageOpen,
  onClose,
}: FeaturesPageProps) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedClientFeatures, setSelectedClientFeatures] = useState<Features>({} as Features);
  const [selectedFeaturesMenuOptions, setSelectedFeaturesMenuOptions] = useState<MenuTabOption | any>({} as MenuTabOption);
  const [isShowMenuOptionsPage, seShowMenuOptionsPage] = useState(false);

  const { companyFeatures, isFetching: isFetchingCompanyFeatures } = useCompanyFeatures(companyId);
  const { update: updateCompanyFeatures } = useUpdateCompanyFeatures();

  // TODO: Remove this and replace by the hook
  useEffect(() => {
    api.get(`/api/features/${companyId}`).then((response) => {
      if (response.data.success) {
        const data = response.data.data as Features;
        setSelectedClientFeatures(data);

      }
      setLoading(false);
    });
  }, [companyId]);

  const handleClosePage = () => {
    onClose();
  }

  const handleMenuOptionDeleteAction = async (id: string) => {
    await FeatureService.deleteMenuOptionById(id);

    let menuOptions = selectedClientFeatures.menuOptions;
    menuOptions = menuOptions.filter((option) => option._id !== id);

    setSelectedClientFeatures({
      ...selectedClientFeatures,
      menuOptions,
    });
  };

  const handleMenuOptionEditAction = (option: MenuTabOption) => {
    seShowMenuOptionsPage(true);
    setSelectedFeaturesMenuOptions(option);
  };

  const handleShowMenuOptionsPage = (isShow: boolean) => {
    seShowMenuOptionsPage(isShow);
    setSelectedFeaturesMenuOptions(null);
  };

  const handleMenuOptionsChange = (
    menuOption: MenuTabOption,
    isUpdate: boolean
  ) => {
    if (isUpdate) {
      const foundIndex = selectedClientFeatures.menuOptions.findIndex(
        (item) => item._id === menuOption._id
      );
      selectedClientFeatures.menuOptions[foundIndex] = menuOption;
    } else {
      selectedClientFeatures.menuOptions.push(menuOption);
    }
    setSelectedClientFeatures({
      ...selectedClientFeatures,
    });
  };

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked, name: switchId } = event.target;

    const updatedFeatures = { ...selectedClientFeatures };

    switch (switchId) {
      case 'reservations':
      case 'multilanguage':
      case 'sellViaWhatsapp':
        updatedFeatures[switchId] = checked;
        break;
      case 'menuOptionsEnabled':
        updatedFeatures.menuOptionsEnabled = checked;
        if (!checked) {
          setSelectedClientFeatures({
            ...selectedClientFeatures,
            menuOptions: [] as MenuTabOption[],
          });
        }
        break;
      case 'isEasypayEnabled':
        updatedFeatures.easypay.isEnabled = checked;
        break;
    }

    setSelectedClientFeatures(updatedFeatures);
    await FeatureService.update(updatedFeatures);
  };

  const handleWhatsappChanges = async (number: string, paymentMethods: string[]) => {
    setSelectedClientFeatures({
      ...selectedClientFeatures,
      whatsappNumber: number,
    });
    selectedClientFeatures.whatsappNumber = number;

    await FeatureService.update(selectedClientFeatures);

    if (companyFeatures) {
      updateCompanyFeatures({
        ...companyFeatures,
        sellsConfiguration: {
          ...companyFeatures.sellsConfiguration,
          paymentMethods,
        },
      }, {});
    }

    dispatchNotification();
  };

  const handleMenuOptionsUpdateCheckShouldBeHidden = async (checked: boolean) => {
    setSelectedClientFeatures({
      ...selectedClientFeatures,
      menuOptionsShouldBeHidden: checked,
    });

    selectedClientFeatures.menuOptionsShouldBeHidden = checked;
    await FeatureService.update(selectedClientFeatures);

    dispatchNotification();
  };

  const onPaymentEasypayChanged = async (easypay: EasypayFeature) => {
    const updatedCompanyFeatures = {
      ...selectedClientFeatures,
      easypay,
    };

    setSelectedClientFeatures(updatedCompanyFeatures);

    await FeatureService.update(updatedCompanyFeatures);

    dispatchNotification();
  };

  return (
    <Drawer
      destroyOnClose={true}
      maskClosable={false}
      onClose={handleClosePage}
      open={isPageOpen}
      placement="right"
      size='large'
      title={'Funcionalidades - ' + companyName}
      footer={<Button
        danger
        onClick={() => {
          handleClosePage();
        }}
      >
        Fechar
      </Button>}
    >
      {isShowMenuOptionsPage ? (
        <NewMenuOption
          featureId={selectedClientFeatures?._id}
          selectedFeaturesMenuOptions={selectedFeaturesMenuOptions}
          handleMenuOptionsChange={handleMenuOptionsChange}
          handleShowMenuOptionsPage={handleShowMenuOptionsPage}
        />
      ) : (isLoading || isFetchingCompanyFeatures) ?
        (
          <Skeleton />
        ) : (
          <Form layout="vertical">
            <CustomerMenuCurrency companyId={companyId} />

            <Timezone companyId={companyId} />

            <MapQuestApiKey companyId={companyId} />

            <FeaturesContent
              companyId={companyId}
              features={selectedClientFeatures}
              handleChange={handleCheckboxChange}
              handleWhatsappChanges={handleWhatsappChanges}
              handleMenuOptionDeleteAction={handleMenuOptionDeleteAction}
              handleMenuOptionEditAction={handleMenuOptionEditAction}
              handleShowMenuOptionsPage={handleShowMenuOptionsPage}
              handleMenuOptionsUpdateCheckShouldBeHidden={handleMenuOptionsUpdateCheckShouldBeHidden}
              onPaymentEasypayChanged={onPaymentEasypayChanged}
            />
          </Form>
        )}
    </Drawer>
  );
};
