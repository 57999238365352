import React, { useMemo, useState } from 'react';
import SlidingPanel from 'react-sliding-side-panel';

import { ModalHeadLess } from '../../../../../components/ModalHeadless';

import { isMobile } from '../../../../../utils/Util';
import { CompanyFeatures } from '../../../../../models/CompanyFeatures';

import { SidePanelContent } from '../../../../../common-styles';
import { Container, OkButton, Title, Subtitle } from './styles';
import ScheduleController from '../../ScheduleController';

interface ClosedStoreProps {
  companyFeatures: CompanyFeatures;
  dismissPage(): void;
};

export const ClosedStore = ({
  companyFeatures,
  dismissPage,
}: ClosedStoreProps) => {
  const [isOpen, setOpen] = useState(true);
  const { openingHours, isEstablishmentOpen } = companyFeatures

  const isMobileDevice = useMemo(() => {
    return isMobile.any();
  }, []);

  const handleClosePage = () => {
    setOpen(false);
    dismissPage();
  };

  const titlePage = useMemo(() => {
    const controller = new ScheduleController();
    const closedReason = controller.getClosedReason(openingHours);

    if (!isEstablishmentOpen || closedReason === null) {
      return 'Loja fechada';
    } else {
      return `Aceitamos pedidos a partir das ${closedReason}`;
    }
  }, [openingHours, isEstablishmentOpen]);

  function bodyContent() {
    return (
      <Container>
        <Title isMobile={!!isMobileDevice}>{titlePage}</Title>
        <Subtitle isMobile={!!isMobileDevice}>
          Continue a verificar os nossos produtos e retorne quando a loja
          estiver aberta.
        </Subtitle>

        <OkButton onClick={handleClosePage}>
          <div>
            <span>Ok</span>
          </div>
        </OkButton>
      </Container>
    );
  }

  return isMobileDevice ? (
    <SlidingPanel
      type={'bottom'}
      isOpen={isOpen}
      backdropClicked={handleClosePage}
      size={30}
    >
      <SidePanelContent>{bodyContent()}</SidePanelContent>
    </SlidingPanel>
  ) : (
    <ModalHeadLess isOpen={isOpen} toggle={handleClosePage} bgModal={'white'}>
      {bodyContent()}
    </ModalHeadLess>
  );
};

