import React, { useMemo } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';

import { WhatsappOrder } from '../../../../../models/DataResponse';
import { Role } from '../../../../../models/Enum';
import { getOrderStatusData } from '../../../../../models/OrderStatus';

import { CASH_MONEY } from '../../../../../utils/Constants';
import { getHoursByTimezone } from '../../../../../utils/dateTime';
import { getTotalFromOrder } from '../../../../../utils/OrderUtil';
import formatCurrency from '../../../../../utils/formatCurrency';

import { RoundTag } from '../../../../../common-styles';
import { BreakPoints, useBreakpoint, useCompanyFeatures, useListCompaniesForCustomers, useLoggedUser } from '../../../../../hooks';
import { ProductComplementsDescription } from '../../../../../components/ProductOrderTable/components/ProductComplementsDescription';
import { OrderTableActions } from './components/TableActions';

interface TableOrdersProps {
  companyId: string;
  currency: string;
  handleItemClick(order: WhatsappOrder): void;
  orders: WhatsappOrder[];
}

export const TableOrders = ({
  companyId,
  currency,
  handleItemClick,
  orders,
}: TableOrdersProps) => {

  const { companyFeatures, isLoading } = useCompanyFeatures(companyId);
  const { data: companies } = useListCompaniesForCustomers();
  const { userLogged: { user: { role } } } = useLoggedUser();
  const { breakPnt } = useBreakpoint();

  const getCompanyName = (id: string) => companies?.find(item => item._id === id)?.fantasyName;

  const hasAddressAvailable = useMemo(() => orders.some((item) => item.client.address), [orders]);

  const columns = [
    {
      title: 'Status',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => {
        const { label, color } = getOrderStatusData(order.status);
        return (
          <RoundTag key={order._id} color={color}>
            {label}
          </RoundTag>
        );
      },
    },
    {
      title: 'Empresa',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => getCompanyName(order.companyId)
    },
    {
      title: 'N° Pedido',
      dataIndex: 'orderId',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
    },
    {
      title: 'Hora do pedido',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => getHoursByTimezone(order.createdAt, companyFeatures?.timezone)
    },
    {
      title: 'Nome',
      dataIndex: ['client', 'name'],
      width: breakPnt === BreakPoints.LG ? 100 : 70,
    },
    {
      title: 'Telefone',
      dataIndex: ['client', 'phone'],
      width: breakPnt === BreakPoints.LG ? 100 : 70,
    },
    {
      title: 'Endereço',
      dataIndex: ['client', 'address'],
      width: breakPnt === BreakPoints.LG ? 150 : 100,
      visible: hasAddressAvailable,
    },
    {
      title: 'Total',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => getTotalFromOrder(currency, order).totalFormatted
    },
    {
      title: 'Forma de pagamento',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => {
        return (
          <div key={order._id}>
            <p>{order.client.paymentType}</p>
            {order.client.paymentType === CASH_MONEY &&
              order.client.moneyChange && (
                <p>
                  Troco: $
                  {formatCurrency(Number(order.client.moneyChange), currency)}
                </p>
              )}
          </div>
        );
      },
    },
    {
      title: 'Produtos',
      width: breakPnt === BreakPoints.LG ? 100 : 70,
      render: (_: any, order: WhatsappOrder) => {
        const productsSize = order.products.length - 1;
        return order.products.map((product, index) => {
          return (
            <ProductComplementsDescription
              lastProduct={productsSize === index}
              product={product}
              showComplement={role === Role.KITCHEN}
              showComplementTitle={companyFeatures?.sellsConfiguration?.orderComplementExhibition?.showTitle}
            />
          );
        });
      },
    },
    {
      title: 'Ações',
      fixed: 'right',
      width: breakPnt === BreakPoints.LG ? 100 : 50,
      render: (_: any, order: WhatsappOrder) => <OrderTableActions
        handleItemClick={handleItemClick}
        order={order}
      />,
    },
  ];

  return (
    <Table
      bordered
      columns={columns as ColumnsType<WhatsappOrder>}
      dataSource={orders}
      loading={isLoading}
      pagination={false}
      scroll={{ x: true }}
    />
  );
};
