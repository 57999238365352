import React, { useState } from 'react';
import noConnection from '../../assets/images/no_connection.svg';
import { ModalPage } from '../Modal';
import { ButtonTryAgain, Container, Image } from './styles';

interface NoConnectionProps {
	onClose(): void;
	onRetry(): void;
}

export const NoConnection = ({ onClose, onRetry }: NoConnectionProps) => {
	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false)
		onRetry();
		onClose();
	};

	return (
		<ModalPage
			bgModal="white"
			color="black"
			isOpen={open}
			toggle={handleClose}
		>
			<Container>
				<Image src={noConnection} alt="Imagem informando a falta de conexão" />
				<h2>Sem Internet</h2>
				<h4>Verifique se você está conectado a uma Wifi ou dados móveis e tente novamente!</h4>
				<ButtonTryAgain onClick={handleClose}>Tentar novamente</ButtonTryAgain>
			</Container>
		</ModalPage>
	);
}