import React from 'react';
import { Button, Drawer } from 'antd';

interface PaymentCardIframeProps {
  onClose: () => void;
  url: string;
}

export const PaymentCardIframe = ({ onClose, url }: PaymentCardIframeProps) => {
  return (
    <Drawer
      closeIcon={null}
      destroyOnClose={true}
      footer={<Button type='primary' onClick={onClose}>Finalizar</Button>}
      height={'100%'}
      maskClosable={false}
      open={true}
      placement="bottom"
      size="large"
    >
      <iframe
        src={url}
        title="Pagamento via cartão de crédito/débito"
        style={{ height: '100vh', width: '100%' }} />
    </Drawer>
  );
};
