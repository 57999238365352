import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useEffectOnce } from "usehooks-ts";
import { usePageVisibility } from "../../../../hooks";
import { LocalStorageService, PAYMENT_EASYPAY } from "../../../../services/LocalStorageService";
import { useEasypayPaymentStatus } from "./useEasypayPaymentStatus";
import { MenuConfiguration } from "../../../../models/DataResponse";
import { useOrderSendMessage } from "./useOrderSendMessage";
import { EasypayPaymentResponse } from "../components/OrdersViaWhatsapp/models/EasypayPayment";
import { usePaymentProcess } from "../../../../hooks/usePaymentProcess";

const handlePaymentError = (onClickCallback: any) => {
  confirmAlert({
    title: 'Erro no pagamento',
    message: 'Ocorreu um erro ao processar o pagamento. Por favor, tente novamente.',
    buttons: [
      {
        label: 'Ok',
        onClick: () => onClickCallback(),
      },
    ],
    closeOnClickOutside: false,
  });
}

export const useCheckPendingPaymentProcess = (slug: string | undefined, menuConfiguration: MenuConfiguration | undefined) => {
  const [isPaymentPending, setIsPaymentPending] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>();
  const isPageVisible = usePageVisibility();

  const { data: paymentStatus } = useEasypayPaymentStatus({ paymentId, slug });
  const { setOrder: setOrderPayment } = useOrderSendMessage({ companySlug: slug, menuConfiguration });

  const hasPaymentPending = isPaymentPending && menuConfiguration?.companyFeatures.isEasypayEnabled;

  const { setIsWaitingPayment } = usePaymentProcess((isSuccess: boolean, paymentPayload: EasypayPaymentResponse | null) => {
    if (isSuccess) {
      setIsPaymentPending(false);
      setOrderPayment(paymentPayload?.order);
    }
    else {
      handlePaymentError(() => {
        LocalStorageService.clear(PAYMENT_EASYPAY);
        setIsPaymentPending(false);
        setIsWaitingPayment(false);
      });
    }
  });

  useEffectOnce(() => {
    const paymentPayload = LocalStorageService.get<EasypayPaymentResponse>(PAYMENT_EASYPAY);

    if (isPageVisible && paymentPayload?.id) {
      setPaymentId(paymentPayload?.id);
    }
  });

  useEffect(() => {
    const status = paymentStatus?.data?.payment_status;

    if (status === 'pending') {
      confirmAlert({
        title: 'Pagamento pendente',
        message: 'Identificamos que o seu pagamento está pendente. Por favor, aguarde alguns instantes.',
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              setIsWaitingPayment(true);
              setIsPaymentPending(true);
            },
          },
        ],
        closeOnClickOutside: false,
      });
    } else if (status === 'error' || status === 'failed') {
      handlePaymentError(() => {
        LocalStorageService.clear(PAYMENT_EASYPAY);
        setIsPaymentPending(false);
        setIsWaitingPayment(false);
      });
    } else {
      const paymentPayload = LocalStorageService.get<EasypayPaymentResponse>(PAYMENT_EASYPAY);
      setOrderPayment(paymentPayload?.order);
      setIsPaymentPending(false);
      setIsWaitingPayment(false);
    }

  }, [paymentStatus, setOrderPayment, setIsWaitingPayment]);

  return {
    hasPaymentPending
  }
}