import { Role } from '../models/Enum';
import { ProductStockResponse } from '../models/ProductStock';
import { useFetch } from './useFetch';
import { useLoggedUser } from './useLoggedUser';

const LIST_PRODUCT_STOCK = 'listProductsOutOfStock';

export const useListProductsOutOfStock = (companyId?: string) => {
  const { data: loggedUser } = useLoggedUser();
  
  const { data, ...rest } = useFetch<ProductStockResponse>({
    enabled: companyId !== undefined && loggedUser?.user?.role === Role.USER,
    headers: { company: companyId },
    queryKey: LIST_PRODUCT_STOCK,
    url: 'api/catalog/listProductsOutOfStock',
  });

  return { data, ...rest };
};
