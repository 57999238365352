import { ProductCatalog } from '../models/ProductCatalog';
import { useFetch } from './useFetch';

const CLIENT_PRODUCTS = 'clientProducts';

export const useClientProducts = (companyId: string | undefined) => {
  const { data: products, isFetching } = useFetch<ProductCatalog[]>({
    enabled: !!companyId,
    isPublicAPI: true,
    queryKey: CLIENT_PRODUCTS,
    url: `api/boot/products/${companyId}`,
  });

  return { products, isFetching };
};
