import React, { useState } from "react";
import { Form, Input } from "antd";
import { useFeatures } from "../../../../../../../hooks";
import { NotificationDispatcher } from "../../../../../../../components/Notification";
import { FeatureService } from "../../FeatureService";

interface MapQuestApiKeyProps {
  companyId: string;
}

export const MapQuestApiKey = ({ companyId }: MapQuestApiKeyProps) => {
  const [apiKey, setApiKey] = useState<string>();

  const { data: features } = useFeatures(companyId);

  const handleChange = async (value: string) => {
    setApiKey(value);

    if (features) {
      await FeatureService.update({ ...features, apiKeys: { ...features.apiKeys, mapQuest: value } });
      NotificationDispatcher({
        message: 'Operação realizada com sucesso',
      });
    }
  };

  return (
    <Form.Item
      label={<label className="label-input-field">Map Quest API key</label>}
    >
      <Input
        id="mapQuest"
        placeholder="Insira API key do MapQuest"
        style={{ height: '40px' }}
        onBlur={(e) => handleChange(e.target.value)}
        onChange={(e) => setApiKey(e.target.value)}
        value={apiKey ?? features?.apiKeys?.mapQuest ?? ''}
      />
    </Form.Item>
  );
}