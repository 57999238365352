import React, { useState, useEffect } from 'react';

import { Button, Drawer, Form, Radio, Select, Space, Tabs } from 'antd';

import { FormInputField } from '../../../../../components/FormInputField';
import { FormRadioGroup } from '../../../../../components/FormRadioGroup';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../components/Notification';
import { RichText } from '../../../../../components/RichText';

import { Category } from '../../../../../models/DataResponse';
import { Features } from '../../../../../models/Features';
import { CompanyActivityLogService } from '../../../../../services/CompanyActivityLogService';
import { FormHelperText } from '../../../../../common-styles';
import { FormLayout } from '../../../../../utils/Styles';
import { useCategory } from '../../../../../hooks';

interface CreateOrUpdateCategoryProps {
  company: string;
  features?: Features;
  isPageOpen: boolean;
  onClose(): void;
  selectedCategory?: Category;
};

enum Tab {
  DETAILS = 'Detalhes',
  TABS = 'Opção de Abas',
}

const OPTION_ALL = {
  key: 'all',
  value: 'Todos',
};

const categoryTypes = [
  { label: 'Categoria', value: true },
  { label: 'Informativo', value: false },
];

const { TabPane } = Tabs;

const TITLE_CHARACTER_LIMIT = 100;

export const CreateOrUpdateCategory = ({
  company,
  features,
  isPageOpen,
  onClose,
  selectedCategory,
}: CreateOrUpdateCategoryProps) => {
  const [isEnabled, setEnabled] = useState<boolean>(true);
  const [isSectionType, setSectionType] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState(Tab.DETAILS);
  const [description, setDescription] = useState<string>();

  const [selectedMenuOptions, setSelectedMenuOptions] = useState<string[]>([
    OPTION_ALL.value,
  ]);

  const { isLoadingCreate, isLoadingUpdate, create, update } = useCategory();

  useEffect(() => {
    if (selectedCategory && selectedCategory !== undefined) {
      setDescription(selectedCategory.description);
      setSectionType(selectedCategory.isSectionType);
      setEnabled(selectedCategory.isEnabled);

      if (selectedCategory.tabsAssociated?.length > 0) {
        const options = selectedCategory.tabsAssociated.map((option) => {
          if (OPTION_ALL.key === option) {
            return OPTION_ALL.value;
          }

          return (
            features?.menuOptions.find((item) => item._id === option)?.name ??
            ''
          );
        });

        setSelectedMenuOptions(options);
      }
    }
  }, [selectedCategory, features]);

  const dismissPage = () => {
    onClose();

    // This is stupid, but form.resetFields is not enough, we do have to reset all state, otherwise it will keep the previous data.
    setCurrentTab(Tab.DETAILS);
    setSelectedMenuOptions([OPTION_ALL.value]);
    setDescription('');
    setSectionType(true);
  };

  const successMessage = () => {
    NotificationDispatcher({
      message: 'Operação realizada com sucesso',
    });
  };

  const addOrUpdateCategory = async (values: any) => {
    const formData = {
      ...values,
      isEnabled,
      description,
      isSectionType,
    } as Category;

    if (
      features?.menuOptions &&
      features?.menuOptions.length > 0 &&
      selectedMenuOptions &&
      selectedMenuOptions.length > 0
    ) {
      formData.tabsAssociated = selectedMenuOptions.map((tabName) => {
        const foundOption = features?.menuOptions.find(
          (option) => option.name === tabName
        );

        if (tabName === 'Todos') {
          tabName = 'all';
        }
        return foundOption ? `${foundOption._id}` : `${tabName}`;
      });
    } else {
      formData.tabsAssociated = [];
    }

    if (selectedCategory) {
      update(
        {
          ...formData,
          company: selectedCategory.company,
          _id: selectedCategory._id,
        },
        {
          onSuccess: () => {
            CompanyActivityLogService.register({ action: `Categoria <strong>${selectedCategory.category}</strong> atualizada`, actionArea: 'Categoria', extra: selectedCategory._id });
            successMessage();
            dismissPage();
          },
          onError: () =>
            NotificationDispatcher({
              message: 'Erro ao tentar atualizar categoria',
              type: NotificationType.ERROR,
            }),
        }
      );
    } else {
      create(
        { ...formData, company },
        {
          onSuccess: (id: string) => {
            CompanyActivityLogService.register({ action: `Nova Categoria: <strong>${formData.category}</strong>`, actionArea: 'Categoria', extra: id });
            successMessage();
            dismissPage();
          },
          onError: () =>
            NotificationDispatcher({
              message: 'Erro ao tentar criar categoria',
              type: NotificationType.ERROR,
            }),
        }
      );
    }
  };

  const dropdownListHandler = (options: string[]) => {
    options = options
      .filter((val) => !val || val !== OPTION_ALL.key)
      .filter((option) => {
        if (
          (options.includes(OPTION_ALL.value) &&
            options[0] !== OPTION_ALL.value) ||
          options[options.length - 1] === OPTION_ALL.value
        ) {
          return option === OPTION_ALL.value;
        }
        return option !== OPTION_ALL.value;
      });

    setSelectedMenuOptions(options);
  };

  const onFinishFailed = () => {
    setCurrentTab(Tab.DETAILS);
  };

  const onTabChanged = (key: string) => setCurrentTab(key as Tab);

  return (
    <Drawer
      title={
        selectedCategory === undefined || !selectedCategory
          ? 'Nova Categoria'
          : selectedCategory.category
      }
      onClose={dismissPage}
      destroyOnClose={true}
      maskClosable={false}
      placement="right"
      open={isPageOpen}
      size="large"
    >
      <Form
        {...FormLayout}
        layout="vertical"
        initialValues={selectedCategory}
        onFinish={addOrUpdateCategory}
        onFinishFailed={onFinishFailed}
      >
        <Tabs
          size="large"
          activeKey={currentTab.toString()}
          onChange={onTabChanged}
        >
          <TabPane tab={Tab.DETAILS} key={Tab.DETAILS}>
            <FormRadioGroup
              label="Disponibilidade"
              propertyId="isEnabled"
              defaultValue={selectedCategory?.isEnabled ?? true}
              handleOnChange={(checked) => setEnabled(checked)}
            />

            <Form.Item
              label={<label className="label-input-field">Tipo</label>}
              name="isSectionType"
            >
              <Radio.Group
                options={categoryTypes}
                value={isSectionType}
                defaultValue={isSectionType}
                onChange={(e) => setSectionType(e.target.value)}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>

            <FormInputField
              label="Categoria"
              name="category"
              placeholder="Categoria"
              maxLength={TITLE_CHARACTER_LIMIT}
              rules={[
                {
                  required: true,
                  message: 'Campo obrigatório',
                },
              ]}
            />

            <Form.Item
              label={<label className="label-input-field">Descrição</label>}
              name="description"
            >
              <RichText
                initialValue={selectedCategory?.description || ''}
                handleInputChange={(text: string) => setDescription(text)}
              />
            </Form.Item>
          </TabPane>

          {features?.menuOptions && features?.menuOptions.length > 0 && (
            <TabPane tab={Tab.TABS} key={Tab.TABS}>
              <Form.Item
                label={<label className="label-input-field">Abas</label>}
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                ]}
              >
                <Select
                  id="tabsAssociated"
                  mode="multiple"
                  style={{ width: '50%' }}
                  placeholder="Selecione a opção de aba"
                  value={selectedMenuOptions}
                  onChange={dropdownListHandler}
                >
                  <Select.Option
                    key={OPTION_ALL.value}
                    value={OPTION_ALL.value}
                  >
                    {OPTION_ALL.value}
                  </Select.Option>

                  {features?.menuOptions?.map((option) => (
                    <Select.Option key={option.name} value={option.name}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <FormHelperText style={{ marginTop: '10px' }}>
                A opção Todos, significa que a categoria será exibida em todas
                as abas.
              </FormHelperText>
            </TabPane>
          )}
        </Tabs>

        <br />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Space>
            <Button
              danger
              style={{ marginRight: '10px' }}
              onClick={dismissPage}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Cancelar
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingCreate || isLoadingUpdate}
            >
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />{' '}
              Salvar
            </Button>
          </Space>
        </div>
      </Form>
    </Drawer>
  );
};
