import { useFetch } from './useFetch';
import { Timezone } from '../models/Timezone';

const TIMEZONES = 'timezones';

export const useTimezones = () => {
  const { data: timezones, isFetching: isFetchingTimezones } = useFetch<
    Timezone[]
  >({
    queryKey: TIMEZONES,
    url: 'api/timezone',
  });

  return { timezones, isFetchingTimezones };
};
