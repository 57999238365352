import React, { useCallback, useMemo, useState } from 'react';

import { Button } from 'antd';

import { CategoryTable } from './components/Table';
import { CreateOrUpdateCategory } from './components/CreateOrUpdate';
import { ContentHeader } from '../../../components/ContentHeader';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../components/Notification';

import { useCategory, useCategories, useFeatures, useLoggedUser } from '../../../hooks';

import { MenuOption } from '../../../models/MenuOption';
import { Category } from '../../../models/DataResponse';

import { CategoryFilters } from './components/Filters';
import { removeAccents } from '../../../utils/Util';
import { Role } from '../../../models/Enum';
import { DEFAULT_SELECT_COMPANY } from '../../../utils/Constants';

const CategoryPage = () => {
  const [isPageOpen, setPageOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Category>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredCategories, setFilteredCategories] = useState<Category[] | null>(null);

  const { userLogged: { user: { company, companyAssociation, role } } } = useLoggedUser();
  const [selectedCompanyId, setSelectedCompanyId] = useState(DEFAULT_SELECT_COMPANY);

  const companyId = useMemo(() => {
    if (role === Role.USER && company) {
      return company;
    } else if (role === Role.KITCHEN && companyAssociation.length === 1) {
      return companyAssociation[0].companyId;
    }

    return selectedCompanyId;
  }, [company, companyAssociation, role, selectedCompanyId]);

  const { data: categories, isFetching } = useCategories(companyId);
  const { updateListPosition } = useCategory();
  const { data: features, isFetching: isFetchingFeatures } = useFeatures(companyId);
  const isLoading = isFetching || isFetchingFeatures;

  const handleRowClick = (selectedItem: Category) => {
    setSelectedItem(selectedItem);
    setPageOpen(true);
  };

  const handleAddOrUpdate = () => {
    setSelectedItem(undefined);
    setPageOpen(true);
  };

  const onClosePage = () => setPageOpen(false);

  async function updateListPositions(list: Category[]) {
    updateListPosition([...list], {
      onSuccess: () => {
        NotificationDispatcher({
          message: 'Posição atualizada',
        });
      },
      onError: () => {
        NotificationDispatcher({
          message: 'Erro ao tentar atualizar lista',
          type: NotificationType.ERROR,
        });
      },
    });
  }

  const onSearchFiltersChanged = useCallback((searchFilter: string) => {
    setSearchInput(searchFilter);
  }, []);

  const onFiltersCategoryChanged = useCallback((categories: Category[] | null) => {
    setFilteredCategories(categories);
  }, []);

  const filteredCategoriesByInputSearch = useMemo(() => {
    const input = removeAccents(searchInput.toLowerCase());
    const list = filteredCategories ?? categories;

    return list?.filter((category) => {
      return (
        category?.category?.toLowerCase().includes(input) ||
        category?.description?.toLowerCase().includes(input)
      );
    });
  }, [categories, filteredCategories, searchInput]);

  const listOfCategories = filteredCategoriesByInputSearch ?? categories ?? [];

  return (
    <>
      <ContentHeader title={MenuOption.CATEGORIES} />
        
      <CategoryFilters
        categories={categories}
        companyId={companyId}
        features={features}
        onCompanySelect={(companyId) => setSelectedCompanyId(companyId)}
        setFilteredCategories={onFiltersCategoryChanged}
        setSearchInputFilter={onSearchFiltersChanged}
      />

      <Button type="primary" onClick={() => handleAddOrUpdate()} style={{ marginBottom: '10px' }}>
        <i
          className="fa fa-plus-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Adicionar
      </Button>

      <CategoryTable
        categories={filteredCategories ?? listOfCategories}
        onTableChanged={updateListPositions}
        features={features}
        isLoading={isLoading}
        handleRowClick={(category) => handleRowClick(category)}
      />

      <CreateOrUpdateCategory
        isPageOpen={isPageOpen}
        onClose={onClosePage}
        selectedCategory={selectedItem}
        company={companyId}
        features={features}
      />
    </>
  );
};

export default CategoryPage;
