import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import {
  Container,
  Controllers,
  Title,
  TitleContainer,
  ToggleButtonContainer,
} from './styles';

type BoxWithLabelProps = {
  title?: string;
  showContent?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
};

const BoxWithLabel: React.FC<BoxWithLabelProps> = ({
  showContent,
  title,
  style,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(showContent ?? true);

  const handleCollapseContent = () => setIsOpen(!isOpen);

  return (
    <Container style={style}>
      <Box
        component="span"
        display="block"
        justifyContent="center"
        p={1}
        m={1}
        border={1}
        borderColor="#e4e4e4"
      >
        <TitleContainer onClick={handleCollapseContent}>
          <ToggleButtonContainer>
            {isOpen ? <UpOutlined /> : <DownOutlined />}
          </ToggleButtonContainer>

          {title && <Title>{title}</Title>}
        </TitleContainer>

        {isOpen && <Controllers id="childrenContainer">{children}</Controllers>}
      </Box>
    </Container>
  );
};

export default BoxWithLabel;
