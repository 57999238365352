import React from "react";
import Skeleton from "react-loading-skeleton";

export const ImageSkeleton = () => {
  return (
    <>
      <Skeleton />

      <Skeleton height={300} />
    </>
  );
}