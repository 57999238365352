import { useFetch } from '../../../../hooks/useFetch';
import { PendingRegistration } from '../models/PendingRegistration';

const PENDING_REGISTRATION = 'pendingRegistration';

export const usePendingRegistration = (uuid: string | undefined) => {
  const { data, ...rest } = useFetch<PendingRegistration>({
    enabled: !!uuid,
    queryKey: [PENDING_REGISTRATION],
    url: `api/prospection/${uuid}`,
  });

  return { data, ...rest };
};
