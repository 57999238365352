import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .button-close {
    margin-top: 20px;
    align-self: end;
  }
`;

export const CustomTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: #3f3e3e;
  
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
    word-wrap: break-word;
    color: #3f3e3e;
  }
  .secondary-td {
    width: 120px;
  }
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #3f3e3e;
    font-size: 16px;
    text-align: center;
  }
`;
