import React from 'react';
import shortid from 'shortid';

import ProductOrderItem from './ProductOrderItem';

import { Coupon } from '../../../../../models/Coupon';
import { ProductMenu } from '../../../../../models/ProductCatalog';
import { SellsConfiguration } from '../../../../../models/SellsConfiguration';
import { DeliveryFee } from '../../../../../models/DeliveryFee';

import formatCurrency from '../../../../../utils/formatCurrency';

import { Separator } from '../../../../../common-styles';
import { ProductRowContainer } from './styles';
import { CouponCode } from './components/CouponCode';
import { useProductCost } from '../../../../../hooks';
import { LabelAndPrice } from '../LabelAndPrice';

interface CurrentOrderProps {
  deliveryFee?: DeliveryFee;
  coupon?: Coupon;
  handleDeleteAction(product: ProductMenu): void;
  handleEditAction(isUpdate: boolean, product: ProductMenu): void;
  hideTotal?: boolean;
  products: ProductMenu[];
  sellsConfiguration: SellsConfiguration;
};

export const CurrentOrder = ({
  deliveryFee,
  coupon,
  handleDeleteAction,
  handleEditAction,
  hideTotal,
  products,
  sellsConfiguration,
}: CurrentOrderProps) => {
  const { currency, orderComplementExhibition, extraFees } = sellsConfiguration;
  const { subtotal, subtotalFormatted, totalCostFormatted } = useProductCost({
    currency,
    coupon,
    deliveryFee,
    extraFees,
    products,
  });

  return (
    <>
      {products.map((product: ProductMenu) => (
        <ProductRowContainer key={shortid.generate() + product._id}>
          <ProductOrderItem
            key={shortid.generate() + product._id}
            product={product}
            currency={currency}
            orderComplementExhibition={orderComplementExhibition}
            handleDeleteAction={() => handleDeleteAction(product)}
            handleEditAction={() => handleEditAction(true, product)}
          />

          <Separator key={shortid.generate()} />
        </ProductRowContainer>
      ))}

      {deliveryFee ? (
        <>
          <LabelAndPrice
            label="Subtotal"
            price={subtotalFormatted}
          />

          {coupon && (
            <CouponCode
              coupon={coupon}
              subtotal={subtotal}
              currency={currency}
            />
          )}

          <LabelAndPrice
            label="Taxa de Entrega"
            price={formatCurrency(deliveryFee?.price ?? 0, currency)}
          />
        </>
      ) : (
        <>
          {coupon && (
            <>
              <LabelAndPrice
                label="Subtotal"
                price={subtotalFormatted}
              />

              <CouponCode
                coupon={coupon}
                subtotal={subtotal}
                currency={currency}
              />
            </>
          )}
        </>
      )}

      {!hideTotal && extraFees.filter(item => item.isEnabled).map((extraFee) => (
        <LabelAndPrice
          key={extraFee._id}
          label={extraFee.label}
          price={formatCurrency(extraFee.value, currency)}
          tooltip={extraFee.tooltip}
        />
      ))}

      <LabelAndPrice
        isBold
        label={hideTotal ? 'Subtotal' : 'Total'}
        price={hideTotal ? subtotalFormatted : totalCostFormatted}
      />
    </>
  );
};
