import styled from 'styled-components';

export const Title = styled.h2`
  text-align: center;
`;

export const Subtitle = styled.h4`
  color: black;
  margin-top: 12px;
`;

export const ProductName = styled.li`
  color: black;
  margin-left: 16px;
`;