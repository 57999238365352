import React from 'react';
import { Skeleton } from 'antd';

import { MenuOption } from '../../../../models/MenuOption';

import { ContentHeader } from '../../../../components/ContentHeader';
import { DefaultTexts } from './components/DefaultTexts';

import { useCompanyFeatures, useLoggedUser } from '../../../../hooks';

export const SettingsDefaultTextsPage = () => {
  const { userLogged: { user: { company } } } = useLoggedUser();
  const { companyFeatures, isFetching } = useCompanyFeatures(company);
  const isLoading = (isFetching || companyFeatures === undefined);

  return (
    <>
      <ContentHeader title={MenuOption.SETTINGS_DEFAULT_TEXT} />

      {isLoading ? <Skeleton /> : <DefaultTexts companyFeatures={companyFeatures} />}
    </>
  );
};
