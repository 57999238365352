import { useCallback } from "react";
import { useUpdateCompanyFeatures } from "../../../../../../../hooks";
import { ExtraFee } from "../../../../../../../models/SellsConfiguration";
import { CompanyFeatures } from "../../../../../../../models/CompanyFeatures";
import { useExtraFees } from "./useExtraFees";

interface UseRemoveProps {
  companyFeatures: CompanyFeatures;
}

export const useRemoveFee = ({ companyFeatures }: UseRemoveProps) => {
  const { isLoading: isLoadingUpdateCompanyFeatures, update } = useUpdateCompanyFeatures();
  const { data: fees, isLoading: isLoadingExtraFees } = useExtraFees(companyFeatures.companyId);
  const isLoading = isLoadingUpdateCompanyFeatures || isLoadingExtraFees;

  const removeFee = useCallback((extraFee: ExtraFee, callbackResult: Function) => {
    if (!fees) return;

    const index = fees.findIndex((item) => item._id === extraFee._id);
    if (index !== -1) {
      const list = [...fees];
      list.splice(index, 1);
      companyFeatures.sellsConfiguration.extraFees = list;
    }

    update(
      { ...companyFeatures },
      {
        onSuccess: () => callbackResult(true),
        onError: () => callbackResult(false)
      },
    );
  }, [companyFeatures, fees, update]);

  return {
    isLoading,
    removeFee,
  }
};