import React from 'react';
import { Button } from 'antd';
import { TableActionLabel } from '../../common-styles';
import { BreakPoints, useBreakpoint } from '../../hooks';

interface TableGenericActionProps {
  danger?: boolean;
  dashed?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  onClick(): void;
  style?: React.CSSProperties;
}

export const TableGenericAction = ({ danger, dashed, disabled, icon, onClick, label, style }: TableGenericActionProps) => {
  const { breakPnt } = useBreakpoint();

  const canRenderIcon = () => breakPnt === BreakPoints.LG;

  return (
    <Button
      disabled={disabled}
      danger={danger}
      onClick={onClick}
      style={{ ...style, width: '100%' }}
      type={dashed ? 'dashed' : 'default'}
    >
      {canRenderIcon() && icon} {label && <TableActionLabel>{label}</TableActionLabel>}
    </Button>
  );
};
