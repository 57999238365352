import React, { useState } from 'react';
import { FormInputField } from '../../../../../../components/FormInputField';
import { FormRadioGroup } from '../../../../../../components/FormRadioGroup';
import { Stock } from '../../../../../../models/ProductStock';

interface StockProps {
  stock?: Stock;
}

export const ProductStock = ({ stock }: StockProps) => {
  const [isEnabled, setEnabled] = useState<boolean>(stock ? stock.isEnabled : false);

  return (
    <>
      <FormRadioGroup
        defaultValue={isEnabled}
        handleOnChange={(checked) => setEnabled(checked)}
        label="Controle de estoque"
        namePath={['stock', 'isEnabled']}
      />

      <FormInputField
        label="Quantidade"
        namePath={['stock', 'quantity']}
        type="number"
      />

      <FormInputField
        help='Ao configurar um valor mínimo de alerta, o sistema irá notificar quando este produto atingir o valor configurado. Ideal para ser previamente notificado e ajudar na gestão.'
        label="Qtd mínima de alerta"
        namePath={['stock', 'minQuantityAlert']}
        type="number"
      />
    </>
  );
}