import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 20px;
  margin: 20px 50px;
`;

export const ImageHolder = styled.img`
  width: 200px;
  height: 200px;
  object-fit: scale-down;

  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;

  cursor: pointer;

  :hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  @media (max-width: 500px) {
    width: 130px;
    height: 100px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  margin: auto;
  cursor: pointer;
  background-color: #678f8e;
  justify-content: center;
  align-items: center;

  .logo-specs {
    width: 60%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const TitlePage = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  > h1 {
    font-weight: 800;
    font-size: 30px;
    text-align: center;
    font-family: Ubuntu;
    margin-top: 20px;
    color: #6c6c80;

    &::after {
      content: '';
      display: block;
      width: 55px;
      text-align: center;
      border-bottom: 10px solid #6c6c80;
    }
  }
`;

export const SubtitlePage = styled.p`
  font-weight: 800;
  font-size: 20px;
  text-align: center;
  font-family: Ubuntu;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100px;
  margin-top: 40px;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
`;

export const Form = styled.div`
  padding: 0px 64px;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.fieldset`
  margin-top: 64px;
  min-inline-size: auto;
  border: 0;
`;

export const FormLegend = styled.legend`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  > small {
    text-align: center;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin-top: 10px;
  }
`;

export const FieldGroup = styled.div`
  flex: 1;
  display: flex;
`;

export const Field = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  padding: 0px 10px;

  > input {
    flex: 1;
    background: #f0f0f5;
    border-radius: 8px;
    border: 0;
    padding: 16px 24px;
    font-size: 16px;
    color: #6c6c80;
  }

  > textarea {
    flex: 1;
    background: #f0f0f5;
    border-radius: 8px;
    border: 0;
    padding: 16px 24px;
    font-size: 16px;
    color: #6c6c80;
  }
`;

export const SubmitButton = styled.button`
  width: 260px;
  height: 56px;
  background: #2fb86e;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  align-self: flex-end;
  margin-top: 40px;
  transition: background-color 0.2s;
  cursor: pointer;

  :hover {
    background: #678f8e;
  }
`;

export const RequestDemoButton = styled.button`
  width: 260px;
  height: 56px;
  background: #2fb86e;
  border-radius: 40px;
  color: #fff;
  font-size: 16px;
  border: 0;
  margin-top: 40px;
  transition: background-color 0.2s;
  cursor: pointer;

  :hover {
    background: #678f8e;
  }
`;

export const ErrorMessage = styled.p`
  text-align: center;
  color: red;
  font-weight: 700;
`;

export const SuccessMessage = styled.p`
  text-align: center;
  color: #678f8e;
  font-weight: 700;
`;
