import React from "react";
import { ContactsOutlined } from '@ant-design/icons';
import { TableGenericAction } from "../../../../../../../../components/TableGenericAction";

interface SelectDriverActionButtonProps {
  onSelectDriver: () => void;
}

export const SelectDriverActionButton = ({ onSelectDriver }: SelectDriverActionButtonProps) => {
  return (
    <TableGenericAction
      dashed
      icon={<ContactsOutlined style={{ fontSize: '18px' }} />}
      label="Selec. Entregador"
      onClick={onSelectDriver}
      style={{
        backgroundColor: '#9f2fb8',
        color: '#fff',
        marginTop: '8px',
      }}
    />
  );
};