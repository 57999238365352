import React from "react";
import { Button, FormInstance, Space } from "antd";

interface FeeButtonsProps {
  formRef: FormInstance<any>;
  isLoading: boolean;
  onClose(): void;
}

export const FeeButtons = ({ formRef, isLoading, onClose }: FeeButtonsProps) => {
  return (
    <Space>
      <Button danger onClick={onClose}>
        <i
          className="fa fa-times"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Cancelar
      </Button>

      <Button type="primary" loading={isLoading} onClick={() => formRef.submit()}>
        <i
          className="fa fa-check-circle"
          aria-hidden="true"
          style={{ marginRight: '10px' }}
        />
        Salvar
      </Button>
    </Space>
  );
};