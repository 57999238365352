import { useMutation } from 'react-query';
import { WhatsappOrder } from './../models/DataResponse';
import { useCallback } from 'react';
import { OrderService } from '../services/OrderService';

type DriverToOder = {
  orderId: string;
  driverId: string;
}

export const useUpdateOrder = () => {
  const mutation = useMutation(
    (order: WhatsappOrder) => OrderService.update(order)
  );

  const mutationAssociateDriver = useMutation(
    (driverToOrder: DriverToOder) => OrderService.associateOrderToDriver(driverToOrder.orderId, driverToOrder.driverId)
  );

  const update = useCallback((order, { onSuccess, onError }) => {
    mutation.mutate(order, {
      onSuccess: (updatedOrder) => {
        onSuccess(updatedOrder);
      },
      onError,
    });
  }, [mutation]
  );

  const associateDriverToOrder = useCallback((orderId, driverId, { onSuccess, onError }) => {
    mutationAssociateDriver.mutate({ orderId, driverId }, {
      onSuccess,
      onError,
    });
  }, [mutationAssociateDriver]
  );

  return {
    associateDriverToOrder,
    update,
  };
};
