import styled from 'styled-components';

interface AddButtonHolderProps {
  withIncrementButton: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  small {
    font-size: 20px;
    font-weight: 500;
    color: #000;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  margin-top: 8px;
  
  small {
    font-size: 20px;
    font-weight: 500;
    color: #1A1919;
  }
`;

export const Title = styled.h2`
  margin-bottom: 0;
  line-height: 1.1;
  color: #1A1919;
`;

export const AddButtonHolder = styled.button<AddButtonHolderProps>`
  height: 50px;
  width: 100%;

  background: #2fb86e;
  border-radius: 4px;

  margin-left: ${(props) => (props.withIncrementButton ? '15px' : '0')};
  padding-left: 10px;
  padding-right: 10px;

  cursor: pointer;
  outline: none;
  box-shadow: none;

  &:disabled {
    background: lightgray;
    cursor: not-allowed;
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  div > span {
    color: white;
    font-weight: 500;
    font-size: 18px;
  }
`;

export const CloseButton = styled.button`
  .close > span:not(.sr-only) {
  /* background-color: transparent; */
  /* color: rgba(0,0,0,.6); */
  /* line-height: 17px; */
  /* height: 1.25rem; */
  width: 1.25rem;
  /* border-radius: 50%; */
  font-size: 35px;
  display: block;
  transition: all 0.15s ease;
  }
`;

export const InputComment = styled.textarea`
  width: 100%;

  margin: 7px 0;
  padding: 10px;

  border-radius: 3px;
  border: 1px solid #dcdcdc;

  resize: none;
`;

export const OrderCommentLabel = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #3f3e3e;

  margin-top: 16px;
  margin-bottom: 6px;
`;

export const ProductDescription = styled.div`
  margin-top: 10px;

  padding: 10px 0;

  p {
    font-size: 16px;
    color: #3f3e3e;
  }
`;

export const ProductPrice = styled.span`
  color: #3f3e3e;
  font-size: 18px;
  font-weight: bold;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  height: 45px;
  
  margin: 20px 0;
  
  > p {
    color: red;
    font-weight: bold;
    text-align: center;
  }
`;