import React, { useEffect, useMemo, useState } from 'react';
import parse from 'html-react-parser';

import { Space, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { MenuOutlined } from '@ant-design/icons';

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { DeleteCategory } from '../Delete';
import { TableEditAction } from '../../../../../components/TableEditAction';

import { Category } from '../../../../../models/DataResponse';
import { Features } from '../../../../../models/Features';
import { useCategory } from '../../../../../hooks/useCategory';
import { NotificationDispatcher } from '../../../../../components/Notification';
import { RoundTag } from '../../../../../common-styles';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerWrapper = SortableContainer((props: any) => (
  <tbody {...props} />
));
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: 'rgb(153, 153, 153)' }} />
));

interface CategoryTableProps {
  isLoading: boolean;
  categories: Category[];
  features?: Features;
  onTableChanged(items: Category[]): void;
  handleRowClick(item: Category): void;
};

export const CategoryTable = ({
  isLoading,
  categories,
  features,
  onTableChanged,
  handleRowClick,
}: CategoryTableProps) => {
  const [dataSource, setDataSource] = useState<Category[]>([]);
  const { update: updateCategory } = useCategory();

  useEffect(() => {
    setDataSource(categories);
  }, [categories]);

  const isExtraMenuOptionAvailable = useMemo(() => {
    return (features?.menuOptions && features?.menuOptions.length > 0) || false;
  }, [features]);

  const hasAnyDescriptionAvailable = useMemo(
    () => categories.filter((item) => item.description).length > 0,
    [categories]
  );

  const hasAnyInformativeSectionAvailable = useMemo(
    () => categories.filter((item) => !item.isSectionType).length > 0,
    [categories]
  );

  const handleAvailabilityOnChange = (checked: boolean, category: Category) => {
    updateCategory(
      { ...category, isEnabled: checked },
      {
        onSuccess: () =>
          NotificationDispatcher({
            message: 'Categoria atualizada com sucesso!',
          }),
        onError: () =>
          NotificationDispatcher({
            message: 'Erro ao atualizar categoria!',
          }),
      }
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      className: 'drag-visible',
      width: "5%",
      align: 'center' as 'center',
      visible: true,
      fixed: 'left',
      render: () => <DragHandle />,
    },
    {
      title: 'Status',
      width: "10%",
      visible: true,
      align: 'center',
      fixed: 'left',
      render: (_: any, category: Category) => (
        <Switch
          key={category._id}
          defaultChecked={category.isEnabled}
          checkedChildren="Ativo"
          unCheckedChildren="Pausado"
          onChange={(isChecked) =>
            handleAvailabilityOnChange(isChecked, category)
          }
        />
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'isSectionType',
      align: 'center' as 'center',
      width: "8%",
      visible: hasAnyInformativeSectionAvailable,
      render: (_: any, category: Category) => {
        const color = category.isSectionType ? 'green' : 'orange';
        return (
          <RoundTag color={color} key={category._id}>
            {category.isSectionType ? 'Categoria' : 'Informativo'}
          </RoundTag>
        );
      },
    },
    {
      title: 'Título',
      dataIndex: 'category',
      visible: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      width: "20%",
      visible: hasAnyDescriptionAvailable,
      render: (text: string | undefined) => {
        const description =
          text !== undefined && text.length > 100
            ? text.trim().substring(0, 100) + '...'
            : text;
        return text && parse(description!!);
      },
    },
    {
      title: 'Aba associada',
      dataIndex: 'tabsAssociated',
      visible: isExtraMenuOptionAvailable,
      width: "10%",
      render: (_: any, category: Category) =>
        category.tabsAssociated
          .map((option) => {
            if ('all' === option) {
              return 'Todos';
            }

            return (
              features?.menuOptions.find((item) => item._id === option)?.name ??
              ''
            );
          })
          .sort((a, b) => a.localeCompare(b))
          .join(', '),
    },
    {
      title: 'Ações',
      fixed: 'right',
      width: "15%",
      visible: true,
      render: (_: any, category: Category) => (
        <Space direction="vertical" key={category._id}>
          <TableEditAction onClick={() => handleRowClick(category)} />
          <DeleteCategory category={category} />
        </Space>
      ),
    },
  ].filter((item) => item.visible);

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        ([] as Category[]).concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);

      onTableChanged(
        newData.map((item, index) => {
          return {
            ...item,
            order: index,
          };
        })
      );
    }
  };

  const DraggableContainer = (props: any) => (
    <SortableContainerWrapper
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.order === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      style={{ paddingBottom: '40px' }}
      pagination={false}
      loading={isLoading}
      dataSource={[...dataSource]}
      columns={columns as ColumnsType<Category>}
      rowKey="order"
      bordered
      scroll={{ x: 1300 }}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};
