import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { PrinterFilled } from '@ant-design/icons';

import { WhatsappOrder } from '../../../models/DataResponse';
import formatCurrency from '../../../utils/formatCurrency';
import { formatDatetimeByTimezone } from '../../../utils/dateTime';
import { PREFIX_LOCALSTORAGE } from '../../../utils/Constants';
import { getDeliveryTypeLabel } from '../../../utils/DeliveryUtil';

import { ContentHeader } from '../../../components/ContentHeader';
import { SummaryList } from '../../../components/SummaryList';
import { dispatchOrderPrintRequest, getTotalFromOrder } from '../../../utils/OrderUtil';
import { useCompanyFeatures } from '../../../hooks';
import { ProductOrderTable } from '../../../components/ProductOrderTable';

/**@deprecated TO be deleted */
const OrderDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const order = (state as any).order as WhatsappOrder;
  const companyId = (state as any).companyId as string;
  const { companyFeatures } = useCompanyFeatures(companyId);

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  const clientInfo = useMemo(() => {
    return order.client;
  }, [order.client]);

  const productsCounter = useMemo(() => {
    let amount = 0;
    order.products.forEach((product) => (amount += product.counter));
    if (isNaN(amount)) {
      amount = order.products.length;
    }
    return amount;
  }, [order.products]);

  const dismissPage = () => navigate(-1);

  const isCurrencyInEuro = useMemo(() => {
    return currency === '€'; // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
  }, [currency]);

  const getScheduledDateTime = () => {
    const { scheduledDate, scheduledTime } = clientInfo;

    if (scheduledDate && scheduledTime) {
      return `${scheduledDate} : ${scheduledTime}`;
    }

    return `${scheduledDate || scheduledTime}`;
  };

  const renderTotalAndCouponData = () => {
    const { coupon, client } = order;
    const { couponFormatted, totalFormatted, subtotalFormatted } = getTotalFromOrder(currency, order);

    let fareText = '';
    if (client.clientFare) {
      const { destiny, price } = client.clientFare;
      fareText = `${destiny}`;
      if (price && price !== 0) {
        fareText = `${formatCurrency(price, currency)} (${destiny})`;
      }
    }

    return (
      <SummaryList.SummarySubList title="Extrato">
        <SummaryList.Item visible={Boolean(coupon || client.clientFare)}>
          <SummaryList.Label>Subtotal</SummaryList.Label>
          <SummaryList.Value>{subtotalFormatted}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(coupon)}>
          <SummaryList.Label>Desconto cupom</SummaryList.Label>
          <SummaryList.Value>{couponFormatted} (cupom: {coupon?.couponCode})</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(client.clientFare)}>
          <SummaryList.Label>Taxa de entrega</SummaryList.Label>
          <SummaryList.Value>{fareText}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Total</SummaryList.Label>
          <SummaryList.Value>{totalFormatted}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList.SummarySubList>
    );
  }

  return (
    <div style={{ padding: '40px' }}>
      <ContentHeader title="Detalhes do Pedido">
        <>
          <Button
            type="dashed"
            onClick={() => dispatchOrderPrintRequest(order._id, companyId, currency)}
            style={{ marginRight: '10px' }}
          >
            <PrinterFilled /> Imprimir
          </Button>

          <Button danger onClick={dismissPage} style={{ marginRight: '10px' }}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Fechar
          </Button>
        </>
      </ContentHeader>

      <SummaryList title="Pedido">
        <SummaryList.Item>
          <SummaryList.Label>Ordem</SummaryList.Label>
          <SummaryList.Value>{order.sequency}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Código</SummaryList.Label>
          <SummaryList.Value>#{order.orderId}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Data e Hora</SummaryList.Label>
          <SummaryList.Value>{formatDatetimeByTimezone(
            order.createdAt,
            companyFeatures?.timezone ?? 'UTC'
          )}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.scheduledDate || clientInfo.scheduledTime)}>
          <SummaryList.Label>Pré-agendamento (Data/Hora)</SummaryList.Label>
          <SummaryList.Value>{getScheduledDateTime()}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.deliveryType)}>
          <SummaryList.Label>Tipo de Entrega</SummaryList.Label>
          <SummaryList.Value>{getDeliveryTypeLabel(clientInfo.deliveryType)}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Forma de pagamento</SummaryList.Label>
          <SummaryList.Value>{clientInfo.paymentType}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.moneyChange)}>
          <SummaryList.Label>Troco</SummaryList.Label>
          <SummaryList.Value>{formatCurrency(
            Number(clientInfo.moneyChange),
            currency
          )}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.observation)}>
          <SummaryList.Label>Observação</SummaryList.Label>
          <SummaryList.Value>{clientInfo.observation}</SummaryList.Value>
        </SummaryList.Item>

        {renderTotalAndCouponData()}
      </SummaryList>

      <SummaryList title={`Produtos (${productsCounter})`} visible={Boolean(companyFeatures?.sellsConfiguration)}>
        <SummaryList.Item>
          <ProductOrderTable
            currency={currency}
            displayPrice
            products={order.products}
            showComplementTitle={companyFeatures?.sellsConfiguration?.orderComplementExhibition?.showTitle ?? false}
          />
        </SummaryList.Item>
      </SummaryList>

      <SummaryList title="Cliente">
        <SummaryList.Item>
          <SummaryList.Label>Nome</SummaryList.Label>
          <SummaryList.Value>{clientInfo.name}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item>
          <SummaryList.Label>Telefone</SummaryList.Label>
          <SummaryList.Value>{clientInfo.phone}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.email)}>
          <SummaryList.Label>Email</SummaryList.Label>
          <SummaryList.Value>{clientInfo.email}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.instagram)}>
          <SummaryList.Label>Instagram</SummaryList.Label>
          <SummaryList.Value>{clientInfo.instagram}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.taxData?.identification)}>
          <SummaryList.Label>{isCurrencyInEuro ? 'NIF' : 'CPF'}</SummaryList.Label>
          <SummaryList.Value>{clientInfo.taxData?.identification}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>

      <SummaryList title="Endereço" visible={Boolean(clientInfo.address)}>
        <SummaryList.Item>
          <SummaryList.Label>{isCurrencyInEuro ? 'Morada' : 'Endereço'}</SummaryList.Label>
          <SummaryList.Value>{clientInfo.address}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.number)}>
          <SummaryList.Label>Número</SummaryList.Label>
          <SummaryList.Value>{clientInfo.number}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.complement)}>
          <SummaryList.Label>Complemento</SummaryList.Label>
          <SummaryList.Value>{clientInfo.complement}</SummaryList.Value>
        </SummaryList.Item>

        <SummaryList.Item visible={Boolean(clientInfo.referencePoint)}>
          <SummaryList.Label>Ponto de referência</SummaryList.Label>
          <SummaryList.Value>{clientInfo.referencePoint}</SummaryList.Value>
        </SummaryList.Item>
      </SummaryList>
    </div>
  );
};

export default OrderDetails;
