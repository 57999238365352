import React, { useEffect } from 'react';
import { usePendingRegistrations } from '../../hooks/usePendingRegistrations';
import { PendingRegistrationCompanyTable } from './components/Table';

interface PendingTabProps {
  onCompaniesCounterLoaded(count: number): void;
}

export const PendingTab = ({ onCompaniesCounterLoaded }: PendingTabProps) => {

  const { data: companies, isLoading } = usePendingRegistrations();

  useEffect(() => {
    if (!companies) return;

    onCompaniesCounterLoaded(companies?.length ?? 0);
  }, [companies, onCompaniesCounterLoaded]);

  return (
    <PendingRegistrationCompanyTable
      companies={companies}
      loading={isLoading}
    />
  );
};
