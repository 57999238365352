import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  TextField,
  FormLabel,
} from '@material-ui/core';

import { Features } from '../../../../../models/Features';
import { MenuTabOption } from '../../../../../models/DataResponse';
import MenuOptionsList from '../../../../../components/MenuOptionsList';
import { PaymentList } from '../../../../../components/PaymentList';

import { FooterHint, Separator } from './styles';
import { FormHelperText } from '../../../../../common-styles';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../components/Notification';
import { EasypayFeature } from '../../../../../models/Easypay';
import { useCompanyFeatures } from '../../../../../hooks';

interface FeaturesContentProps {
  companyId: string;
  features: Features;
  handleChange: any;
  handleWhatsappChanges(number: string, paymentMethods: string[]): void;
  handleMenuOptionEditAction(option: MenuTabOption): void;
  handleMenuOptionDeleteAction(id: string): void;
  handleShowMenuOptionsPage(isShow: boolean): void;
  handleMenuOptionsUpdateCheckShouldBeHidden(checked: boolean): void;
  onPaymentEasypayChanged(easypay: EasypayFeature): void;
}

const FeaturesContent = ({
  companyId,
  features,
  handleChange,
  handleWhatsappChanges,
  handleMenuOptionEditAction,
  handleMenuOptionDeleteAction,
  handleShowMenuOptionsPage,
  handleMenuOptionsUpdateCheckShouldBeHidden,
  onPaymentEasypayChanged,
}: FeaturesContentProps) => {
  const { companyFeatures } = useCompanyFeatures(companyId);

  const [whatsappNumber, setWhatsappNumber] = useState<string>(features.whatsappNumber);
  const [paymentMethods, setPaymentMethods] = useState<string[]>(companyFeatures?.sellsConfiguration?.paymentMethods ?? []);
  const [easypayFeature, setEasypayFeature] = useState<EasypayFeature>(features.easypay);

  const handleEasypayChanges = () => {
    if (isEmpty(easypayFeature.accountId) ||
      isEmpty(easypayFeature.apiKey) ||
      isEmpty(easypayFeature.host) ||
      isEmpty(easypayFeature.mainPaymentAccountId) ||
      isEmpty(easypayFeature.marginAccountId)) {
      NotificationDispatcher({
        message: 'Todos os campos são obrigatórios',
        type: NotificationType.ERROR,
      });
      return;
    }

    onPaymentEasypayChanged(easypayFeature);
  };

  const renderPaymentEasypayContent = () => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary>
          <h2>
            <strong>
              <h3>Easypay (Pagamento)</h3>
            </strong>
          </h2>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <TextField
            id="accountId"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Account Id"
            required
            onChange={(e) => setEasypayFeature({
              ...easypayFeature,
              accountId: e.target.value,
            })}
            value={easypayFeature.accountId}
          />

          <TextField
            id="apiKey"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="ApiKey"
            required
            onChange={(e) => setEasypayFeature({
              ...easypayFeature,
              apiKey: e.target.value,
            })}
            value={easypayFeature.apiKey}
          />

          <TextField
            id="host"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Host"
            required
            onChange={(e) => setEasypayFeature({
              ...easypayFeature,
              host: e.target.value,
            })}
            value={easypayFeature.host}
          />

          <TextField
            id="mainPaymentAccountId"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Main Payment Account Id"
            required
            onChange={(e) => setEasypayFeature({
              ...easypayFeature,
              mainPaymentAccountId: e.target.value,
            })}
            value={easypayFeature.mainPaymentAccountId}
          />

          <TextField
            id="marginAccountId"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Margin Account Id"
            required
            onChange={(e) => setEasypayFeature({
              ...easypayFeature,
              marginAccountId: e.target.value,
            })}
            value={easypayFeature.marginAccountId}
          />

          <Button
            variant="outlined"
            color="primary"
            className="form-button"
            onClick={handleEasypayChanges}
          >
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderWhatsappContent = () => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary>
          <h2>
            <strong>
              <h3>Vendas por WhatsApp</h3>
            </strong>
          </h2>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <TextField
            id="whatsappNumber"
            variant="outlined"
            type="text"
            className="form-general-input"
            label="Número do Whatsapp"
            onChange={(e) => setWhatsappNumber(e.target.value)}
            value={whatsappNumber}
          />

          <Separator />

          <FormLabel
            component="legend"
            style={{ paddingBottom: '6px', marginTop: '20px' }}
          >
            <strong>Forma de Pagamento</strong>
          </FormLabel>

          <PaymentList
            paymentsMethods={paymentMethods}
            handleSelectedPayments={(options) => setPaymentMethods(options)}
          />

          <Button
            variant="outlined"
            color="primary"
            className="form-button"
            onClick={() => handleWhatsappChanges(whatsappNumber, paymentMethods)}
          >
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Salvar
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderMultiMenuOptions = () => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary>
          <h2>
            <strong>
              <h3>Opções de Abas</h3>
            </strong>
          </h2>
        </AccordionSummary>
        <AccordionDetails className="form-general">
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={features.menuOptionsShouldBeHidden}
                    onChange={(e) => {
                      handleMenuOptionsUpdateCheckShouldBeHidden(
                        e.target.checked
                      );
                    }}
                    name="hiddenTab"
                  />
                }
                label="Ocultar Aba"
              />
            </FormGroup>

            <FormHelperText>
              Ao selecionar a opção de Ocultar Aba, as Abas serão escondidas ou
              exibidas conforme a configuração escolhida de exibir por dia ou
              por hora. Por padrão, todas as abas serão exibidas.
            </FormHelperText>
          </FormControl>

          {features.menuOptions.length > 0 && (
            <MenuOptionsList
              menuOptions={features.menuOptions}
              deleteAction={handleMenuOptionDeleteAction}
              editAction={handleMenuOptionEditAction}
            />
          )}

          <Button
            variant="outlined"
            color="primary"
            className="form-button"
            onClick={() => handleShowMenuOptionsPage(true)}
          >
            <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ marginRight: '10px' }}
            />
            Adicionar Tab
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <FormControl component="fieldset" title="Módulos">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={features.sellViaWhatsapp}
              onChange={handleChange}
              name="sellViaWhatsapp"
            />
          }
          label="Vendas por WhatsApp"
        />

        {features.sellViaWhatsapp && renderWhatsappContent()}

        <FormControlLabel
          control={
            <Checkbox
              checked={features.menuOptionsEnabled}
              onChange={handleChange}
              name="menuOptionsEnabled"
            />
          }
          label="Ativar multi-menus"
        />

        {features.menuOptionsEnabled && renderMultiMenuOptions()}

        <FormControlLabel
          control={
            <Checkbox
              checked={features.easypay.isEnabled}
              onChange={handleChange}
              name="isEasypayEnabled"
            />
          }
          label="Easypay"
        />

        {features.easypay.isEnabled && renderPaymentEasypayContent()}
      </FormGroup>

      <FooterHint>
        <FormHelperText>
          Ao selecionar/deselecionar um item, ele será salvo automaticamente.
        </FormHelperText>
      </FooterHint>
    </FormControl>
  );
};

export default FeaturesContent;
