import styled from 'styled-components';

export const SelectInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  .select {
    width: 30%;
    padding-right: 10px;
  }

  .play-button {
    margin-left: 10px;
    height: 34px;
    align-self: baseline;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;