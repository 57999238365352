
import styled from 'styled-components';

export const RemoveLabel = styled.span`
  color: #ea1d2c;
  font-size: 14px;
  font-weight: 500;

  cursor: pointer;

  margin-left: 16px;
  text-decoration: underline;
`;