import styled from 'styled-components';

interface ColorItemProps {
  color: string;
}

interface ComplementContentRowProps {
  hasExtraProps: boolean;
}

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #f2f2f2;
  padding: 14px;

  .header-title {
    display: flex;
    flex-direction: column;
  }

  .required-label {
    height: 20px;
    background-color: #717171;
    color: #f5f0eb;
    border: none;
    border-radius: 3px;
    padding: 6px 6px 4px;
    text-transform: uppercase;
    font-size: 9px;
  }

  .optional-label {
    height: 20px;
    background-color: #dcdcdc;
    color: #3d3d3d;
    border: none;
    border-radius: 3px;
    padding: 6px 6px 4px;
    text-transform: uppercase;
    font-size: 9px;
  }

  #title {
    font-size: 20px;
    font-weight: 500;
    color: #3f3e3e;
  }

  #description {
    font-size: 16px;
    color: #717171;
    margin-top: 3px;
  }

  .count-required-label {
    font-size: 14px;
    font-weight: 300;
    margin-top: 3px;
  }
`;

export const ItemContainer = styled.div`
  padding: 12px;

  .item-title-container {
    font-size: 16px;
    font-weight: 500;
    color: #3f3e3e;
  }

  .item-description-container {
    font-size: 14px;
    color: #717171;
    line-height: 25px;
  }

  span {
    font-size: 15px;
    font-weight: 700;
    color: #ea1d2c;

    &::before {
      content: '+ ';
    }
  }
`;

export const ComplementContentRow = styled.div<ComplementContentRowProps>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0;
  margin: 0;

  height: ${(props) => props.hasExtraProps ? '100%' : '50px'}
`;

export const Separator = styled.hr`
  border-top: 1px solid #eeee;
  margin-top: 5px;
`;

export const TitleHolder = styled.div`
  display: flex;
`;

export const ColorItem = styled.div<ColorItemProps>`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  background-color: ${(props) => `#${props.color}`};
`;
