import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  align-items: initial;
  margin-top: 10px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DefaultLabel = styled.span`
  color: #717171;
  font-size: 18px;
  text-align: start;

  display: inline-block;
  max-width: 100%;      
  white-space: nowrap;  
  overflow: hidden;     
  text-overflow: ellipsis;

  @media (max-width: 430px) {
    font-size: 16px;
  }
`;

const DefaultValueLabel = styled.span`
color: #3f3e3e;
  font-size: 18px;
  text-align: end;
  word-wrap: break-word; 

  @media (max-width: 430px) {
    font-size: 16px;
  }
`;

export const NormalLabel = styled(DefaultLabel)``;

export const NormalValue = styled(DefaultValueLabel)``;

export const BoldLabel = styled(DefaultLabel)`
  color: #3f3e3e;
  font-weight: 500;
`;

export const BoldValue = styled(DefaultValueLabel)`
  color: #3f3e3e;
  font-weight: 500;
  text-align: end;
`;
