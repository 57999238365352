import { Company } from '../models/Company';
import { Role } from '../models/Enum';
import { useFetch } from './useFetch';
import { useLoggedUser } from './useLoggedUser';

const ASSOCIATED_COMPANIES = 'associated-companies';

export const useListCompaniesForCustomers = () => {
  const { userLogged: { user: { role } } } = useLoggedUser();

  const { data, ...rest } = useFetch<Company[]>({
    queryKey: ASSOCIATED_COMPANIES,
    url: role === Role.ADMIN ? 'api/company?status=active' : 'api/company/listAssociations',
  });

  return { data, ...rest };
};
