import { useEffect } from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import { confirmAlert } from 'react-confirm-alert';

const PENDING_ORDERS = 'pendingOrders';

type CheckPendingOrdersResponse = {
  pendingOrders: number;
}

export const useCheckPendingOrders = (date: string) => {
  const { data } = useFetch<CheckPendingOrdersResponse>({
    queryKey: [PENDING_ORDERS],
    url: `api/orders/pending?startDate=${date}&endDate=${date}`,
    cacheTime: 0,
  });

  useEffect(() => {
    if (data) {
      const count = data.pendingOrders;
      if (count >= 1) {
        const message = count === 1 ? '1 pedido pendente' : `${count} pedidos pendentes`;
        confirmAlert({
          title: `Você possui ${message}`,
          buttons: [
            {
              label: 'Ok',
              onClick: () => {},
            },
          ],
        });
      }
    }
  }, [data]);
}