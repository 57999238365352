import { useMutation } from 'react-query';
import api from '../services/api';

export enum RestType {
  POST,
  PUT,
}

/**
 * @deprecated To be removed ASAP
 */
export function useMutations(
  type: RestType = RestType.POST,
  url: string,
  headers?: any
) {
  return useMutation((body) => {
    return type === RestType.POST
      ? api.post(url, body, {
          headers: headers,
        })
      : api.put(url, body, {
          headers: headers,
        });
  });
}
