import React from 'react';
import { Container } from './style';

interface FloatingShoppingButtonProps {
  counter: number;
};

export const FloatingShoppingButton = ({ counter }: FloatingShoppingButtonProps) => {
  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <Container onClick={scrollToBottom}>
      <div className="chart-circle-counter">{counter}</div>
      <i className="fa fa-shopping-basket floating-chart-icon" />
    </Container>
  );
};
