import React from 'react';
import { Skeleton } from 'antd';

import { MenuOption } from '../../../../models/MenuOption';

import { ContentHeader } from '../../../../components/ContentHeader';
import { FeedbackItems } from './components/FeedbackItems';

import { useCompanyFeatures, useLoggedUser } from '../../../../hooks';

export const SettingsFeedbackPage = () => {
  const { userLogged: { user: { company } } } = useLoggedUser();
  const { companyFeatures, isFetching } = useCompanyFeatures(company);

  if (isFetching || companyFeatures === undefined) {
    return <Skeleton />;
  }

  return (
    <>
      <ContentHeader title={MenuOption.SETTINGS_FEEDBACK} />

      <FeedbackItems
        companyFeatures={companyFeatures}
      />
    </>
  );
};
