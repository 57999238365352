import React from 'react';
import logoImg from '../../assets/images/logo-no-bg.png';
import notFoundImg from '../../assets/images/not-found.png';
import { Container, Logo, NotFoundImage } from './styles';

interface PageNotFoundProps {
  message?: string;
  subtitle?: string;
  extra?: React.ReactNode;
};

const TITLE = 'Desculpe, esta página não está disponível.';

export const PageNotFound = ({ message, subtitle, extra }: PageNotFoundProps) => {
  return (
    <Container>
      <Logo src={logoImg} alt="QRcode Preferido" />

      <NotFoundImage src={notFoundImg} alt="Not found image" />

      <h2>{message ?? TITLE}</h2>

      {subtitle ? subtitle : (
        <p>A página que você tentou acessar não existe ou não está disponível. Caso tenha clicado em algum link ou acessado algum cardápio/catálogo,
          favor enviar um email para <strong><a style={{textDecoration: 'underline'}} href='mailto:suporte@qrcodepreferido.com'>suporte@qrcodepreferido.com</a></strong> com uma imagem do link que você clicou ou
          entre em contato com o nosso suporte pelo <strong><a style={{textDecoration: 'underline'}} href='https://api.whatsapp.com/send?phone=351910919855'>WhatsApp</a></strong>.</p>
      )}

      {extra && extra}
    </Container>
  );
};
