import React, { useMemo } from 'react';
import { Space, Table } from 'antd';
import moment from 'moment-timezone';

import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../../components/Notification';
import { TableEditAction } from '../../../../../components/TableEditAction';
import { TableDeleteAction } from '../../../../../components/TableDeleteAction';

import api from '../../../../../services/api';
import { queryClient } from '../../../../../services/queryClient';
import { ActionType, CouponStatus } from '../../../../../models/Enum';
import { Coupon } from '../../../../../models/Coupon';
import formatCurrency from '../../../../../utils/formatCurrency';
import {
  convertServerDateToLocalDate,
  formatDatetimeByTimezone,
} from '../../../../../utils/dateTime';
import { PREFIX_LOCALSTORAGE } from '../../../../../utils/Constants';
import { RoundTag } from '../../../../../common-styles';
import { COUPONS, useCoupons } from '../../../../../hooks';

interface CouponTableProps {
  companyId: string;
  handleAction(coupon: Coupon, action: ActionType): void;
};

const { Column, ColumnGroup } = Table;

export const CouponTable = ({ companyId, handleAction }: CouponTableProps) => {

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  const { data: coupons, isFetching } = useCoupons(companyId);

  const executeDeletion = async (coupon: Coupon) => {
    await api
      .delete(`api/coupon/${coupon._id}`, {
        headers: {
          company_id: companyId,
        },
      })
      .then(() => {
        const couponList = queryClient.getQueryData<Coupon[]>(COUPONS) || [];
        const filteredList = couponList.filter(
          (item) => item._id !== coupon._id
        );
        queryClient.setQueryData(COUPONS, [...filteredList]);

        NotificationDispatcher({
          message: 'Cupom removido com sucesso.',
        });
      })
      .catch(() => {
        NotificationDispatcher({
          message: 'Não foi possível excluir o cupom.',
          type: NotificationType.ERROR,
        });
      });
  };

  const translateStatus = (status: string) => {
    switch (status) {
      case CouponStatus.CANCELLED:
        return 'Cancelado';
      case CouponStatus.IN_PROGRESS:
        return 'Ativo';
      case CouponStatus.FINISHED:
        return 'Finalizado';
      case CouponStatus.PAUSED:
        return 'Pausado';
    }
  };

  return (
    <Table bordered dataSource={coupons ? [...coupons] : []} loading={isFetching}>
      <Column
        title="Status"
        width={80}
        align={'center' as 'center'}
        render={(coupon: Coupon) => {
          let color = 'red';

          switch (coupon.status) {
            case CouponStatus.CANCELLED:
              color = 'red';
              break;
            case CouponStatus.IN_PROGRESS:
              color = 'green';
              break;
            case CouponStatus.FINISHED:
              color = 'gray';
              break;
            case CouponStatus.PAUSED:
              color = 'red';
              break;
          }

          return (
            <RoundTag color={color} key={coupon._id}>
              {translateStatus(coupon.status)}
            </RoundTag>
          );
        }}
      />
      <Column title="Título" dataIndex="title" />
      <Column title="Código" dataIndex="couponCode" />
      <Column
        title="Tipo"
        render={(coupon: Coupon) => {
          const isPercentage = Number(coupon.discountType) === 0;
          const discountType = isPercentage ? 'Percentual' : 'Valor';
          const formattedValue = isPercentage
            ? `${coupon.discountValue}%`
            : formatCurrency(coupon.discountValue, currency);
          return `${discountType} - ${formattedValue}`;
        }}
      />

      <ColumnGroup title="Quantidade">
        <Column
          title="Utilizados"
          render={(coupon: Coupon) => {
            const hasQuantity =
              coupon.usedQuantity && coupon.usedQuantity !== undefined;
            return hasQuantity ? coupon.usedQuantity : '0';
          }}
        />
        <Column
          title="Total"
          render={(coupon: Coupon) => {
            const hasQuantity =
              coupon.quantity && coupon.quantity !== undefined;
            return hasQuantity ? coupon.quantity : 'Ilimitado';
          }}
        />
      </ColumnGroup>
      <Column
        title="Data de criação"
        render={(coupon: Coupon) =>
          formatDatetimeByTimezone(coupon.createdAt, moment.tz.guess())
        }
      />
      <Column
        title="Data de validade"
        render={(coupon: Coupon) => {
          return `${convertServerDateToLocalDate(coupon.expirationDate)} 23:59`;
        }}
      />
      <Column title="Info" dataIndex="statusMessage" />
      <Column
        title="Ações"
        width={150}
        render={(coupon: Coupon) => (
          <Space direction="vertical" key={coupon._id}>
            <TableEditAction
              disabled={coupon.status === CouponStatus.CANCELLED || coupon.status === CouponStatus.FINISHED}
              onClick={() => handleAction(coupon, ActionType.UPDATE)}
            />

            <TableDeleteAction
              onClick={() => executeDeletion(coupon)}
              title={
                <span>
                  Tem certeza que deseja remover o cupom{' '}
                  <strong>{coupon.title}</strong>? <br />
                  Uma vez removido, esta ação não poderá ser revertida.
                </span>
              }
            />
          </Space>
        )}
      />
    </Table>
  );
}
