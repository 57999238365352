
export interface OrderStatusResponse {
  order: {
    status: string;
    isDelivery: boolean;
  },
  company: {
    id: string;
    logo: string;
    name: string;
    whatsappNumber: string;
  },
}

export enum OrderStatus {
  PENDING = 'PENDING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  IN_TRANSIT = 'IN_TRANSIT',
  CONCLUDED = 'CONCLUDED',
  NOT_DELIVERED = 'NOT_DELIVERED',
  CANCELLED = 'CANCELLED',
}

export enum OrderStatusLabel {
  CANCELLED = 'Cancelado',
  CONCLUDED = 'Entregue',
  IN_PROGRESS = 'Em execução',
  IN_TRANSIT = 'Em trânsito',
  NOT_DELIVERED = 'Não entregue',
  PENDING = 'Pendente',
  READY = 'Pronto',
  WAITING_PAYMENT = 'Pagamento pendente',
}

export enum DriverStatusInOrder {
  DRIVER_ACCEPTED = 'DRIVER_ACCEPTED',
  DRIVER_DENIED = 'DRIVER_DENIED',
  PICKED_UP_PRODUCT = 'PICKED_UP_PRODUCT',
  WAITING_DRIVER_ACCEPT = 'WAITING_DRIVER_ACCEPT',
}

export const getOrderStatusData = (status: string) => {
  switch (status) {
    case OrderStatus.CANCELLED:
      return { label: OrderStatusLabel.CANCELLED, color: 'red' };
    case OrderStatus.NOT_DELIVERED:
      return { label: OrderStatusLabel.NOT_DELIVERED, color: 'red' };
    case OrderStatus.WAITING_PAYMENT:
      return { label: OrderStatusLabel.WAITING_PAYMENT, color: '#FFA07A' };
    case OrderStatus.IN_PROGRESS:
      return { label: OrderStatusLabel.IN_PROGRESS, color: '#FF5F1F' };
    case OrderStatus.READY:
      return { label: OrderStatusLabel.READY, color: 'blue' };
    case OrderStatus.CONCLUDED:
      return { label: OrderStatusLabel.CONCLUDED, color: 'green' };
    case OrderStatus.IN_TRANSIT:
      return { label: OrderStatusLabel.IN_TRANSIT, color: 'orange' };
    default:
      return { label: OrderStatusLabel.PENDING, color: '#edbf09' };
  }
}

export const convertStatusToStep = (status: string, isDelivery: boolean) => {
  let current = 0; // OrderStatus.PENDING
  switch (status) {
    case OrderStatus.IN_PROGRESS:
      current = 1;
      break;
    case OrderStatus.READY:
      current = 2;
      break;

    case OrderStatus.IN_TRANSIT:
      current = 3;
      break;

    case OrderStatus.NOT_DELIVERED:
    case OrderStatus.CONCLUDED:
      current = isDelivery ? 5 : 4;
      break;
  }

  return current;
}