import React from "react";
import { MenuOption } from "../../../models/MenuOption";
import { ContentHeader } from "../../../components/ContentHeader";
import { ContactsTable } from "./components/Table";

export const ContactPage = () => {
  return (
    <>
      <ContentHeader title={MenuOption.CONTACT} />

      <ContactsTable />
    </>
  );
};
