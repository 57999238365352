import React from "react";
import { Button, Descriptions, Divider, Table } from "antd";

import { FormHelperText, RoundTag } from "../../../../../../common-styles";

import { EditorCustomInformation } from "./components/EditorCustomInformation";

import { BreakPoints, useBreakpoint, useModal } from "../../../../../../hooks";
import { CompanyFeatures } from "../../../../../../models/CompanyFeatures";
import { CustomInformationOption } from "../../../../../../models/SellsConfiguration";

interface CustomInformationProps {
  companyFeatures: CompanyFeatures;
}

export const CustomInformation = ({ companyFeatures }: CustomInformationProps) => {
  const { close, isOpen, toggle } = useModal();
  const { breakPnt } = useBreakpoint();

  const { sellsConfiguration: { customInformation } } = companyFeatures;
  const options = customInformation?.options ?? [];

  return (
    <>
      <FormHelperText>Essas informações serão apresentadas no resumo do pedido. A informação escolhida pelo usuário, também será exibida na mensagem enviada pelo WhatsApp.</FormHelperText>

      <Divider />

      <div style={{ marginTop: '16px' }}>

        <Button
          type="primary"
          onClick={() => toggle()}
        >
          <i
            className="fa fa-plus-circle"
            aria-hidden="true"
            style={{ marginRight: '10px' }}
          />
          Editar Informações
        </Button>

        <EditorCustomInformation
          close={close}
          companyFeatures={companyFeatures}
          isOpen={isOpen}
        />

        <Descriptions
          bordered
          column={1}
          style={{ marginTop: '16px' }}
          title="Informações"
        >
          {customInformation?.title && (
            <Descriptions.Item label="Título">
              {customInformation.title}
            </Descriptions.Item>
          )}
          {customInformation?.description && (
            <Descriptions.Item label="Descrição">
              {customInformation.description}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Status">
            <RoundTag color={customInformation?.isEnabled ? 'green' : 'red'}>
              {customInformation?.isEnabled ? 'Ativo' : 'Pausado'}
            </RoundTag>
          </Descriptions.Item>
          <Descriptions.Item label="Obrigatório">
            <RoundTag color={customInformation?.isRequired ? 'green' : 'red'}>
              {customInformation?.isRequired ? 'Sim' : 'Não'}
            </RoundTag>
          </Descriptions.Item>
        </Descriptions>

        <Divider orientation="left">Opções</Divider>

        <Table
          bordered
          dataSource={options}
          pagination={false}
        >
          <Table.Column
            title="Status"
            align={'center' as 'center'}
            width={breakPnt === BreakPoints.LG ? 100 : 70}
            render={(option: CustomInformationOption) => (
              <RoundTag color={option.isEnabled ? 'green' : 'red'} key={option._id}>
                {option.isEnabled ? 'Ativo' : 'Pausado'}
              </RoundTag>
            )}
          />
          <Table.Column
            dataIndex="title"
            title="Título"
          />
          <Table.Column
            dataIndex="description"
            title="Descrição"
          />
        </Table>
      </div>
    </>
  );
}