import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { CompanyFeatures } from '../models/CompanyFeatures';
import { DEFAULT_SELECT_COMPANY, PREFIX_LOCALSTORAGE } from '../utils/Constants';
import { useFetch } from './useFetch';

export const COMPANY_FEATURES = 'companyFeatures';

export const useCompanyFeatures = (companyId: string | undefined) => {
  const { data: companyFeatures, isFetching, ...rest } = useFetch<CompanyFeatures>({
    enabled: !isEmpty(companyId) && companyId !== DEFAULT_SELECT_COMPANY,
    queryKey: [COMPANY_FEATURES, companyId],
    url: `/api/company/${companyId}/features`,
  });

  useEffect(() => {
    if (!companyFeatures) return;

    localStorage.setItem(`${PREFIX_LOCALSTORAGE}:currency`, companyFeatures.sellsConfiguration.currency);
  }, [companyFeatures]);


  return { companyFeatures, isFetching, ...rest };
};
