import React, { useEffect, useState } from 'react';
import { Lightbox } from 'react-modal-image';

interface ModalImageProps {
  content: string;
}

export const ModalImage = ({ content }: ModalImageProps) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    isOpen ? (
      <Lightbox
        alt=""
        hideDownload
        medium={content}
        large={content}
        onClose={handleClose}
      />
    ) : null
  );
};
