import React, { useState } from 'react';
import { Avatar, Button, Descriptions, Divider, List, Skeleton } from 'antd';

import { LinkButton } from './components/LinkButton';
import { LinkTreeEditor } from './components/LinkTreeEditor';
import {
  PanelResult,
  PanelResultStatus,
} from '../../../components/PanelResult';

import { RoundTag } from '../../../common-styles';
import { useQrlinks } from '../../../hooks';

export const QRLinksPage = () => {
  const [isPageDetailVisible, setPageDetailVisible] = useState(false);

  const { data: linkTree, isFetching, refetch } = useQrlinks();

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <>
      <LinkTreeEditor
        isVisible={isPageDetailVisible}
        linkTree={linkTree}
        onClose={(shouldRefetch) => {
          setPageDetailVisible(false);

          if (shouldRefetch) {
            refetch();
          }
        }}
      />

      {linkTree ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="primary" onClick={() => setPageDetailVisible(true)}>
              <i
                className="fa fa-pencil-square-o"
                aria-hidden="true"
                style={{ marginRight: '10px' }}
              />
              Editar Links
            </Button>

            <LinkButton />
          </div>

          <Descriptions
            title="QRLinks Info"
            column={1}
            style={{ marginTop: '16px' }}
          >
            <Descriptions.Item label="Título">
              {linkTree.title}
            </Descriptions.Item>
            {linkTree.subtitle && (
              <Descriptions.Item label="Descrição">
                {linkTree.subtitle}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Status">
              <RoundTag color={linkTree.isEnabled ? 'green' : 'red'}>
                {linkTree.isEnabled ? 'Ativo' : 'Pausado'}
              </RoundTag>
            </Descriptions.Item>
            <Descriptions.Item label="Tipo de layout">
              <RoundTag color={'orange'}>
                {linkTree.isGrid ? 'Grid' : 'Lista'}
              </RoundTag>
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">Links</Divider>
          <List
            itemLayout="vertical"
            dataSource={linkTree.links}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.imageUrl ? <Avatar src={item.imageUrl} /> : null}
                  description={item.url}
                  title={
                    <>
                      {item.name}
                      <RoundTag color={item.isEnabled ? 'green' : 'red'} style={{ marginLeft: '16px'}}>
                        {item.isEnabled ? 'Ativo' : 'Pausado'}
                      </RoundTag>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        </>
      ) : (
        <PanelResult
          status={PanelResultStatus.NOT_FOUND}
          title="Nenhum QRLink criado"
          subtitle="Clique no botão abaixo e inicie o seu QRLink."
          extra={
            <Button type="primary" onClick={() => setPageDetailVisible(true)}>
              Criar
            </Button>
          }
        />
      )}
    </>
  );
};
