import { useFetch } from "../../../../../../../hooks/useFetch";
import { ExtraFee } from "../../../../../../../models/SellsConfiguration";

export const EXTRA_FEES = 'extraFees';

export const useExtraFees = (companyId: string) => {
  const { data, ...rest } = useFetch<ExtraFee[]>({
    queryKey: [EXTRA_FEES, companyId],
    url: `api/company/${companyId}/features/extra-fees`,
  });

  return { data, ...rest };
};