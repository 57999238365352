import React, { useState } from 'react';
import { Form, Skeleton, Tabs } from 'antd';

import { CompanyFeatures } from '../../../../models/CompanyFeatures';
import { MenuOption } from '../../../../models/MenuOption';

import { ControlVisibilityFields } from './components/ControlVisibilityFields';
import { CustomInformation } from './components/CustomInformation';
import { DeliveryFees } from './components/DeliveryFees';
import { ExtraFees } from './components/ExtraFees';
import { General } from './components/General';
import { NotificationTab } from './components/NotificationTab';
import { OrderSummaryWhatsappTable } from './components/OrderSummaryWhatsapp';

import { ContentHeader } from '../../../../components/ContentHeader';
import {
  NotificationDispatcher,
  NotificationType,
} from '../../../../components/Notification';
import { PanelResult, PanelResultStatus } from '../../../../components/PanelResult';

import { useCompanyFeatures, useFeatures, useLoggedUser, useUpdateCompanyFeatures } from '../../../../hooks';
import { useUpdateSettingsCentralizer } from './components/hooks/useUpdateSettingsCentralizer';
import { Role } from '../../../../models/Enum';

interface SettingsOrdersPageProps {
  companyIdOpt?: string;
}

export enum Tab {
  CUSTOM_INFORMATION = 'Informações Adicionais',
  DELIVERY_FEES = 'Fretes',
  FEES = 'Taxas',
  GENERAL = 'Geral',
  NOTIFICATIONS = 'Notificações',
  ORDER_SUMMARY = 'Visualização de Pedidos',
  SHOW_FIELDS = 'Exibição de Campos',
}

const { TabPane } = Tabs;

export const SettingsOrdersPage = ({ companyIdOpt }: SettingsOrdersPageProps) => {
  const [selectedTab, setSelectedTab] = useState(Tab.GENERAL);

  const { userLogged } = useLoggedUser();
  const { user: { company, role } } = userLogged;
  const companyId = companyIdOpt ?? company;

  const { companyFeatures, isLoading: isLoadingCompanyFeatures } = useCompanyFeatures(companyId);
  const { data: features, isLoading: isLoadingFeatures } = useFeatures(companyId);
  const { update } = useUpdateCompanyFeatures();
  const { updateCompanySettingsCentralizer } = useUpdateSettingsCentralizer();
  const isLoading = !companyFeatures || !companyFeatures.sellsConfiguration || isLoadingCompanyFeatures || isLoadingFeatures;

  const handleCheckboxChange = async (checkedValue: boolean, switchId: string) => {
    if (!companyFeatures || !companyFeatures.sellsConfiguration) return;

    // In case of User wants disable delivery option, we will check if the PickUp(Take Away) is disabled as well,
    // In an affirmative case, we'll not allow it, due to we need at least one option enabled.
    if (
      switchId === 'hasDelivery' &&
      !checkedValue &&
      !companyFeatures.sellsConfiguration.hasPickUp
    ) {
      companyFeatures.sellsConfiguration.hasPickUp = true;
    } else if (
      switchId === 'hasPickUp' &&
      !checkedValue &&
      !companyFeatures.sellsConfiguration.hasDelivery
    ) {
      companyFeatures.sellsConfiguration.hasDelivery = true;
    }

    if (
      switchId === 'isTaxDataVisible' &&
      !checkedValue
    ) {
      companyFeatures.sellsConfiguration.isTaxDataRequired = false;
    }

    switch (switchId) {
      case 'showInformeSellsViaWhatsapp':
        companyFeatures.showInformeSellsViaWhatsapp = checkedValue;
        break;
      case 'orderComplementExibitionTitle':
        companyFeatures.sellsConfiguration.orderComplementExhibition.showTitle = checkedValue;
        break;
      case 'playUntilAccept':
        companyFeatures.sellsConfiguration.ringing.playUntilAccept = checkedValue;
        break;
      case 'hasDelivery':
      case 'hasEatInLoco':
      case 'hasPickUp':
      case 'isDatePickerRequired':
      case 'isDatetimeForDeliveryOrPickupVisible':
      case 'isEmailRequired':
      case 'isEmailVisible':
      case 'isFareRequired':
      case 'isInstagramRequired':
      case 'isInstagramVisible':
      case 'isTaxDataRequired':
      case 'isTaxDataVisible':
      case 'isTelephoneRequired':
      case 'isTimePickerForDeliveryOrPickupVisible':
      case 'isTimePickerRequired':
      case 'sendLinkToFollowOrder':
      case 'showCouponFeature':
      case 'showZeroValue':
        companyFeatures.sellsConfiguration[switchId] = checkedValue;
        break;
    }

    updateCompanyFeatures({ ...companyFeatures });

    if (role === Role.MANAGER) {
      updateCompanySettingsCentralizer(companyFeatures.sellsConfiguration);
    }
  }

  const updateCompanyFeatures = (companyFeatures: CompanyFeatures) => {
    update(
      { ...companyFeatures },
      {
        onSuccess: () => NotificationDispatcher({
          message: 'Atualizado com sucesso.',
        }),
        onError: () => NotificationDispatcher({
          message: 'Erro ao tentar atualizar.',
          type: NotificationType.ERROR,
        })
      },
    );
  }

  const onTabChanged = (key: string) => setSelectedTab(key as Tab);

  if (isLoading) {
    return <Skeleton />;
  }

  if (!features?.sellViaWhatsapp) {
    return (
      <PanelResult
        status={PanelResultStatus.INFO}
        title="Para ter acesso a esta funcionalidade, é preciso ativar o módulo de pedidos."
        subtitle="Entre já em contato com a equipe QRcode Preferido para mais informações."
      />
    );
  }

  return (
    <>
      <ContentHeader title={MenuOption.SETTINGS_ORDER} />

      <Form layout="vertical" initialValues={companyFeatures}>
        <Tabs
          size="large"
          activeKey={selectedTab.toString()}
          onChange={onTabChanged}
        >
          <TabPane tab={Tab.GENERAL} key={Tab.GENERAL}>
            <General
              companyFeatures={companyFeatures}
              handleCheckboxChange={handleCheckboxChange}
            />
          </TabPane>

          <TabPane tab={Tab.NOTIFICATIONS} key={Tab.NOTIFICATIONS}>
            <NotificationTab
              handleCheckboxChange={handleCheckboxChange}
              onSettingsChanged={(settings) => {
                updateCompanyFeatures({ ...companyFeatures, sellsConfiguration: settings })

                if (role === Role.MANAGER) {
                  updateCompanySettingsCentralizer(settings);
                }
              }}
              sellsConfiguration={companyFeatures.sellsConfiguration}
            />
          </TabPane>

          <TabPane tab={Tab.SHOW_FIELDS} key={Tab.SHOW_FIELDS}>
            <ControlVisibilityFields
              companyFeatures={companyFeatures}
              handleCheckboxChange={handleCheckboxChange}
            />
          </TabPane>

          <TabPane tab={Tab.DELIVERY_FEES} key={Tab.DELIVERY_FEES}>
            <DeliveryFees
              companyId={companyId}
              companyFeatures={companyFeatures}
              handleCheckboxChange={handleCheckboxChange}
            />
          </TabPane>

          <TabPane tab={Tab.FEES} key={Tab.FEES}>
            <ExtraFees companyFeatures={companyFeatures} />
          </TabPane>

          <TabPane tab={Tab.ORDER_SUMMARY} key={Tab.ORDER_SUMMARY}>
            <OrderSummaryWhatsappTable companyId={companyId} companyFeatures={companyFeatures} />
          </TabPane>

          <TabPane tab={Tab.CUSTOM_INFORMATION} key={Tab.CUSTOM_INFORMATION}>
            <CustomInformation companyFeatures={companyFeatures} />
          </TabPane>
        </Tabs>
      </Form>
    </>
  );
};
