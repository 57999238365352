import React, { ReactNode } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { TableGenericAction } from '../TableGenericAction';
import { Popconfirm } from 'antd';

interface TableDeleteActionProps {
  label?: string;
  onClick(): void;
  title: ReactNode;
}

export const TableDeleteAction = ({ label, onClick, title }: TableDeleteActionProps) => {
  return (
    <Popconfirm
      placement="bottomRight"
      title={title}
      onConfirm={onClick}
      okText="Sim"
      cancelText="Não"
    >
      <TableGenericAction
        danger
        dashed
        icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
        label={label ?? "Remover"}
        onClick={() => { }}
      />
    </Popconfirm>
  );
};
