import { Driver } from '../models/Driver';
import { DEFAULT_SELECT_COMPANY } from '../utils/Constants';
import { useFetch } from './useFetch';

const DRIVERS = 'drivers';

export const useAvailableDrivers = (selectedCompanyId?: string) => {
  const { data, ...rest } = useFetch<Driver[]>({
    enabled: (selectedCompanyId !== undefined && selectedCompanyId !== DEFAULT_SELECT_COMPANY),
    queryKey: [DRIVERS, selectedCompanyId],
    url: `api/driver?selectedCompanyId=${selectedCompanyId}`,
    cacheTime: 0,
    staleTime: 0,
  });

  return {
    data,
    ...rest
  };
};
