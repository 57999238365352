import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    height: 25vh;
    border-radius: 4px;
    object-fit: scale-down;
  }
  /* 
  @media (max-width: 500px) {
    img {
      width: 86px;
      height: 86px;
      margin-left: 20px;
    }
  }

  @media (max-width: 320px) {
    img {
      width: 78px;
      height: 78px;
      margin-left: 15px;
    }
  } */
`;
