import { Role } from "../models/Enum";

export const translateRole = (role: string) => {
  let label, color;

  switch (role) {
    case Role.ADMIN:
      label = 'Super Admin';
      color = 'red';
      break;
    case Role.KITCHEN:
      label = 'Cozinha';
      color = 'blue';
      break;
    case Role.DRIVER:
      label = 'Entregador/Estafeta';
      color = 'cyan';
      break;
    case Role.MANAGER:
      label = 'Gerente';
      color = 'orange';
      break;
    default:
      label = 'Lojista';
      color = 'green';
      break;
  }

  return { color, label };
};

/*
  - Super Admin is only possible via API by the system admin
*/
export const Roles = [
  {
    value: Role.KITCHEN,
    label: 'Cozinha',
  },
  {
    value: Role.DRIVER,
    label: 'Entregador/Estafeta',
  },
  {
    value: Role.MANAGER,
    label: 'Gerente',
  },
  {
    value: Role.USER,
    label: 'Lojista',
  },
];
