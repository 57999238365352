import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { WeekdaysList } from '../../utils/Constants';
import { CheckboxContainer } from './styles';

interface WeekdayListProps {
  selectedWeekdays: number[];
  handleSelectedWeekdays(days: number[]): void;
}

const ENTRY_LIST = [0, 1, 2, 3, 4, 5, 6];

const WeekdayList: React.FC<WeekdayListProps> = ({
  selectedWeekdays,
  handleSelectedWeekdays,
}) => {
  const [checkedList, setCheckedList] = useState<number[]>([]);
  
  useEffect(() => {
    if (selectedWeekdays.length === 0) {
      setCheckedList(ENTRY_LIST);
    } else {
      setCheckedList(selectedWeekdays);
    }
  }, [selectedWeekdays, setCheckedList]);

  useEffect(() => {
    handleSelectedWeekdays(checkedList.sort((a, b) => a - b));
  }, [checkedList, handleSelectedWeekdays]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checkedList.indexOf(value);
    const newChecked = [...checkedList];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedList(newChecked);
  };

  return (
    <CheckboxContainer>
      {ENTRY_LIST.map((value: number) => {
        return (
          <FormControlLabel
            onClick={handleToggle(value)}
            control={
              <Checkbox
                defaultChecked
                size="small"
                checked={checkedList.indexOf(value) !== -1}
              />
            }
            label={WeekdaysList[value].substring(0, 3)}
          />
        );
      })}
    </CheckboxContainer>
  );
};

export default WeekdayList;
