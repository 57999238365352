import React, { useMemo, useState } from 'react';
import { Form, FormInstance, Input } from 'antd';

import { sanitizePrice } from '../../../../../../utils/priceUtil';
import { PREFIX_LOCALSTORAGE } from '../../../../../../utils/Constants';
import { FormInputField } from '../../../../../../components/FormInputField';
import { FormRadioGroup } from '../../../../../../components/FormRadioGroup';

interface PriceAndPromotionProps {
  formRef: FormInstance<any>;
  hasPromotion: boolean;
  promotionType: boolean;
  setPromotionType(promotionType: boolean): void;
};

const promotionTypes = [
  { label: 'Diferença de Preços', value: true },
  { label: 'Desconto em %', value: false },
];

export const PriceAndPromotion = ({
  hasPromotion,
  promotionType,
  setPromotionType,
  formRef,
}: PriceAndPromotionProps) => {
  const promotion = formRef.getFieldValue('promotion');

  const [oldPrice, setOldPrice] = useState(promotion ? promotion.oldPrice : '');
  const [discountPercent, setDiscountPercent] = useState(
    promotion ? promotion.discountPercent : ''
  );

  const currency = useMemo(() => {
    // TODO: https://github.com/uiltonjose/qrcodepreferido-app/issues/52
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}:currency`)!!;
  }, []);

  const calculateNewPrice = () => {
    if (!discountPercent || !oldPrice) return;

    const price = sanitizePrice(oldPrice);

    const priceNumber = Number(price);
    const discount = Number(discountPercent) / 100;
    const newPrice = (priceNumber - priceNumber * discount)
      .toFixed(2)
      .replace('.', ',');

    formRef.setFieldsValue({
      promotion: {
        newPrice,
      },
    });
  }

  return hasPromotion ? (
    <div style={{ width: '100%', marginTop: '16px' }}>
      <FormRadioGroup
        defaultValue={promotionType}
        handleOnChange={(checked) => setPromotionType(checked)}
        label="Tipo de promoção"
        options={promotionTypes}
        propertyId="promotionType"
      />

      {promotionType ? (
        <>
          <FormInputField
            label="Antigo Preço"
            namePath={['promotion', 'oldPrice']}
            style={{ width: '58%' }}
            prefix={currency}
          />

          <FormInputField
            label="Novo Preço"
            namePath={['promotion', 'newPrice']}
            style={{ width: '58%' }}
            prefix={currency}
          />
        </>
      ) : (
        <>
          <Form.Item
            label={<label className="label-input-field">Preço</label>}
            name={['promotion', 'oldPrice']}
          >
            <Input
              style={{ width: '58%', height: '40px' }}
              onBlur={calculateNewPrice}
              onChange={(e) => setOldPrice(e.target.value)}
              addonBefore={currency}
            />
          </Form.Item>

          <Form.Item
            label={<label className="label-input-field">Porcentagem</label>}
            name={['promotion', 'discountPercent']}
          >
            <Input
              type="number"
              style={{ width: '58%', height: '40px' }}
              onBlur={calculateNewPrice}
              onChange={(e) => setDiscountPercent(e.target.value)}
              addonBefore="%"
            />
          </Form.Item>

          <Form.Item
            label={
              <label className="label-input-field">Preço com desconto</label>
            }
            name={['promotion', 'newPrice']}
          >
            <Input disabled style={{ width: '58%', height: '40px' }} addonBefore={currency} />
          </Form.Item>
        </>
      )}
    </div>
  ) : (
    <div style={{ marginTop: '16px' }}>
      <Form.Item
        label={<label className="label-input-field">Preço</label>}
        name="price"
      >
        <Input
          addonBefore={currency}
          placeholder="Ex: 10,00"
          style={{ width: '30%', height: '40px' }}
        />
      </Form.Item>
    </div>
  );
};
