import QRCode from 'qrcode';

export const generateQRInBase64 = async (data: string) => {
  if (data.includes('<CLIENT_SLUG>')) {
    return null;
  }

  try {
    return await QRCode.toDataURL(data);
  } catch (err) {
    throw err;
  }
};
