import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LoaderSpinner } from '../../../../../components/LoaderSpinner';
import api from '../../../../../services/api';
import { validateEmail } from '../../../../../utils/Util';

import {
  ErrorMessage,
  Field,
  Form,
  FormContent,
  FormLegend,
  SubmitButton,
  SubtitlePage,
  SuccessMessage,
} from '../../styles';

export const CustomerForm = () => {
  const [isFormLoading, setFormLoading] = useState(false);
  const [sentSuccessfully, setSentSuccessfully] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const firstInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    firstInput?.current?.focus();
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  }

  function validateFormInputs() {
    return (
      formData.email && formData.name && formData.phone && formData.message
    );
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!validateFormInputs()) {
      setErrorMessage('Todos os campos são obrigatórios');
      return;
    }

    if (!validateEmail(formData.email)) {
      setErrorMessage('Insira um email válido');
      return;
    }

    setFormLoading(true);
    setErrorMessage('');

    formData.message += ' [Sent via - Clientes link bio]';

    const response = await api.post('/api/contacts', formData);
    if (response.status === 200) {
      setSentSuccessfully(true);
      setFormData({
        ...formData,
        message: ''
      });
    } else {
      setErrorMessage('Erro ao tentar enviar os dados');
    }
    setFormLoading(false);
  }

  return (
    <Form>
      <FormContent>
        <FormLegend>
          <SubtitlePage>Falta pouco!</SubtitlePage>
          <small>
            Preencha o formulário abaixo, vamos entrar em contato o mais rápido
            possível, respeitando a ordem de chegada.
          </small>
        </FormLegend>

        <Field>
          <label htmlFor="name">Nome</label>
          <input
            type="text"
            id="name"
            name="name"
            ref={firstInput}
            onChange={handleInputChange}
          />
        </Field>

        <Field>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={handleInputChange}
          />
        </Field>
        <Field>
          <label htmlFor="phone">Telefone</label>
          <input
            type="text"
            id="phone"
            name="phone"
            onChange={handleInputChange}
          />
        </Field>

        <Field>
          <label htmlFor="name">Mensagem</label>
          <textarea
            rows={8}
            id="message"
            name="message"
            onChange={(e) =>
              setFormData({
                ...formData,
                message: e.target.value,
              })
            }
          />
        </Field>
      </FormContent>

      {sentSuccessfully && (
        <SuccessMessage>Dados enviados com sucesso!</SuccessMessage>
      )}
      <ErrorMessage>{errorMessage}</ErrorMessage>

      {isFormLoading ? (
        <LoaderSpinner isSmall />
      ) : (
        <SubmitButton type="submit" onClick={handleSubmit}>
          Enviar
        </SubmitButton>
      )}
    </Form>
  );
};
