import React from "react";
import { ErrorDialogContent, ErrorDialogWrapper } from "./styles";

interface ErrorDialogProps {
  title?: string;
  error: string;
  onClose: () => void;
}

export const ErrorDialog = ({ title, error, onClose }: ErrorDialogProps) => {
  return (
    <ErrorDialogWrapper>
      <ErrorDialogContent>
        {title && <h2>{title}</h2>}
        <p>{error}</p>
        <button onClick={onClose}>Ok</button>
      </ErrorDialogContent>
    </ErrorDialogWrapper>
  );
};