import React from 'react';
import { Modal } from 'reactstrap';

import './styles.css';

interface ModalHeadLessProps {
  bgModal?: string;
  body?: string;
  children?: React.ReactNode;
  dialogClassName?: string;
  isOpen: boolean;
  toggle?: () => void;
}

export const ModalHeadLess = ({
  bgModal,
  body,
  children,
  dialogClassName,
  isOpen,
  toggle,
}: ModalHeadLessProps) => {
  const bgColor = bgModal ? bgModal : 'green';
  return (
    <Modal
      className={'modal-dialog-centered modal-danger ' + dialogClassName}
      contentClassName={`bg-gradient-${bgColor}`}
      isOpen={isOpen || false}
      backdrop={'static'}
      toggle={toggle}
    >
      <div className="modal-body">
        {body && (
          <div className="py-3 text-center">
            <p>{body}</p>
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};
