import React from "react";
import { ContentHeader } from "../../../../../components/ContentHeader";
import { ActionType } from "../../../../../models/Enum";
import { User } from "../../../../../models/User";
import { CreateOrUpdateUser } from "./components/CreateOrUpdateUser";
import { ViewUser } from "./components/View";

interface UserEditorProps {
  action: ActionType;
  onClosePage(): void;
  user?: User;
}

export const UserEditor = ({ action, onClosePage, user }: UserEditorProps) => {
  const getTitle = () => {
    if (action === ActionType.ADD) {
      return 'Novo usuário';
    } else if (action === ActionType.UPDATE) {
      return 'Atualizar usuário';
    }
    return 'Dados do usuário';
  }

  return (
    <>
      <ContentHeader title={getTitle()} />

      {action === ActionType.OPEN && user ?
        (<ViewUser onClosePage={onClosePage} user={user} />) :
        (<CreateOrUpdateUser onClosePage={onClosePage} user={user} />)}
    </>
  );
}