import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  background-color: #f2fef7;
  background-image: url('https://res.cloudinary.com/qrcode-preferido/image/upload/v1645651025/qrcode-logo/login-bg_aotbpi.svg');
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
`;

export const Content = styled.div`
  background: white;
  width: 550px;
  padding: 50px 50px;

  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

  > img {
    height: 140px;
    margin: 0 auto 10px;
    display: block;

    @media (max-width: 500px) {
      height: 120px;
      margin-top: 10px;
    }

    @media (max-width: 320px) {
      height: 90px;
      margin-top: 20px;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    max-width: 550px;
  }

  @media (max-width: 375px) {
    margin-top: 10%;
    padding: 50px 20px;
  }

  @media (max-width: 320px) {
    margin-top: 20%;
  }
`;
