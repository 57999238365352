import axios from 'axios';
import { PREFIX_LOCALSTORAGE } from '../utils/Constants';
import { baseURL } from './endpoints';

const api = axios.create({ baseURL });

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem(`${PREFIX_LOCALSTORAGE}token`);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default api;
