import styled from 'styled-components';

interface ProductComplementItemOptionProps {
  showTitle: boolean;
}

export const ProductTitle = styled.h3`
  text-align: left;
`;

export const ProductComplementItemOption = styled.li<ProductComplementItemOptionProps>`
  margin-left: ${(props) => (props.showTitle ? '20px' : '0')};
`;

export const StatusContainer = styled.div`
  display: flex;
`;

export const UpdateButton = styled.button`
  width: 110px;
  height: 32px;
  background: #2fb86e;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  border: 0;
  margin-left: 10px;
  transition: background-color 0.2s;
  cursor: pointer;

  :hover {
    background: #678f8e;
  }
`;
